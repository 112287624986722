import React, { useEffect, useState } from "react";
import { PmsGridSort } from "../../Util/PmsHelper";
import MaterialDataGrid from "../Common/MaterialDataGrid";

export default function EdenWbWriteBackSetting({ WriteBackPreferences }) {
  const [rowData, setRowData] = useState([]);
  const tableColumn = [
    {
      field: "patientMatchOnFields",
      headerName: "PatientMatchOnFields",
      width: 230,
      sortComparator: PmsGridSort,
    },
    {
      field: "doWSApptWritebackForValidatedPatient",
      headerName: "DoWSApptWritebackForValidatedPatient",
      type: "boolean",
      width: 360,
      sortComparator: PmsGridSort,
    },
    {
      field: "useExistingPlaceholderPatient",
      headerName: "UseExistingPlaceholderPatient",
      type: "boolean",
      width: 290,
      sortComparator: PmsGridSort,
    },

    {
      field: "createdDate",
      headerName: "CreatedDate",
      width: 200,
      sortComparator: PmsGridSort,
    },
    {
      field: "lastModifiedDate",
      headerName: "LastModifiedDate",
      width: 200,
      sortComparator: PmsGridSort,
    },
  ];

  useEffect(() => {
    if (WriteBackPreferences === undefined || WriteBackPreferences === null)
      return;
    setRowData(WriteBackPreferences);
  }, [WriteBackPreferences]);

  return (
    <>
      <label>Web Scheduled Appointments Setting</label>
      <MaterialDataGrid
        tableHeight={200}
        tableColumns={tableColumn}
        tableData={rowData}
        onSelectedRow={undefined}
      />
    </>
  );
}
