/**
 * Represents a collection of enum values.
 */
const PmsEnums = {
  ShadowConfigDisplay: {
    get LANDING() {
      return 1;
    },

    get MODAL() {
      return 0;
    },

    get PROFILE() {
      return 5;
    },
  },
  BulkDisplay: {
    get SHADOWTEAMS(){
      return 1;
    },
    get COVERKEY(){
      return 2;
    }
  }
};

// Lock object to prevent modification (true static).
Object.freeze(PmsEnums);

export default PmsEnums;
