import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment/moment';


export default function RowDetailsVisit(props) {
    const data = props.row
  
    return (
      <React.Fragment>
          <Box margin={1}>
  
          <Typography variant="h6" gutterBottom component="div">
            </Typography>
            <Table size="small" aria-label="details">
            <TableHead>
                <TableRow>
                  <TableCell>CoverKey</TableCell>                  
                  <TableCell>VwVisitID</TableCell>
                  <TableCell>PmsVisitID</TableCell>
                  <TableCell>VwPatientID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.coverKey}</TableCell>                    
                    <TableCell>{data.vwVisitID}</TableCell>
                    <TableCell>{data.pmsVisitID}</TableCell>
                    <TableCell>{data.vwPatientID}</TableCell>                  
                  </TableRow>                     
              </TableBody>
            <TableHead>
                <TableRow>
                  <TableCell>VwLocationID</TableCell>                  
                  <TableCell>VwProviderID</TableCell>
                  <TableCell>PmsAppointmentID</TableCell>
                  <TableCell>VisitDate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.vwLocationID}</TableCell>                    
                    <TableCell>{data.vwProviderID}</TableCell>
                    <TableCell>{data.pmsAppointmentID}</TableCell>
                    <TableCell>{data.visitDate}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>VisitType</TableCell>                  
                  <TableCell>VisitReason</TableCell>
                  <TableCell>ShowedUp</TableCell>
                  <TableCell>ICD1</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.visitType}</TableCell>                    
                    <TableCell>{data.visitReason}</TableCell>
                    <TableCell>{data.showedUp ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{data.icD1}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>ICD2</TableCell>                  
                  <TableCell>ICD3</TableCell>
                  <TableCell>CPT1</TableCell>
                  <TableCell>CPT2</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.icD2}</TableCell>                    
                    <TableCell>{data.icD3}</TableCell>
                    <TableCell>{data.cpT1}</TableCell>    
                    <TableCell>{data.cpT2}</TableCell>             
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>CPT3</TableCell>                  
                  <TableCell>ProductType</TableCell>
                  <TableCell>ProductBrand1</TableCell>
                  <TableCell>ProductBrand2</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.cpT3}</TableCell>                    
                    <TableCell>{data.productType}</TableCell>
                    <TableCell>{data.productBrand1}</TableCell>    
                    <TableCell>{data.productBrand2}</TableCell>             
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>ProductBrand3</TableCell>                  
                  <TableCell>SpendAmt</TableCell>
                  <TableCell>PmsCreatedDate</TableCell>
                  <TableCell>PmsLastModifiedDate</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.productBrand3}</TableCell>                    
                    <TableCell>{data.spendAmt}</TableCell>
                    <TableCell>{data.pmsCreatedDate}</TableCell>    
                    <TableCell>{data.pmsLastModifiedDate}</TableCell>             
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>Deleted</TableCell>                  
                  <TableCell>CreatedDate</TableCell>
                  <TableCell>LastModifiedDate</TableCell>
                  <TableCell></TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.deleted ? 'Yes' : 'No'}</TableCell>                    
                    <TableCell>{data.createdDate}</TableCell>
                    <TableCell>{data.lastModifiedDate}</TableCell>    
                    <TableCell></TableCell>             
                  </TableRow>                     
              </TableBody>
            </Table>                     
            <br/>
          </Box>      
       </React.Fragment>
    );   

}
