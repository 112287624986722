import React from "react";
import MaterialCheckBox from "../Components/Common/MaterialCheckBox";

export default function HfJobTwo({ setValue, mockData }) {
  return (
    <div>
      <div className="shadow-bulk__container">
        <span className="pms-ws_subTitle hf">Clear Orphaned Shadow Data</span>
        <span className="pms-ws_description hf">
          Job runs on a specified schedule to remove all data in one or more
          shadow databases for a particular cover provided that one of the
          following two conditions is satisfied: (i) For covers with a blade
          directive of None, if the most recent LastModifiedDate in any table is
          older than six months ago. (ii) For (active) covers with a blade
          directive not set to None or Clear, and in a shadow database other
          than the actively assigned one, if the most recent LastModifiedDate in
          any table is older than six months ago.
        </span>

        <MaterialCheckBox
          onChange={(e) => setValue(e, true)}
          value={mockData.Job02EnableExecution || false}
          name="Job02EnableExecution"
          label="Enable execution?"
        />
      </div>
    </div>
  );
}
