import React from "react";
import { Grid } from "@material-ui/core";
import TextBoxWithValidator from "../Components/Common/TextBoxWithValidator";
import MaterialCheckBox from "./../Components/Common/MaterialCheckBox";
import MaterialTransferList from "../Components/Common/MaterialTransferList";

export default function HfJobFive({ setValue, mockData, setSelectedCovers }) {
  return (
    <div>
      <div className="shadow-bulk__container">
        <span className="pms-ws_subTitle hf">Clear Orphaned Shadow Data</span>
        <span className="pms-ws_description hf">
          Job runs on a specified schedule to remove all data in one or more
          shadow databases for a particular cover provided that one of the
          following two conditions is satisfied: (i) For covers with a blade
          directive of None, if the most recent LastModifiedDate in any table is
          older than six months ago. (ii) For (active) covers with a blade
          directive not set to None or Clear, and in a shadow database other
          than the actively assigned one, if the most recent LastModifiedDate in
          any table is older than six months ago.
        </span>

        <MaterialCheckBox
          onChange={(e) => setValue(e, true)}
          value={mockData.Job05EnableExecution || false}
          name="Job05EnableExecution"
          label="Enable execution?"
        />
        <Grid
          container
          alignItems="center"
          spacing={1}
          style={{ display: "flex", alignItems: "baseline" }}
        >
          <Grid item sm={6}>
            <div className="actionButton__container">
              <TextBoxWithValidator
                label="Threshold percentage"
                fullWidth={true}
                value={mockData.job05AvSlotThresholdPercentage || ""}
                onInput={(e) => setValue(e)}
                name="job05AvSlotThresholdPercentage"
                id="job05AvSlotThresholdPercentage"
              />
            </div>
          </Grid>
          <Grid item sm={6}>
            <div className="actionButton__container hf">
              <TextBoxWithValidator
                label="Days to Keep Records"
                fullWidth={true}
                value={mockData.job05AvSlotDaysRecord || ""}
                onInput={(e) => setValue(e)}
                name="job05AvSlotDaysRecord"
                id="job05AvSlotDaysRecord"
              />
            </div>
          </Grid>
          <Grid item sm={12} className="pms_jobfiveTransferList">
            <MaterialTransferList
              mockData={mockData}
              setSelectedCovers={setSelectedCovers}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
