import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import * as React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function MaterialTransferList({
  mockData,
  setSelectedCovers: setSelectedCoversInMockData,
}) {
  const [checked, setChecked] = React.useState([]);
  const [unselectedCoverFilter, setUnselectedCoverFilter] = React.useState([]);
  const [selectedCoverFilter, setSelectedCoverFilter] = React.useState([]);

  const [unselectedCover, setUnselectedCover] = React.useState([]);
  const [selectedCover, setSelectedCover] = React.useState([]);

  React.useEffect(() => {
    const unselected = mockData.listCoverKeys
      .filter((item) => !mockData.Job05Covers.includes(item.coverKey))
      .map((item) => parseInt(item.coverKey));

    const selected = mockData.listCoverKeys
      .filter((item) => mockData.Job05Covers.includes(item.coverKey))
      .map((item) => parseInt(item.coverKey));

    setUnselectedCoverFilter(unselected);
    setSelectedCoverFilter(selected);
  }, [mockData]);

  const leftChecked = intersection(checked, unselectedCoverFilter);
  const rightChecked = intersection(checked, selectedCoverFilter);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  //from left to right.
  const handleCheckedRight = () => {
    var data = unselectedCover.filter((item) => !leftChecked.includes(item));
    setUnselectedCover(data);

    //used to set the selected covers to be updated in the database.
    //we concat it with the existing selected covers by adding the covers checked in left.
    setSelectedCoversInMockData(selectedCoverFilter.concat(leftChecked));

    setSelectedCoverFilter(selectedCoverFilter.concat(leftChecked));
    setUnselectedCoverFilter(not(unselectedCoverFilter, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  //from right to left.
  const handleCheckedLeft = () => {
    //this variable will contains a data if there is a selected cover from the dropdown.
    var data = selectedCover.filter((item) => !rightChecked.includes(item));
    setSelectedCover(data);

    //used to set the selected covers to be updated in the database.
    //we use this condition... if there is a selected cover on the autodropdown, we use the other variable when filtering out the datas
    if (data.length == 0)
      setSelectedCoversInMockData(not(selectedCoverFilter, rightChecked));
    else setSelectedCoversInMockData(not(selectedCover, rightChecked));

    setUnselectedCoverFilter(unselectedCoverFilter.concat(rightChecked));
    setSelectedCoverFilter(not(selectedCoverFilter, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const selected = "SELECTED";
  const unselected = "UNSELECTED";
  const OnAutoCompleteChange = (value, action) => {
    if (action == selected) {
      if (value == null) {
        setSelectedCoverFilter(selectedCover);
        return;
      }

      var data = selectedCoverFilter.filter((n) => n == value);
      setSelectedCover(selectedCoverFilter);
      setSelectedCoverFilter(data);
      return;
    }
    if (action == unselected) {
      if (value == null) {
        setUnselectedCoverFilter(unselectedCover);
        return;
      }
      var data = unselectedCoverFilter.filter((n) => n == value);
      setUnselectedCover(unselectedCoverFilter);
      setUnselectedCoverFilter(data);
      return;
    }
  };

  const customList = (title, items, action) => (
    <Card className="pms_cardMasterTransfer">
      <CardHeader
        className="pms_cardHeaderTransfer"
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            className="pms_cardHeaderTransferCheckBox"
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        subheader={
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={items}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label={title} />}
            getOptionLabel={(option) => option.toString()}
            onChange={(e, value) => OnAutoCompleteChange(value, action)}
          />
        }
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 50,
          bgcolor: "background.paper",
          overflowY: "scroll",
        }}
        dense
        className="pms_cardHeaderTransferList"
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
              className="pms_transferListItem"
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                  className="pms_transferListItemCheckBox"
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item className="pms_transferListColumns">
        {customList(
          "Do not run job for these covers",
          unselectedCoverFilter,
          unselected
        )}
      </Grid>
      <Grid item className="pms_transferList">
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item className="pms_transferListColumns">
        {customList("Run job for these covers", selectedCoverFilter, selected)}
      </Grid>
    </Grid>
  );
}
