import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Box, Button, Paper, CircularProgress } from '@mui/material';
import { Delete, CheckCircleOutline } from '@material-ui/icons';
import { useV5 } from '../../ContextLib/CoreConsumer/v5Contexts';
import ConfirmationModal from './Components/ConfirmationModal';
import SnackBarHelper from '../PmsConfig/Components/Helper/SnackBarHelper';

const LocatorIntegration = ({ mappings, getMappings }) => {
  const { f } = useV5();
  const [covers, setCovers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isFetchingPracticeDetails, setIsFetchingPracticeDetails] = useState(false);
  const [currentMap, setCurrentMap] = useState(null);
  const [selectedCoverLoc, setSelectedCoverLoc] = useState(null);
  const [locatorSearchResults, setLocatorSearchResults] = useState([]);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');


  /**
   * Gets list of locations by the given coverKey
   * @param {number} coverKey 
   */
  const getLocationsByCover = async (coverKey) => {
    const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getLocationsByCover`;

    await axios.get(url, {
        params: {
          secretCode: `${process.env.REACT_APP_API_KEY}`,
          queryType: "coverKey",
          queryString: coverKey
        }
      })
      .then(({ data, status }) => {
        if (status === 200) {
          setCovers(data);
        }
      });
  };

  /**
   * Searches Locator ECPs with matching zip code
   * @param {string | number} zipCode 
   */
  const searchPracticesByZip = async (zipCode) => {
    const url = `${process.env.REACT_APP_LOCATOR_INTEGRATION_API}/SearchLocator`;

    await axios.get(url, {
        params: {
          postalCode: zipCode
        }
      })
      .then(({ data, status }) => {
        if (status === 200) {
          setLocatorSearchResults(data);
        }
        setIsLoading(false);
      });
  };

  /**
   * Gets ECP details
   * @param {string} identifier 
   */
  const fetchPracticeDetails = async (identifier) => {
    const url = `${process.env.REACT_APP_LOCATOR_INTEGRATION_API}/EcpDetails`;

    await axios.get(url, {
        params: {
          practiceIdentifier: identifier
        }
      })
      .then(({ data, status }) => {
        if (status === 200) {
          setCurrentMap(data);
          getMappings();
        }
        setIsFetchingPracticeDetails(false);
      });
  };

  /**
   * Resets to initial value
   */
  const resetValues = () => {
    if (locatorSearchResults.length > 0) {
      setLocatorSearchResults([]);
    }
    
    if (selectedCoverLoc) {
      setSelectedCoverLoc(null);
    }

    if (currentMap) {
      setCurrentMap(null);
    }
  };

  useEffect(() => {
    getLocationsByCover(f.coverKey);
    resetValues();
  }, [f.coverKey]);

  useEffect(() => {
    if (covers.length > 0) {
      const coverLocMatch = covers.find(cover => cover.locKey === f.locKey);

      if (coverLocMatch) {
        setSelectedCoverLoc(coverLocMatch);
      }

      if (f.coverKey && f.locKey) {
        const locatorMapping = mappings.find(({ coverKey, locKey }) => coverKey === f.coverKey && locKey === f.locKey);

        if (locatorMapping && locatorMapping.practiceIdentifier) {
          setIsFetchingPracticeDetails(true);
          fetchPracticeDetails(locatorMapping.practiceIdentifier);
        }
      }
    }

    resetValues();
  }, [f.locKey]);

  useEffect(() => {
    if (selectedCoverLoc && selectedCoverLoc.zip) {
      setIsLoading(true);
      searchPracticesByZip(selectedCoverLoc.zip);
    }
  }, [selectedCoverLoc]);

  /**
   * Displays a snackbar with a custom message
   * @param {string} severity 
   * @param {string} message 
   */
  const displaySnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setShowSnackbar(true);
  };

  /**
   * Assigns Locator ECP to the selected Cover & Location
   * @param {*} practice 
   */
  const handleAssign = async (practice) => {
    const url = `${process.env.REACT_APP_LOCATOR_INTEGRATION_API}/PracticeBookingLink`;

    await axios.put(url, {
        locKey: f.locKey,
        coverKey: f.coverKey,
        tenantKey: 'wky6dgyr6a',
        practiceIdentifier: practice.identifier
      })
      .then(({ data, status }) => {
        if (status === 200 && data.success) {
          // setCurrentMap(practice);
          displaySnackbar('success', 'Successfully assigned Locator ECP to the selected cover and location')
          fetchPracticeDetails(practice.identifier);
        } else {
          displaySnackbar('error', 'Failed to assign Locator ECP to the selected cover and location');
        }
      });
  };

  /**
   * Gets the complete address from the address components
   * @param {*} addressComponents 
   * @returns complete address
   */
  const getCompleteAddress = (addressComponents) => {
    const filteredComponents = addressComponents.filter(component => !!component);

    if (filteredComponents.length === 0) {
      return null;
    }
    
    return filteredComponents.reduce((combinedAddress, currentComponent) => `${combinedAddress}, ${currentComponent}`);
  };

  const renderSearchResults = () => {
    if (!selectedCoverLoc) {
      return null;
    }

    if (locatorSearchResults.length > 0) {
      return locatorSearchResults.map((practice, index) =>
        <PracticeCardLocator
          key={index}
          practice={practice}
          action="assign"
        />
      );
    }
    
    return <Typography textAlign="center" lineHeight={2} variant="body2">
      No ECP in Locator matches zip code <strong>{ selectedCoverLoc.zip ?? '<null>' }</strong>. <br />
      Please ensure that this ECP is qualified to be in the Locator. <br />
      Please reach out to EssilorUSA support for questions.
    </Typography>;
  }

  /**
   * Deletes the current assignment
   */
  const handleDeleteMapping = async () => {
    const url = `${process.env.REACT_APP_LOCATOR_INTEGRATION_API}/PracticeCoverMapping`;

    await axios.delete(url, {
        params: {
          locKey: f.locKey,
          coverKey: f.coverKey
        }
      })
      .then(({ status }) => {
        if (status === 200) {
          setCurrentMap(null);
          setShowConfirmationModal(false);
          displaySnackbar('success', 'Successfully unassigned current Locator ECP');
        } else {
          displaySnackbar('error', 'Failed to unassign current Locator ECP');
        }
      });
  };

  const PracticeCard4PC = ({ practice }) => {
    const { orgName, address1, address2, city, state, zip } = practice;
    const addressComponents = [
      address1,
      address2,
      city,
      state,
      zip
    ];
    const completeAddress = getCompleteAddress(addressComponents);

    return (
      <Paper sx={{ p: 3, my: 3, height: '100px', width: '100%' }} elevation={2}>
        <Typography fontSize={17} color="#3f51b5" fontWeight={500}>{ orgName }</Typography>
        <Typography
          fontSize={14}
          variant="body2"
          {...(completeAddress ? {} : { fontStyle: 'italic' })} >
          { completeAddress ?? 'empty address' }
        </Typography>
      </Paper>
    );
  };

  const LocatorSearchResults = () => (
    <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="column" width="100%">
      <Typography variant="overline" sx={{ marginY: 6 }} fontSize={15}>
        { f.locKey ? 'No existing assignment' : 'Please choose a single location' }
      </Typography>
      <Box display="inherit" justifyContent="center" flexDirection="inherit" width="inherit" >
        { renderSearchResults() }
      </Box>
    </Box>
  );

  const PracticeCardLocator = ({ practice, action }) => {
    const { identifier, name, address1, address2, city, region, country, postalCode, position } = practice;
    const addressComponents = [
      address1,
      address2,
      city,
      region,
      country,
      postalCode
    ];

    const coordinates = `${position.lat}, ${position.lng}`;
    const completeAddress = getCompleteAddress(addressComponents);
    const linkToDetailsPage = `${process.env.REACT_APP_LOCATOR_URL}/#/practice/${identifier}?lang=en-us&skipRedirect=${process.env.REACT_APP_LOCATOR_SKIP_REDIRECT}`;

    return (
      <Paper sx={{ marginY: 2, padding: 3, display: 'flex', justifyContent: 'space-between' }} elevation={2}>
        <Box>
          <Typography fontSize={17} color="#3f51b5" fontWeight={500}>{ name }</Typography>
          <a target="_blank" href={linkToDetailsPage}>
            <Typography fontSize={15} variant="overline">{ identifier }</Typography>
          </a>
          <Typography
            fontSize={14}
            variant="body2"
            {...(completeAddress ? {} : { fontStyle: 'italic' })} >
            { completeAddress ?? 'empty address' }
          </Typography>
          <Typography fontSize={12} variant="caption">{ coordinates }</Typography>
        </Box>
        <Box display="inherit" justifyContent="inherit" alignItems="center">
        {
          action === 'unassign' &&
          <Button
            variant="contained"
            color="error"
            sx={{ textTransform: 'capitalize' }}
            startIcon={<Delete />}
            onClick={() => setShowConfirmationModal(true)}
          >
            { action }
          </Button>
        }
        {
          action === 'assign' &&
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: 'capitalize' }}
            startIcon={<CheckCircleOutline />}
            onClick={() => handleAssign(practice)}
          >
            { action }
          </Button>
        }
        </Box>
      </Paper>
    );
  };

  const SectionContainer = ({ title, children }) => (
    <Box width="100%" minHeight="20vh">
      <Typography variant="h6">{ title }</Typography>
      { children }
    </Box>
  );

  const LocatorSection = () => {
    return currentMap
      ? <PracticeCardLocator practice={currentMap} action="unassign" />
      : (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight={350}>
          { isLoading || isFetchingPracticeDetails ? <CircularProgress /> : <LocatorSearchResults /> }
        </Box>
      );
  };

  const FourPCSection = () => (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%">
      {
        selectedCoverLoc
          ? <PracticeCard4PC practice={selectedCoverLoc} />
          : <Typography variant="overline" sx={{ marginY: 6 }} fontSize={15}> No match found </Typography>
      }
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" rowGap={3} padding={3}>
      {
        showConfirmationModal &&
        <ConfirmationModal
          title="Unassign Locator ECP"
          content="Are you sure you want to unassign this Locator ECP to the selected cover and location?"
          isOpen={showConfirmationModal}
          btnCloseText="Cancel"
          btnContinueText="Yes, proceed"
          onClose={() => setShowConfirmationModal(false)}
          onContinue={handleDeleteMapping}
        />
      }
      <Typography>The Locator will show a WebSchedule link when the Cover & Location is assigned to the Locator ECP</Typography>
      <SectionContainer title="Selected Cover & Location">
        <FourPCSection />
      </SectionContainer>
      <SectionContainer title="Assigned Locator ECP">
        <LocatorSection />
      </SectionContainer>
      <SnackBarHelper
        openSnackBar={showSnackbar}
        setOpenSnackBar={setShowSnackbar}
        Severity={snackbarSeverity}
        AlertMessage={snackbarMessage}
      />
    </Box>
  );
}

export default LocatorIntegration;
