import React, { useState, useEffect } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import TimerIcon from '@material-ui/icons/Timer';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import "./InsuranceTypes.scss";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Request from '../../ContextLib/CoreConsumer/dataRequest';
import InsuranceList from "./insuranceList.json";
import { useFiltersContext, useUIContext } from '../../ContextLib/contextHooks';
import { Severity } from '../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useV5 } from '../../ContextLib/CoreConsumer/v5Contexts';

export default function InsuranceTypeEntry(props) {
    let componentId = "insuranceTypeEntry";
    const ui = useUIContext(componentId);

    const {f} = useV5();

    const [insName, setInsName] = useState("NO_SELECTION");
    const [daysBlocked, setDaysBlocked] = useState(0);
    const [orderKey, setOrderKey] = useState(1);
    const [showDisplayOrder, setShowDisplayOrder] = useState(false);

    const saveInsuranceData = async () => {
        let errors = [];
        if (insName == "NO_SELECTION") {
            errors.push("Please select Insurance Type");
        }
        
        if (isNaN(daysBlocked)) {
            errors.push("Invalid Pre-Processing Time entry");
        }

        if (errors.length == 1) {
            ui.ErrorBox(errors[0], "Save Insurance Data");
            return;
        } else if (errors.length > 1) {
            ui.ErrorBox(<ul>{errors.map(x => <li>{x}</li>)}</ul>, "Save Insurance Data");
            return;
        }

        const model = { 
            coverKey: f.coverKey, 
            locKey: f.locKey, 
            acctKey: f.acctKey, 
            insName: insName, 
            daysBlocked: daysBlocked,
            orderKey: orderKey 
        };
          
        props.saveInsuranceData(model, ()=> {
            setInsName("NO_SELECTION");
            setDaysBlocked(0);
        });
    };

    const useStyles = makeStyles((theme) => ({
        margin: {
            margin: theme.spacing(1),
        },
        root: {
            padding: 5,
            fontWeight: 600,
        },
        addinstype: {
            fontSize: "16px",
            fontWeight: "bold"
        }
    }));

    const handleOnInsNameChange = (value) => {
        let _insName = value.trim();
        setInsName(_insName);
        handleToggleDisplayOrder(_insName.toLowerCase());
    }

    const handleToggleDisplayOrder = (strInsName) => {
        if (strInsName === 'no insurance' || strInsName === 'other insurance') {
            setShowDisplayOrder(true);
            setOrderKey(1);
        } else {
            setShowDisplayOrder(false);
            setOrderKey(0);
        }
    }

    const classes = useStyles();

  return (
    <Box p={2}>
         <div className={classes.addinstype}>Add Insurance Type:</div>
                <ValidatorForm onSubmit={saveInsuranceData} onError={errors => console.log(errors)}>
                    <Grid container justify="flex-start" alignItems="center" spacing={2} id="grid-1">
                        <Grid item style={{ width: 250 }}>
                           {/*  <Select
                                id="insuranceType"
                                value={insName}
                                onChange={e => setInsName(e.target.value)}
                                variant="outlined"
                                startAdornment={<InputAdornment className={classes.margin}>
                                    <Icon><ListAltSharpIcon /></Icon>
                                </InputAdornment>}
                            >
                                <MenuItem value={"NO_SELECTION"} key={0}>Select Insurance Type</MenuItem>
                                {InsuranceList.map((x, i) =>
                                        <MenuItem value={x} key={i + 1}>{x}</MenuItem>
                                    )}
                            </Select> */}
                            <Autocomplete
                                        freeSolo
                                        id="insuranceType"
                                        disableClearable
                                        options={InsuranceList.map((option) => option)}
                                        onInputChange={(event, newValue) => {
                                            handleOnInsNameChange(newValue);
                                        }}
                                        renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            placeholder="Insurance Type"
                                            variant="outlined"
                                            validators={['required']}
                                            value={insName}
                                            onInput={(e)=>setInsName(e.target.value)} 
                                            errorMessages={['Insurance Type is required']}  
                                            InputProps={{ ...params.InputProps, type: 'search'}}
                                            inputProps={{ ...params.inputProps, maxLength: 50 }}
                                        />
                                        )}
                                    />
                        </Grid>

                        <Grid item>
                            <TextField
                                id="daysBlocked"
                                value={daysBlocked}
                                onChange={e => setDaysBlocked(parseInt(e.target.value))}
                                label="Pre-Processing Time (days)"
                                type="number"
                                validators={['required']}
                                errorMessages={['Appointment Type is required']}  
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <TimerIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"

                            />
                        </Grid>
                        { showDisplayOrder && 
                            <Grid item>
                                <FormControl>
                                    <InputLabel id="display-order-label">Display Order</InputLabel>
                                    <Select
                                        labelId="display-order-label"
                                        label="Display Order"
                                        id="displayOrder"
                                        variant="outlined"
                                        value={orderKey}
                                        onChange={ e=>setOrderKey(e.target.value)}  
                                        >
                                        <MenuItem value={1}>Display first</MenuItem>
                                        <MenuItem value={-1}>Display last</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        
                        <Grid item >
                            <Button variant="contained" color="primary" type="submit" style={{ paddingLeft: 60, paddingRight: 60 }}>
                                ADD
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Box>
  );
}