/**
 * Represents a collection of strongly typed constants.
 */
const Constants = {
    /**
     * Represents x-crm-apiauth-key.
     */
    get AuthKey() {
        return 'a3f4979a73d04dfd98fb212ff4768654';
    },

    /**
     * Represents the current onboarding info key.
     */
    get currOnboardingInfoKey() {
        return 'coi';
    },

    /**
     * Represents the current collection of resources.
     */
    get currResources() {
        return 'res';
    },

    /**
     * Represents the current user key.
     */
    get currUserKey() {
        return 'cusr';
    },

    /**
     * Represents the current location id key
     */
    get currLocIdKey() {
        return 'clid';
    },

    /**
     * Represents the current selected queued appointment from the mobile queue.
     */
    get currSelectedQueueAppt() {
        return 'csqa';
    },

    /**
     * Represents the siteConfig
     */
    get siteConfig() {
        return 'siteConfig';
    },

    /**
     * Represents the language tag from the location
     */
    get langTag() {
        return 'langTag';
    },

    /**
     * Represents the support info key.
     */
    get supportInfoKey() {
        return 'si';
    },

    get signalRLocationConfigMessage() {
        return 'LocationUpdatedEvent';
    },

    /**
     * Represents the resource filter change event
     */
    get resourceFilterUpdatedEmitter() {
        return 'resourceFilterUpdated';
    },

    /**
     * Represents the default display name for the default resource
     */
    get defaultResourceDisplayName() {
        return 'Appointments';
    },

    /**
     * Represents the Spanish language tag.
     */
    get spanishLanguageTag() {
        return 'es';
    },

    /**
     * Represents the Portuguese language tag.
     */
    get portugueseLanguageTag() {
        return 'pt';
    },

    /**
   * Gets the turkey language tag.
   */
    get turkeyLanguageTag() {
        return 'tr-TR';
    },

    /**
     * Represents strongly typed date formats.
     */
    DateFormats: {
        /**
         * Represents the notification page date format.
         */
        get notificationAppointmentDateFormat() {
            return 'ddd MMM D - LT';
        }
    },

    /**
     * Represents strongly typed event names.
     */
    Events: {
        /**
         * Represents an updated appointment event.
         */
        get appointmentUpdated() {
            return 'appointment:updated';
        },

        /**
         * Represents a deleted appointment event.
         */
        get deleteAppointment() {
            return 'appointment:delete';
        },

        /**
         * Represents a new appointment event.
         */
        get newAppointment() {
            return 'appointment:new';
        },

        /**
         * Represents a new notification event.
         */
        get newNotification() {
            return 'notification:new';
        },

        /**
         * Represents the resource change event.
         */
        get resourceChange() {
            return 'resource:change';
        },

        /**
         * Represents the resources change event.
         */
        get resourcesUpdated() {
            return 'resources:updated';
        },

        /**
         * Represents a scheduled appointment event.
         */
        get scheduleAppointment() {
            return 'appointment:schedule'
        },

        /**
         * Represents the logout event.
         */
        get onLogout() {
            return 'user:logout';
        },

        /**
         * Represents the location changed event.
         */
        get locationChanged() {
            return 'locationconfig:changed';
        },

        /**
         * Represents the location configuration update event.
         */
        get locationConfigUpdate() {
            return 'locationconfig:update';
        },

        /**
         * Represents the toggle add appointment event.
         */
        get onToggleAddAppointment() {
            return 'appointment:add';
        },

        /**
         * Represents the toggle admin settings event.
         */
        get onToggleAdminSettings() {
            return 'admin:settings'
        },

        /**
         * Represents the toggle calendar pane event.
         */
        get onToggleCalendarPane() {
            return 'calendar:pane';
        },

        /**
         * Represents system message reset event
         */
        get onResetSystemMessageCount() {
            return 'systemmessage:reset'
        },

        /**
         * Represents the toggle edit resource system message event.
         */
        get onToggleEditResourceSystemMessage() {
            return 'resources:edit:systemmessage';
        },

        /**
         * Represents the toggle edit service types system message.
         */
        get onToggleEditServiceTypesSystemMessage() {
            return 'servicetypes:edit:systemmessage';
        },

        /**
         * Represents the toggle edit store hours system message.
         */
        get onToggleEditStoreHoursSystemMessage() {
            return 'storehourse:edit:systemmessage';
        },

        /**
         * Represents the read all notifications event.
         */
        get readAllNotifications() {
            return 'notification:readall';
        },

        /**
         * Represents the unread notifications event.
         */
        get unreadNotifications() {
            return 'notification:unread';
        },

        /**
         * Represents a temp password login event
         */
        get tempLogin() {
            return 'login:tempPassword';
        },
        /**
         * Represents grid Data
         */
        get gridData() {
            return 'grid:Data';
        }

    },

    /**
     * Represents strongly typed signalR messages.
     */
    SignalRMessages: {
        /**
         * Represents an updated appointment event.
         */
        get appointmentUpdated() {
            return 'AppointmentUpdate'
        },

        /**
         * Represents a location updated event.
         */
        get locationUpdatedEvent() {
            return 'LocationUpdatedEvent'
        },

        /**
         * Represents a new notification message.
         */
        get newNotification() {
            return 'NewNotification';
        }
    },

    /**
     * Represents strongly typed routes.
     */
    Routes: {
        /**
         * Represents the onboarding route.
         */
        get onboarding() {
            return '/onboarding';
        },

        /**
         * Represents the English onboarding route.
         */
        get onboardingEnglish() {
            return '/welcome';
        },

        /**
         * Represents the Spanish onboarding route.
         */
        get onboardingSpanish() {
            return '/bienvenidos';
        },

        /**
         * Represents the Portuguese onboarding route.
         */
        get onboardingPortuguese() {
            return '/bem-vinda';
        },

        /**
         * Represents the Turkish onboarding route.
         */
        get onboardingTurkish() {
            return '/Hoşgeldiniz';
        },

        /**
         * Represents the notifications route.
         */
        get notifications() {
            return '/notifications';
        },

        /**
         * Represents the schedule route.
         */
        get schedule() {
            return '/schedule';
        },

        /**
         * Represents the settings route.
         */
        get settings() {
            return '/settings';
        }
    }
};

// Lock object to prevent modification (true static).
Object.freeze(Constants);

export default Constants;
