import MaterialTable from "material-table";
import React, { useState, useEffect, useContext } from 'react';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';

import './CustomMessages.scss';


import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useRequest, useUIContext } from "../../ContextLib/contextHooks";
import { Severity } from "../../ContextLib/CoreConsumer/Components/SnackbarMessage";
import { arraySort } from '../../ContextLib/Core/coreLib';
import { useV5 } from "../../ContextLib/CoreConsumer/v5Contexts";
import axios from 'axios';
import Modal from '@mui/material/Modal';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    padding: 'none',
    boxShadow: 24,
    width: 1000,
    pt: 2,
    px: 4,
    pb: 3,
};


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 250,
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid #dadde9',
        fontFamily: 'Noto Sans, sans-serif',
    },
}))(Tooltip);


export default function Messages() {
    let componentId = "messages";
    const ui = useUIContext(componentId);

    let lastCoverKey = null;

    const { f, Request } = useV5();

    const initData = async () => {
        if (lastCoverKey != f.coverKey) {

            lastCoverKey = f.coverKey;
            setCoverLevelContent('')
            setWSCoverConfigV2(0)
        }
        loadData();
    };


    useEffect(() => {
        initData();
    }, [f.coverKey]);


    const [hasData, setHasData] = useState(false);
    const [coverSelected, setCoverSelected] = useState(false);
    const [coverLevelContent, setCoverLevelContent] = useState('');
    const [wsCoverConfigV2, setWSCoverConfigV2] = useState(0);
    const [policyIndex, setPolicyIndex] = useState('');
    const [customMessagesData, setCustomMessageData] = useState([]);
    const [noRecordMsg, setNoRecordMsg] = useState('');
    const columns = [
        // { field: 'coverKey', title: 'Coverkey', width: 100 , editable: 'never', emptyValue: '-'},
        { title: 'wsCoverConfigV2', field: 'wsCoverConfigV2', hidden: true },
        { field: 'customMessage', title: 'Message', width: 150, editable: 'never', emptyValue: '-- No custom message --', cellStyle: {  whiteSpace: 'pre-wrap'}}
        // { field: 'customMessage', title: 'Message', width: 150, editable: 'never', emptyValue: '-- No custom message --', cellStyle: {  whiteSpace: 'pre-wrap'}, render: dataRow => { return <HtmlTooltip title={dataRow.customMessage} placement="top-start"><span>{dataRow.customMessage}</span></HtmlTooltip> } }


    ];
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [count, setCount] = React.useState(0);

    const handleMessageContentChange = event => {
        setCoverLevelContent(event.target.value);
        setCount(event.target.value.length)
    };

    const handleEditClick = (rowData) => {
        setCoverLevelContent(rowData.customMessage)
        setCount(rowData.customMessage === null ? 0 : rowData.customMessage.length)
        setWSCoverConfigV2(rowData.wsCoverConfigV2)
        setOpen(true)
    };



    const _handleSubmit = async () => {
        const warningMsg = (<>The entry will be applied to {f.coverKey}. <br /><br />Do you want to continue?</>);
        let confirmed = await ui.ConfirmBox(warningMsg);
        if (confirmed == 1) {
            const apiKey = `${process.env.REACT_APP_API_KEY}`;

            const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/v1/configuration/saveWebSchedCoverConfigV2?secretCode=${apiKey}&locKey=${f.locKey}`;
            //const url = `https://localhost:44332/api/v1/configuration/saveWebSchedCoverConfigV2?secretCode=${apiKey}`;


            ui.ShowOverlay();
            const data = { 'wSCoverConfigV2': Number(wsCoverConfigV2), 'coverKey': Number(f.coverKey), 'policyIndex': Number(policyIndex), 'customMessage': coverLevelContent };

            const res = await axios.post(url, data);


            if (res.data) {
                loadData();
                ui.ShowSnackbar("Custom message were successfully saved.");
            }
            else {
                ui.ShowSnackbar("Error in saving custom message. Please try again later.");
            }
            ui.HideOverlay();
            handleClose();
        }
    }


    const handlePurge = async (oldData) => {
        const warningMsg = (<>The custom message for {f.coverKey} will be deleted. <br /><br />Do you want to continue?</>);
        let confirmed = await ui.ConfirmBox(warningMsg);
        if (confirmed == 1) {

            const apiKey = `${process.env.REACT_APP_API_KEY}`;
            const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/v1/Configuration/saveWebSchedCoverConfigV2?secretCode=${apiKey}`;
            //const url = `https://localhost:44332/api/v1/Configuration/saveWebSchedCoverConfigV2?secretCode=${apiKey}`;
            const data = { 'wSCoverConfigV2': Number(wsCoverConfigV2), 'coverKey': Number(f.coverKey), 'policyIndex': Number(policyIndex), 'customMessage': null };

            const res = await axios.post(url, data);
            if (res.data) {
                loadData();
                ui.ShowSnackbar("Custom message deleted successfully.");
            }
            else {
                ui.ShowSnackbar(
                    "Error in saving custom message. Please try again later.",
                    Severity.warning,
                    5000);
            }
        }

    }

    const loadData = async () => {
        if (f.coverKey == 0) return;

        let arrLocKeys = [], arrAcctKeys = [];

        if (f.locKey !== 0) {
            arrLocKeys.push(f.locKey.toString());
        } else {
            if (f.locations.length > 0) {
                f.locations.map((loc) => {
                    arrLocKeys.push(loc.locKey.toString())
                });
            }

        }

        if (f.acctKey !== 0) {
            arrAcctKeys.push(f.acctKey.toString());
        } else {
            if (f.providers.length > 0) {
                f.providers.map((prov) => {
                    arrAcctKeys.push(prov.acctKey.toString())
                });
            }
        }

        ui.ShowOverlay();
        const params = { "acctKeys": arrAcctKeys, "locKeys": arrLocKeys };
        if (f.coverKey !== 0) {

            const apiKey = `${process.env.REACT_APP_API_KEY}`;
            const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/v1/configuration/getWebSchedCoverConfigV2?secretCode=${apiKey}&coverKey=${f.coverKey}`;
            //const url = `https://localhost:44332/api/v1/configuration/getWebSchedCoverConfigV2?secretCode=${apiKey}&coverKey=${f.coverKey}`;
            const res = await axios.get(url);
            if (res.data?.length > 0) {

                let d = arraySort(res.data, "customMessage");
                setCustomMessageData(d);
                setHasData(true);
                setCoverSelected(true);
            } else {
                setCustomMessageData([]);
                //setNoRecordMsg('-- No records found. Please select another Cover --');
            }
        }
        if (customMessagesData.length > 0) {
            setHasData(true);
            setCoverSelected(true);
        }

        ui.HideOverlay();
    };


    //#endregion
    return (
        <div className="reporting-box">
            <Box p={2} bgcolor="background.paper" className="page-header">
                <Typography variant="h5">
                    Message
                </Typography>
            </Box>
            <Divider />
            {/* <Box p={2} bgcolor="background.paper" className="info">
            <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>
              <Grid item xs={12} sm={6}>
                <h3>  
                The following information is Patient Appointments data:
                </h3>
              </Grid>
            </Grid>        
          </Box>        
          <Divider /> */}

            <div className="page-body-outline">
                {coverSelected ?
                    <>
                        <Box>
                           <Grid container spacing={2}>
                                <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
                                    <Typography variant="body1">Cover</Typography>
                                </Grid>
                                <Grid item xs={6} container justifyContent="flex-end">
                                    <Button size="small" color="primary" onClick={handleOpen} disabled={customMessagesData.length > 0}
                                    >
                                        + Add Message
                                    </Button>
                                </Grid>
                            </Grid>
                            <MaterialTable
                                height="20"
                                columns={columns}
                                data={customMessagesData}
                                onRowClick={handleOpen}
                                options={{
                                    pageSize: 10,
                                    pageSizeOptions: [10, 15, 20],
                                    toolbar: false,
                                    paging: true,
                                    draggable: false,
                                    sorting: false,
                                    tableLayout: 'fixed',
                                }}
                                actions={[
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => handleEditClick(rowData),
                                    },
                                    (rowData) => ({
                                        icon: 'delete',
                                        tooltip: 'Delete',
                                        onClick: (event, rowData) => handlePurge(rowData),
                                        disabled: rowData.customMessage === null,
                                    })
                                ]}
                            />
                        </Box>
                    </>
                    :
                    <Box>
                        <Typography align="center" color="primary">{noRecordMsg}</Typography>
                    </Box>
                }

            </div>
            <div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">

                    <Box sx={style}>

                        <Button style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>X</Button>

                        <Box p={1} bgcolor="background.paper">
                            <Grid container justify="flex-start" alignItems="center" spacing={2}>
                                <Typography variant="h6" color={"primary"} >
                                    Custom Messaging
                                </Typography>
                                <Divider orientation="vertical" variant="middle" flexItem />

                                <Typography variant="subtitle1">
                                    Create custom message for your practice's website
                                </Typography>
                            </Grid>

                            <Box pt={1}>

                                <ValidatorForm onSubmit={_handleSubmit} onError={errors => console.log(errors)}>
                                    <Grid container direction="row" justify="center" alignItems="flex-end" spacing={3}>
                                        <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                                            <div style={{ marginTop: '50px', marginBottom: '10px' }}>
                                                <label style={{ verticalAlign: 'middle' }} >Message Content:<textarea maxlength="1000" width="500px"
                                                    type="text"
                                                    rows={5}
                                                    className="full_height_Width"
                                                    onChange={handleMessageContentChange}
                                                    value={coverLevelContent}
                                                    required
                                                /></label>
                                                <p style={{ textAlign: 'right' }}>{`${count}/1000`}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <br />
                                    <Grid
                                        sx={{ pt: 20 }}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="flex-end"
                                        spacing={1}
                                    >
                                        {/* <Grid item >
                                    <Typography variant="subtitle1">  
                                        Create custom message for your practice's website
                                    </Typography>
                                    </Grid> */}
                                        <Grid item >
                                            <Button
                                                variant="contained"
                                                color={"primary"}
                                                type="submit"
                                            >
                                                {wsCoverConfigV2 === 0 ? "Create New" : "Update Message"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </div>

    )
}




