import { useContext, useEffect, useMemo } from 'react';
import FiltersContext from './CoreConsumer/filtersContext';
import CoverFilterContext from './CoreConsumer/coverContext';
import UIContext, {ClUIClient} from './CoreConsumer/uiContext';
import {ClDataRequestObject} from './CoreConsumer/dataRequest';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { createGuid } from './Core/coreLib';

export function useUIContext(componentId) {    
    const uiContext = useContext(UIContext);
    return new ClUIClient(uiContext, componentId ?? createGuid());
}

export function useFiltersContext(componentId, filtersChangedDelegate, valuesUpdatedDelegate) {
    const context = useContext(FiltersContext);

    context.Subscribe_FiltersChanged(componentId, filtersChangedDelegate);
    context.Subscribe_ValuesUpdated(componentId, valuesUpdatedDelegate);

    useEffect(()=> {
        filtersChangedDelegate && filtersChangedDelegate(context.Values);

        return ()=> {
            context.UnSubscribe_FiltersChanged(componentId);   
            context.UnSubscribe_ValuesUpdated(componentId);     
        }
    }, []);

    return context;
}

export function useCoverFiltersContext(componentId, filtersChangedDelegate, valuesUpdatedDelegate) {
    const context = useContext(CoverFilterContext);

    context.Subscribe_FiltersChanged(componentId, filtersChangedDelegate);
    context.Subscribe_ValuesUpdated(componentId, valuesUpdatedDelegate);

    useEffect(()=> {
        filtersChangedDelegate && filtersChangedDelegate(context.Values);

        return ()=> {
            context.UnSubscribe_FiltersChanged(componentId);   
            context.UnSubscribe_ValuesUpdated(componentId);     
        }
    }, []);

    return context;
}

export function useRequest(onReturn) {
    const source = axios.CancelToken.source();

    const dro = new ClDataRequestObject(source.token, onReturn);

    useEffect(()=> {        
        return ()=> {
            source.cancel();    
        }
    }, []);

    return  dro.Request;
}

export function useV5Request(onReturn) {
    const source = axios.CancelToken.source();

    const dro = new ClDataRequestObject(source.token, onReturn);

    useEffect(()=> {        
        return ()=> {
            source.cancel();    
        }
    }, []);

    return dro.V5Request;
}


export function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
  }