import React, { useCallback, useEffect, useState } from 'react';
import GeneralEditor, { createTableLookup } from '../GeneralEditor/GeneralEditor';
import { Box, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { useUIContext } from '../../ContextLib/contextHooks';

const apiRoot = process.env.REACT_APP_MAPPINGINTEGRATION_API;

const useStyles = makeStyles((theme) => ({
  userMappingContainer: {
    '& .MuiTableCell-alignRight': {
      textAlign: 'left'
    }
  }
}));

const apiGet = async (url) => {
  let apiResult = undefined;
  try {
    apiResult = await axios.get(url)
  } catch {}
  let finalResult = undefined;
  if(apiResult?.data){
    finalResult = [...apiResult.data];
  }
  return finalResult;
};

const apiPush = async (method, url, data) => {
  let success = false;
  try {
    await method(url, data);
    success = true;
  } catch {}
  return success;
}

const api = {
  get: async () => {
    const url = `${apiRoot}/getUserMappings`;
    return apiGet(url);
  },

  create: async (item) => {
    const url = `${apiRoot}/createUserMapping`;
    return apiPush(axios.post, url, item);
  },

  delete: async (item) => {
    const url = `${apiRoot}/deleteUserMapping`;
    return await apiPush(axios.delete, url, { data: item } );
  },

  getMappingTypes: async () => {
    const url = `${apiRoot}/getMappingTypes`;
    return apiGet(url);
  }
}

const UserMapping = ({onError}) => {
  const componentId = "userMapping";
  const ui = useUIContext(componentId);

  const styles = useStyles();
  const [mappingTypes, setMappingTypes] = useState([]);
  const [userMappings, setUserMappings] = useState([]);

  const mappingTypesLookup = createTableLookup(mappingTypes, 'mappingTypeId', 'mappingDescription');

  const handleError = useCallback((error) => {
    if(onError) {
      onError(error);
    }
  }, [onError]);

  useEffect(() => {
    if(mappingTypes.length === 0) {
      const fetchMappingTypes = async () => {
        ui.ShowOverlay();
        const fetchedMappingTypes = await api.getMappingTypes();
        ui.HideOverlay();
        if(fetchedMappingTypes) {
          setMappingTypes(fetchedMappingTypes);
        } else {
          handleError("Unable to fetch mapping types");
        }
      };
      fetchMappingTypes();
    }
  }, [handleError, mappingTypes.length]);

  const fetchUserMappings = useCallback(async () => {
    ui.ShowOverlay();
    const fetchedUserMappings = await api.get();
    ui.HideOverlay();
    if(fetchedUserMappings) {
      setUserMappings(fetchedUserMappings);
    } else {
      handleError("Unable to fetch user mappings");
    }
  }, [handleError]);
  useEffect(() => {
    fetchUserMappings();
  }, [fetchUserMappings]);


  const handleCreate = async (userMapping) => {
    ui.ShowOverlay();
    const success = await api.create(userMapping);
    ui.HideOverlay();
    if(success) {
      await fetchUserMappings();
    } else {
      handleError("Failed to create user mapping");
    }
  }
  const handleDelete = async (userMapping) => {
    ui.ShowOverlay();
    const success = await api.delete(userMapping);
    ui.HideOverlay();
    if(success) {
      await fetchUserMappings();
    } else {
      handleError("Failed to delete user mapping");
    }
  }

  return <Box sx={{
    display: "flex",
    justifyContent: "center",
    paddingTop: 10
  }}>
    <Box className={styles.userMappingContainer} sx={{
      width: 800
    }}>
      <UserMappingEditor data={userMappings} onCreate={handleCreate} onDelete={handleDelete} mappingTypesLookup={mappingTypesLookup} />
    </Box>
  </Box>
}

const UserMappingEditor = (props) => {
  const columns = [
    { field: 'mappingTypeId', title: 'Mapping Type', cellStyle: {padding:10}, type: 'numeric', lookup: props.mappingTypesLookup, align: "left", validate: row => Boolean(row.mappingTypeId)},
    { field: 'userID', title: 'User ID', cellStyle: {padding:10}, align: "left", validate: row => Boolean(row.userID)}
  ];
  return <GeneralEditor {...props} columns={columns} title="User Mapping" />
}


export default UserMapping;
