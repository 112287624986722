import React from "react";
import MaterialDataGrid from "../Components/Common/MaterialDataGrid";
import { Button, Modal } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";

export default function ShadowTeamBulkConfirmation({
  showModal = false,
  onCloseModal,
  tableColumns,
  tableData,
  onSubmit,
  ConfirmationValue
}) {
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => onCloseModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Shadow-modal__wrapper"
      >
        <div className="bulk-confirmation__container">
          <div className="bulk-confirmation-grid__wrapper">
            <h2>Confirmation </h2>
            <MaterialDataGrid
              tableColumns={tableColumns}
              tableData={tableData}
              rowHeight={30}
              tableHeight={400}
            />
            <span id="bulk-span-id">
              The records in the grid are the records that will be updated by
              the execution
            </span>

            <ValidatorForm
              onSubmit={onSubmit}
              className="bulk-details__wrapper"
            >

              <ConfirmationValue/>

              <Button variant="outlined" color="primary" type="submit">
                Execute Bulk Update
              </Button>
            </ValidatorForm>
          </div>
        </div>
      </Modal>
    </>
  );
}
