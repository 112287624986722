import "../PmsConfig.scss";
import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ShadowMgmtModalForm from "./ShadowMgmtModalForm";
import { useUIContext } from "./../../../ContextLib/contextHooks";
import MaterialDataGrid from "./../Components/Common/MaterialDataGrid";
import TextSearchWithIcon from "./../Components/Common/TextSearchWithIcon";
import {
  UpsertJ47ShadowLogin,
  getJ47ShadowLogins,
} from "../Util/pmsIntegrationShadMgmtApiUtil";
import { PmsGridSort } from "../Util/PmsHelper";

export default function ShadowMgmtLanding({
  onHandleShadowTeamChange,
  onShadowTeamView,
  onHandleSnackBar,
  selectedShadowTeam,
}) {
  const ui = useUIContext("v5filters");
  const [coverKey, setCoverKey] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isActionAdd, setIsActionAdd] = useState(false);
  const [listJ47ShadowLogins, setlistJ47ShadowLogins] = useState([]);

  const tableColumns = [
    {
      field: "login",
      headerName: "Login",
      width: 250,
      sortComparator: PmsGridSort,
    },
    {
      field: "pwd",
      headerName: "Password",
      width: 180,
      sortComparator: PmsGridSort,
    },
    {
      field: "description",
      headerName: "Description",
      width: 650,
      sortComparator: PmsGridSort,
    },
    {
      field: "shadowKey",
      headerName: "ShadowKey",
      width: 160,
      type: "number"

    },
    {
      field: "isContainInstallPath",
      headerName: "Install Path?",
      width: 160,
      type: "boolean",
    },
  ];

  useEffect(() => {
    onLoadShadowLogins();
  }, []);

  const onLoadShadowLogins = () => {
    ui.ShowOverlay();
    getJ47ShadowLogins()
      .then((resp) => {
        const newData = resp.data.map((obj) => ({
          ...obj,
          id: obj.shadowKey,
          isContainInstallPath: obj.updateInstallationPath ? true : false,
        }));
        setlistJ47ShadowLogins(newData);
      })
      .catch((error) => {
        onHandleSnackBar(
          "error",
          `Error while fetching ShadowLogin records: ${error}`,
          false
        );
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };

  const _handleAddNewShadowLogin = async (data) => {
    ui.ShowOverlay();
    if (
      data.updateInstallationPath === "" ||
      data.updateInstallationPath === null
    ) {
      onHandleSnackBar(
        "warning",
        `Please remember to fill in the installation path once the Shadow Manager is installed!`,
        false
      );
    }
    const promises = [
      UpsertJ47ShadowLogin(data)
        .then(async (resp) => {
          let isExist = listJ47ShadowLogins.find(
            (x) => x.shadowKey == resp.data.shadowKey
          );

          let newObject = [...listJ47ShadowLogins];

          data.updateInstallationPath = resp.data.updateInstallationPath;
          data.priorUpdateInstallationPath =
            resp.data.priorUpdateInstallationPath;
          data.updateInstallationPathDateModified =
            resp.data.updateInstallationPathDateModified;
          data.shadowKey = resp.data.shadowKey;

          if (isExist) {
            var modifiedObj =
              newObject[
                listJ47ShadowLogins.findIndex(
                  (x) => x.shadowKey == resp.data.shadowKey
                )
              ];

            data.tableData = modifiedObj.tableData;

            newObject[
              listJ47ShadowLogins.findIndex(
                (x) => x.shadowKey == resp.data.shadowKey
              )
            ] = data;

            onHandleShadowTeamChange(data);

            await new Promise((resolve) => setTimeout(resolve, 2000));

            onHandleSnackBar(
              "success",
              `You have successfully  UPDATED a record`,
              false
            );
          } else {
            data.id = resp.data.shadowKey;
            newObject.push(data);
            onHandleSnackBar(
              "success",
              `You have successfully  ADDED a record`,
              false
            );
          }

          setlistJ47ShadowLogins(newObject);
          setShowModal(false);
        })
        .catch((error) =>
          onHandleSnackBar(
            "error",
            `Error while updating of records: ${error}`,
            false
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const _handleSearchLoginUsingCoverKey = async () => {
    ui.ShowOverlay();
    getJ47ShadowLogins(coverKey)
      .then((resp) => {
        const newData = resp.data.map((obj) => ({ ...obj, id: obj.shadowKey }));
        setlistJ47ShadowLogins(newData);
      })
      .catch((error) => {
        onHandleSnackBar(
          "error",
          `Error while fetching ShadowLogin records: ${error}`,
          false
        );
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };

  const HandleShadowTeamAdd = () => {
    setIsActionAdd(true);
    setShowModal(true);
  };

  const HandleShadowTeamEdit = () => {
    if (selectedShadowTeam.length === 0) {
      onHandleSnackBar(
        "warning",
        "Please select a record you want to MODIFY.",
        false
      );
      return;
    }

    setIsActionAdd(false);
    setShowModal(true);
  };

  const HandleCoverKeyChange = (data) => setCoverKey(data);

  return (
    <>
      <h1 className="pmstitle-title__wrapper">Shadow Teams</h1>

      <ShadowMgmtModalForm
        isActionAdd={isActionAdd}
        showModal={showModal}
        onCloseModal={setShowModal}
        onAddNewLogin={_handleAddNewShadowLogin}
        selectedShadowTeam={selectedShadowTeam}
      />

      <div className="shadowMgmt_wrapper">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={4} className="landingTbl-txtSearch__wrapper">
            <TextSearchWithIcon
              type="number"
              label="Search CoverKey"
              onClick={_handleSearchLoginUsingCoverKey}
              onInput={HandleCoverKeyChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialDataGrid
              HandleViewRecord={onShadowTeamView}
              HandleAddRecord={HandleShadowTeamAdd}
              HandleEditRecord={HandleShadowTeamEdit}
              tableColumns={tableColumns}
              tableData={listJ47ShadowLogins}
              onSelectedRow={onHandleShadowTeamChange}
              HandleRefreshRecord={onLoadShadowLogins}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
