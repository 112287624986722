import { DateOnly, getDateTimeObj } from "../../../ContextLib/Core/coreLib";
import moment from 'moment';

export const RepeatType = {
    Once: "once",
    Weekly: "weekly",
    BiWeekly: "bi-weekly",
    Monthly: "monthly"
};

// const convertUTCDateToLocalDate = (date) => {
//     var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

//     var offset = date.getTimezoneOffset() / 60;
//     var hours = date.getHours();

//     newDate.setHours(hours - offset);

//     return newDate;
// }

export const CreateEventsFromBlocks = (blocks, appointmentTypesData) => {   
    // Accepts the array and key
    const groupByStartDate = (array, key) => {
      
        // Return the end result
        return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[moment(currentValue[key]).format("MM/DD/yyyy")] = result[moment(currentValue[key]).format("MM/DD/yyyy")] || []).push(
            currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
        }, {}); // empty object is the initial value for result object
    };
    
    // Create a dictionary of group by StartDate 
    var groupStartDateBlocks = groupByStartDate(blocks,"blockStart")

    const events = blocks.map((block) => {   
        
        const isGeneral = block.calendarConfig.configFormData.repetition.repeats === "weekly" &&
                          block.calendarConfig.configFormData.repetition.ends === "never"     
        var isInvalidBlock = false   
        var sDate = moment(block.blockStart).format("MM/DD/yyyy")
        groupStartDateBlocks[sDate].forEach(blk => {
            var currentBlockKind = (blk.calendarConfig.configFormData.repetition.repeats === "weekly" &&
                                    blk.calendarConfig.configFormData.repetition.ends === "never") &&
                                    !blk.isExempted                     
                if  (currentBlockKind !== isGeneral)
                    isInvalidBlock = true

        });

        const blockStart = getDateTimeObj(new Date(block.blockStart)).dateTime;
        const blockEnd = getDateTimeObj(new Date(block.blockEnd)).dateTime;
       
        const config = block.calendarConfig;
        const configFormData = config.configFormData;
        const isBreak = block.isBreak;
        const isBlackout = block.isBlackOut;
        //const fromOnboarding = cfg.fromOnboarding;
        //const fromLegacy = cfg.configSource == 1; //0-none, 1-legacy, 2-v5
        const anyAppointment = configFormData.anyAppointment;
        const appointmentTypeList = (configFormData.appointmentTypeList ?? []).map(x=>{ 
            const t = appointmentTypesData.find(y => y.taskKey == x.taskKey);
            return t ? {
                taskKey: t.taskKey,
                name: t.name,
                length: t.length
            } : x;
        });
        const color = isGeneral ? 'green' : 'black';
        const groupId = config.wsConfigFormId;

        const title = isBlackout ? "Blackout" :
                isBreak ? "Break" :
                anyAppointment ? "Any Appointment" : "Selected Appointments";

        const c = {
            textColor: isBlackout ? 'white' : isBreak ? 'black' : 'white',
            backgroundColor: isBreak ? 'lightgray' : isBlackout ? 'dimgray' : isInvalidBlock ? 'red' :  'rgb(63,81,181)'
        };

        const isExempted = block.isExempted;

        let ret = {
            ...c,
            title: title,
            extendedProps: { 
                selected: false, 
                isBreak: isBreak,
                // fromLegacy: fromLegacy,
                // fromOnboarding: fromOnboarding,
                anyAppointment: anyAppointment,
                isBlackout: isBlackout, 
                apptTypes: (appointmentTypeList ?? []).map((x) => { return { name: x.name, length: x.length }; }),
                config: config,
                editSingleEvent: DateOnly(blockStart),
                groupColor: color
            },            
            groupId: groupId,
            start: blockStart,
            end: blockEnd,
            isexempted : isExempted
        };

        return ret;
    });



    // Remove Exempted Dates
    var finalEvents = events.filter(p => !p.isexempted);
    return finalEvents;
}