import moment from "moment";
import React, { useEffect, useState } from "react";
import { PmsGridSort } from "../../Util/PmsHelper";
import MaterialDataGrid from "../Common/MaterialDataGrid";

export default function EdenWbWebScheduled({ tableData, j47WebSchedLog }) {
  const [rowData, setRowData] = useState([]);
  const [webSchedCount, setWebSchedCount] = useState(0);
  const tableColumn = [
    {
      field: "count",
      headerName: "Count in last 36 hours",
      width: 230,
      type: "number",
    },
    {
      field: "result",
      headerName: "Result",
      width: 350,
      sortComparator: PmsGridSort,
    },
  ];

  useEffect(() => {
    if (j47WebSchedLog.length === 0) return setWebSchedCount(0);

    //back date of 36hrs.
    const date36HrsBack = moment().subtract("hours", 36);
    const data = [];
    console.log(j47WebSchedLog);
    j47WebSchedLog.forEach((n, i) => {
      const { logTime, apptKey } = n;
      if (logTime === undefined || null) return;
      if (!moment(logTime).isAfter(date36HrsBack)) return;

      if (apptKey > 0)
        return data.push({
          ...n,
          id: i,
        });
    });

    setWebSchedCount(data.length);
  }, [j47WebSchedLog]);

  useEffect(() => {
    if (tableData.length === 0) return setRowData([]);

    //back date of 36hrs.
    const date36HrsBack = moment().subtract("hours", 36);
    const data = [];

    tableData.forEach((n, i) => {
      const { lastModifiedDate, createdDate } = n;
      if (lastModifiedDate === undefined || null) return;
      if (!moment(lastModifiedDate).isAfter(date36HrsBack)) return;

      return data.push({
        ...n,
        id: i,
        lastModifiedDate: moment(lastModifiedDate).format(
          "MM/DD/YYYY hh:mm:ss A"
        ),
        createdDate: moment(createdDate).format("MM/DD/YYYY hh:mm:ss A"),
      });
    });

    var groupAndGetCount = data.reduce((p, c) => {
      var name = c.result;
      if (!p.hasOwnProperty(name)) {
        p[name] = 0;
      }
      p[name]++;
      return p;
    }, {});

    var grouped = Object.keys(groupAndGetCount).map((k, i) => {
      return { result: k, count: groupAndGetCount[k], id: i };
    });

    setRowData(grouped);
  }, [tableData]);

  return (
    <>
      <label>Web Scheduled Appt Count in last 36 hours: {webSchedCount}</label>
      <MaterialDataGrid
        tableHeight={250}
        tableColumns={tableColumn}
        tableData={rowData}
        onSelectedRow={undefined}
      />
    </>
  );
}
