import { Grid, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import SnackBarHelper from "../Components/Helper/SnackBarHelper";
import TextBoxWithValidator from "../Components/Common/TextBoxWithValidator";
import MaterialTableSelect from "./../Components/Common/MaterialTableSelect";
import {
  GetJ47WebSchedConfig,
  getPmsLocations,
  getPmsProviders,
  getShadowDbAliases,
  GetWSCSMapConfigList,
  PostWSCSMapConfigList,
} from "../Util/pmsIntegrationApiUtil";
import { useUIContext } from "../../../ContextLib/contextHooks";

export default function WebScheduler() {
  const ui = useUIContext("v5filters");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const [wsLocKeys, setWsLocKeys] = useState([]);
  const [wsAcctKeys, setWsAcctKeys] = useState([]);

  const [conStrings, setConStrings] = useState([]);
  const [vwLocations, setVwLocations] = useState([]);
  const [vwProviders, setvwProviders] = useState([]);

  const [wbLocKeys, setWbLocKeys] = useState([]);
  const [wbAcctKeys, setWbAcctKeys] = useState([]);

  const [mockData, setMockData] = useState({
    wsCoverKey: "",
    wsLocKey: "",
    wsAcctKey: "",
    csCoverKey: "",
    csApiUrl: "qasdb",
    csLocationId: "",
    csProviderId: "",
    wbCoverKey: "",
    wbLocKey: "",
    wbAcctKey: "",
  });

  const [wcscsMapConfigList, setWcscsMapConfigList] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      await _getConString();
      await _executeAllCalls();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await _getWsAcctKey();
      setMockData({
        ...mockData,
        wsAcctKey: "",
      });
    };
    fetchData();
  }, [mockData.wsLocKey]);

  useEffect(() => {
    const fetchData = async () => {
      await _getWbAcctKey();
      setMockData({
        ...mockData,
        wbAcctKey: "",
      });
    };
    fetchData();
  }, [mockData.wbLocKey]);

  useEffect(() => {
    const obj = wsLocKeys.find((n) => n.id === mockData.wsLocKey);
    if (obj === undefined) setMockData({ ...mockData, wsLocKey: "" });
  }, [wsLocKeys]);

  useEffect(() => {
    const obj = wbLocKeys.find((n) => n.id === mockData.wbLocKey);
    if (obj === undefined) setMockData({ ...mockData, wbLocKey: "" });
  }, [wbLocKeys]);

  useEffect(() => {
    const obj = vwProviders.find((n) => n.id === mockData.csProviderId);
    if (obj === undefined) setMockData({ ...mockData, csProviderId: "" });
  }, [vwProviders]);

  useEffect(() => {
    const obj = vwLocations.find((n) => n.id === mockData.csLocationId);
    if (obj === undefined) setMockData({ ...mockData, csLocationId: "" });
  }, [vwLocations]);

  useEffect(() => {
    const fetchData = async () => {
      await _getVwLocationId();
      await _getVwProviderId();
    };

    fetchData();
  }, [mockData.csApiUrl]);

  const _executeAllCalls = async () => {
    const promises = [
      await _getWcscsMapConfigList(),
      await _getWsLocKey(),
      await _getVwLocationId(),
      await _getVwProviderId(),
      await _getWbLocKey(),
    ];

    await Promise.all(promises);
  };

  const _upsertData = async () => {
    if (!ValidateFields()) return;

    ui.ShowOverlay();
    PostWSCSMapConfigList(mockData)
      .then((resp) => {
        setWcscsMapConfigList(resp.data);
        HandleUpdateSnackBar(
          "success",
          `SUCCESS upserting of the records!`,
          false
        );
      })
      .catch((error) => {
        HandleUpdateSnackBar("error", `PostWSCSMapConfigList: ${error}`, false);
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };
  const _getWcscsMapConfigList = async () => {
    if (mockData.wsCoverKey === "") return;
    ui.ShowOverlay();
    GetWSCSMapConfigList(mockData.wsCoverKey)
      .then((resp) => {
        setWcscsMapConfigList(resp.data);
      })
      .catch((error) => {
        HandleUpdateSnackBar("error", `GetWSCSMapConfigList: ${error}`, false);
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };

  const _getConString = async () => {
    ui.ShowOverlay();
    getShadowDbAliases()
      .then((resp) => {
        let newData = resp.data.map((obj) => ({
          id: obj,
          display: obj,
        }));
        newData = newData.filter((n) => n.id !== "SQL Server, 1.0");
        setConStrings(newData);
      })
      .catch((error) => {
        HandleUpdateSnackBar("error", `getShadowDbAliases: ${error}`, false);
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };
  const _getWsLocKey = async () => {
    GetJ47WebSchedConfig(mockData.wsCoverKey, 1)
      .then((resp) => {
        const newData = resp.data.map((obj) => ({
          ...obj,
          id: obj.locKey,
          display: obj.locKey + " " + obj.orgName,
        }));

        setWsLocKeys(newData);
      })
      .catch((error) => {
        HandleUpdateSnackBar(
          "error",
          `GetGetJ47WebSchedConfig: ${error}`,
          false
        );
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };
  const _getWsAcctKey = async () => {
    ui.ShowOverlay();
    GetJ47WebSchedConfig(mockData.wsLocKey, 2)
      .then((resp) => {
        const newData = resp.data.map((obj) => ({
          ...obj,
          id: obj.acctKey,
          display: obj.acctKey + " " + obj.firstName + " " + obj.lastName,
        }));

        setWsAcctKeys(newData);
      })
      .catch((error) => {
        HandleUpdateSnackBar(
          "error",
          `GetGetJ47WebSchedConfig: ${error}`,
          false
        );
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };
  const _getVwLocationId = async () => {
    ui.ShowOverlay();
    getPmsLocations(mockData.csApiUrl, mockData.csCoverKey)
      .then((resp) => {
        const newData = resp.data.map((obj) => ({
          ...obj,
          id: obj.vwLocationID,
          display: obj.vwLocationID + " " + obj.locationName,
        }));

        setVwLocations(newData);
      })
      .catch((error) => {
        HandleUpdateSnackBar("error", `getPmsLocations: ${error}`, false);
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };
  const _getVwProviderId = async () => {
    ui.ShowOverlay();
    getPmsProviders(mockData.csApiUrl, mockData.csCoverKey)
      .then((resp) => {
        const newData = resp.data.map((obj) => ({
          ...obj,
          id: obj.vwProviderID,
          display: `${obj.vwProviderID} ${obj.providerFirstName} ${obj.providerLastName}`,
        }));

        setvwProviders(newData);
      })
      .catch((error) => {
        HandleUpdateSnackBar("error", `getPmsProviders: ${error}`, false);
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };
  const _getWbLocKey = async () => {
    ui.ShowOverlay();
    GetJ47WebSchedConfig(mockData.wbCoverKey, 1)
      .then((resp) => {
        const newData = resp.data.map((obj) => ({
          ...obj,
          id: obj.locKey,
          display: obj.locKey + " " + obj.orgName,
        }));

        setWbLocKeys(newData);
      })
      .catch((error) => {
        HandleUpdateSnackBar(
          "error",
          `GetGetJ47WebSchedConfig: ${error}`,
          false
        );
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };
  const _getWbAcctKey = async () => {
    ui.ShowOverlay();
    GetJ47WebSchedConfig(mockData.wbLocKey, 2)
      .then((resp) => {
        const newData = resp.data.map((obj) => ({
          ...obj,
          id: obj.acctKey,
          display: obj.acctKey + " " + obj.firstName + " " + obj.lastName,
        }));

        setWbAcctKeys(newData);
      })
      .catch((error) => {
        HandleUpdateSnackBar(
          "error",
          `GetGetJ47WebSchedConfig: ${error}`,
          false
        );
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };
  const HandleUpdateSnackBar = (
    severity,
    message,
    doNotOpenSnackBar = true
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);

    if (!doNotOpenSnackBar) setOpenSnackBar(true);
  };
  const setValue = (e) => {
    const { name, value } = e.target;
    setMockData({
      ...mockData,
      [name]: value,
    });
  };
  const ValidateFields = () => {
    if (
      mockData.wsCoverKey === "" ||
      mockData.wsLocKey === "" ||
      mockData.wsAcctKey === "" ||
      mockData.csCoverKey === "" ||
      mockData.csApiUrl === "" ||
      mockData.csLocationId === "" ||
      mockData.csProviderId === "" ||
      mockData.wbLocKey === "" ||
      mockData.wbAcctKey === ""
    ) {
      HandleUpdateSnackBar(
        "warning",
        `Some of the fields are still missing.`,
        false
      );
      return false;
    }
    return true;
  };

  return (
    <div>
      <SnackBarHelper
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Severity={snackBarSeverity}
        AlertMessage={snackBarMessage}
      />
      <h1 className="pmstitle-title__wrapper">
        Web Scheduler / Shadow DB Mapping
      </h1>
      <ValidatorForm
        onSubmit={(data) => console.log(data)}
        onError={(errors) => console.log(errors)}
      >
        <div style={{ marginBottom: 15, marginRight: 10 }}>
          <Button
            onClick={() => _executeAllCalls()}
            variant="outlined"
            color="primary"
            style={{ marginRight: 10 }}
            type="button"
          >
            Refresh
          </Button>
          <Button
            onClick={() => _upsertData()}
            type="button"
            variant="outlined"
            color="primary"
          >
            Upsert
          </Button>
        </div>
        <Grid container alignItems="center">
          <Grid
            item
            lg={4}
            md={12}
            className="landingTbl-txtSearch__wrapper grid-webScheduler"
          >
            <span className="columnSpan">
              1. Web Scheduler CoverKey, LocKey, AcctKey
            </span>
            <div className="pms-ws-firstRow">
              <label htmlFor="websched-coverKey">
                Populate all CoverKeys then hit Refresh
              </label>
              <TextBoxWithValidator
                fullWidth={true}
                label="CoverKey"
                name="wsCoverKey"
                value={mockData.wsCoverKey || ""}
                onInput={(e) => setValue(e)}
                id="websched-coverKey"
              />
            </div>

            <div className="pms-ws-secondRow">
              <label>
                <strong>LocKey</strong> - Select LocKey from Drop downs OR enter
                values in text boxes
              </label>
              <MaterialTableSelect
                fullWidth={true}
                data={wsLocKeys}
                name="wsLocKey"
                label="LocKey"
                onChange={(e) => setValue(e)}
                value={mockData.wsLocKey || ""}
                displayKey="id"
                displayAttribute="display"
              />
            </div>

            <div className="pms-ws-thirdRow">
              <label>
                <strong>AcctKey</strong> - AcctKey dropdown will populate after
                LocKey is selected
              </label>
              <MaterialTableSelect
                fullWidth={true}
                data={wsAcctKeys}
                name="wsAcctKey"
                label="AcctKey"
                onChange={(e) => setValue(e)}
                value={mockData.wsAcctKey || ""}
                displayKey="id"
                displayAttribute="display"
              />
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            md={12}
            className="landingTbl-txtSearch__wrapper grid-webScheduler"
          >
            <span className="columnSpan">2. Shadow Database Source</span>

            <div className="pms-ws-firstRow">
              <label htmlFor="websched-coverKey">
                <br></br>
              </label>
              <TextBoxWithValidator
                label="CoverKey"
                fullWidth={true}
                name="csCoverKey"
                value={mockData.csCoverKey || ""}
                onInput={(e) => setValue(e)}
                id="websched-coverKeymiddle"
              />
            </div>
            <div className="pms-ws-secondRow">
              <label htmlFor="websched-coverKey">
                <br />
                <br />
              </label>
              <MaterialTableSelect
                fullWidth={true}
                data={conStrings}
                name="csApiUrl"
                label="Connection String"
                onChange={(e) => setValue(e)}
                value={mockData.csApiUrl || ""}
                displayKey="id"
                displayAttribute="display"
              />
            </div>

            <div className="pms-ws-thirdRow">
              <label>
                <br />
                <br />
              </label>
              <MaterialTableSelect
                fullWidth={true}
                data={vwLocations}
                name="csLocationId"
                label="VwLocationID"
                onChange={(e) => setValue(e)}
                value={mockData.csLocationId || ""}
                displayKey="id"
                displayAttribute="display"
              />
              <div>
                <br />
              </div>
              <MaterialTableSelect
                fullWidth={true}
                data={vwProviders}
                name="csProviderId"
                label="VwProviderID"
                onChange={(e) => setValue(e)}
                value={mockData.csProviderId || ""}
                displayKey="id"
                displayAttribute="display"
              />
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            md={12}
            className="landingTbl-txtSearch__wrapper grid-webScheduler"
          >
            <span className="columnSpan">
              3. Writeback CoverKey, LocKey, AcctKey
            </span>

            <div className="pms-ws-firstRow">
              <label htmlFor="websched-threeCoverKey">
                Writeback Destination
              </label>
              <TextBoxWithValidator
                label="CoverKey"
                fullWidth={true}
                name="wbCoverKey"
                value={mockData.wbCoverKey || ""}
                onInput={(e) => setValue(e)}
                id="websched-threeCoverKey"
              />
            </div>

            <div className="pms-ws-secondRow">
              <label>
                <strong>LocKey</strong> - Select LocKey from Drop downs OR enter
                values in text boxes
              </label>
              <MaterialTableSelect
                fullWidth={true}
                data={wbLocKeys}
                name="wbLocKey"
                label="LocKey"
                onChange={(e) => setValue(e)}
                value={mockData.wbLocKey || ""}
                displayKey="id"
                displayAttribute="display"
              />
            </div>
            <div className="pms-ws-thirdRow">
              <label>
                <strong>AcctKey</strong>
              </label>
              <div>
                <br />
              </div>
              <MaterialTableSelect
                fullWidth={true}
                data={wbAcctKeys}
                name="wbAcctKey"
                label="AcctKey"
                onChange={(e) => setValue(e)}
                value={mockData.wbAcctKey || ""}
                displayKey="id"
                displayAttribute="display"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            className="landingTbl-txtSearch__wrapper"
            style={{ minHeight: 800, marginTop: 20 }}
          >
            <TextBoxWithValidator
              required={false}
              label=""
              fullWidth={true}
              multiline={true}
              rows={20}
              value={wcscsMapConfigList || ""}
              name="footerText"
              onInput={(e) => setValue(e)}
            />
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
}
