import axios from "axios";
import { ReturnObj } from "./v5Core";

export const defaultRequestResult = {
    data: null,
    success: true,
    statusCode: null,
    cancelled: false,
    error: "",
}

export const defaultV5RequestResult = {
    ...defaultRequestResult,
    messages: [],
    errors: []
}

const successResponseCodes = [200,201,202,203,204,205,206,207,208,226,];

export default class ClDataCore {
    onReturn;
    
    async _Req(method, url, data, cancelToken) {
        try {
            let res = null;
    
            if (method == "get") {
                let p = "";
                
                if (data) {
                    p = Object.keys(data).map(k => {
                        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
                    }).join('&');
    
                    if (p) url += (url.indexOf("?") >= 0 ? "&" : "?") + p;
                }
    
                res = await axios.get(url, {
                    cancelToken: cancelToken
                });
    
            } else if (method == "post") {
                res = await axios.post(url, data, {
                    cancelToken: cancelToken
                });
            }
            
            if (res && successResponseCodes.includes(res?.status)) {
                return {...defaultRequestResult, data: res.data, statusCode: res?.status};
            } else {
                return {...defaultRequestResult, data: res?.data, success: false, statusCode: res?.status, 
                    error: "An error occured while processing the request." + (res?.status ? " (" + res?.status + ")" : "")};
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                return {...defaultRequestResult, success: false, cancelled: true, error: "The request was cancelled. " + e?.message}; 
            } else {
                return {...defaultRequestResult, success: false, error: "An error occured while processing the request. " + e?.message};
            }
        }
    }

    setOnReturn(ret, name) {
        this.onReturn && this.onReturn(new ReturnObj(ret, name));
    }

    async _Request(name, method, url, data, cancelToken) {
        const ret = await this._Req(method, url, data, cancelToken);
        this.setOnReturn(ret, name);
        return ret;
    }

    async _V5Request(name, method, url, data, cancelToken) {
        const res = await this._Req(method, url, data, cancelToken);
        const ret = {
            ...defaultV5RequestResult,
            ...res,
            data: res?.data?.data,
            success: res?.data?.success ?? false,
            messages: res?.data?.messages ?? [],
            errors: res?.data?.errors ?? [],
        };
        this.setOnReturn(ret, name);
        return ret;
    }
}


