import "../PmsConfig.scss";
import Enums from "../../../enums";
import React, { useState } from "react";
import SnackBarHelper from "../Components/Helper/SnackBarHelper";
import MappingPageSdbAndCoverKey from "../Components/MappingPage/MappingPageSdbAndCoverKey.js";
import MappingPageSearchAndListComp from "../Components/MappingPage/MappingPageSearchAndListComp.js";
import {
  GetMapShadowApptReasonsAndTaskKey,
  DeletePmsApptReasonsTo4pcAppointmentReasonMap,
  UpsertPmsApptReasonsTo4pcAppointmentReasonMap,
} from "../Util/pmsIntegrationApiUtil";
import { PmsGridSort } from "../Util/PmsHelper";

export default function AppointmentReasonMapping() {
  const [cover, setCover] = useState("4800");
  const [activeShadowDb, setActiveShadowDb] = useState("sdb4");
  const [selectedTaskKey, setselectedTaskKey] = useState(0);
  const [selectedReasonId, setselectedReasonId] = useState(0);
  const [isOngoingRequest, setIsOngoingRequest] = useState(false);

  const [textTaskKeySearch, setTextTaskKeySearch] = useState("");
  const [textReasonIdSearch, setTextReasonIdSearch] = useState("");

  const [selectedGridItem, setSelectedGridItem] = useState({});

  const [listOfTaskKey, setListOfTaskKey] = useState([]);
  const [listOfTaskKeySearch, setListOfTaskKeySearch] = useState([]);
  const [listOfReasons, setListOfReasons] = useState([]);
  const [listOfReasonsSearch, setListOfReasonsSearch] = useState([]);
  const [listOfReasonsMap, setListOfReasonsMap] = useState([]);

  const [selectedDirection, setSelectedDirection] = useState(
    Enums.PmsMapDirection
  );

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const columns = [
    {
      field: "coverKey",
      headerName: "CoverKey",
      width: 160,
      sortComparator: PmsGridSort,
    },
    {
      field: "pmsAppointmentReasonID",
      headerName: "PMSAppointmentReasonID",
      width: 300,
      sortComparator: PmsGridSort,
    },
    {
      field: "fpcApptType",
      headerName: "FPCApptType",
      width: 220,
      sortComparator: PmsGridSort,
    },
    {
      field: "locKey",
      headerName: "LocKey",
      width: 180,
      sortComparator: PmsGridSort,
    },
    {
      field: "acctKey",
      headerName: "AcctKey",
      width: 180,
      sortComparator: PmsGridSort,
    },
    {
      field: "direction",
      headerName: "Direction",
      width: 150,
    },
  ];

  const HandleCoverKeyChange = (cover) => setCover(cover);
  const handleShadowDbChange = (args) => setActiveShadowDb(args.target.value);

  const HandleRightRowSelected = (e, row) => setselectedTaskKey(row);
  const HandleLeftRowSelected = (e, row) => setselectedReasonId(row);

  const HandleProviderTextChange = (data) => setTextReasonIdSearch(data);
  const HandleAcctKeyTextChange = (data) => setTextTaskKeySearch(data);

  const HandleDirectionDown = () =>
    setSelectedDirection(Enums.PmsMapDirection.Down);

  const HandleDirectionUp = () =>
    setSelectedDirection(Enums.PmsMapDirection.Up);

  const HandleSearchReasons = () => {
    let data = [...listOfReasons];
    if (!textReasonIdSearch || textReasonIdSearch.length === 0)
      return setListOfReasonsSearch(data);

    let filteredData = data.filter((n) =>
      n.pmsReasonID.includes(textReasonIdSearch)
    );
    setListOfReasonsSearch(filteredData);
  };

  const HandleSearchTaskKey = () => {
    let data = [...listOfTaskKey];
    if (!textTaskKeySearch || textTaskKeySearch.length === 0)
      return setListOfTaskKeySearch(data);

    let filteredData = data.filter((n) =>
      n.taskKey.toString().includes(textTaskKeySearch)
    );
    setListOfTaskKeySearch(filteredData);
  };

  const updateSnackBar = (severity, message, doNotOpenSnackBar = true) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);

    if (!doNotOpenSnackBar) setOpenSnackBar(true);
  };

  const _handleDeleteRow = async () => {
    if (Object.keys(selectedGridItem).length === 0) {
      updateSnackBar("warning", `Kindly select a record first.`, false);
      return;
    }

    setIsOngoingRequest(true);

    const promises = [
      DeletePmsApptReasonsTo4pcAppointmentReasonMap(selectedGridItem.id)
        .then((resp) => {
          let newDataSet = [...listOfReasonsMap].filter(
            (n) => n.id != selectedGridItem.id
          );
          setListOfReasonsMap(newDataSet);

          updateSnackBar(
            "success",
            "You have successfully DELETED a record",
            false
          );
        })
        .catch((error) =>
          updateSnackBar(
            "error",
            `Error while deleting of records: ${error}`,
            false
          )
        )
        .finally(() => {
          setIsOngoingRequest(false);
        }),
    ];

    await Promise.all(promises);
  };

  const _handleSubmitSearchByCoverKeyAndSdb = async () => {
    setIsOngoingRequest(true);
    const promises = [
      GetMapShadowApptReasonsAndTaskKey(activeShadowDb, cover)
        .then((resp) => {
          console.log(resp.data);
          setSelectedGridItem({});
          setselectedTaskKey({});
          setselectedReasonId({});

          let mockReasonMaps = [];

          resp.data.appointmentReasonMaps.map((n) => {
            console.log("N", n);
            let tasks = resp.data.j45Tasks.find(
              (task) => task.taskKey === n.fpcApptType
            );

            if (tasks == undefined || tasks == null) {
              console.log("T1", tasks);

              return;
            }
            console.log("T", tasks);
            n.locKey = tasks.locKey;
            n.acctKey = tasks.acctKey;

            mockReasonMaps.push(n);
          });

          const { pmsAppointmentReasons, j45Tasks, pmsAppointmentReasonsEden } =
            resp.data;

          let data = [...pmsAppointmentReasons];
          if (activeShadowDb.toLowerCase() === "eden") {
            data = pmsAppointmentReasonsEden.map((n) => {
              return {
                pmsReasonID: n.pmsAppointmentReasonID,
                coverKey: n.coverKey,
                reasonName: n.pmsAppointmentReasonName,
              };
            });
          }

          setListOfReasons(data);
          setListOfReasonsSearch(data);
          setListOfTaskKey(j45Tasks);
          setListOfTaskKeySearch(j45Tasks);
          setListOfReasonsMap(mockReasonMaps);
        })
        .catch((error) =>
          updateSnackBar(
            "error",
            `Error while getting the records: ${error}`,
            false
          )
        )
        .finally(() => {
          setIsOngoingRequest(false);
        }),
    ];
    await Promise.all(promises);
  };

  const _handleSubmitByDirection = async () => {
    if (
      Object.keys(selectedReasonId).length === 0 ||
      Object.keys(selectedTaskKey).length === 0
    ) {
      updateSnackBar("warning", `Kindly select a record first.`, false);
      return;
    }

    const jsonBody = {
      coverKey: selectedReasonId.coverKey,
      fpcApptType: selectedTaskKey.taskKey,
      pmsAppointmentReasonID: selectedReasonId.pmsReasonID,
      direction: selectedDirection,
    };

    setIsOngoingRequest(true);
    const promises = [
      UpsertPmsApptReasonsTo4pcAppointmentReasonMap(jsonBody)
        .then((resp) => {
          let isExist = listOfReasonsMap.find((x) => x.id == resp.data);

          jsonBody.id = resp.data;
          let newObject = [...listOfReasonsMap];

          if (isExist) {
            let indexOfReason = listOfReasonsMap.findIndex(
              (x) => x.id == resp.data
            );

            newObject[indexOfReason] = jsonBody;

            let tasks = listOfTaskKey.find(
              (task) => task.taskKey === jsonBody.fpcApptType
            );

            newObject[indexOfReason].locKey = tasks.locKey;
            newObject[indexOfReason].acctKey = tasks.acctKey;

            updateSnackBar(
              "success",
              "You have successfully UPDATED a record",
              false
            );
          } else {
            let tasks = listOfTaskKey.find(
              (task) => task.taskKey === jsonBody.fpcApptType
            );

            jsonBody.locKey = tasks.locKey;
            jsonBody.acctKey = tasks.acctKey;

            newObject.push(jsonBody);

            updateSnackBar(
              "success",
              "You have successfully ADDED a record",
              false
            );
          }

          setListOfReasonsMap(newObject);
        })
        .catch((error) =>
          updateSnackBar(
            "error",
            `Error while updating of records: ${error}`,
            false
          )
        )
        .finally(() => {
          setIsOngoingRequest(false);
        }),
    ];
    await Promise.all(promises);
  };

  return (
    <div>
      <SnackBarHelper
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Severity={snackBarSeverity}
        AlertMessage={snackBarMessage}
      />
      <MappingPageSdbAndCoverKey
        titlePage="Map Shadow Appointment Reasons"
        onCoverKeyChange={HandleCoverKeyChange}
        propCover={cover}
        onShadowDbChange={handleShadowDbChange}
        propShadowDb={activeShadowDb}
        onSearchByCoverKeyAndSdb={_handleSubmitSearchByCoverKeyAndSdb}
        isOngoingRequest={isOngoingRequest}
      />

      <MappingPageSearchAndListComp
        selectedShadowDb={activeShadowDb}
        leftSearchBarLabelName="PMSAppointmentReasonID"
        rightSearchBarLabelName="TaskKey (w Lockey and AcctKey)"
        leftListDataSource={listOfReasonsSearch}
        rightListDataSource={listOfTaskKeySearch}
        propSelectedItemRightList={selectedTaskKey}
        onLeftItemSelected={HandleLeftRowSelected}
        propSelectedItemLeftList={selectedReasonId}
        onRightItemSelected={HandleRightRowSelected}
        onDirectionDown={HandleDirectionDown}
        onDirectionUp={HandleDirectionUp}
        tableColumns={columns}
        onSubmitDirection={_handleSubmitByDirection}
        tableData={listOfReasonsMap}
        handleDeleteRow={_handleDeleteRow}
        isOngoingRequest={isOngoingRequest}
        onSearchLeftList={HandleSearchReasons}
        onSearchRightList={HandleSearchTaskKey}
        onTextChangeLeftSearchText={HandleProviderTextChange}
        onTextChangeRightSearchText={HandleAcctKeyTextChange}
        onSelectedGridItem={(data) => setSelectedGridItem(data)}
      />
    </div>
  );
}
