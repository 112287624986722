import "../PmsConfig.scss";
import React, { useState } from "react";
import ShadowMgmtLandingPage from "./ShadowMgmtLandingPage";
import SnackBarHelper from "../Components/Helper/SnackBarHelper";
import ShadowMgmtMembers from "./ShadowTeamMember/ShadowMgmtMembers";

export default function ShadowMgmt() {
  const [selectedShadowTeam, setselectedShadowTeam] = useState([]);
  const [openShadowTeamMemberPage, setOpenShadowTeamMemberPage] =
    useState(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const HandleUpdateSnackBar = (
    severity,
    message,
    doNotOpenSnackBar = true
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);

    if (!doNotOpenSnackBar) setOpenSnackBar(true);
  };

  const HandleShadowTeamChange = (data) => 
    setselectedShadowTeam(data);

  const HandleShadowTeamView = () => {
    if (selectedShadowTeam.length === 0) {
      HandleUpdateSnackBar(
        "warning",
        "Please select a record you want to VIEW.",
        false
      );
      return;
    }
    setOpenShadowTeamMemberPage(true);
  };

  return (
    <div className="shadowMgmt_wrapper">
      <SnackBarHelper
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Severity={snackBarSeverity}
        AlertMessage={snackBarMessage}
      />

      {!openShadowTeamMemberPage && (
        <ShadowMgmtLandingPage
          onHandleShadowTeamChange={HandleShadowTeamChange}
          onShadowTeamView={HandleShadowTeamView}
          onHandleSnackBar={HandleUpdateSnackBar}
          selectedShadowTeam={selectedShadowTeam}
        />
      )}

      {openShadowTeamMemberPage && (
        <ShadowMgmtMembers
          selectedShadowTeam={selectedShadowTeam}
          onHandleSnackBar={HandleUpdateSnackBar}
          closeShadowMgmt={() => setOpenShadowTeamMemberPage(false)}
          openShadowTeamMemberPage={openShadowTeamMemberPage}
        />
      )}
    </div>
  );
}
