import {
  Button,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

import React from "react";
import MaterialDataGrid from "../Common/MaterialDataGrid";
import TextSearchWithIcon from "./../Common/TextSearchWithIcon";
import { ValidatorForm } from "react-material-ui-form-validator";

const MappingPageSearchAndListComp = React.memo(
  ({
    leftSearchBarLabelName,
    rightSearchBarLabelName,
    leftListDataSource,
    rightListDataSource,
    propSelectedItemRightList,
    onLeftItemSelected,
    propSelectedItemLeftList,
    onRightItemSelected,
    onDirectionDown,
    onDirectionUp,
    onSubmitDirection,
    onSearchLeftList,
    onSearchRightList,
    tableColumns,
    tableData,
    handleDeleteRow,
    isOngoingRequest,
    onTextChangeLeftSearchText,
    onTextChangeRightSearchText,
    onSelectedGridItem,
    selectedShadowDb,
  }) => {
    const disableMayDirectionButton = () => {
      if (leftListDataSource.length == 0 || rightListDataSource == 0)
        return true;

      if (propSelectedItemLeftList == 0 || propSelectedItemRightList == 0)
        return true;

      return isOngoingRequest ? true : false;
    };

    const renderRightListRowLabels = (data) => {
      if (leftSearchBarLabelName.includes("PMSAppointmentReasonID"))
        return `${data.taskKey} | ${data.locKey} | ${data.acctKey} | ${data.name}`;
      if (leftSearchBarLabelName.includes("PMSProviderID"))
        return `${data.acctKey} | ${data.firstName} ${data.lastName}`;
      if (leftSearchBarLabelName.includes("PMSLocationID"))
        return `${data.orgName} | ${data.locKey}`;
    };

    const renderLeftListRowLabels = (data) => {
      if (leftSearchBarLabelName.includes("PMSAppointmentReasonID"))
        return `${data.pmsReasonID} | ${data.reasonName}`;
      if (leftSearchBarLabelName.includes("PMSProviderID"))
        return `${data.pmsProviderID} | ${data.providerFirstName} ${data.providerLastName} `;
      if (leftSearchBarLabelName.includes("PMSLocationID"))
        return `${data.locationName} | ${data.pmsLocationID}`;
    };

    const handleRightItemSelected = (event, val) => {
      event.stopPropagation();
      onRightItemSelected(event, val);
    };
    return (
      <>
        <ValidatorForm onSubmit={onSubmitDirection}>
          <Grid
            container
            alignItems="center"
            spacing={1}
            style={{ marginTop: 30 }}
          >
            <Grid item xs={6}>
              <TextSearchWithIcon
                label={leftSearchBarLabelName}
                onInput={onTextChangeLeftSearchText}
                onClick={onSearchLeftList}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSearchWithIcon
                label={rightSearchBarLabelName}
                onInput={onTextChangeRightSearchText}
                onClick={onSearchRightList}
              />
            </Grid>
            <Grid item xs={6}>
              <Box className="pms-providerPage-box_wrapper">
                <List>
                  {leftListDataSource.map((val, i) => {
                    return (
                      <ListItem
                        key={i}
                        selected={propSelectedItemLeftList == val}
                        component="div"
                        button
                        onClick={(event) => onLeftItemSelected(event, val)}
                      >
                        <ListItemIcon>
                          <ListItemText
                            primary={renderLeftListRowLabels(val)}
                          />
                        </ListItemIcon>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="pms-providerPage-box_wrapper">
                <List>
                  {rightListDataSource.map((val, x) => {
                    return (
                      <ListItem
                        key={x}
                        selected={propSelectedItemRightList == val}
                        component="div"
                        button
                        onClick={(event) => handleRightItemSelected(event, val)}
                      >
                        <ListItemIcon>
                          <ListItemText
                            primary={renderRightListRowLabels(val)}
                          />
                        </ListItemIcon>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Grid>
            <Grid style={{ margin: "10px 0" }} item xs={12}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={disableMayDirectionButton()}
                onClick={onDirectionDown}
                style={{ marginRight: 5 }}
              >
                Map Down
              </Button>

              <Button
                type="submit"
                variant="outlined"
                color="primary"
                onClick={onDirectionUp}
                disabled={disableMayDirectionButton()}
              >
                Map Up
              </Button>
            </Grid>
            <Grid style={{ margin: "10px 0" }} item xs={12}>
              <MaterialDataGrid
                HandleDeleteRecord={(data) => handleDeleteRow(data)}
                tableData={tableData}
                tableColumns={tableColumns}
                onSelectedRow={onSelectedGridItem}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </>
    );
  }
);

export default MappingPageSearchAndListComp;
