import "../PmsConfig.scss";
import Enums from "../../../enums";
import React, { useState } from "react";
import SnackBarHelper from "../Components/Helper/SnackBarHelper";
import MappingPageSdbAndCoverKey from "../Components/MappingPage/MappingPageSdbAndCoverKey.js";
import MappingPageSearchAndListComp from "../Components/MappingPage/MappingPageSearchAndListComp.js";
import {
  GetMapShadowLocationsAndLoc,
  UpsertPmsLocationTo4pcLocationMaps,
  DeletePmsLocationTo4pcLocationMaps,
} from "../Util/pmsIntegrationApiUtil";
import { PmsGridSort } from "../Util/PmsHelper";

export default function LocationMapping() {
  const [cover, setCover] = useState("");
  const [activeShadowDb, setActiveShadowDb] = useState("");
  const [selectedLocKey, setselectedLocKey] = useState(0);
  const [selectedLocationId, setselectedLocationId] = useState(0);
  const [isOngoingRequest, setIsOngoingRequest] = useState(false);

  const [textLocKeySearch, setTextLocKeySearch] = useState("");
  const [selectedGridItem, setSelectedGridItem] = useState({});
  const [textLocationIdSearch, setTextLocationIdSearch] = useState("");

  const [listOfLocKey, setListOfLocKey] = useState([]);
  const [listOfLocKeySearch, setListOfLocKeySearch] = useState([]);
  const [listOfLocations, setListOfLocations] = useState([]);
  const [listOfLocationsSearch, setListOfLocationsSearch] = useState([]);
  const [listOfLocationMap, setListOfLocationMap] = useState([]);

  const [selectedDirection, setSelectedDirection] = useState(
    Enums.PmsMapDirection
  );

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const columns = [
    {
      field: "coverKey",
      headerName: "CoverKey",
      width: 160,
      sortComparator: PmsGridSort,
    },
    {
      field: "locKey",
      headerName: "LocKey",
      width: 350,
      sortComparator: PmsGridSort,
    },
    {
      field: "pmsLocationID",
      headerName: "PMSLocationID",
      width: 350,
      sortComparator: PmsGridSort,
    },
    {
      field: "direction",
      headerName: "Direction",
      width: 150,
    },
  ];

  const HandleCoverKeyChange = (cover) => setCover(cover);
  const handleShadowDbChange = (args) => setActiveShadowDb(args.target.value);

  const HandleRightRowSelected = (e, row) => setselectedLocKey(row);
  const HandleLeftRowSelected = (e, row) => setselectedLocationId(row);

  const HandleProviderTextChange = (data) => setTextLocationIdSearch(data);
  const HandleLocKeyTextChange = (data) => setTextLocKeySearch(data);

  const HandleDirectionDown = () =>
    setSelectedDirection(Enums.PmsMapDirection.Down);

  const HandleDirectionUp = () =>
    setSelectedDirection(Enums.PmsMapDirection.Up);

  const HandleSearchProvider = () => {
    let data = [...listOfLocations];
    if (!textLocationIdSearch || textLocationIdSearch.length === 0)
      return setListOfLocationsSearch(data);

    let filteredData = data.filter((n) =>
      n.pmsLocationID.includes(textLocationIdSearch)
    );
    setListOfLocationsSearch(filteredData);
  };

  const HandleSearchLocKey = () => {
    let data = [...listOfLocKey];
    if (!textLocKeySearch || textLocKeySearch.length === 0)
      return setListOfLocKeySearch(data);

    let filteredData = data.filter((n) =>
      n.locKey.toString().includes(textLocKeySearch)
    );
    setListOfLocKeySearch(filteredData);
  };

  const updateSnackBar = (severity, message, doNotOpenSnackBar = true) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);

    if (!doNotOpenSnackBar) setOpenSnackBar(true);
  };

  const _handleDeleteRow = async (data) => {
    if (Object.keys(selectedGridItem).length === 0) {
      updateSnackBar("warning", `Kindly select a record first.`, false);
      return;
    }

    setIsOngoingRequest(true);

    const promises = [
      DeletePmsLocationTo4pcLocationMaps(selectedGridItem.id)
        .then((resp) => {
          let newDataSet = [...listOfLocationMap].filter(
            (n) => n.id != selectedGridItem.id
          );
          setListOfLocationMap(newDataSet);
          setSelectedGridItem({});

          updateSnackBar(
            "success",
            "You have successfully DELETED a record",
            false
          );
        })
        .catch((error) =>
          updateSnackBar(
            "error",
            `Error while deleting of records: ${error}`,
            false
          )
        )
        .finally(() => {
          setIsOngoingRequest(false);
        }),
    ];

    await Promise.all(promises);
  };

  const _handleSubmitSearchByCoverKeyAndSdb = async () => {
    setIsOngoingRequest(true);
    const promises = [
      GetMapShadowLocationsAndLoc(activeShadowDb, cover)
        .then((resp) => {
          setSelectedGridItem({});
          setselectedLocationId({});
          setselectedLocKey({});

          setListOfLocations(resp.data.pmsLocations);
          setListOfLocationsSearch(resp.data.pmsLocations);
          setListOfLocKey(resp.data.j45Locs);
          setListOfLocKeySearch(resp.data.j45Locs);
          setListOfLocationMap(resp.data.locationMaps);
        })
        .catch((error) =>
          updateSnackBar(
            "error",
            `Error while getting the records: ${error}`,
            false
          )
        )
        .finally(() => {
          setIsOngoingRequest(false);
        }),
    ];
    await Promise.all(promises);
  };

  const _handleSubmitByDirection = async () => {
    if (
      Object.keys(selectedLocationId).length === 0 ||
      Object.keys(selectedLocKey).length === 0
    ) {
      updateSnackBar("warning", `Kindly select a record first.`, false);
      return;
    }

    const jsonBody = {
      coverKey: selectedLocationId.coverKey,
      locKey: selectedLocKey.locKey,
      pmsLocationID: selectedLocationId.pmsLocationID,
      direction: selectedDirection,
    };

    setIsOngoingRequest(true);
    const promises = [
      UpsertPmsLocationTo4pcLocationMaps(jsonBody)
        .then((resp) => {
          let isExist = listOfLocationMap.find((x) => x.id == resp.data);

          jsonBody.id = resp.data;
          let newObject = [...listOfLocationMap];

          if (isExist) {
            newObject[listOfLocationMap.findIndex((x) => x.id == resp.data)] =
              jsonBody;

            updateSnackBar(
              "success",
              "You have successfully UPDATED a record",
              false
            );
          } else {
            newObject.push(jsonBody);

            updateSnackBar(
              "success",
              "You have successfully ADDED a record",
              false
            );
          }

          setListOfLocationMap(newObject);
        })
        .catch((error) =>
          updateSnackBar(
            "error",
            `Error while updating of records: ${error}`,
            false
          )
        )
        .finally(() => {
          setIsOngoingRequest(false);
        }),
    ];
    await Promise.all(promises);
  };

  return (
    <div>
      <SnackBarHelper
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Severity={snackBarSeverity}
        AlertMessage={snackBarMessage}
      />

      <MappingPageSdbAndCoverKey
        titlePage="Map Shadow Locations"
        onCoverKeyChange={HandleCoverKeyChange}
        propCover={cover}
        onShadowDbChange={handleShadowDbChange}
        propShadowDb={activeShadowDb}
        onSearchByCoverKeyAndSdb={_handleSubmitSearchByCoverKeyAndSdb}
        isOngoingRequest={isOngoingRequest}
      />

      <MappingPageSearchAndListComp
        leftSearchBarLabelName="PMSLocationID"
        rightSearchBarLabelName="LocKey"
        leftListDataSource={listOfLocationsSearch}
        rightListDataSource={listOfLocKeySearch}
        propSelectedItemRightList={selectedLocKey}
        onLeftItemSelected={HandleLeftRowSelected}
        propSelectedItemLeftList={selectedLocationId}
        onRightItemSelected={HandleRightRowSelected}
        onDirectionDown={HandleDirectionDown}
        onDirectionUp={HandleDirectionUp}
        tableColumns={columns}
        onSubmitDirection={_handleSubmitByDirection}
        tableData={listOfLocationMap}
        handleDeleteRow={_handleDeleteRow}
        isOngoingRequest={isOngoingRequest}
        onSearchLeftList={HandleSearchProvider}
        onSearchRightList={HandleSearchLocKey}
        onTextChangeLeftSearchText={HandleProviderTextChange}
        onTextChangeRightSearchText={HandleLocKeyTextChange}
        onSelectedGridItem={(data) => setSelectedGridItem(data)}
      />
    </div>
  );
}
