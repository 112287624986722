import React, { useState, useEffect } from "react";
import "../PmsConfig.scss";
import moment from "moment";
import { useUIContext } from "../../../ContextLib/contextHooks";
import SnackBarHelper from "./../Components/Helper/SnackBarHelper";
import { Button, Grid } from "@material-ui/core";
import MaterialTableSelect from "./../Components/Common/MaterialTableSelect";
import {
  GetCoverStatus,
  GetDataTableInbound,
  GetIniInbound,
} from "../Util/pmsIntegrationApiUtil";
import TcDataViewerBody from "./TcDataViewerBody";

export default function TcDataViewer() {
  const ui = useUIContext("v5filters");
  const [table, setTable] = useState([]);
  const [coverKeys, setCoverKeys] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [dataTableInboundWithIni, setDataTableInboundWithIni] = useState([]);
  const [dataTableInbound, setDataTableInbound] = useState([]);
  const [iniInbound, setIniInbound] = useState([]);
  const [mockData, setMockData] = useState({
    coverKey: "",
  });

  const HandleUpdateSnackBar = (
    severity,
    message,
    doNotOpenSnackBar = true
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);

    if (!doNotOpenSnackBar) setOpenSnackBar(true);
  };

  const setValue = (e) => {
    const { name, value } = e.target;
    setMockData({
      ...mockData,
      [name]: value,
    });
  };

  useEffect(() => {
    const _getCoverStatus = async () => {
      ui.ShowOverlay();
      const promises = [
        GetCoverStatus()
          .then((resp) => {
            setCoverKeys(resp.data);
          })
          .catch((error) =>
            HandleUpdateSnackBar("error", `GetCoverStatus: ${error}`, false)
          )
          .finally(() => {
            ui.HideOverlay();
          }),
      ];
      await Promise.all(promises);
    };

    _getCoverStatus();
  }, []);

  useEffect(() => {
    if (mockData.coverKey === "") return;

    //reset the data first before fetching the new one.
    setTable([]);
    setDataTableInbound([]);
    setDataTableInboundWithIni([]);
    setIniInbound([]);

    const getData = async () => {
      await _DataTableInbound();
      await _DataTableInboundJoinIniInbound();
      await _IniInbound();
    };

    getData();
  }, [mockData.coverKey]);

  const RenderDateDisplay = () => {
    if (mockData.coverKey === "") return "";
    const data = coverKeys.find((n) => n.coverKey === mockData.coverKey);
    return moment(data.modifiedDate).format("MM/DD/YYYY hh:mm:ss A");
  };

  const _DataTableInbound = async () => {
    ui.ShowOverlay();
    const promises = [
      GetDataTableInbound(mockData.coverKey, "DataTableInbound")
        .then((resp) => {
          setDataTableInbound(resp.data);
        })
        .catch((error) =>
          HandleUpdateSnackBar("error", `GetCoverStatus: ${error}`, false)
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const _IniInbound = async () => {
    ui.ShowOverlay();
    const promises = [
      GetIniInbound(mockData.coverKey)
        .then((resp) => {
          setIniInbound(resp.data);
        })
        .catch((error) =>
          HandleUpdateSnackBar("error", `GetIniInbound: ${error}`, false)
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const _DataTableInboundJoinIniInbound = async () => {
    ui.ShowOverlay();
    const promises = [
      GetDataTableInbound(mockData.coverKey, "DataTableInboundJoinIniInbound")
        .then((resp) => {
          var sortedByType = resp.data.sort((a, b) => b.type - a.type);
          setDataTableInboundWithIni(sortedByType);
        })
        .catch((error) =>
          HandleUpdateSnackBar("error", `GetCoverStatus: ${error}`, false)
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  useEffect(() => {
    const HandleGetTableInbound = () => {
      //typically, all of these tables would contain a value. We will perform the transforming after that.
      if (dataTableInbound.length == 0 || dataTableInboundWithIni.length == 0)
        return;

      const tables = [];

      //get all the column names
      const columnNames = [];
      //we remove all the duplicates columns first and get the unique values.
      dataTableInbound
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.columnName === value.columnName &&
                t.tableName === value.tableName
            )
        )
        //then we get the column & table property.
        .map((n) => {
          return columnNames.push({ column: n.columnName, table: n.tableName });
        });

      //we distinct all table, and put their respective columns.
      dataTableInboundWithIni.map((curTbl) => {
        const temptbl = {};
        //we specify the tablename
        temptbl.tableName = curTbl.name;
        temptbl.tableType = curTbl.type;

        let tmpColumn = [];

        if (curTbl.type != "Data Table") return;
        tmpColumn.push("RowKey");

        columnNames.map(
          (curCol) =>
            curCol.table == curTbl.name && tmpColumn.push(curCol.column)
        );

        //we specify the column of the selected table in the loop.
        temptbl.columns = tmpColumn;

        const filteredRows = dataTableInbound.filter(
          (n) => n.tableName == curTbl.name
        );

        const transformedData = [];

        //Get All RowsKeys
        const rowKeys = [];
        filteredRows.forEach((n) => {
          const x = rowKeys.find((row) => row == n.rowKey);
          if (!x) {
            if (n.rowKey == "") {
              const keysContainWhiteString = rowKeys.filter((row) => row == "");
              if (keysContainWhiteString.length != 0) return;
            }
            rowKeys.push(n.rowKey);
          }
        });

        tmpColumn.forEach((col) => {
          //..
          rowKeys.forEach((keys) => {
            // if (curTbl.name == "Local_Settings") debugger;
            let rowsExist = filteredRows.find(
              (ent) => ent.rowKey === keys && ent.columnName == col
            );

            if (rowsExist) {
              const existingEntry = transformedData.find(
                (entry) => entry.keys === keys
              );
              //we push it on the values property which holds the row values
              existingEntry.values.push(rowsExist.columnValueMax);
            }
            //undefined could mean it is referring to the rowKey;
            else {
              transformedData.push({
                keys,
                values: [keys],
                table: curTbl.name,
              });
            }
          });
        });

        temptbl.rows = transformedData;

        //push the tmptbl to the table
        tables.push(temptbl);
      });

      return tables;
    };

    const HandleGetIniInbound = () => {
      //typically, all of these tables would contain a value. We will perform the transforming after that.
      if (iniInbound.length == 0 || dataTableInboundWithIni.length == 0) return;

      const tables = [];

      //get all the column names
      const columnNames = [];
      //we remove all the duplicates columns first and get the unique values.
      iniInbound
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.propertyPath === value.propertyPath &&
                t.fileName === value.fileName
            )
        )
        //then we get the column & table property.
        .map((n) => {
          return columnNames.push({
            column: n.propertyPath,
            table: n.fileName,
          });
        });

      //we distinct all table, and put their respective columns.
      dataTableInboundWithIni.map((curTbl) => {
        const temptbl = {};
        //we specify the tablename
        temptbl.tableName = curTbl.name;
        temptbl.tableType = curTbl.type;

        let tmpColumn = [];

        if (curTbl.type == "Data Table") return;
        columnNames.map(
          (curCol) =>
            curCol.table == curTbl.name && tmpColumn.push(curCol.column)
        );

        //we specify the column of the selected table in the loop.
        temptbl.columns = tmpColumn;

        const filteredRows = iniInbound.filter(
          (n) => n.fileName == curTbl.name
        );
        const transformedData = [];

        tmpColumn.forEach((col) => {
          //..
          let rowsExist = filteredRows.find((ent) => ent.propertyPath === col);

          if (rowsExist) {
            const existingEntry = transformedData.find(
              (entry) => entry.table === curTbl.name
            );

            if (existingEntry) {
              existingEntry.values.push(rowsExist.propertyValueMax);
            } else {
              transformedData.push({
                values: [rowsExist.propertyValueMax],
                table: curTbl.name,
              });
            }
          }
        });

        temptbl.rows = transformedData;

        //push the tmptbl to the table
        tables.push(temptbl);
      });
      return tables;
    };

    const GetIni = HandleGetIniInbound();
    const GetTableInbound = HandleGetTableInbound();

    if (GetIni === undefined || GetTableInbound === undefined) return;

    const datas = [...GetTableInbound, ...GetIni];
    setTable(datas);
  }, [dataTableInbound, iniInbound, dataTableInboundWithIni]);

  return (
    <>
      <SnackBarHelper
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Severity={snackBarSeverity}
        AlertMessage={snackBarMessage}
      />
      <h1 className="pmstitle-title__wrapper">
        Thin Client Collected Data Viewer
      </h1>

      <div className="pms-tcdataviewer-container">
        <div className="pms-tcdataviewer-textfield-container">
          <MaterialTableSelect
            size="medium"
            data={coverKeys}
            label="Select CoverKey"
            onChange={(e) => setValue(e)}
            value={mockData.coverKey | ""}
            fullWidth={true}
            name="coverKey"
            displayKey="coverKey"
            displayAttribute="coverKey"
          />
        </div>
        <label style={{ marginTop: "5px", fontSize: "15px" }}>
          {RenderDateDisplay()}
        </label>
        <div className="pms-tcdataviewer-table-container">
          {dataTableInboundWithIni && <TcDataViewerBody table={table} />}
        </div>
      </div>
    </>
  );
}
