import MaterialTable from "material-table";
import React, { useState,useEffect  } from 'react';

import './TriageReportingForm.scss'

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Overlay from '../Overlay/Overlay';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Checkbox } from '@material-ui/core';
import Row from './TriageRow'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}; 

export default function ReportingForm(props) {
  const {
    data,
    columns,
    reportType
  } = props;
  const hasData = (data.length > 0 ? true: false);
  const [collapsePanel, setCollapsePanel] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  //const [open, setOpen] = React.useState(false);
  const [opened, setOpened] = React.useState(true);
  const classes = useRowStyles();
  const [rowData, setRowData] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [showLoadingOverlay, setshowLoadingOverlay] = useState(false);


  useEffect(() => {
    // code to run on component mount
    setPage(0);
  }, [data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const _handleCollapsePanel = () => {
    if (hasData) {
      setCollapsePanel(!collapsePanel);
    }
  };

  const _snackBarShow = (value) =>
  {
    setOpenSnackBar(value)
  }

  const _overlayShow = (value) =>
  {
    setshowLoadingOverlay(value)
  }


  return (
    <>
    <div className="reporting-data">
        <Grid container justify="space-between" alignItems="center" spacing={2} onClick={_handleCollapsePanel}> 
            <Grid item>
                <Typography variant="h5">
                {reportType}
                </Typography>
            </Grid>
            {hasData ? 
            <Grid item>
              {collapsePanel ? <ExpandLess style={{cursor:"pointer"}} onClick={_handleCollapsePanel} /> : <ExpandMore  style={{cursor:"pointer"}} onClick={_handleCollapsePanel} />}
            </Grid>
            : null}
        </Grid>

        { hasData ?       
        <Collapse in={collapsePanel} timeout="auto">
        <Box p={1} bgcolor="background.paper">
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell/>
                  {columns.map((col, index) => {
                    return <TableCell key={index}>{col.title}</TableCell>
                  })}
                  {/* <TableCell align="left">
                    <Checkbox
                        checked={1}
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </TableCell> */}
                </TableRow>
              </TableHead>
              {hasData && <TableBody>
                 {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => {
                  return  (              
                    <Row key={index} row={row} rowData={rowData} columns={columns} type={reportType} snackbar={_snackBarShow} overlay={_overlayShow}  /> 
                  );
                })}
              </TableBody>}
            </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />          
{/*             <MaterialTable
                    height="200"
                    columns={columns}
                    data={data}
                    options={{
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20],
                        toolbar: true,
                        paging: true,
                        draggable: false,
                        sorting: false,
                        doubleHorizontalScroll: true,
                        tableLayout: 'fixed',
                        showTitle: false,
                    }}        
                    actions={[
                        {
                        icon: () => <VisibilityIcon fontSize="small" />,
                        tooltip: 'View Detail',
                        onClick: (event, rowData) => _onViewClick(rowData.parentId),
                        headerStyle: { minWidth: 80}
                        }
                    ]}
                    />  */}
        </Box>
        </Collapse> 
        : 
        null
        }

        <Divider/>
    </div>         
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => setOpenSnackBar(false)}>
        <Alert onClose={() => setOpenSnackBar(false)} severity="info">
          No Information available!
        </Alert>
    </Snackbar>    
    <Overlay show={showLoadingOverlay}>
      <i className="spinner-eclipse"></i>
    </Overlay>      
    </>
  );
}