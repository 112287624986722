

import React , { useState, useEffect } from 'react';

import axios from 'axios';
import MaterialTable from "material-table";

import { Button, Box, Divider, Grid, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { useCoverFiltersContext, useUIContext } from "../../ContextLib/contextHooks";
import { Severity } from '../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import moment from 'moment';
import Constants from '../../constants';
import VisibilityIcon from '@material-ui/icons/Visibility';

import ReportingForm from '../../Components/Reporting/TriageReportingForm';
import ReportingFormPatient from '../../Components/Reporting/TriageReportingPatientForm';

import { ValidatorForm } from 'react-material-ui-form-validator';
import './TriageReports.scss';

export default function ShadowDetail() {
  let componentId = "ShadowDetail";
  const ui = useUIContext(componentId);
  const [vwPatientData, setVwPatientData] = useState([])
  const [selectedPatientData, setSelectedPatientData] = useState([])
  const [vwAppointmentData, setVwAppointmentData] = useState([])
  const [vwVisitData, setVwVisitData] = useState([])
  const [vwRecallData, setVwRecallData] = useState([])
  const [vwProductPickupData, setVwProductPickupData] = useState([])

  const [patientFirstName, setPatientFirstName] = useState('')
  const [patientLastName, setPatientLastName] = useState('')
  const [patientId, setPatientId] = useState('')
  const [patientPhone, setPatientPhone] = useState('')
  const [patientEmail, setPatientEmail] = useState('')

  const [showPatientSearch, setShowPatientSearch] = useState(true)
  const [showPatientGrid, setShowPatientGrid] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [patientDisplayName, setPatientDisplayName] = useState('')
  let coverKey = -1;
  let lastCoverKey = null;

  const filtersContext = useCoverFiltersContext(componentId, null, async (newContext) => {
    //On filters changed
    coverKey = newContext.coverKey;

    if (lastCoverKey != newContext.coverKey) {
        lastCoverKey = newContext.coverKey;
        setVwPatientData([]);
    }

    let ready = newContext.coverKey != 0;
  });

  const [selectedCover, setSelectedCover] = useState(filtersContext.Values.coverKey);

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(16),
      border: '1px solid #dadde9',
      fontFamily:'Noto Sans, sans-serif',
    },
  }))(Tooltip);

  const VwPatientcolumns = [
    // { field: 'coverKey', title: 'Coverkey', width: 100 , editable: 'never', emptyValue: '-'},
    { field: 'vwPatientID', title: 'VwPatientID', width: 100 , editable: 'never', emptyValue: '-'},
    { field: 'pmsPatientID', title: 'PmsPatientID', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'vwLocationID', title: 'VwLocationID', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'vwProviderID', title: 'VwProviderID', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'patientNumber', title: 'Patient Number', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.patientNumber} placement="top-start"><span>{dataRow.patientNumber}</span></HtmlTooltip>}},
    { field: 'firstName', title: 'First Name', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.firstName} placement="top-start"><span>{dataRow.firstName}</span></HtmlTooltip>}},
    { field: 'lastName', title: 'Last Name', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.lastName} placement="top-start"><span>{dataRow.lastName}</span></HtmlTooltip>}},
      { field: 'email', title: 'Email', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'primaryPhone', title: 'Primary Phone', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.primaryPhone} placement="top-start"><span>{dataRow.primaryPhone}</span></HtmlTooltip>}},
    // { field: 'primaryPhoneType', title: 'Primary Phone Type', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.primaryPhoneType} placement="top-start"><span>{dataRow.primaryPhoneType}</span></HtmlTooltip>}},
    // { field: 'secondaryPhone', title: 'Secondary Phone Type', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.secondaryPhone} placement="top-start"><span>{dataRow.secondaryPhone}</span></HtmlTooltip>}},
    // { field: 'cellPhone', title: 'CellPhone', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'email', title: 'Email', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'address1', title: 'Address1', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'address2', title: 'Address2', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'city', title: 'City', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'state', title: 'State', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'zip', title: 'Zip', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'dob', title: 'DOB', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'ssn', title: 'SSN', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'gender', title: 'Gender', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'preferredLanguage', title: 'Preferred Language', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.preferredLanguage} placement="top-start"><span>{dataRow.preferredLanguage}</span></HtmlTooltip>}},
    // { field: 'title', title: 'title', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'communicationChoice1', title: 'Communication Choice 1', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.communicationChoice1} placement="top-start"><span>{dataRow.communicationChoice1}</span></HtmlTooltip>}},
    // { field: 'communicationChoice2', title: 'Communication Choice 2', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.communicationChoice2} placement="top-start"><span>{dataRow.communicationChoice2}</span></HtmlTooltip>}},
    // { field: 'communicationChoice3', title: 'Communication Choice 3', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.communicationChoice3} placement="top-start"><span>{dataRow.communicationChoice3}</span></HtmlTooltip>}},
    // { field: 'surveyEmailPreference', title: 'Survey Email Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.surveyEmailPreference} placement="top-start"><span>{dataRow.surveyEmailPreference}</span></HtmlTooltip>}},
    // { field: 'surveySMSPreference', title: 'Survey SMS Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.surveySMSPreference} placement="top-start"><span>{dataRow.surveySMSPreference}</span></HtmlTooltip>}},
    // { field: 'surveyVoicePreference', title: 'Survey Voice Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.surveyVoicePreference} placement="top-start"><span>{dataRow.surveyVoicePreference}</span></HtmlTooltip>}},
    // { field: 'surveyPostalPreference', title: 'Survey Postal Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.surveyPostalPreference} placement="top-start"><span>{dataRow.surveyPostalPreference}</span></HtmlTooltip>}},
    // { field: 'reminderEmailPreference', title: 'Reminder Email Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.reminderEmailPreference} placement="top-start"><span>{dataRow.reminderEmailPreference}</span></HtmlTooltip>}},
    // { field: 'reminderSMSPreference', title: 'Reminder SMS Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.reminderSMSPreference} placement="top-start"><span>{dataRow.reminderSMSPreference}</span></HtmlTooltip>}},
    // { field: 'reminderVoicePreference', title: 'Reminder Voice Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.reminderVoicePreference} placement="top-start"><span>{dataRow.reminderVoicePreference}</span></HtmlTooltip>}},
    // { field: 'reminderPostalPreference', title: 'Reminder Postal Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.reminderPostalPreference} placement="top-start"><span>{dataRow.reminderPostalPreference}</span></HtmlTooltip>}},
    // { field: 'recallEmailPreference', title: 'Recall Email Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.recallEmailPreference} placement="top-start"><span>{dataRow.recallEmailPreference}</span></HtmlTooltip>}},
    // { field: 'recallSMSPreference', title: 'Recall SMS Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.recallSMSPreference} placement="top-start"><span>{dataRow.recallSMSPreference}</span></HtmlTooltip>}},
    // { field: 'recallVoicePreference', title: 'Recall Voice Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.recallVoicePreference} placement="top-start"><span>{dataRow.recallVoicePreference}</span></HtmlTooltip>}},
    // { field: 'recallPostalPreference', title: 'Recall Postal Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.recallPostalPreference} placement="top-start"><span>{dataRow.recallPostalPreference}</span></HtmlTooltip>}},
    // { field: 'pickupEmailPreference', title: 'Pickup Email Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.pickupEmailPreference} placement="top-start"><span>{dataRow.pickupEmailPreference}</span></HtmlTooltip>}},
    // { field: 'pickupSMSPreference', title: 'Pickup SMS Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.pickupSMSPreference} placement="top-start"><span>{dataRow.pickupSMSPreference}</span></HtmlTooltip>}},
    // { field: 'pickupVoicePreference', title: 'Pickup Voice Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.pickupVoicePreference} placement="top-start"><span>{dataRow.pickupVoicePreference}</span></HtmlTooltip>}},
    // { field: 'pickupPostalPreference', title: 'Pickup Postal Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.pickupPostalPreference} placement="top-start"><span>{dataRow.pickupPostalPreference}</span></HtmlTooltip>}},
    // { field: 'otherEmailPreference', title: 'Other Email Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.otherEmailPreference} placement="top-start"><span>{dataRow.otherEmailPreference}</span></HtmlTooltip>}},
    // { field: 'otherSMSPreference', title: 'Other SMS Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.otherSMSPreference} placement="top-start"><span>{dataRow.otherSMSPreference}</span></HtmlTooltip>}},
    // { field: 'otherVoicePreference', title: 'Other Voice Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.otherVoicePreference} placement="top-start"><span>{dataRow.otherVoicePreference}</span></HtmlTooltip>}},
    // { field: 'otherPostalPreference', title: 'Other Postal Preference', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.otherPostalPreference} placement="top-start"><span>{dataRow.otherPostalPreference}</span></HtmlTooltip>}},
    // { field: 'status', title: 'Status', width: 150  , editable: 'never' , emptyValue: '-'},
    // { field: 'icD1', title: 'ICD1', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'icD2', title: 'ICD2', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'icD3', title: 'ICD3', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'icD1Date', title: 'ICD1Date', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'icD2Date', title: 'ICD2Date', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'icD3Date', title: 'ICD3Date', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'vwInsurerIDPrimary', title: 'VwInsurerIDPrimary', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'vwInsurerIDSecondary', title: 'VwInsurerIDSecondary', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'vwInsurerIDTertiary', title: 'VwInsurerIDTertiary', width: 150 , editable: 'never', emptyValue: '-'},
    // { field: 'spendingBehavior', title: 'Spending Behavior', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.spendingBehavior} placement="top-start"><span>{dataRow.spendingBehavior}</span></HtmlTooltip>}},
    // { field: 'contactLensWearer', title: 'Contact Lens Wearer', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.contactLensWearer} placement="top-start"><span>{dataRow.contactLensWearer}</span></HtmlTooltip>}},
    // { field: 'primaryInsuranceEIN', title: 'Primary Insurance EIN', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.primaryInsuranceEIN} placement="top-start"><span>{dataRow.primaryInsuranceEIN}</span></HtmlTooltip>}},
    // { field: 'primaryInsuranceGroupID', title: 'Primary Insurance Group ID', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.primaryInsuranceGroupID} placement="top-start"><span>{dataRow.primaryInsuranceGroupID}</span></HtmlTooltip>}},
    // { field: 'secondaryInsuranceGroupID', title: 'Secondary Insurance Group ID', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.secondaryInsuranceGroupID} placement="top-start"><span>{dataRow.secondaryInsuranceGroupID}</span></HtmlTooltip>}},
    // { field: 'tertiaryInsuranceGroupID', title: 'Rertiary Insurance Group ID', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.tertiaryInsuranceGroupID} placement="top-start"><span>{dataRow.tertiaryInsuranceGroupID}</span></HtmlTooltip>}},
    // { field: 'primaryInsurancePolicyNo', title: 'Primary Insurance Policy No', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.primaryInsurancePolicyNo} placement="top-start"><span>{dataRow.primaryInsurancePolicyNo}</span></HtmlTooltip>}},
    // { field: 'secondaryInsurancePolicyNo', title: 'Secondary Insurance Policy No', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.secondaryInsurancePolicyNo} placement="top-start"><span>{dataRow.secondaryInsurancePolicyNo}</span></HtmlTooltip>}},
    // { field: 'tertiaryInsurancePolicyNo', title: 'Tertiary Insurance Policy No', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.tertiaryInsurancePolicyNo} placement="top-start"><span>{dataRow.tertiaryInsurancePolicyNo}</span></HtmlTooltip>}},
    // { field: 'primaryInsurancePlanName', title: 'Primary Insurance Plan Name', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.primaryInsurancePlanName} placement="top-start"><span>{dataRow.primaryInsurancePlanName}</span></HtmlTooltip>}},
    // { field: 'secondaryInsurancePlanName', title: 'Secondary Insurance Plan Name', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.secondaryInsurancePlanName} placement="top-start"><span>{dataRow.secondaryInsurancePlanName}</span></HtmlTooltip>}},
    // { field: 'tertiaryInsurancePlanName', title: 'Tertiary Insurance Plan Name', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.tertiaryInsurancePlanName} placement="top-start"><span>{dataRow.tertiaryInsurancePlanName}</span></HtmlTooltip>}},
    // { field: 'pmsSpecificName1', title: 'Pms Specific Name1', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.pmsSpecificName1} placement="top-start"><span>{dataRow.pmsSpecificName1}</span></HtmlTooltip>}},
    // { field: 'pmsSpecificValue1', title: 'Pms Specific Value1', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.pmsSpecificValue1} placement="top-start"><span>{dataRow.pmsSpecificValue1}</span></HtmlTooltip>}},
    // { field: 'pmsCreatedDate', title: 'pmsCreatedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'pmsLastModifiedDate', title: 'PmsLastModifiedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'lastVisitOrExamDate', title: 'LastVisitOrExamDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'deleted', title: 'Deleted', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'createdDate', title: 'CreatedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'lastModifiedDate', title: 'LastModifiedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'utcCreatedDate', title: 'UtcCreatedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'utcLastModifiedDate', title: 'utcLastModifiedDate', width: 200 , editable: 'never', emptyValue: '-'},
  ];

  const VwAppointmentColumns = [
    { field: 'coverKey', title: 'CoverKey', width: 100 , editable: 'never', emptyValue: '-'}, 
    { field: 'vwPatientID', title: 'VwPatientID', width: 100 , editable: 'never', emptyValue: '-'},  
    { field: 'vwAppointmentID', title: 'VwAppointmentID', width: 100 , editable: 'never', emptyValue: '-'},  
    { field: 'apptStatus', title: 'Status', width: 100 , editable: 'never', emptyValue: '-'},
    { field: 'apptType', title: 'Type', width: 100 , editable: 'never', emptyValue: '-'},
    { field: 'startDate', title: 'Start Date', width: 200 , editable: 'never', emptyValue: '-'},
    { field: 'startTime', title: 'Start Time', width: 200  , editable: 'never', emptyValue: '-'},
    // { field: 'showedUp', title: 'ShowedUp', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'createdDate', title: 'CreatedDate', width: 200  , editable: 'never'},
    //{ field: 'spendAmt', title: 'spendAmt', width: 150  , editable: 'never', emptyValue: '-'}
    // { field: 'Deleted', title: 'Deleted', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'pmsAppointmentID', title: 'pmsAppointmentID', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'vwLocationID', title: 'VwLocationID', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'VwProviderID', title: 'VwProviderID', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'vwReasonID', title: 'VwReasonID', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'vwResourceID', title: 'VwResourceID', width: 100  , editable: 'never' , emptyValue: '-'},
  ]; 

  const VwVisitColumns = [
    { field: 'coverKey', title: 'coverKey', width: 100 , editable: 'never', emptyValue: '-'}, 
    { field: 'vwPatientID', title: 'VwPatientID', width: 100 , editable: 'never', emptyValue: '-'},  
    { field: 'vwVisitID', title: 'VwVisitID', width: 100 , editable: 'never', emptyValue: '-'},  
    { field: 'pmsVisitID', title: 'PmsVisitID', width: 100 , editable: 'never', emptyValue: '-'},  
    { field: 'vwLocationID', title: 'VwLocationID', width: 100  , editable: 'never' , emptyValue: '-'},
    { field: 'vwProviderID', title: 'VwProviderID', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'pmsAppointmentID', title: 'PmsAppointmentID', width: 200 , editable: 'never', emptyValue: '-'},
    { field: 'visitType', title: 'VisitType', width: 100 , editable: 'never', emptyValue: '-'},
    { field: 'visitDate', title: 'VisitDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'visitReason', title: 'visitReason', width: 200  , editable: 'never', emptyValue: '-'}, 
    // { field: 'showedUp', title: 'showedUp', width: 150  , editable: 'never' , emptyValue: '-'},
    // { field: 'icD1', title: 'icD1', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'icD2', title: 'icD2', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'icD3', title: 'icD3', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'cpT1', title: 'cpT1', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'cpT2', title: 'cpT2', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'cpT3', title: 'cpT3', width: 100 , editable: 'never', emptyValue: '-'},
    // { field: 'productType', title: 'productType', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'productBrand1', title: 'productBrand1', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'productBrand2', title: 'productBrand2', width: 200  , editable: 'never'},
    // { field: 'productBrand3', title: 'productBrand3', width: 200  , editable: 'never'},
    // { field: 'spendAmt', title: 'spendAmt', width: 150  , editable: 'never', emptyValue: '-'},
    // { field: 'pmsCreatedDate', title: 'pmsCreatedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'pmsLastModifiedDate', title: 'pmsLastModifiedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'Deleted', title: 'Deleted', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'createdDate', title: 'createdDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'lastModifiedDate', title: 'lastModifiedDate', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'utcCreatedDate', title: 'utcCreatedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'utcLastModifiedDate', title: 'utcLastModifiedDate', width: 100  , editable: 'never' , emptyValue: '-'},  
  ]; 

  const VwRecallColumns = [
    { field: 'coverKey', title: 'CoverKey', width: 100 , editable: 'never', emptyValue: '-'}, 
    { field: 'vwRecallID', title: 'VwRecallID', width: 100 , editable: 'never', emptyValue: '-'},  
    { field: 'pmsRecallID', title: 'PmsRecallID', width: 100 , editable: 'never', emptyValue: '-'},  
    { field: 'vwPatientID', title: 'VwPatientID', width: 100 , editable: 'never', emptyValue: '-'},  
    { field: 'vwLocationID', title: 'VwLocationID', width: 100  , editable: 'never' , emptyValue: '-'},
    { field: 'vwProviderID', title: 'VwProviderID', width: 100  , editable: 'never' , emptyValue: '-'},
    { field: 'recallDate', title: 'RecallDate', width: 200 , editable: 'never', emptyValue: '-'},
    { field: 'vwRecallReasonID', title: 'VwRecallReasonID', width: 100 , editable: 'never', emptyValue: '-'},      
    //  { field: 'deleted', title: 'Deleted', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'createdDate', title: 'createdDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'lastModifiedDate', title: 'lastModifiedDate', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'utcCreatedDate', title: 'utcCreatedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'utcLastModifiedDate', title: 'utcLastModifiedDate', width: 100  , editable: 'never' , emptyValue: '-'},  
  ]; 

  const VwProductPickupColumns = [
    { field: 'coverKey', title: 'CoverKey', width: 100 , editable: 'never', emptyValue: '-'}, 
    { field: 'vwPickupID', title: 'VwPickupID', width: 100 , editable: 'never', emptyValue: '-'},  
    { field: 'pmsOrderID', title: 'PmsOrderID', width: 100 , editable: 'never', emptyValue: '-'},  
    { field: 'vwPatientID', title: 'VwPatientID', width: 100 , editable: 'never', emptyValue: '-'},  
    { field: 'vwLocationID', title: 'VwLocationID', width: 100  , editable: 'never' , emptyValue: '-'},
    { field: 'vwProviderID', title: 'VwProviderID', width: 100  , editable: 'never' , emptyValue: '-'},
    { field: 'orderDate', title: 'OrderDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'receivedDate', title: 'ReceivedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'notifiedDate', title: 'NotifiedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'pickedUpDate', title: 'PickedUpDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'productType', title: 'ProductType', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'productBrand1', title: 'ProductBrand1', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'productBrand2', title: 'ProductBrand2', width: 200  , editable: 'never'},
    // { field: 'productBrand3', title: 'ProductBrand3', width: 200  , editable: 'never'},
    // { field: 'spendAmt', title: 'SpendAmt', width: 100  , editable: 'never', emptyValue: '-'},
    // { field: 'orderStatus', title: 'OrderStatus', width: 100  , editable: 'never', emptyValue: '-'},
    // { field: 'pricedDate', title: 'PricedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'statusChangedDate', title: 'StatusChangedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'Deleted', title: 'Deleted', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'pmsCreatedDate', title: 'pmsCreatedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'pmsLastModifiedDate', title: 'pmsLastModifiedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'createdDate', title: 'createdDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'lastModifiedDate', title: 'lastModifiedDate', width: 100  , editable: 'never' , emptyValue: '-'},
    // { field: 'utcCreatedDate', title: 'utcCreatedDate', width: 200 , editable: 'never', emptyValue: '-'},
    // { field: 'utcLastModifiedDate', title: 'utcLastModifiedDate', width: 100  , editable: 'never' , emptyValue: '-'},  
  ]; 

	const _returnToPatient = () => {
		setShowPatientSearch(false)
		setShowPatientGrid(false)
		setSelectedCover('')
		setPatientFirstName('')
		setPatientLastName('')
		setPatientId('')
		setPatientPhone('')
		setPatientEmail('')
		setPatientDisplayName('')
	}

  const _returnToPatientSearh = () =>{
    setShowPatientSearch(true)
    setShowPatientGrid(false)
    setShowReport(false)
    setPatientFirstName('')
    setPatientLastName('')
    setPatientId('')
    setPatientPhone('')
    setPatientEmail('')
    setPatientDisplayName('')
  }

  const _clearForm = async() => {
    ui.ShowOverlay();
    setShowPatientSearch(true)
    setShowPatientGrid(false)
    setShowReport(false)
    setPatientFirstName('')
    setPatientLastName('')
    setPatientId('')
    setPatientPhone('')
    setPatientEmail('')
    setPatientDisplayName('')
    setVwPatientData([])
    setVwAppointmentData([])
    setVwVisitData([])
    setVwRecallData([])
    setVwProductPickupData([])
    ui.HideOverlay();
  }

  const _getPatientData = async() => {
    let _data = [];
    try {
      ui.ShowOverlay();
      var requestPatient = {
        params: {},
        headers: {'x-crm-apiauth-key': Constants.AuthKey}
      }
      const url = process.env.REACT_APP_TRIAGEREPORTS_API + "/GetShadowPatients?pmsPatientID=" + patientId + "&coverKey=" + selectedCover.toString() + "&FirstName=" + patientFirstName + "&LastName=" + patientLastName + "&PhoneNumber=" + patientPhone + "&Email=" + patientEmail
      const res = await axios.get(url, requestPatient);
      let d = res.data;

	  let g = JSON.stringify(res.data).replace(/null/g, '"-"');
	 let convertedData =JSON.parse(g);

    
      if (res?.data !== 'NotFound') { 
        res.data.forEach((data) => {
          if (data.icD1Date) data.visitDate = moment(data.visitDate).format('MM/DD/YYYY - hh:mm A')
          if (data.icD2Date) data.visitDate = moment(data.visitDate).format('MM/DD/YYYY - hh:mm A')
          if (data.icD3Date) data.visitDate = moment(data.visitDate).format('MM/DD/YYYY - hh:mm A')
          if (data.pmsCreatedDate) data.pmsCreatedDate = moment(data.pmsCreatedDate).format('MM/DD/YYYY - hh:mm A')
          if (data.createdDate) data.createdDate = moment(data.createdDate).format('MM/DD/YYYY - hh:mm A')
          if (data.lastModifiedDate) data.lastModifiedDate = moment(data.lastModifiedDate).format('MM/DD/YYYY - hh:mm A')
          if (data.utcCreatedDate) data.utcCreatedDate = moment(data.utcCreatedDate).format('MM/DD/YYYY - hh:mm A')
          if (data.utcLastModifiedDate) data.utcLastModifiedDate = moment(data.utcLastModifiedDate).format('MM/DD/YYYY - hh:mm A')
          // if (data.deleted) data.deleted = data.deleted === true ? "Yes" : "No"
        })
        setVwPatientData(convertedData) 
        setShowPatientGrid(true)
        setShowPatientSearch(false)
      } else { 
        ui.ShowSnackbar("Patient does not exist in the database.", Severity.warning); 
        _clearForm();
      }
    } catch (error) {
      ui.ShowSnackbar("Invalid search parameter! Please update search value and try again.", Severity.error);
      _clearForm();
    }
    ui.HideOverlay();
  };
    
  const _handleSelectedPatient= async (val) =>{
    let patientData = vwPatientData.filter((p) => p !== undefined && p.vwPatientID === val.vwPatientID);
    setSelectedPatientData(patientData)
    setPatientDisplayName("Patient ID: " + val.pmsPatientID + " | " + val.firstName + " " + val.lastName)
    setShowPatientSearch(false)
    // setShowPatientGrid(false)
  }

  const _getReportData = async (val) => {
    try {
      ui.ShowOverlay();
      _handleSelectedPatient(val)
      var requestAppointment = {
          params: {},
          headers: {'x-crm-apiauth-key': Constants.AuthKey}
      }

      const url = process.env.REACT_APP_TRIAGEREPORTS_API + "/GetShadowAppointments?VwPatientID=" + val.vwPatientID + "&coverKey=" + selectedCover.toString();   
      const res = await axios.get(url, requestAppointment)
      if (res?.data !== 'NotFound') { 
        res.data.forEach((data) => {
          if (data.startDate) data.startDate = moment(data.startDate).format('MM/DD/YYYY - hh:mm A')
          if (data.startTime) data.startTime = moment(data.startTime).format('MM/DD/YYYY - hh:mm A')
          if (data.createdDate) data.createdDate = moment(data.createdDate).format('MM/DD/YYYY - hh:mm A')
          //if (data.showedUp) data.showedUp = data.showedUp === true ? "Yes" : "No"
          // if (data.deleted) data.deleted = data.deleted ? 'Yes' : 'No'
        })
		let g = JSON.stringify(res.data).replace(/null/g, '"-"');
		let convertedData =JSON.parse(g);
        setVwAppointmentData(convertedData) 
      }

      var requestVisit = {
        params: {},
        headers: {'x-crm-apiauth-key': Constants.AuthKey}
      }

      const urlVisit = process.env.REACT_APP_TRIAGEREPORTS_API + "/GetShadowVisits?VwPatientID=" + val.vwPatientID + "&coverKey=" + selectedCover.toString();
      const resVisit = await axios.get(urlVisit, requestVisit)
      if (resVisit?.data !== 'NotFound') { 
        resVisit.data.forEach((data) => {
          if (data.visitDate) data.visitDate = moment(data.visitDate).format('MM/DD/YYYY - hh:mm A')
          if (data.pmsCreatedDate) data.pmsCreatedDate = moment(data.pmsCreatedDate).format('MM/DD/YYYY - hh:mm A')
          if (data.createdDate) data.createdDate = moment(data.createdDate).format('MM/DD/YYYY - hh:mm A')
          // if (data.showedUp) data.showedUp = data.showedUp === true ? "Yes" : "No"
          // if (data.deleted) data.deleted = data.deleted === true ? "Yes" : "No"
        })
		let g = JSON.stringify(resVisit.data).replace(/null/g, '"-"');
		let convertedData =JSON.parse(g);
        setVwVisitData(convertedData) 
      }

      var requestRecall = {
        params: {},
        headers: {'x-crm-apiauth-key': Constants.AuthKey}
      }

      const urlRecall =  process.env.REACT_APP_TRIAGEREPORTS_API + "/GetShadowRecalls?VwPatientID=" + val.vwPatientID + "&coverKey=" + selectedCover.toString();
      const resRecall = await axios.get(urlRecall, requestRecall)
      if (resRecall?.data !== 'NotFound') { 
        resRecall.data.forEach((data) => {
          if (data.recallDate) data.recallDate = moment(data.recallDate).format('MM/DD/YYYY - hh:mm A')
          if (data.createdDate) data.createdDate = moment(data.createdDate).format('MM/DD/YYYY - hh:mm A')
          if (data.lastModifiedDate) data.lastModifiedDate = moment(data.lastModifiedDate).format('MM/DD/YYYY - hh:mm A')
          if (data.utcCreatedDate) data.utcCreatedDate = moment(data.utcCreatedDate).format('MM/DD/YYYY - hh:mm A')
          if (data.utcLastModifiedDate) data.utcLastModifiedDate = moment(data.utcLastModifiedDate).format('MM/DD/YYYY - hh:mm A')
          // if (data.deleted) data.deleted = data.deleted === true ? "Yes" : "No"
        })
		let g = JSON.stringify(resRecall.data).replace(/null/g, '"-"');
		let convertedData =JSON.parse(g);
        setVwRecallData(convertedData) 
      }

      var requestProductPickup = {
        params: {},
        headers: {'x-crm-apiauth-key': Constants.AuthKey}
      }
      
      const urlProductPickup = process.env.REACT_APP_TRIAGEREPORTS_API + "/GetShadowProductPickups?VwPatientID=" + val.vwPatientID + "&coverKey=" + selectedCover.toString();
      const resProductPickup  = await axios.get(urlProductPickup, requestProductPickup )
      if (resProductPickup?.data !== 'NotFound') { 
        resProductPickup.data.forEach((data) => {
          if (data.orderDate) data.orderDate = moment(data.orderDate).format('MM/DD/YYYY - hh:mm A')
          if (data.receivedDate) data.receivedDate = moment(data.receivedDate).format('MM/DD/YYYY - hh:mm A')
          if (data.notifiedDate) data.notifiedDate = moment(data.notifiedDate).format('MM/DD/YYYY - hh:mm A')
          if (data.pricedDate) data.pricedDate = moment(data.pricedDate).format('MM/DD/YYYY - hh:mm A')
          if (data.statusChangedDate) data.statusChangedDate = moment(data.statusChangedDate).format('MM/DD/YYYY - hh:mm A')
          // if (data.deleted) data.deleted = data.deleted === true ? "Yes" : "No"
        })
		let g = JSON.stringify(resProductPickup.data).replace(/null/g, '"-"');
		let convertedData =JSON.parse(g);
        setVwProductPickupData(convertedData) 
      }
    } catch (error) {
      ui.ShowSnackbar("Unexpected error in retrieving patient data.", Severity.error)
    }
    setShowReport(true)
    ui.HideOverlay();
  }  

  const ReportData = () => { 
    return(
      <Box p={4} bgcolor="background.paper">
        <ReportingFormPatient reportType="Patient Information" columns={VwPatientcolumns} data={selectedPatientData} />   
        <ReportingForm reportType='Appointment' columns={VwAppointmentColumns} data={vwAppointmentData} />   
        <ReportingForm reportType='Visit' columns={VwVisitColumns} data={vwVisitData} />   
        <ReportingForm reportType='Recall' columns={VwRecallColumns} data={vwRecallData} />   
        <ReportingForm reportType='ProductPickup' columns={VwProductPickupColumns} data={vwProductPickupData} />  
        <Box p={4} bgcolor="background.paper">
          <Grid container spacing={3}>
            <Grid item xs={12}  sm={12}>
              <Button style={{ width: "200px" }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={_clearForm}>
                Clear Form
              </Button>
            </Grid>      
          {/* <Button style={{ width: "200px" ,marginLeft: "10px" }}
              type="button"
              variant="contained"
              color="primary"
              onClick={_returnToPatientSearh}
            >
              Back
                </Button>          */}                             
          </Grid>   
        </Box>
      </Box>
    )
  }

  const PatientGrid = () => {
    return(
      <Box p={4} bgcolor="background.paper">
        <MaterialTable
          height="200"
          columns={VwPatientcolumns}
          data={vwPatientData}
          title="Select Patient"
          options={{
            toolbar: true,
            paging: false,
            draggable: false,
            sorting: false,
            tableLayout: 'fixed',
            search: false
          }} 
          actions={[
            {
            icon: () => <VisibilityIcon fontSize="small" />,
            tooltip: 'View Data',
            onClick: async (event, rowData) => _getReportData({vwPatientID: rowData.vwPatientID,pmsPatientID:rowData.pmsPatientID, firstName: rowData.firstName, lastName : rowData.lastName  })
            }
          ]}
        /> 
      </Box>
    )
  }
  
  const PatientSearch = () => {
    return(
      <Box p={4} bgcolor="background.paper">
          <Typography variant="h6" gutterBottom>
            Search Patient Information
          </Typography>
          <ValidatorForm onSubmit={_getPatientData} onError={errors => console.log(errors)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField                       
                    id="patientFirstName"                
                    name="patientFirstName"
                    label="Patient First Name"
                    fullWidth      
                    value={patientFirstName}
                    onInput={ e=>setPatientFirstName(e.target.value)}                                
                />         
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField                        
                    id="patientLastName"                
                    name="patientLastName"
                    label="Patient Last Name"
                    fullWidth      
                    value={patientLastName}
                    onInput={ e=>setPatientLastName(e.target.value)}                                
                />    
              </Grid>
              <Grid item xs={12}  sm={4}>
                <TextField
                    id="patientId"                
                    name="patientId"
                    label="Patient ID"
                    fullWidth      
                    value={patientId}
                    onInput={ e=>setPatientId(e.target.value)}                                
                />            
              </Grid>
              <Grid item xs={12}  sm={4}>
                <TextField                          
                  id="patientPhone"                
                  name="patientPhone"
                  label="Patient Phone"
                  fullWidth                    
                  value={patientPhone}
                  onInput={ e=>setPatientPhone(e.target.value)}                                
                  />               
              </Grid>     
              <Grid item xs={12}  sm={4}>
                <TextField
                  id="patientEmail"                
                  name="patientEmail"
                  label="Patient Email"
                  fullWidth                   
                  value={patientEmail}
                  onInput={ e=>setPatientEmail(e.target.value)}                                
                />           
              </Grid>                    
                      
              <Grid item xs={12}  sm={12}>
                <Button style={{ width: "200px" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Search Patient
                </Button>
              </Grid>
          {/* <Button style={{ width: "200px" ,marginLeft: "10px" }}
            type="button"
            variant="contained"
            color="primary"
            onClick={_returnToPatient}
          >
            Back
              </Button>             */}                                 
            </Grid>   
          </ValidatorForm>               
        </Box>
    )                
  };

  return(
  <div className="shadow__detail">
    { patientDisplayName !== '' &&
      <Box p={4} bgcolor="background.paper">
        <Grid container justify="flex-start" alignItems="center" spacing={2}>
          <Typography variant="h6">  
            {patientDisplayName}
          </Typography>
        </Grid>
      </Box>
    }
    <Divider/>  
    { selectedCover && showPatientSearch ? PatientSearch() : null }       
    <Divider />
    { showPatientGrid ? <PatientGrid /> : null }      
    <Divider/>   
    { showReport ? <ReportData /> : null }       
  </div>
  )
}