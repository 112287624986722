
import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import RowDetailsAppointment from './RowDetailsAppointment'
import RowDetailsVisit from './RowDetailsVisit'
import RowDetailsRecall from './RowDetailsRecall'
import RowDetailsProductPickup from './RowDetailsProductPickup'


import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import Overlay from '../Overlay/Overlay';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
      tableRow: {
        "&$selected, &$selected:hover": {
          backgroundColor: "red"
        }
      },      
    },
  });

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };  
  
export default function ReportingRow(props) {
    const { row,columns,type,snackbar,overlay } = props;
    const [open, setOpen] = React.useState(false);
    const [opened, setOpened] = React.useState(true);
    const [data, setData] = React.useState({});
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [showLoadingOverlay, setshowLoadingOverlay] = useState(false);
    const classes = useRowStyles();
    
    useEffect(() => {
      setOpen(false) 
    }, [row]);

    const clickCollapse = async () =>
    {
       if (!open)
       { 
            overlay(true)
            const apiKey = `${process.env.REACT_APP_API_KEY}`;
            const appointmentID = props.row.appointmentId
            const patientID = props.row.pmsPatientID
            const coverKey = props.row.coverKey;
            let url = "" 
            let commType = ""
 
            if (type === "Appointment")
               commType = "Appointment"
            if (type === "Visit")
               commType = "Visit"      
            if (type === "Recall")
               commType = "Recall" 
               if (type === "ProductPickup")
               commType = "ProductPickup" 
            try
            { 
            setData({})
            const res =  props.row
                if (res?.vwPatientID) {
                    let d = res;
                    setData(d)
                    setOpen(true)     
                    overlay(false)
                    // console.log('value', coverkey);
                }   
                else
                {
                  setOpen(false)   
                  snackbar(true);
                  overlay(false)
                }
           }
           catch
           {
                setOpen(false)   
                snackbar(true);
                overlay(false)
           } 
       }
       else
          setOpen(!open) 
    } 
  
    return (
      <React.Fragment>
         <TableRow hover={true}  selected={open} >
          <TableCell>
          <Tooltip title="Show More Details" aria-label="Show More Details">  
            <IconButton aria-label="expand row" size="small" onClick={() => clickCollapse()}>
              {open ? <RemoveRedEyeIcon /> : <RemoveRedEyeOutlinedIcon />}
            </IconButton>
           </Tooltip>
          </TableCell>
          {columns.map((col, index) => {
            return <TableCell key={index}>{row[col.field]}</TableCell>
          })}
        </TableRow>
        {!!data.vwPatientID && <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length+1}>
            <Collapse in={open} timeout="auto" >
                {(open  && type === "Appointment") && <RowDetailsAppointment row={data} otherData={row}/>}
                {(open  && type === "Visit") && <RowDetailsVisit row={data} otherData={row}/>}    
                {(open  && type === "Recall") && <RowDetailsRecall row={data} otherData={row}/>} 
                {(open  && type === "ProductPickup") && <RowDetailsProductPickup row={data} otherData={row}/>} 
            </Collapse>
          </TableCell>
        </TableRow>}          
      </React.Fragment>
          
    );
}