import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography } from '@mui/material';

const ConfirmationModal = ({ title, content, isOpen, onClose, onContinue, btnCloseText, btnContinueText }) => (
  <Dialog open={isOpen}>
    <DialogTitle>{ title }</DialogTitle>
    <DialogContent>
      <Typography variant="body1">{ content }</Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" sx={{ textTransform: 'capitalize' }} onClick={onClose}>
        { btnCloseText }
      </Button>
      <Button variant="contained" color="error" sx={{ textTransform: 'capitalize' }} onClick={onContinue}>
        { btnContinueText }
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationModal;
