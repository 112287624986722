import React, { useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Grid, Paper, Switch, Typography } from "@material-ui/core";
import EventNoteIcon from "@material-ui/icons/EventNote";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ApptRemSettingsMain from "./ApptRemSettingsMain";
import { BorderStyle } from "@material-ui/icons";
import ReminderSchedule from "./ReminderSchedule";
import AppointmentSettings from "./AppointmentSettings";
import { useState } from "react";
import axios from 'axios';
import Overlay from "../../Components/Overlay/Overlay";
const AppointmentSettingsTab = (props) => {
 
  const useMountEffect = (functionName) => useEffect(functionName, []);
  const [showLoadingOverlay,setShowLoadingOverlay]=useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
  }));
  const classes = useStyles();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
        className="p-0 m-0"
      >
        {value === index && (
          <Box p={0} m={0}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }
  const [coverEnabled,setCoverEnabled]=useState(false);
  async function toggleCoverEnabled(tmpData){
    setCoverEnabled(!tmpData); 

    const url = `${process.env.REACT_APP_ARTS_API}/SaveCoverStatus?secretCode=ditac-1&coverkey=${props.coverkey}&isDeleted=${tmpData}`;
    const res =  await axios.post(url); 
    if (res?.data) 
    {
      // console.log(res?.data);
    }


  }
  const TabAppointmentType = () => {
    return (
      <div className={classes.root}>  
      <AppBar position="static"
      style={{backgroundColor:"snow",width:"100%"}}
        color="default" 
       variant="fullWidth"
       >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable"
          style={{ borderColor: "white",paddingTop:"0px" }}
          className="pb-0 mb-0"
        >
          
          <Tab
            label={
              <a className="blue-text font-weight-bold">
                Pre-Appointment
                <br />
              </a>
            }
            icon={<EventNoteIcon />}
            {...a11yProps(0)}
          />
          <Tab
            label={
              <a className="blue-text font-weight-bold">
                Appointment
                <br />
              </a>
            }
            icon={<EventAvailableIcon />}
            {...a11yProps(1)}
          />
        </Tabs></AppBar>
        <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>  
      </div>
    );
  };
 

  async function createDefaultSettings()
  {
    setShowLoadingOverlay(true);
    const url = `${process.env.REACT_APP_ARTS_API}/InitializeDefaultConfiguration?secretCode=ditac-1&coverkey=${props.coverkey}&communicationtypeid=${props.communicationType}`;
    await axios.post(url).then((res)=>{
      
      setShowLoadingOverlay(false);
    }).catch(()=>{
      console.log('error creating default settings.')
    }); 
    
  }

  async function LoadCoverConfigStatus()
  {
    const url = `${process.env.REACT_APP_ARTS_API}/getCoverStatus?secretCode=ditac-1&coverkey=${props.coverkey}`;
    const res =  await axios.get(url); 
    if (res?.data) 
      {
        // toggleCoverEnabled(res.data.isDeleted);
       setCoverEnabled(!res.data.isDeleted);
      }
  }

 

  

  useEffect(()=>{
  
     createDefaultSettings();

  },[props.coverkey,props.communicationType]);

  useEffect(()=>{
  
   
      LoadCoverConfigStatus().then(()=>{
      
       });
   
   

 },[props.coverkey]);

  return (
    <React.Fragment>
<Grid container  style={{padding:"20px",margin:"0px"}}>
          <Grid item md={12} xs={12} >
          <p style={{paddingBottom:"0px",marginBottom:"0px",fontWeight:"bold"}}>
          {"Appointment Reminder Settings" } &nbsp;<Switch
                    checked={coverEnabled}
                   onChange={()=>toggleCoverEnabled(coverEnabled)}
                    color="primary"
                  /> 
                  
                  </p>
          
          </Grid>
        </Grid>
      <TabAppointmentType />
  
    
      <TabPanel value={value} index={0} style={{borderColor:"black",BorderStyle:"solid"}}>
       
      
        <AppointmentSettings appointmenttype={2} coverkey={props.coverkey} />
      </TabPanel>
      <TabPanel value={value} index={1}>
     
     
        <AppointmentSettings appointmenttype={1} coverkey={props.coverkey} />
        
      </TabPanel>
     
    </React.Fragment>
  );
};

export default AppointmentSettingsTab;
