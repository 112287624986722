import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SelectWrapper } from "./SelectWrapper";

import FormControl from "@material-ui/core/FormControl";

import "./ApptReminder.scss";

import { TimePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  dropdownfield: {
    minWidth: "300px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
}));

const ApptRemSettingsMain = (props) => {
  const classes = useStyles();

  const useMountEffect = (functionName) => useEffect(functionName, []);

  const [frm, setValues] = useState({
    appointmentstatus: { value: "1", label: "Kept" },
    appointmentreason: { value: "1", label: "Glaucoma" },
  });
  const handleChange = (objName) => (selOption) => {
    setValues({ ...frm, [objName]: selOption });
  };

  const [selectedAppointmentReason, setAppointmentReason] = useState([
    frm.appointmentreason,
  ]);
  const AppointmentReasonOptions = [
    { value: "1", label: "Glaucoma" },
    { value: "2", label: "Comprehensive Eye" },
    { value: "3", label: "Retina Eye Exam" },
  ];
  const AppointmentStatusOptions = [
    { value: "1", label: "Confirmed" },
    { value: "2", label: "Kept" },
  ];

  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (selectedAppointmentReason != []) {
      setValues({ ...frm, ["appointmentreason"]: selectedAppointmentReason });
    }
  }, [selectedAppointmentReason]);

  console.log(
    "Appointment Reason: " +
      frm.appointmentreason.value +
      " " +
      frm.appointmentreason.label
  );
  return (
    <React.Fragment>
    
    <Grid container>
        <Grid  item md={12} xs={12}>
            <span><b>Appointment Status</b></span>
          </Grid>
          <Grid  item md={12} xs={12} >
          <span>Inclusions</span>
        </Grid>
      
        <Grid  item md={6} xs={6} style={{paddingRight:"40px"}}>
        <Select
            placeholderText="Select Appointment Type"
            isSearchable
            isMulti
            value={frm.appointmentstatus}
            onChange={handleChange("appointmentstatus")}
            options={AppointmentStatusOptions}
            required
            //   isDisabled={disabled}
          />
          </Grid></Grid>

        <Grid container>
        <Grid  item md={12} xs={12}>
            <span><b>Appointment Reasons</b></span>
          </Grid>
          <Grid  item md={6} xs={6} >
          <span>Inclusions</span>
        </Grid>
        <Grid  item md={6} xs={6}>
        <span>Exclusions</span>
          </Grid>
        <Grid  item md={6} xs={6} style={{paddingRight:"40px"}}>
         <SelectWrapper
            options={AppointmentReasonOptions}
            value={selectedAppointmentReason}
            onChange={setAppointmentReason}
          />
        </Grid>
        <Grid  item md={6} xs={6} style={{paddingRight:"40px"}}>
       <SelectWrapper
            options={AppointmentReasonOptions}
            value={selectedAppointmentReason}
            onChange={setAppointmentReason}
          />
          </Grid></Grid>
       
        
      
     

     

      <Box sx={{ paddingBottom: "0px",paddingTop:"50px" }}>
        <span><b>Reminder Schedule</b></span>
      </Box>
      <Grid container>
        {/* Email */}
          <Grid item md={4} xs={4}> 
          <span 
          // className="rowItemHeader2"
           style={{ paddingTop: "50px" }}>
            <u>Email</u>
          </span>
          <br />
          Days before appt:
          <br />
          <FormControl variant="outlined" style={{ width: 100 }}>
            <TextField
              value={""}
              variant="outlined"
              size="small"
              style={{ marginRight: 10, height: 30 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">days</InputAdornment>
                ),
              }}
            />
          </FormControl>
          </Grid>
          {/* SMS */}
          <Grid item md={4} xs={4}>
          <span 
          // className="rowItemHeader2" 
          style={{ paddingTop: "50px" }}>
           <u>SMS</u> 
          </span>
          <br />
          Days before appt:
              <br />
              <FormControl variant="outlined" style={{ width: 100 }}>
                <TextField
                  value={""}
                  variant="outlined"
                  size="small"
                  style={{ marginRight: 10, height: 30 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">days</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
          </Grid>
          {/* Voice */}
          <Grid item md={4} xs={4}> 
          <span 
          // className="rowItemHeader2"
          >
            <u>Voice</u>
            </span>
          <br />
          Days before appt:
          <br />
          <FormControl variant="outlined" style={{ width: 100 }}>
            <TextField
              value={""}
              variant="outlined"
              size="small"
              style={{ marginRight: 10, height: 30 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">days</InputAdornment>
                ),
              }}
            />
          </FormControl>
          </Grid>
      </Grid>

      {/* SMS Additional Settings*/}
     <Box>
      <Grid container style={{paddingTop:"50px"}}>
          <Grid item md={12} xs={12}> 
          <span 
          // className="rowItemHeader2"
          >
           <u> SMS Additional Settings</u>
            </span>
         
          <br />
          </Grid>
      </Grid>
     </Box>
      <Grid container>
        <Grid item md={4} xs={6}>
        Start Time:
              <br />
              <TextField
                clearable
                id="time"
                label="Start"
                ampm={false}
                type="time"
                defaultValue="08:00"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
              <br />
        </Grid>
        <Grid item md={4} xs={6}>
        
             
              End Time:
              <br />
              <TextField
                clearable
                id="time"
                label="Start"
                ampm={false}
                type="time"
                defaultValue="19:00"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
              <br />
        </Grid>
        <Grid item md={4} xs={6}>
        
             
              Actual Time:
              <br />
              <TextField
                clearable
                id="time"
                label="Actual"
                ampm={false}
                type="time"
                defaultValue="16:00"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
              <br />
        </Grid>
        <Box>
      <Grid container style={{paddingTop:"50px"}}>
          <Grid item md={12} xs={12}> 
          <span >Appointment Before 11:00 AM</span>
         
          <br />
          </Grid>
      </Grid>
     </Box>
     <Grid container>
        <Grid item md={4} xs={4}>
        Appointment Day:
              <br />
              <FormControl variant="outlined" style={{ width: 170 }}>
                <Select value={""} label="Type" style={{ height: 30 }}>
                  <MenuItem value={1}>Monday</MenuItem>
                  <MenuItem value={2}>Sunday</MenuItem>
                </Select>
              </FormControl>
        </Grid>
        <Grid item md={4} xs={4}>
        SMS Day:
              <br />
              <FormControl variant="outlined" style={{ width: 170 }}>
                <Select value={""} label="Type" style={{ height: 30 }}>
                  <MenuItem value={1}>Monday</MenuItem>
                  <MenuItem value={2}>Sunday</MenuItem>
                </Select>
              </FormControl>
      </Grid>
     </Grid>
        
      </Grid>
      {/* End of Main Grid */}
    </React.Fragment>
  );
};

export default ApptRemSettingsMain;
