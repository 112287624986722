import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export default function SnackBarHelper({
  openSnackBar,
  setOpenSnackBar,
  AlertMessage,
  Severity,
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={openSnackBar}
      autoHideDuration={3000}
      onClose={() => setOpenSnackBar(false)}
    >
      <MuiAlert elevation={6} variant="filled" severity={Severity}>
        {AlertMessage}
      </MuiAlert>
    </Snackbar>
  );
}
