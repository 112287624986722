import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Utils from '../../utils';
import Constants from '../../constants';
import { withTranslation } from 'react-i18next';
import Storage from '../../storage';
import Overlay from '../../Components/Overlay/Overlay';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MaterialTable, { MTableToolbar } from "material-table";
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AppBar, Tabs, Tab, TextField, Paper } from '@material-ui/core';
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import { TabContext } from '@material-ui/lab';
import { TabPanel } from '../../ContextLib/CoreConsumer/Components/TabPanel';
import './Campaign.scss';
import AppointmentReminderSettings from "../AppointmentReminder/AppointmentReminderSettings";
import EventIcon from '@material-ui/icons/Event';
import AppointmentSettings from '../AppointmentReminder/AppointmentSettings';
import AppointmentSettingsTab from '../AppointmentReminder/AppointmentSettingsTab';

import Locations from '../AppointmentReminder/Locations';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};  



class CoverSearch extends Component {
  constructor(props) {
        super(props);    

    this.state = {
        data : [],
        coverData : [],
        provData: [],
        provTableData:[],
        redirectToLogin: false,
        showLoadingOverlay: false,
        coverKey : '',
        openSnackBar : false,
        SnackbarMessage : '',
        recordsFound : '',
        communicationType : null,
        errorSeverity : 'success',
        selectedRow: null,
        advDisabled: true,
        showAdvanced: false,
        advData: [],
        recallSrcData: [],
        isCommDropdownDisabled: true,
        isCommTypeDisabled: true,
        env: `${process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT}`,
        setValueTab : 0
    };    
    this.logoUrl = Utils.getLogoUrl();
  };


  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const user = Storage.getItem(Constants.currUserKey);
    if (user && user.jwt) {
        try {
          this.setState(() => ({ showLoadingOverlay: true, coverKey: '' }));
          const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
          await axios.get(jwtCheckUrl);
          this.setState(() => ({ showLoadingOverlay: false }));
        }
        catch {
            this.setState(() => ({
                redirectToLogin: true
            }));
        }

    } 
    else {
        this.setState(() => ({ redirectToLogin: true }));
        return;
    }       
  }
  
  _handleTextFieldChange = (ck) => {
    this.setState(() => ({ coverKey: ck }));   
    this.setState(() => ({ isCommTypeDisabled: true, communicationType: "", advDisabled: true }));  
    };
    
  _handleTypedChange = (commType) => {
    const adv = commType == 7  ? false: true ;
    this.setState(() => ({ communicationType: commType, advDisabled: adv }));   
    };  

   _handleToggle  =  async () =>
   {
     if (this.state.coverKey !== "" && this.state.communicationType !== 0)
     {
        const apiKey = `${process.env.REACT_APP_API_KEY}`; 
        this.setState(() => ({ showLoadingOverlay: true, showAdvanced: false}));
      // let ck = this.coverKey;
        const url = `${process.env.REACT_APP_CAMPAIGN_API}/SearchByCoverKeyAndCommunicationId/${this.state.coverKey}/${this.state.communicationType}?secretCode=${apiKey}`;
        const res =  await axios.get(url);

        const providerUrl = `${process.env.REACT_APP_CAMPAIGN_API}/SearchProviderCommunicationXrefByCoverKeyAndCommunicationIdAndAcctKey/${this.state.communicationType}/${this.state.coverKey}?secretCode=${apiKey}`;
        const providerRes =  await axios.get(providerUrl);

        if (res?.data) {
            const d = res.data;
            const p = providerRes.data;

            if(p.length > 0)
              {
                await Promise.all(p.map(async (prov) => {
                  try {
                    const j45AcctUrl = `${process.env.REACT_APP_J45_API}/practices/${this.state.coverKey}/Providers/${prov.acctKey}`;
                    const j45AcctRes = await axios.get(j45AcctUrl);
                    if (j45AcctRes?.data) {
                      prov.provName = `${j45AcctRes.data.firstName} ${j45AcctRes.data.lastName}`;
                    }
                    return prov;
                  } catch (error) {
                    console.log(`Error getting provider name for acctKey ${prov.acctKey}: ${error}`);
                  }
                }));
              }
            
            let s = 0;
            d.forEach(loc => {
              if(loc.status == 1)
                {
                  s = 1;
                }
            });
            let c = [{
              id: 1,
              status: s, //set to 1 if any enabled locs
              coverKey: this.state.coverKey,
              locsEnabled: this._getNumEnabled(d),
              providersEnabled: this._getNumEnabled(p)
            }];
            if(d.length === 0) c =[];

            let a = [];
              if(this.state.communicationType === 7)
              {
                const advUrl = `${process.env.REACT_APP_SCENARIO_API}/GetCoverScenarioInfo/?coverKey=${this.state.coverKey}&secretCode=${apiKey}`;
                const advRes = await axios.get(advUrl);
                a = advRes.data;
              }   

              //Get Recall Table Source
              let r = [];
              if(this.state.communicationType === 7)
              {
                const recallSrcUrl = `${process.env.REACT_APP_RECALL_DATE_TRIGGER_API}/GetCoverRecallDateTrigger?coverKey=${this.state.coverKey}&secretCode=${apiKey}`;
                const recallSrcRes = await axios.get(recallSrcUrl);
                r = recallSrcRes.data;
              } 

            this.setState(() => ({ data: d, provData: p, coverData: c, provTableData: [], advData: a, recallSrcData: r }));
        }
        else   
          this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info', openSnackBar: true }));     
          this.setState(() => ({ showLoadingOverlay: false })); 
     }
     else
     {
          this.setState(() => ({ errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Invalid parameter query.'})); 
     }      

   }

   _getNumEnabled = (data) =>
   {
    const count = `${data.filter(d => d.status == 1).length}/${data.length}`;
    return count;
   }

   _getScenarioAndRecallTrigger = async () =>
   {
     const ad = this.state.advData;
     if(ad.length > 0)
     {
       this.setState(() => ({ showLoadingOverlay: true }));
       const apiKey = `${process.env.REACT_APP_API_KEY}`; 
       let a = [];
       if(this.state.communicationType === 7)
       {
         const advUrl = `${process.env.REACT_APP_SCENARIO_API}/GetCoverScenarioInfo/?coverKey=${this.state.coverKey}&secretCode=${apiKey}`;
         const advRes = await axios.get(advUrl);
         a = advRes.data;
       }  
       
       //Get Recall Table Source
       let r = [];
       if(this.state.communicationType === 7)
       {
         const recallSrcUrl = `${process.env.REACT_APP_RECALL_DATE_TRIGGER_API}/GetCoverRecallDateTrigger?coverKey=${this.state.coverKey}&secretCode=${apiKey}`;
         const recallSrcRes = await axios.get(recallSrcUrl);
         r = recallSrcRes.data;
       } 
        this.setState(() => ({advData: a, recallSrcData: r, showLoadingOverlay: false}));
     }
 
   }

   _UpdateCover = async (e,updateData) =>
   {
    
    var d = this.state.data;
    var s = Number(updateData.status);

    d.forEach(async loc => {
      loc.status = s;
      await this._UpdateRecord(null, loc);
    });

    const dataUpdate = [{...this.state.coverData[0], status: s}];
    this.setState(() => ({ coverData: dataUpdate}));
   }

   //update location record (and associated providers)
   _UpdateRecord  =  async (e, updateData, updateProvs=true) =>
   {
    try {
        const userId = Storage.getItem('userId');
        const dto = {'CoverKey' : updateData.coverKey, 'LocKey' : updateData.locKey, 'CommunicationTypeId': Number(this.state.communicationType), 'Status' : Number(updateData.status), 'UpdatedBy' : userId};
        const apiKey = `${process.env.REACT_APP_API_KEY}`; 
        this.setState(() => ({ showLoadingOverlay: true }));
        const url = `${process.env.REACT_APP_CAMPAIGN_API}/InsertOrDelete?secretCode=${apiKey}`;
        const res =  await axios.post(url,dto);    
        if (res?.data) {
          var s = Number(updateData.status);
          var d = this.state.data;
          d.forEach(loc => {
            if(loc.locKey == updateData.locKey)
            {
              loc.status = s;
            }
              
          });
          var p = this.state.provData;

          if(updateProvs)
          {
            p.forEach(async prov => {
              if(prov.locKey == updateData.locKey)
              {
                prov.status = s;
                await this._UpdateProvider(null,prov);
              }
            });
          }
          

          let cs = 0;
          d.forEach(loc => {
            if(loc.status == 1)
              {
                cs = 1;
              }
          });

          const c = [{...this.state.coverData[0], locsEnabled: this._getNumEnabled(d), status: cs}];
          this.setState(() => ({ data: d, coverData: c,  errorSeverity: 'success', showLoadingOverlay: false,openSnackBar: true, SnackbarMessage: 'Successfully updated the record.'})); 
        }
        else
          this.setState(() => ({ showLoadingOverlay: false, errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Error in update.'})); 

      }
      catch {
        this.setState(() => ({ showLoadingOverlay: false, errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Error in update.'})); 
      }        
   }

   _UpdateProvider  =  async (e, updateData) =>
   {
    try {
      const userId = Storage.getItem('userId');
      const dto = {'AcctKey' : Number(updateData.acctKey) ,'ProviderId' : Number(updateData.providerId),'CoverKey' : updateData.coverKey, 'LocKey' : updateData.locKey, 'CommunicationTypeId': Number(this.state.communicationType), 'Status' : Number(updateData.status), 'UpdatedBy' : userId};
      const apiKey = `${process.env.REACT_APP_API_KEY}`; 
      this.setState(() => ({ showLoadingOverlay: true }));
      const url = `${process.env.REACT_APP_CAMPAIGN_API}/Provider/InsertOrDelete?secretCode=${apiKey}`;
      const res =  await axios.post(url,dto);    
      if (res?.data) {
        var s = Number(updateData.status);
        var d = this.state.provData;
        var ld = this.state.data;

        d.forEach(prov => {
            if(prov.id == updateData.id)
            {
              prov.status = s;
            }
              
          });
        
        ld.forEach(async loc => {
            if(s==1 && loc.locKey == updateData.locKey && loc.status != s)
            {
              loc.status = s;
              await this._UpdateRecord(null, loc, false);
            }
        });
        
        const c = [{...this.state.coverData[0], providersEnabled: this._getNumEnabled(d)}];
        this.setState(() => ({ provData: d, coverData: c,  errorSeverity: 'success', showLoadingOverlay: false,openSnackBar: true, SnackbarMessage: 'Successfully updated the record.'})); 
        this.setState(() => ({ errorSeverity: 'success', showLoadingOverlay: false,openSnackBar: true, SnackbarMessage: 'Successfully updated the record.'})); 
      }
      else
        this.setState(() => ({ showLoadingOverlay: false, errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Error in update.'})); 

    }
    catch(e) {
      this.setState(() => ({ showLoadingOverlay: false, errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Error in update.'})); 
    }        
 }

  _searchCoverKey = async (coverkey) => {
    try {  
      if (this.state.communicationType !== 0 && coverkey !== "")
      {
            const apiKey = `${process.env.REACT_APP_API_KEY}`;
            this.setState(() => ({ showLoadingOverlay: true, showAdvanced: false }));
            const url = `${process.env.REACT_APP_CAMPAIGN_API}/SearchByCoverKeyAndCommunicationId/${coverkey}/${this.state.communicationType}/?secretCode=${apiKey}`;
            const res =  await axios.get(url);

            const providerUrl = `${process.env.REACT_APP_CAMPAIGN_API}/SearchProviderCommunicationXrefByCoverKeyAndCommunicationIdAndAcctKey/${this.state.communicationType}/${this.state.coverKey}?secretCode=${apiKey}`;
            const providerRes =  await axios.get(providerUrl);

            

            let title = '';
            if (res?.data) {
              let d = res.data;
              console.log(res.data);
              let p = providerRes.data;

              if(p.length > 0)
              {
                p.forEach(async (prov) =>  {
                  try{
                  const j45AcctUrl = `${process.env.REACT_APP_J45_API}/practices/${this.state.coverKey}/Providers/${prov.acctKey}`;
                  const j45AcctRes = await axios.get(j45AcctUrl);
                  if(j45AcctRes?.data)
                    prov.provName = `${j45AcctRes.data.firstName} ${j45AcctRes.data.lastName}`;
                  }
                  catch{
                    console.log(`error getting provider name for acctKey ${prov.acctKey}`);
                  }
                });
              }

              const locCount = d.filter(loc => loc.status === 1).length;
              const provCount = p.filter(prov => prov.status === 1).length;

              let s = 0;
              d.forEach(loc => {
                if(loc.status == 1)
                {
                  s = 1;
                }
              });

              let c = [{
                id: 1,
                status: s, //set to 1 if any enabled locs
                coverKey: this.state.coverKey,
                locsEnabled: `${locCount}/${d.length}`,
                providersEnabled: `${provCount}/${p.length}`
              }];
              if(d.length === 0) c =[];

              if (d.length > 0)
                 title = (d.length) + " record/s found.";
              else   
                 this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info',openSnackBar: true }));

              
              let a = [];
              if(this.state.communicationType === 7)
              {
                const advUrl = `${process.env.REACT_APP_SCENARIO_API}/GetCoverScenarioInfo/?coverKey=${this.state.coverKey}&secretCode=${apiKey}`;
                const advRes = await axios.get(advUrl);
                a = advRes.data;
              }  
              
              //Get Recall Table Source
              let r = [];
              if(this.state.communicationType === 7)
              {
                const recallSrcUrl = `${process.env.REACT_APP_RECALL_DATE_TRIGGER_API}/GetCoverRecallDateTrigger?coverKey=${this.state.coverKey}&secretCode=${apiKey}`;
                const recallSrcRes = await axios.get(recallSrcUrl);
                r = recallSrcRes.data;
              } 

            this.setState(() => ({ data: d, provData: p, coverData: c, provTableData: [], advData: a, recallSrcData: r }));
              
            }     
            else   
                 this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info', openSnackBar: true }));

            this.setState(() => ({ showLoadingOverlay: false,recordsFound : title }));
      }
      else
      {
           this.setState(() => ({ errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Invalid parameter query.'})); 
      }      
    }
    catch {

      this.setState(() => ({  errorSeverity: 'error',showLoadingOverlay: false, openSnackBar: true, SnackbarMessage: 'Error in searching the coverkey.'})); 
    }     
  }

  _searchProgramAssociationId = async (coverkey) => {
    try {
        if (coverkey !== "") {
            const apiKey = `${process.env.REACT_APP_API_KEY}`;
            this.setState(() => ({ showLoadingOverlay: true }));
            const url = `${process.env.REACT_APP_CAMPAIGN_API}/SearchProgramAssociationId/${this.state.coverKey}?secretCode=${apiKey}`;
            const res = await axios.get(url);

            if(res.data === ''){
              this.setState(() => ({ isCommTypeDisabled: true, isCommDropdownDisabled: true }));
              this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info', openSnackBar: true }));  
            }
            else{
              let d = res.data
              if (d.programAssociationId == 2) {
                  this.setState(() => ({ isCommDropdownDisabled: false,  isCommTypeDisabled: false }));
              }
              else {
                  this.setState(() => ({ isCommDropdownDisabled: true,  isCommTypeDisabled: false }));
              }
            } 
        }
    }
    catch (error) {
      this.setState(() => ({  SnackbarMessage: 'Coverkey doesn\'t exist!',errorSeverity: 'info', openSnackBar: true }));     
    }

    this.setState(() => ({ showLoadingOverlay: false })); 
}

  _setProviderTableData = (rowData) => {
    let pTableData = [];
    pTableData = this.state.provData.filter(prov => prov.locKey === rowData.locKey);

    this.setState(() => ({ provTableData:pTableData}));
  }

  _setSelectedRow = (id) =>
  {
    this.setState(() => ({selectedRow: id}));
  }

  _getScenarioAndRecallTrigger = async () =>
  {
    
    if(this.state.advData.length === 0)
    {
      this.setState(() => ({ showLoadingOverlay: true }));
      const apiKey = `${process.env.REACT_APP_API_KEY}`; 
      let a = [];
      if(this.state.communicationType === 7)
      {
        const advUrl = `${process.env.REACT_APP_SCENARIO_API}/GetCoverScenarioInfo/?coverKey=${this.state.coverKey}&secretCode=${apiKey}`;
        const advRes = await axios.get(advUrl);
        a = advRes.data;
      }  
      
      //Get Recall Table Source
      let r = [];
      if(this.state.communicationType === 7)
      {
        const recallSrcUrl = `${process.env.REACT_APP_RECALL_DATE_TRIGGER_API}/GetCoverRecallDateTrigger?coverKey=${this.state.coverKey}&secretCode=${apiKey}`;
        const recallSrcRes = await axios.get(recallSrcUrl);
        r = recallSrcRes.data;
      } 
       this.setState(() => ({advData: a, recallSrcData: r, showLoadingOverlay: false}));
    }

  }

  _toggleAdvancedEdit = () =>
  {
    const adv = !this.state.showAdvanced;
    this._getScenarioAndRecallTrigger();
    this.setState(() => ({showAdvanced: adv}));
  }

  



  _updateRecallModality = async (checked, rowData, modality) =>
  {
    let ad = this.state.advData;
    ad.forEach(data => {
      if(data.scenarioTypeId === rowData.scenarioTypeId)
      {
        data.modalityId = modality;
        data.isDeleted =  checked ? 0: 1;
      }
    });

/*     if(checked)
    { */
      this.setState(() => ({ showLoadingOverlay: true }));
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      const advUrl = `${process.env.REACT_APP_SCENARIO_API}/UpdateCoverScenarioXref/?secretCode=${apiKey}`;

      const update = {
        scenarioTypeId : rowData.scenarioTypeId,
        coverKey : Number(this.state.coverKey),
        modalityId : modality,
        isDeleted : checked ? 0: 1
      };

      const advRes = await axios.post(advUrl, update);
   // }

    this.setState(() => ({advData: ad, showLoadingOverlay: false}));
  }

  _updateRecallSource= async (checked, rowData, recallTriggerCode) =>
  {
    const userId = Storage.getItem('userId');
    let ad = this.state.recallSrcData;

    this.setState(() => ({ showLoadingOverlay: true }));
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const recallSrcUrl = `${process.env.REACT_APP_RECALL_DATE_TRIGGER_API}/InsertORUpdateRecallDateTrigger?secretCode=${apiKey}`;
   
    const update = {
        coverKey : Number(this.state.coverKey),
        triggerCode : recallTriggerCode,
        userId : userId
      };
      let r = [];
      const recallSrcRes = await axios.post(recallSrcUrl, update)
      r= recallSrcRes.data;
      this.setState(() => ({recallSrcData: r, showLoadingOverlay: false}));
  }

  _updateRecallStatus = async (checked, rowData) =>
  {
    this.setState(() => ({ showLoadingOverlay: true }));

    let s = Number(!checked);
    let ad = this.state.advData;
    ad.forEach(data => {
      if(data.scenarioTypeId === rowData.scenarioTypeId)
      {
        data.status = Number(checked);
      }
    });
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const advUrl = `${process.env.REACT_APP_SCENARIO_API}/UpdateCoverScenarioXref/?secretCode=${apiKey}`;

    const update = {
      scenarioTypeId : rowData.scenarioTypeId,
      coverKey : Number(this.state.coverKey),
      modalityId : rowData.modalityId,
      isDeleted : s
    };

    const advRes = await axios.post(advUrl, update);
    this.setState(() => ({advData: ad, showLoadingOverlay: false}));

  }

_handleTabChange = (event, newValue) => {
    this.setState(() => ({setValueTab: newValue}));
  };




    /**
   * Renders the component.
   */
  render() {
    const {
      redirectToLogin,
      data,
      coverData,
      provData,
      provTableData,
      showLoadingOverlay,
      openSnackBar,
      SnackbarMessage,
      recordsFound,
      errorSeverity,
      coverKey,
      communicationType,
      selectedRow,
      advDisabled,
      showAdvanced,
      advData,
      recallSrcData
    } = this.state;


    const coverColumns = [
      {
        title: 'Status',
        field: 'status',
        width: 50,
        cellStyle: {padding:10},
        headerStyle: {padding:20},
        render: (rowData) => 
        <Switch 
          checked={rowData.status} 
          onChange={(event) => this._UpdateCover(event, {...rowData, status:event.target.checked})}
          color="primary"
        />
      },  
      { field: 'coverKey', title: 'CoverKey', width: 70 , editable: 'never' },
      { field: 'locsEnabled', title: 'Locations Enabled', width: 70 , editable: 'never'},
      { field: 'providersEnabled', title: 'Providers Enabled', width: 200 , editable: 'never' },
    ];
    
    const locColumns = [
      { field: 'id', title: '#', width: 25, cellStyle: {padding:10} , editable: 'never'},
      {
        title: 'Status',
        field: 'status',
        render: (rowData) => 
        <Switch 
          checked={rowData.status} 
          onChange={(event) => this._UpdateRecord(event, {...rowData, status:event.target.checked})}
          color="primary"
        />,
        width: 75,
        cellStyle: {padding:10},
        headerStyle: {padding:20}
      },  
      { field: 'coverKey', title: 'CoverKey', width: 70, cellStyle: {padding:10} , editable: 'never' },
      { field: 'locKey', title: 'LocKey', width: 70, cellStyle: {padding:10}  , editable: 'never'},
      { field: 'locName', title: 'Location Name', width: 175, cellStyle: {padding:10} , editable: 'never' },
    ];
  
    const provColumns = [
      {
        title: 'Status',
        field: 'status',
        render: (rowData) => 
        <Switch 
          checked={rowData.status} 
          onChange={(event) => this._UpdateProvider(event, {...rowData, status:event.target.checked})}
          color="primary"
          />,
        width: 50,
        cellStyle: {padding:10},
        headerStyle: {padding:20}
      },  
      { field: 'acctKey', title: 'AcctKey', width: 70, cellStyle: {padding:10} , editable: 'never' },
      { field: 'provName', title: 'Provider Name', width: 70, cellStyle: {padding:10}  , editable: 'never'},
    ];

    const advColumns = [
      
      { field: 'month', title: 'Month', width: 15, cellStyle: {padding:10} , editable: 'never' },
      { 
        field: 'modalityId', 
        title: 'Email',
        render: (rowData) => 
        <Switch
             checked={rowData.modalityId === 1 && rowData.isDeleted == 0}
             onChange={(event) => this._updateRecallModality(event.target.checked, rowData, 1)}
             color="primary"
        />
        ,
        width: 50, cellStyle: {padding:10} },
        { 
          field: 'modalityId', 
          title: 'SMS',
          render: (rowData) => 
          <Switch
               checked={rowData.modalityId === 2 && rowData.isDeleted == 0}
               onChange={(event) => this._updateRecallModality(event.target.checked, rowData, 2)}
               color="primary"
          />
          ,
          width: 50, cellStyle: {padding:10} },
          { 
            field: 'modalityId', 
            title: 'Voice',
            render: (rowData) => 
            <Switch
                 checked={rowData.modalityId === 3 && rowData.isDeleted == 0}
                 onChange={(event) => this._updateRecallModality(event.target.checked, rowData, 3)}
                 color="primary"
            />
            ,
            width: 50, cellStyle: {padding:10} },                  

    ];

    


    const advApptReminderColumns = [
      {
        title: 'Type',
        field: 'apptReminderType',
        render: (rowData) => (
          <FormControl variant="outlined" style={{ width: 170}}>
            <Select
              labelId={`select-label-${rowData.tableData.id}`}
              value={rowData.type || ''}
              label="Type"
              style={{ height: 30 }}
            >
              <MenuItem value={1}>Normal</MenuItem>
              <MenuItem value={2}>Pre-Appointment</MenuItem>
            </Select>
          </FormControl>
        ),
        width: 75,
        cellStyle: {padding:10},
        headerStyle: {padding:10}
      },  
        { 
          title: 'Reason', 
          field: 'apptReminderReason',
          render: (rowData) => (
            <FormControl variant="outlined" style={{ width: 190 }}>
              <TextField
                labelId={`select-label-${rowData.tableData.id}`}
                value={rowData.Reason || ''}
                variant="outlined"
                size="small"
                style={{ height: 30}}
              >
              </TextField>
            </FormControl>
          ),
        }
    ];

    const advApptReminderCadenceColumns = [
    
      { 
        field: 'apptReminderEmail', 
        title: 'Email',
        render: (rowData) => (
          <FormControl variant="outlined" style={{ width: 100 }}>
           <TextField
            value={rowData.Email || ''}
            variant="outlined"
            size="small"
            style={{ marginRight: 10, height:30 }}
            
            InputProps={{
              endAdornment: <InputAdornment position="end">days</InputAdornment>,
            }}
          />
          </FormControl>
        ),
        width: 50, cellStyle: {padding:10} },
        { 
          field: 'apptReminderSMS', 
          title: 'SMS',
          render: (rowData) => (
            <FormControl variant="outlined" style={{ width: 100 }}>
             <TextField
              value={rowData.SMS || ''}
              variant="outlined"
              size="small"
              style={{ marginRight: 10, height:30 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">days</InputAdornment>,
              }}
            />
            </FormControl>
          ),
          width: 50, cellStyle: {padding:10} },
          { 
            field: 'apptReminderVoice', 
            title: 'Voice',
            render: (rowData) => (
              <FormControl variant="outlined" style={{ width: 100 }}>
               <TextField
                value={rowData.Voice || ''}
                variant="outlined"
                size="small"
                style={{ marginRight: 10, height:30 }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">days</InputAdornment>,
                }}
              />
              </FormControl>
            ),
          }
    ];


    const recallSrcColumns = [
      { 
        field: 'triggerCode', 
        title: 'Last Visit Date',
        render: (rowData) => 
        <Switch
             checked={rowData.triggerCode === 'LV'}
             onChange={(event) => this._updateRecallSource(event.target.checked, rowData, 'LV')}
             color="primary"
        />
        ,
        width: 50, cellStyle: {padding:10} },
        { 
          field: 'triggerCode', 
          title: 'Recall Date',
          render: (rowData) => 
          <Switch
               checked={rowData.triggerCode === 'RD'}
               onChange={(event) => this._updateRecallSource(event.target.checked, rowData, 'RD')}
               color="primary"
          />
          ,
          width: 50, cellStyle: {padding:10} },
          { 
            field: 'triggerCode', 
            title: 'Standard Waterfall',
            render: (rowData) => 
            <Switch
                 checked={rowData.triggerCode === 'SW'}
                 onChange={(event) => this._updateRecallSource(event.target.checked, rowData, 'SW')}
                 color="primary"
            />
            ,
            width: 50, cellStyle: {padding:10} },                  
    ];

    if (redirectToLogin) {
        return <Redirect to={{ pathname: '/' }} />;
      }


    return (
        <div>
          <Box p={3} bgcolor="background.paper">
             <Typography >
                Enable/Disable communication type of a cover key.
             </Typography>  
          </Box>
          <Divider/>
          <Box p={3} bgcolor="background.paper">
          <ValidatorForm onSubmit={this._handleToggle} onError={errors => console.log(errors)}> 
          <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>
            <Grid item xs={12} sm={2} >
              <TextValidator
                  validators={['required', 'isPositive']}
                  errorMessages={['this field is required', 'Must be numeric and greater than zero']}
                  fullWidth
                  value={coverKey}
                  onInput={(e) => this._handleTextFieldChange(e.target.value)}
                  label="Search Coverkey"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton type="button" >
                            <SearchIcon onClick={(ev) => {
                            this._searchProgramAssociationId(ev.target.value);
                            ev.preventDefault();
                        }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
             <FormControl fullWidth>
             <InputLabel id="Communication_Type">Comm. Type</InputLabel>
             { this.state.env !== "production" ? (
              <Select
                required
                validators={['required']}
                errorMessages={['this field is required']}
                labelId="Communication_Type"
                id="communication_type"
                value={communicationType}
                onChange={(e)=>this._handleTypedChange(e.target.value)}
                disabled={this.state.isCommTypeDisabled}
              >
                <MenuItem value={2}>Early Recall</MenuItem>
                <MenuItem value={3}>Cancellation Retrieval</MenuItem>
                <MenuItem value={4}>Purchase Retrieval</MenuItem>
                <MenuItem value={5}>Welcome Email</MenuItem>
                <MenuItem value={6}>No Show</MenuItem>
                <MenuItem value={7}>Recall </MenuItem>
                <MenuItem value={1} disabled={this.state.isCommDropdownDisabled}>Appointment Reminder</MenuItem>
                <MenuItem value={10} disabled={this.state.isCommDropdownDisabled}>Product Pick Up</MenuItem>
              </Select>
           ) : (
              <Select
                required
                validators={['required']}
                errorMessages={['this field is required']}
                labelId="Communication_Type"
                id="communication_type"
                value={communicationType}
                onChange={(e)=>this._handleTypedChange(e.target.value)}
                disabled={this.state.isCommTypeDisabled}
              >
                <MenuItem value={2}>Early Recall</MenuItem>
                <MenuItem value={3}>Cancellation Retrieval</MenuItem>
                <MenuItem value={4}>Purchase Retrieval</MenuItem>
                <MenuItem value={5}>Welcome Email</MenuItem>
                <MenuItem value={6}>No Show</MenuItem>
                <MenuItem value={7}>Recall </MenuItem>
              </Select>
            )}
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} >
              <Button variant="contained" color="primary" type="submit" disabled={this.state.isCommTypeDisabled}>
                  Search
              </Button>
            </Grid>
                {!advDisabled &&
                <Grid item xs={12} sm={3} > 
                  <Button variant="contained" color="tertiary" disabled={advDisabled}  onClick={() => this._toggleAdvancedEdit()}>
                           Advanced
                  </Button>                 
                </Grid>
                }                   
            </Grid>   
            </ValidatorForm> 
          </Box>

          {this.state.communicationType != 1 && 
          
          <Box p={1} bgcolor="background.paper"> 
            <MaterialTable
              height="30"
              columns={coverColumns}
              data={coverData}
			  title={
				<h2>CoverKey Status</h2>
			   }
              options={{
                pageSize: 1,
                pageSizeOptions: [],
                toolbar: true,
                paging: false,
                search: false
              }}
            />            
          </Box>   }  
          {showAdvanced && this.state.communicationType == 7 &&
              <Box p={1} bgcolor="background.paper"> 
              <MaterialTable
                height="30"
                columns={recallSrcColumns}
                data={recallSrcData}
				title={
					<>
					<h2>Recall Trigger</h2>
					<h5>Only one selection can be made for a trigger point</h5>
					</>
				}
                options={{
                  pageSize: 1,
                  pageSizeOptions: [],
                  toolbar: true,
                  paging: false,
                  search: false
                }}
              />            
            </Box>     
          }
          
          <Box p={1} bgcolor="background.paper">
          <Grid container direction="row" justify="flex-start"  alignItems="flex-start" spacing={1}>
          {!showAdvanced && this.state.communicationType != 1 && 
            <Grid item xs={6}>
              <MaterialTable
                height="100"
                width="100"
                columns={locColumns}
                data={data}
                title="Location Status"
                options={{
                  pageSize: 10,
                  pageSizeOptions: [],
                  toolbar: true,
                  paging: true,
                  rowStyle: (rowData) => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF',
                  }),
                  headerStyle: {
                    fontSize: 14,
                    padding: 10
                  },
                  actionsColumnIndex: -1,
                  tableLayout: "fixed"
                }}
                actions={[
                  {
                    icon: 'face',
                    tooltip: 'Edit Providers',
                    headerStyle: {
                      padding:10
                    },
                    onClick: (event, rowData) => {
                      this._setProviderTableData(rowData);
                      this._setSelectedRow(rowData.tableData.id);
                    }
                  }
                ]}               
              />
            </Grid>
            }



            {!showAdvanced &&  this.state.communicationType != 1 && 
            <Grid item xs={6}>
              <MaterialTable
                height="100"
                width="100"
                columns={provColumns}
                data={provTableData}
                title="Provider Status"
                options={{
                  pageSize: 10,
                  pageSizeOptions: [],
                  toolbar: true,
                  paging: true,
                  rowStyle: {
                    fontSize: 14
                  },
                  headerStyle: {
                    fontSize: 14,
                    padding: 10
                  },
                  actionsColumnIndex: -1,
                  tableLayout: "fixed"
                }}   
              />
              
            </Grid>
            }
            {showAdvanced && this.state.communicationType == 7 &&
            <Grid item xs={6}>
              <MaterialTable
                height="100"
                width="100"
                columns={advColumns}
                data={advData.slice(0,12)}
                title="Recall Details"
                options={{
                  pageSize: 13,
                  pageSizeOptions: [],
                  toolbar: false,
                  paging: false,
                  rowStyle: {
                    fontSize: 14
                  },
                  headerStyle: {
                    fontSize: 14,
                    padding: 10
                  },
                  actionsColumnIndex: -1,
                  tableLayout: "fixed"
                }}
              />
            </Grid>
            }
            {showAdvanced && this.state.communicationType == 7 &&
            <Grid item xs={6}>
              <MaterialTable
                height="100"
                width="100"
                columns={advColumns}
                data={advData.slice(12,25)}
                title="Recall Details"
                options={{
                  pageSize: 13,
                  pageSizeOptions: [],
                  toolbar: false,
                  paging: false,
                  rowStyle: {
                    fontSize: 14
                  },
                  headerStyle: {
                    fontSize: 14,
                    padding: 10
                  },
                  actionsColumnIndex: -1,
                  tableLayout: "fixed"
                }}
              />
            </Grid>
            }

            {/* jomel */}
            {/* Appointment Reminder Settings */}
            {data.length>0 && this.state.communicationType == 1 &&
            <React.Fragment>
             <Paper square style={{padding:"0px",width:"100%", margin:"5px"}}>
             
             <AppointmentSettingsTab coverkey={this.state.coverKey} communicationType={this.state.communicationType} />
             </Paper>
             
            <Grid item xs={12}>
              <Locations coverkey={this.state.coverKey} communicationType={this.state.communicationType}/>
            </Grid>
            </React.Fragment>
            }{/* END Appointment Reminder Settings */}
            
          </Grid>            
          </Box> 
          <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>      
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => this.setState({openSnackBar: false})}>
            <Alert onClose={() => this.setState({openSnackBar: false})} severity={errorSeverity}>
            {SnackbarMessage} 
            </Alert>
          </Snackbar>                
        </div>
    ); 
  }
        
}

export default withTranslation()(CoverSearch);