import { Box, FormControl, InputLabel, MenuItem, Select, Typography, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import GeneralEditor, { createTableLookup } from '../GeneralEditor/GeneralEditor';
import { useWindowSize } from '../../Hooks/useWindowSize';
import axios from 'axios';
import { useV5 } from '../../ContextLib/CoreConsumer/v5Contexts';
import { useUIContext } from '../../ContextLib/contextHooks';

const apiRoot = process.env.REACT_APP_MAPPINGINTEGRATION_API;

const sizeThreshold = 1715;

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectMappingType: {
    fontSize: "1.2rem"
  },
  tableContainer: {
    '& .MuiTableCell-alignRight': {
      textAlign: 'left'
    }
  }
}));


const apiGet = async (url) => {
  let apiResult = undefined;
  try {
    apiResult = await axios.get(url)
  } catch {}
  let finalResult = undefined;
  if(apiResult?.data){
    finalResult = [...apiResult.data];
  }
  return finalResult;
};

const apiPush = async (method, url, data) => {
  let success = false;
  try {
    await method(url, data);
    success = true;
  } catch {}
  return success;
}

const api = {
  getApptTypes: async (mappingTypeId) => {
    const url = `${apiRoot}/GetAppointmentTypes?mappingTypeId=${mappingTypeId}`;
    return apiGet(url);
  },

  createApptType: async (item) => {
    const url = `${apiRoot}/createAppointmentType`;
    return await apiPush(axios.post, url, item);
  },

  updateApptType: async (item) => {
    const url = `${apiRoot}/updateAppointmentType`;
    return await apiPush(axios.put, url, item);
  },

  deleteApptType: async (item) => {
    const url = `${apiRoot}/deleteAppointmentType`;
    return await apiPush(axios.delete, url, { data: item } );
  },

  getTaskKeys: async (mappingTypeId, locKey, acctKey) => {
    const url = `${apiRoot}/getTaskKeys?mappingTypeId=${mappingTypeId}&locKey=${locKey}&acctKey=${acctKey}`;
    return apiGet(url);
  },

  createTaskKey: async (item) => {
    const url = `${apiRoot}/createTaskKey`;
    return await apiPush(axios.post, url, item);
  },

  updateTaskKey: async (newItem, oldItem) => {
    const url = `${apiRoot}/updateTaskKey`;
    return await apiPush(axios.put, url, { newTaskKey: newItem, oldTaskKey: oldItem });
  },

  deleteTaskKey: async (item) => {
    const url = `${apiRoot}/deleteTaskKey`;
    return await apiPush(axios.delete, url, { data: item } );
  },

  getStatuses: async () => {
    const url = `${apiRoot}/getExternalApptTypeStatuses`;
    return apiGet(url);
  },

  getMappingTypes: async () => {
    const url = `${apiRoot}/getMappingTypes`;
    return apiGet(url);
  },

  getExternalLocations: async (mappingTypeId, locKey) => {
    const url = `${apiRoot}/getExternalLocations?mappingTypeId=${mappingTypeId}&locKey=${locKey}`;
    return apiGet(url);
  },

  getLookupTaskKeys: async (locKey, acctKey) => {
    const url = `${apiRoot}/getLookupTaskKeys?locKey=${locKey}&acctKey=${acctKey}`;
    return apiGet(url);
  }
}

//todo: is it actually Appointment Type Mapping?
const AppointmentTypeMapping = ({onError}) => {
  const componentId = "appointmentTypeMapping";
  const ui = useUIContext(componentId);

  const styles = useStyles();
  const windowSize = useWindowSize();
  const [statuses, setStatuses] = useState([]);
  const [mappingTypes, setMappingTypes] = useState([]);
  const [externalLocations, setExternalLocations] = useState([]);
  const [apptTypes, setApptTypes] = useState([]);
  const [taskKeys, setTaskKeys] = useState([]);
  const [lookupTaskKeys, setLookupTaskKeys] = useState([]);
  const [selectedMappingType, setSelectedMappingType] = useState(undefined);

  const { f } = useV5();
  const {locKey, acctKey} = f;

  const statusesLookup = createTableLookup(statuses, 'externalApptTypeStatusId', 'statusValue');
  const apptTypesLookup = createTableLookup(apptTypes, 'externalApptTypeId', 'externalApptType');
  const externalLocationLookup = createTableLookup(externalLocations, 'externalLocationId', 'externalLocationName');
  const taskKeysLookup = createTableLookup(lookupTaskKeys, 'taskKey', 'name');

  const handleError = useCallback((error) => {
    if(onError) {
      onError(error);
    }
  }, [onError]);

  useEffect(() => {
    if(mappingTypes.length === 0) {
      const fetchMappingTypes = async () => {
        ui.ShowOverlay();
        const fetchedMappingTypes = await api.getMappingTypes();
        ui.HideOverlay();
        if(fetchedMappingTypes) {
          setMappingTypes(fetchedMappingTypes);
          if(fetchedMappingTypes.length > 0) {
            setSelectedMappingType(fetchedMappingTypes[0]);
          }
        } else {
          handleError("Unable to fetch mapping types");
        }
      };
      fetchMappingTypes();
    }
  }, [handleError, mappingTypes.length]);

  useEffect(() => {
    if(statuses.length === 0) {
      const fetchStatuses = async () => {
        ui.ShowOverlay();
        const fetchedStatuses = await api.getStatuses();
        ui.HideOverlay();
        if(fetchedStatuses) {
          setStatuses(fetchedStatuses);
        } else {
          handleError("Unable to fetch statuses");
        }
      };
      fetchStatuses();
    }
  }, [handleError, statuses.length]);

  useEffect(() => {
    if(locKey > 0) {
      const fetchLookupTaskKeys = async () => {
        ui.ShowOverlay();
        const fetchedLookupTaskKeys = await api.getLookupTaskKeys(locKey, acctKey);
        ui.HideOverlay();
        if(fetchedLookupTaskKeys) {
          setLookupTaskKeys(fetchedLookupTaskKeys.filter((key, index) => index < 20));
        } else {
          handleError("Unable to fetch lookup task keys");
        }
      };
      fetchLookupTaskKeys();
    }
  }, [handleError, lookupTaskKeys.length, locKey, acctKey]);

  useEffect(() => {
    const fetchExternalLocations = async () => {
      if(selectedMappingType !== undefined) {
        ui.ShowOverlay();
        const fetchedExternalLocations = await api.getExternalLocations(selectedMappingType.mappingTypeId, locKey);
        ui.HideOverlay();
        if(fetchedExternalLocations) {
          setExternalLocations(fetchedExternalLocations);
        } else {
          handleError("Unable to fetch external locations");
        }
      }
    };
    fetchExternalLocations();
  }, [selectedMappingType, handleError]);

  const fetchAppointmentTypes = useCallback(async () => {
    if(selectedMappingType !== undefined) {
      ui.ShowOverlay();
      const fetchedApptTypes = await api.getApptTypes(selectedMappingType.mappingTypeId);
      ui.HideOverlay();
      if(fetchedApptTypes) {
        setApptTypes(fetchedApptTypes);
      } else {
        handleError("Unable to fetch appointment types");
      }
    }
  }, [selectedMappingType, handleError]);
  useEffect(() => {
    fetchAppointmentTypes();
  }, [fetchAppointmentTypes]);

  const fetchTaskKeys = useCallback(async () => {
    if(selectedMappingType !== undefined && locKey > 0) {
      ui.ShowOverlay();
      const fetchedTaskKeys = await api.getTaskKeys(selectedMappingType.mappingTypeId, locKey, acctKey);
      ui.HideOverlay();
      if(fetchedTaskKeys.length<=0)
      {
        handleError("External Appointment Types are not yet mapped to this location");
      }
      if(fetchedTaskKeys) {
        setTaskKeys(fetchedTaskKeys);
      } else {
        handleError("Unable to fetch task keys");
      }
    }
  }, [selectedMappingType, handleError, locKey, acctKey]);
  useEffect(() => {
    fetchTaskKeys();
  }, [fetchTaskKeys]);

  const handleApptTypeCreate = async (apptType) => {
    ui.ShowOverlay();
    const success = await api.createApptType(apptType);
    ui.HideOverlay();
    if(success) {
      await fetchAppointmentTypes();
    } else {
      handleError("Failed to create appointment type");
    }
  }
  const handleApptTypeUpdate = async (apptType) => {
    ui.ShowOverlay();
    const success = await api.updateApptType(apptType);
    ui.HideOverlay();
    if(success) {
      await fetchAppointmentTypes();
    } else {
      onError("Failed to update appointment type");
    }
  }
  const handleApptTypeDelete = async (apptType) => {
    ui.ShowOverlay();
    const success = await api.deleteApptType(apptType);
    ui.HideOverlay();
    if(success) {
      await fetchAppointmentTypes();
    } else {
      handleError("Failed to delete appointment type");
    }
  }
  const handleTaskKeyCreate = async (taskKey) => {
    ui.ShowOverlay();
    const success = await api.createTaskKey(taskKey);
    ui.HideOverlay();
    if(success) {
      await fetchTaskKeys();
    } else {
      handleError("Failed to create task key mapping");
    }
  }
  const handleTaskKeyUpdate = async (newTaskKey, oldTaskKey) => {
    ui.ShowOverlay();
    const success = await api.updateTaskKey(newTaskKey, oldTaskKey);
    ui.HideOverlay();
    if(success) {
      await fetchTaskKeys();
    } else {
      handleError("Failed to update task key mapping");
    }
  }
  const handleTaskKeyDelete = async (taskKey) => {
    ui.ShowOverlay();
    const success = await api.deleteTaskKey(taskKey);
    ui.HideOverlay();
    if(success) {
      await fetchTaskKeys();
    } else {
      handleError("Failed to delete task key mapping");
    }
  }

  return <Box sx={{
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10
  }}>
    <Box sx={{
        display: "flex",
        justifyContent: "center"
    }}>
      {selectedMappingType &&
        <Box sx={{
          display: "flex",
          alignItems: "center",
          gap: "1em"
        }}>
          <Typography className={styles.selectMappingType}>Select mapping type:</Typography>
          <FormControl className={styles.formControl} >
            <SimpleSelect
              id="mappingTypeSelector"
              items={mappingTypes}
              value={selectedMappingType}
              onChange={setSelectedMappingType}
              toStringFunc={(mappingType) => mappingType.mappingDescription}
            />
          </FormControl>
        </Box>
      }
    </Box>
    {selectedMappingType &&
      <Box sx={{
        minHeight: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 10
      }}>
        <Box className={styles.tableContainer} sx={{
          width: 800,
          minHeight: 0
        }}>
          <ApptTypeEditor
            data={apptTypes}
            onCreate={handleApptTypeCreate}
            onUpdate={handleApptTypeUpdate}
            onDelete={handleApptTypeDelete}
            windowSize={windowSize}
            mappingTypeId={selectedMappingType.mappingTypeId}
            statusesLookup={statusesLookup}
          />
        </Box>
        <Box className={styles.tableContainer} sx={{
          width: 800,
          minHeight: 0
        }}>
          <TaskKeyEditor
            data={taskKeys}
            onCreate={handleTaskKeyCreate}
            onUpdate={handleTaskKeyUpdate}
            onDelete={handleTaskKeyDelete}
            windowSize={windowSize}
            mappingTypeId={selectedMappingType.mappingTypeId}
            apptTypesLookup={apptTypesLookup}
            externalLocationLookup={externalLocationLookup}
            taskKeysLookup={taskKeysLookup}
          />
        </Box>
      </Box>
    }
  </Box>
};



const ApptTypeEditor = (props) => {
  const pageSize = props.windowSize[0] > sizeThreshold ? 10 : 5;
  const columns = [
    { field: 'externalApptType', title: 'External Appt Type', cellStyle: {padding:10}, align: "left", validate: row => Boolean(row.externalApptType)},
    { field: 'externalApptTypeStatusId', title: 'Status', cellStyle: {padding:10}, type: 'numeric', lookup: props.statusesLookup, align: "left", validate: row => Boolean(row.externalApptTypeStatusId)},
  ];
  return <GeneralEditor {...props} columns={columns} autoValues={{ mappingTypeId: props.mappingTypeId }} pageSize={pageSize} title="Appointment Types" />
}

const TaskKeyEditor = (props) => {
  const pageSize = props.windowSize[0] > sizeThreshold ? 10 : 5;
  const columns = [
    { field: 'externalApptTypeId', title: 'External Appt Type', cellStyle: {padding:10}, type: 'numeric', lookup: props.apptTypesLookup, align: "left", validate: row => Boolean(row.externalApptTypeId)},
    { field: 'externalLocationId', title: 'External Location', cellStyle: {padding:10}, lookup: props.externalLocationLookup, align: "left", validate: row => Boolean(row.externalLocationId)},
    { field: 'taskKey', title: 'Task Key', cellStyle: {padding:10}, type: 'numeric', align: "left", lookup: props.taskKeysLookup, validate: row => Boolean(row.taskKey)}
  ];
  return <GeneralEditor {...props} columns={columns} autoValues={{ mappingTypeId: props.mappingTypeId }} pageSize={pageSize} title="Task Keys" />
}

const SimpleSelect = ({ id, label, items, value, onChange, toStringFunc, eqFunc }) => {
  const handleChange = (evt) => {
    onChange(items[evt.target.value]);
  }
  const itemToString = (item) => {
    return toStringFunc ? toStringFunc(item) : `${item}`;
  }
  const itemsEqual = (a, b) => {
    return eqFunc ? eqFunc(a, b) : a === b;
  }
  const index = (value === null || value === undefined) ? -1 : items.findIndex(item => itemsEqual(item, value));
  const labelId = (id && label) ? `${id}-label` : undefined;
  return <>
    {labelId &&
      <InputLabel id={labelId}>{label}</InputLabel>
    }
    <Select
      labelId={labelId ? labelId : undefined}
      id={id}
      value={index >= 0 ? index : undefined}
      label={label}
      onChange={handleChange}
    >
      {items.map((item, index) => 
        <MenuItem key={index} value={index}>{itemToString(item)}</MenuItem>
      )}
    </Select>
  </>
}



export default AppointmentTypeMapping;

