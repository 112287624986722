import axios from "axios";
import React, { useContext, useState } from "react";

import { Box, Divider, Grid, Typography } from "@material-ui/core";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CoverFilter from "../../ContextLib/CoreConsumer/Components/CoverLookup";
import { useCoverFiltersContext, useUIContext } from "../../ContextLib/contextHooks";
import { Severity } from "../../ContextLib/CoreConsumer/Components/SnackbarMessage";

import ShadowUpload from "./ShadowUpload.js";
import ShadowDetail from "./ShadowDetail.js";
import "./TriageReports.scss";

const SELECT_COVER_MESSAGE = "Please search and select a Cover";
const SELECT_REPORT_MESSAGE = "Please select Report Type";

export default function TriageReportsMaster(props) {
  let componentId = "TriageReportsMaster";
  const ui = useUIContext(componentId);
  let coverKey = 0;
  let lastCoverKey = null;

  const filtersContext = useCoverFiltersContext(componentId, null, async (context) => {
    //On filters changed
    coverKey =context.coverKey;

    if (lastCoverKey != context.coverKey) {
      lastCoverKey = context.coverKey;
      setCoverData([]);
    }

    let ready = context.coverKey != 0;
    _handleGetCoverData(ready)
  });

  const [msg, setMsg] = useState(SELECT_COVER_MESSAGE);
  const [coverData, setCoverData] = useState([]);
  const [coverTitleInformation, setCoverTitleInformation] = useState(null);
  const [coverAddress, setCoverAddress] = useState(null);
  const [showCoverSearch, setShowCoverSearch] = useState(true);
  const [expandCoverInfoTriage, setExpandCoverInfoTriage] = useState(false);
  const [expandCoverInfoShadowRep, setExpandCoverInfoShadowRep] = useState(false);
  const [expandCoverInfoShadowUpl, setExpandCoverInfoShadowUpl] = useState(false);

  const _handleGetCoverData = async (ready) => {
    if (ready) {
      ui.ShowOverlay();
      try {
        var request = {
          params: {
            secretCode: `${process.env.REACT_APP_API_KEY}`,
            coverKey: coverKey
          }
        }

        const url = `${process.env.REACT_APP_ADMINTRIAGEREPORTS_API}/getTriageReport`;
        const res = await axios.get(url, request);
        let d = res.data;
      
        if (res?.data) { 
          setCoverData(d); 
          
          let _phone = null;
          if (d.phone.length === 10) {
            _phone = `${d.phone.substring(0,3)}-${d.phone.substring(3,6)}-${d.phone.substring(6,10)}`;
          }

          let _coverTitle = `Cover Number: ${d.coverKey} | ${d.practiceName} ${d.isSmartBook === "N/A" ? "" : `| ${d.isSmartBook}`} | ${d.isShadow} ${d.pms ? `| ${d.pms}` : ""}  | ${_phone ? _phone : d.phone}`;
          let _coverAddress = `${d.address}, ${d.city}, ${d.state}`;
          
          setCoverTitleInformation(_coverTitle);
          setCoverAddress(_coverAddress);
          setMsg(SELECT_REPORT_MESSAGE);
          setShowCoverSearch(false);
        }
        else { 
          setCoverData([]);
        }
      } catch {
        ui.ShowSnackbar("Error in getting Cover Data", Severity.error);
      }
      ui.HideOverlay();
    }
  }

  const TriageReports = () => {
    if (!showCoverSearch) {
      return(
        <>
        <div className="triagereports--page__title">
          <Box p={2} bgcolor="background.paper"> 
            <Grid container justify="flex-start" alignItems="center" spacing={2}>
              <Grid item xs={11}>
                <Typography variant="h5">
                  Triage Reports
                </Typography>
                <Divider orientation="vertical" variant="middle" flexItem/>
                <Typography variant="h6">
                {coverTitleInformation}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                  {expandCoverInfoTriage ? <ExpandLess onClick={() => setExpandCoverInfoTriage(false)} /> : <ExpandMore onClick={() => setExpandCoverInfoTriage(true)}/>}
              </Grid>
            </Grid>
            <Collapse in={expandCoverInfoTriage} timeout="auto">
              <div className="triagereports--page__title--address__triage"> 
              {coverAddress}
              </div>
            </Collapse>
          </Box>
        </div>
        <Divider />
        <div className="select-cover-message">
          <h2>{msg}</h2>
        </div>      
        <hr />
        </>             
      )
    } 
    return(
      <>
        <div className="triagereports--page__title">
          <Box p={2} bgcolor="background.paper"> 
            <Typography variant="h5">
              {props.menu === 19 && "Triage Reports"}
              {props.menu === 20 && "Shadow Upload"}
              {props.menu === 21 && "Shadow Detail"}
            </Typography>
          </Box>
        </div>
        <Divider />
        <div className="select-cover-message">
            <h2>{msg}</h2>
        </div>                    
        <hr/>
      </>
    )
  }

  const ShadowReport = (page) => {
    if (showCoverSearch) {
      return(
        <>
        <div className="triagereports--page__title">
          <Box p={3} bgcolor="background.paper"> 
            <Grid container justify="flex-start" alignItems="center" spacing={2}>
              <Typography variant="h5">
              {
                page === 'shadowupload' ? "Shadow Upload Report" : "Shadow Detail Report"
              }
              </Typography>
            </Grid>
          </Box>
        </div>
        <Divider />
        <div className="select-cover-message">
          <h2>{msg}</h2>
        </div>      
        <hr />
        </>
      )
    }
    return( 
      <>
        <div className="triagereports--page__title">
          <Box p={2} bgcolor="background.paper"> 
            <Grid container justify="space-between" alignItems="center" spacing={2}>
              <Grid item xs={11}>
                <Typography variant="h5">
                {
                page === 'shadowupload' ? "Shadow Upload Report" : "Shadow Detail Report"
                }
                </Typography>
                <Divider orientation="vertical" variant="middle" flexItem/>
                <Typography variant="h6">
                {coverTitleInformation}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {
                  page === 'shadowupload' ? 
                  <>
                  {expandCoverInfoShadowUpl ? <ExpandLess onClick={() => setExpandCoverInfoShadowUpl(false)} /> : <ExpandMore onClick={() => setExpandCoverInfoShadowUpl(true)}/>}
                  </>
                  :
                  <>
                  {expandCoverInfoShadowRep ? <ExpandLess onClick={() => setExpandCoverInfoShadowRep(false)} /> : <ExpandMore onClick={() => setExpandCoverInfoShadowRep(true)}/>}
                  </>
                }
              </Grid>
            </Grid>
            {
              page === 'shadowupload' ? 
              <>
              {expandCoverInfoShadowUpl &&
                <Collapse in={expandCoverInfoShadowUpl} timeout="auto">
                  <div className="triagereports--page__title--address__shadowupload"> 
                  {coverAddress}
                  </div>
                </Collapse>
              }
              </>
              :
              <>
              {expandCoverInfoShadowRep &&
                <Collapse in={expandCoverInfoShadowRep} timeout="auto">
                  <div className="triagereports--page__title--address__shadowreport"> 
                  {coverAddress}
                  </div>
                </Collapse>
              }
              </>
            }
          </Box>
        </div>
        <Divider />
        {
          page === 'shadowupload' ? <ShadowUpload /> : <ShadowDetail />
        }
      </>
    )
  }

  return(
    <div className='triagereports'>
      {props.menu === 19 &&
        <div className='triagereports--cover__search'>
           <CoverFilter/>
        </div>
      }
      {props.menu === 19 && <TriageReports />}
      {props.menu === 20 && ShadowReport("shadowupload")}
      {props.menu === 21 && ShadowReport("shadowdetail")}                
    </div>
  );
}