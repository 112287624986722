import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useEffect, useState } from 'react'
import SettingsIcon from '@material-ui/icons/Settings';
import { Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import AppointmentReminderSettings from './AppointmentReminderSettings';
import axios from 'axios';
import Overlay from "../../Components/Overlay/Overlay";
const Locations = (props) => {
  const user = JSON.parse(localStorage.getItem("userId").toString());
  const [frm, setValues] = useState({
    secretcode:'ditac-1',
    coverkey:props.coverkey,
    appointmenttype:props.appointmenttype,
    appointmentstatus: [{ value: "1", label: "Kept" },{ value: "12", label: "Confirmed" }],
    appointmentreason: { value: "0", label: "ALL" },
    appointmentreasonexcluded: { value: "-1", label: "None" },
    user:user
  });
  const [showLoadingOverlay,setShowLoadingOverlay]=useState(false);
    const [data,setData]=useState([]);
    

    const toggleLocationStatus=(rowData,currentstatus)=>{
    console.log(rowData);
    
      SaveLocationStatus(rowData.coverKey,rowData.locKey,1,!currentstatus,user);
      
    }

    async function GetCatalogRecordByCoverKeyAndCommunicationId()
    {
      const url = `${process.env.REACT_APP_ARTS_API}/GetLocationARStatus?secretCode=ditac-1&coverKey=${frm.coverkey}&communicationtypeid=1`;
      
      await axios.get(url).then((res)=>{

        setData(res.data);
        
       
      }).catch((error)=>{
        console.log(error);
      });; 
      
    }
    

    async function SaveLocationStatus(coverKey,locKey, communicationtypeid,status, updatedby){
      setShowLoadingOverlay(true);
  
     
  
      const url = `${process.env.REACT_APP_ARTS_API}/SaveLocationStatus?secretCode=ditac-1&coverKey=${coverKey}&lockey=${locKey}&communicationtypeid=${communicationtypeid}&status=${status}&updatedby=${updatedby}`;
      const res =  await axios.post(url,data); 
      if (res?.data) 
      {
        console.log('Communication Channel saved');
        GetCatalogRecordByCoverKeyAndCommunicationId();
        setShowLoadingOverlay(false);
       
      }
  
  
    }

    function Title(props){

    return(
    <React.Fragment>
    <span style={{paddingBottom:"0px",marginBottom:"0px",fontWeight:"bold",fontSize:"1.2rem"}}>{props.title}</span><br/>
    <span><b>{props.description}</b></span>
    </React.Fragment>);
  
  }

  
    const advApptReminderlocColumns = [
      
        {
          field: "locName",
          title: "#",
          width: "10%",
          searchable:true,
          hidden:true,
          editable: "never",
        },
        {
          field: "locKey",
          title: "#",
          width: "10%",
          searchable:true,
          hidden:true,
          editable: "never",
        },
       
        {
          title: <span><b>Location Details</b></span>,
          field: "status",
          render: (rowData) => (
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <span 
                 
                  >
                    {rowData.id + ". " + rowData.locName + " | " + rowData.locKey}{" "}
                   
                  </span>
                </Grid>
              </Grid>
           
            </React.Fragment>
          ),
          width: "89%",
          // cellStyle: {padding:10},
          headerStyle: { padding: 20 },
        },
        {
          title: <span><b>Status</b></span>,
          field: "status",
          render: (rowData) => (
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <span 
             
                  >
                  
                    <Switch
                      name={rowData.locKey}
                      checked={rowData.status}
                      onChange={()=>toggleLocationStatus(rowData,rowData.status)}
                      color="primary"
                    />
                  </span>
                </Grid>
              </Grid>
            
            </React.Fragment>
          ),
          width: "10%",
          // cellStyle: {padding:10},
          headerStyle: { padding: 20 },
        },
      ];


      useEffect(()=>{
        GetCatalogRecordByCoverKeyAndCommunicationId();
      },[props.coverkey]);
  return (
    <React.Fragment>
<MaterialTable
                height="100"
               
                columns={advApptReminderlocColumns}
                data={data}
                title={<Title title="Location" description="Appointment Reminder Settings"/>}
                options={{
                  pageSize: 10,
                  pageSizeOptions: [],
                  toolbar: true,
                  paging: true,
                  
                  headerStyle: {
                    fontSize: 14,
                    padding: 10,
                    zIndex:0
                  },
                  actionsColumnIndex: -1,
               
                }}  
                
                components={{
                  Toolbar: props => (
                    <div>
                      <MTableToolbar {...props} />
                      
                    </div>
                  ),
                }}

                detailPanel={[
                  {
                    icon: ()=><SettingsIcon />,
                    tooltip: 'Show Appointment Reminder Settings',
                    render: rowData => {
                      return (
                       <React.Fragment> 
                        <Grid container>
                          <Grid item md={12} xs={12}>
                            <AppointmentReminderSettings coverkey={props.coverkey} rowData={rowData} communicationType={props.communicationType}/>
                          </Grid>
                        </Grid>
                     </React.Fragment>
                      )
                    },
                  },
                  
                ]}
              />

<Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>  
  
    </React.Fragment>
  )
}

export default Locations;