import axios from 'axios';
import React , { useEffect, useRef, useState, createRef } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Overlay from '../../Components/Overlay/Overlay';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

export default function NotificationLogs() {
    const [logsData, setLogsData] = React.useState([]);
    const [logsOrigData, setLogsOrigData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [searchString, setSearchString] = React.useState('');
    const [searchStringHighlight, setSearchStringHighlight] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showLoadingOverlay, setshowLoadingOverlay] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [totalSearchCount, setTotalSearchCount] = React.useState(0);


    useEffect(() => {
        (async () => {
          try
          {
            setshowLoadingOverlay(true)  
            const logsUrl = `${process.env.REACT_APP_NOTIFICATION_LOGS}`;
            const APIKey =  `${process.env.REACT_APP_NOTIF_API_KEY}`;
            var requestAPI = {
              params: {},
              headers: {'APIKey': APIKey}
            }            
            var response = await axios.get(logsUrl,requestAPI);
            //parse response
            var data = response.data.split("/************************/");
            data = data.slice(0,-1)
            data = data.reverse();
            setLogsData(data)
            setLogsOrigData(data)
            setshowLoadingOverlay(false)
          }
          catch(ex)
          {
            setshowLoadingOverlay(false)
          }  
        })();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      }; 

      const handleSearchLog = () => {
        const search = searchString.toLowerCase().trim()
        var data = logsOrigData.filter(value => value.toLowerCase().includes(search))
        setSearchStringHighlight(searchString)
        setPage(0)
        setLogsData(data)
        if (search === "") 
          setIsSearch(false)
        else
        {
          setIsSearch(true)
          setTotalSearchCount(data.length)
        }  
      };  

      const escapeRegExp = (str = '') => (
        str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
      );

      const Highlighted = (text ,highlight) => {
        if (!highlight.trim()) {
          return `<span>${text}</span>`
        }
        const regex = RegExp(`(${escapeRegExp(highlight.trim())})`, 'i');
        const parts = text.split(regex)
        let finalStr = ""
        parts.map((part, i) => (
          regex.test(part) ? finalStr += `<mark key=${i}>${part}</mark>` : finalStr += `<span key=${i}>${part}</span>`
       ))
        return finalStr
      }      

    return(
        <>    
        <Box p={1} bgcolor="background.paper">
        <Grid container spacing={1} >
              <Grid item xs={8} sm={8} >  
                <Typography variant="h5">
                  Desktop Notifier Logs
                </Typography>           
              </Grid>
              <Grid item xs={4} sm={4} >  
              <ValidatorForm onSubmit={(ev) => handleSearchLog()} onError={errors => console.log(errors)}> 
                <TextField
                    onInput={ (e)=>setSearchString(e.target.value)}  
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                       // var searchValue = ev.target.value;
                       setSearchStringHighlight(ev.target.value)
                        handleSearchLog()
                        ev.preventDefault();
                        }                      
                }}            
                label="Search a word here..."
                fullWidth 
                InputProps={{
                    endAdornment: (
                    <InputAdornment>
                        <IconButton type="submit">
                        <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                    )
                }}
                />   
                </ValidatorForm>    
                </Grid>
        </Grid>     
        {isSearch && <Grid container spacing={1}     
              alignItems='flex-end'
              justify='flex-end'
              style={{ minHeight: "5vh" }}>
           <Grid item  >
                 <Typography variant="overline">
                    {totalSearchCount > 0 ? `Total search found : ${totalSearchCount.toLocaleString(undefined, {maximumFractionDigits:0}) }` : 'No match found.'}
                 </Typography>   
            </Grid> 
        </Grid>}                        
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                   <TableCell width={15} key={0}>#</TableCell>
                    <TableCell key={1}>Logs</TableCell>
                </TableRow>
              </TableHead>
              {logsData.length > 0 && <TableBody>
                 {logsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => {
                  return  (              
                    <TableRow hover={true} >
                    <TableCell>
                        {(page * rowsPerPage)+index+1}
                    </TableCell>                        
                    <TableCell variant='body' component="div">
                        <div dangerouslySetInnerHTML={{__html: Highlighted(row,searchStringHighlight)}}></div>
                    </TableCell>
                  </TableRow>                  
                  );
                })}
              </TableBody>}
            </Table>
          </TableContainer>  
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={logsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />                      
        </Box>
        <Overlay show={showLoadingOverlay}>
        <i className="spinner-eclipse"></i>
        </Overlay>             
        </>
    )
}