import React, { useEffect, useState, useRef } from 'react';
import { useCoverFiltersContext } from "../../ContextLib/contextHooks";
import { UploadReport, DEFAULT_SHADOW_FILTERS } from "@essilorditac/admin-dash-ui";
import "./ShadowUpload.scss";
import "@essilorditac/admin-dash-ui/lib/index.css";

export default function ShadowUpload() {
    const componentId = "ShadowUpload";
  
    const [selected, setSelected] = React.useState(
      DEFAULT_SHADOW_FILTERS
    );
    const childRef = useRef();

    const [selectedCover, setSelectedCover] = useState(null);
    const context = useCoverFiltersContext(componentId, null, async (newContext) => {
      if (selectedCover !== newContext.coverKey) {
        setSelectedCover(newContext.coverKey);
        childRef.current && childRef.current.editSearch();
      }
    });

    useEffect(() => {
      const initCoverKey = context.Values.coverKey;
      initCoverKey && setSelectedCover(initCoverKey);
    }, [context])
  
    return(
      <div className="cover-reports">
        {selectedCover && <UploadReport
          ref={childRef}
          selected={selected}
          setSelected={setSelected}
          coverKey={String(selectedCover)}
        />}
      </div>
    );
}