import React, { useState, useEffect, useContext } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';
import './PatientInfo.scss';
import PatientInfo_Validation from './Components/PatientInfo_Validation';
import PatientInfo_Demographics from './Components/PatientInfo_Demographics';
import PatientInfo_Insurance from './Components/PatientInfo_Insurance';
import PatientInfo_Others from './Components/PatientInfo_Others';
import Button from '@material-ui/core/Button';
import { objectsAreEqual } from '../../ContextLib/Core/coreLib';
import { TabPanel } from '../../ContextLib/CoreConsumer/Components/TabPanel';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useRequest, useUIContext } from '../../ContextLib/contextHooks';
import Config from "./patientinfo-config.json";
import { useV5 } from '../../ContextLib/CoreConsumer/v5Contexts';

export default function PatientInfo () {
  const componentId = "patientInfo";
  const ui = useUIContext(componentId);
  
  const { f, Request } = useV5();

  useEffect(()=>{
    loadData();
  }, [f.coverKey, f.locKey, f.acctKey]);

  const confAndFilters = () => {
    return {...Config.fields,
      coverKey: f.coverKey,
      locKey: f.locKey,
      acctKey: f.acctKey};
  };
  
  let p = confAndFilters();

  const [rec, setRec] = useState(p);
  const [recBak, setRecBak] = useState(p);
  const [inDB, setInDB] = useState(false);
  const [inDBBak, setInDBBak] = useState(false);
  const [isDefaultConfig, setIsDefaultConfig] = useState(true);
  const [notify, setNotify] = useState("");

  const [tabValue, setTabValue] = useState(0);

  const onSave = async () => {
    if (f.coverKey == 0) {
      ui.MessageBox("Please select a Cover first");
      return;
    }

    const xvision = 
      rec.v4DVisInsSect == 1 && 
      rec.dinsCarrier == -1 && 
      rec.dSubDOB == -1 && 
      rec.dSubId == -1 && 
      rec.dGroupNum == -1 && 
      rec.dSubName == -1 && 
      rec.dRelToPat == -1 && 
      rec.dCustSrvNum == -1 && 
      rec.v4DVisInsOtherIns == -1;

      const xmedical = 
        rec.v4DMedInsSect == 1 && 
        rec.v4DMedInsCarrier == -1 && 
        rec.v4DMedInsSubDOB == -1 && 
        rec.v4DMedInsSubId == -1 && 
        rec.v4DMedInsGroupNum == -1 && 
        rec.v4DMedInsSubName == -1 && 
        rec.v4DMedInsRel2Pat == -1 && 
        rec.v4DMedInsCarrierSvcNum == -1 && 
        rec.v4DMedInsOtherIns == -1;

    if (xvision || xmedical) {
      ui.ErrorBox('To display vision or medical insurance, at least one (1) sub-item should be set to desired or required', 'Saving Settings');
      return;
    }

    const warningMsg = (<>The settings on this page will be applied to {f.strText}. <br /><br />Do you want to continue?</>);
    let confirmed = await ui.ConfirmBox(warningMsg);
    if (confirmed == 1) { 
      ui.ShowOverlay();

      let res = await Request.savePatientInfo({
        ...arrangeOthers(rec), 
        CoverKey: f.coverKey, 
        LocKey: f.locKey, 
        AcctKey: f.acctKey
      });

      if (res.success && res.data) {
        loadData();

        let d = res.data;
        let summary = "";
        if (d.updated.length > 0) summary += " Updated " + d.updated.length + " existing records";
        if (d.inserted.length > 0) summary += (summary == "" ? "" : " and") + " Added " + d.inserted.length + " new records"; 
        
        ui.MessageBox("Preferences were successfully saved." + summary);
      } else {
        ui.ErrorBox(res.error, "Saving Patient Info");
      }      
      
      ui.HideOverlay();
    }      
  };
  
  const onChange = (fieldName, value) => {
    let obj = {};
    obj[fieldName] = value;

    let newRec = {...rec, ...obj};   
    setRec(newRec);
    updateNotify({
      rec: newRec,
      recBak: recBak,
      inDB: inDB,
      inDBBak: inDBBak,
      isDefaultConfig: isDefaultConfig
    }, true);
  };

  const onChange_Multiple = (fieldName, value, fieldName2, value2, fromDelete) => {
    if (fieldName2 === 'notesLbl' || fieldName2 === 'dReferredBy1lbl' || fieldName2 === 'dReferredBy2lbl' ||
        fieldName2 === 'dReferredBy3lbl' || fieldName2 === 'dReferredBy4lbl' || fieldName2 === 'dReferredBy5lbl') {
        value2 = removeExtraQuestionMark(value2);
    }

    let obj = {};
    obj[fieldName] = value;

    let obj2 = {};
    obj2[fieldName2] = value2;

    let newRec = {...rec, ...obj, ...obj2};   
    
    if (fromDelete) newRec = arrangeOthers(newRec);
    setRec(newRec);
    updateNotify({
      rec: newRec,
      recBak: recBak,
      inDB: inDB,
      inDBBak: inDBBak,
      isDefaultConfig: isDefaultConfig
    }, true);
  }

  const isLocked = (fieldName) => {
    return fieldName == "fname" || fieldName == "lname" || Config.locks.some(x => x == fieldName);
  };

  const dataIsEqualToDefaultConfig = (data) => {
    let obj1 = confAndFilters();
    return objectsAreEqual(data, obj1);
  }

  const updateNotify = (s, reevaluate) => {
    let ret = "";

    if (reevaluate) {
      s.isDefaultConfig = dataIsEqualToDefaultConfig(s.rec);

      let eq2 = objectsAreEqual(s.recBak, s.rec);
      s.inDB = s.inDBBak && eq2;    

      setIsDefaultConfig(s.isDefaultConfig);
      setInDB(s.inDB);
    }

    ret += s.isDefaultConfig ? "Default Configuration" : "";
    ret += s.inDB ? (ret == "" ? "" : ". ") + "This setting has already been saved in the Database" : "";
    
    setNotify(ret);
  }

  const loadData = async () => {    
    if (f.coverKey != 0) {
      
      ui.ShowOverlay();
      let res = {};
      res = await Request.getPatientInfo({coverKey: f.coverKey, locKey: f.locKey, acctKey: f.acctKey});
      // if (locKey == 0 || acctKey == 0 ) {
      //   res = await Request.getPatientInfo({coverKey: coverKey, locKey: 0, acctKey: 0});
      // } else {
      //   res = await Request.getPatientInfo({coverKey: coverKey, locKey: locKey, acctKey: acctKey});
      // }

      if (res.success) {
        var p, db, eq;

        if (res.data) {        

          //TEMPORARY
          if (res.data.vL4DSSN == -1) res.data.vL4DSSN = 0;
          if (res.data.vWholeAddress == -1) res.data.vWholeAddress = 0;

          p = {...confAndFilters(), ...res.data};
          eq = dataIsEqualToDefaultConfig(res.data);        
          db = true;
        } else {
          p = confAndFilters();
          eq = true;
          db = false;
        }
  
        setRec(p);
          setRecBak(p);
          setInDB(db);
          setInDBBak(db);
          setIsDefaultConfig(eq);
    
          updateNotify({
            rec: p,
            recBak: p,
            inDB: db,
            inDBBak: db,
            isDefaultConfig: eq
          });
      } else if (!res.cancelled) {
        ui.ErrorBox(res.error, "Getting Patient Info");
      }

      ui.HideOverlay();
    }
  };

  const arrangeOthers = (model) => {
    let tmp = [
      {a: model.notesFld, b: model.notesLbl},
      {a: model.dReferredBy1, b: model.dReferredBy1lbl},
      {a: model.dReferredBy2, b: model.dReferredBy2lbl},
      {a: model.dReferredBy3, b: model.dReferredBy3lbl},
      {a: model.dReferredBy4, b: model.dReferredBy4lbl},
      {a: model.dReferredBy5, b: model.dReferredBy5lbl}
    ];

    tmp = tmp.filter(x => x.b.trim() != "");
    let dummy = {
      notesFld: -1,
      dReferredBy1: -1,
      dReferredBy2: -1,
      dReferredBy3: -1,
      dReferredBy4: -1,
      dReferredBy5: -1,
      notesLbl: "",      
      dReferredBy1lbl: "",
      dReferredBy2lbl: "",
      dReferredBy3lbl: "",
      dReferredBy4lbl: "",
      dReferredBy5lbl: ""
    }

    tmp.forEach((x, i)=>{
      if (i == 0) {
        dummy.notesFld=x.a;
        dummy.notesLbl=appendQuestionMark(x.b);
      } else if (i == 1) {
        dummy.dReferredBy1=x.a;
        dummy.dReferredBy1lbl=appendQuestionMark(x.b);
      } else if (i == 2) {
        dummy.dReferredBy2=x.a;
        dummy.dReferredBy2lbl=appendQuestionMark(x.b);
      } else if (i == 3) {
        dummy.dReferredBy3=x.a;
        dummy.dReferredBy3lbl=appendQuestionMark(x.b);
      } else if (i == 4) {
        dummy.dReferredBy4=x.a;
        dummy.dReferredBy4lbl=appendQuestionMark(x.b);
      } else if (i == 5) {
        dummy.dReferredBy5=x.a;
        dummy.dReferredBy5lbl=appendQuestionMark(x.b);
      }    
    });

    return {...model, ...dummy};
  }

  const appendQuestionMark = (customQuestionValue) => {
    if (customQuestionValue.slice(-1) !== "?") {
      return customQuestionValue + "?";
    }
    return customQuestionValue;
  }

  const removeExtraQuestionMark = (customQuestionValue) => {
    let valueLength = customQuestionValue.length - 1;
    if (customQuestionValue.slice(-2) === '??') {
       return customQuestionValue.substring(0, valueLength);
    }
    return customQuestionValue;
  }


  return (
    <div className="reporting-box">
      <Box p={2} bgcolor="background.paper" className="page-header"> 
        <Typography variant="h5">
          Patient Information
        </Typography>
      </Box>
      <Divider />
      <Box p={2} bgcolor="background.paper" className="info">
        <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>
          <Grid item xs={12} sm={6}>
            <h3>  
            The following information is Patient Information data:
            </h3>
          </Grid>
        </Grid>        
      </Box>        
      <Divider />
     
      <Box p={2} bgcolor="background.paper" border="1px solid #e0e0e0">
        {notify && <div className="default-config">{notify}</div>}
        
        <TabContext value="-1">
          <AppBar position="static" color="default">
            <Tabs value={tabValue} onChange={(e,v) => setTabValue(v)} aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
            >
              <Tab label="Patient Validation" />
              <Tab label="Patient Demographics" />
              <Tab label="Patient Insurance" />
              <Tab label="Other" />
            </Tabs>
          </AppBar>
          
          <TabPanel value={tabValue} index={0} className="pi-tabpanel">
            <PatientInfo_Validation model={rec} isLocked={isLocked} onChange={onChange} />
          </TabPanel>
          <TabPanel value={tabValue} index={1} className="pi-tabpanel">
              <PatientInfo_Demographics model={rec} isLocked={isLocked} onChange={onChange} />
          </TabPanel>
          <TabPanel value={tabValue} index={2} className="pi-tabpanel">
            <PatientInfo_Insurance model={rec} isLocked={isLocked} onChange={onChange} />
          </TabPanel>
          <TabPanel value={tabValue} index={3} className="pi-tabpanel">
            <PatientInfo_Others model={rec} onChangeMultiple={onChange_Multiple} />
          </TabPanel>
        </TabContext>
          
      </Box>
      <Box pt={1}>
        <Grid container direction="row" justify="center" alignItems="flex-end" spacing={3}>
          <Grid item xs={12} sm={12} style={{textAlign:"right"}}>
            <Button
              variant="contained"
              color={f.coverKey > 0 ? "primary" : "default"}
              onClick={onSave}
            >
              Save
            </Button>
          </Grid>   
        </Grid>       
      </Box>
    </div>
  );
}

