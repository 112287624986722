import "../PmsConfig.scss";
import moment from "moment";
import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { useUIContext } from "../../../ContextLib/contextHooks";
import SnackBarHelper from "../Components/Helper/SnackBarHelper";
import { ValidatorForm } from "react-material-ui-form-validator";
import TextBoxWithValidator from "../Components/Common/TextBoxWithValidator";
import EdenWbWebScheduled from "../Components/EdenWriteBackStatus/EdenWbWebScheduled";
import EdenWbWebScheduledList from "../Components/EdenWriteBackStatus/EdenWbWebScheduledList";
import EdenWbWriteBackSetting from "../Components/EdenWriteBackStatus/EdenWbWriteBackSetting";
import EdenWbReminderSetting from "./../Components/EdenWriteBackStatus/EdenWbReminderSetting";
import {
  GetJ47WebSchedLog,
  GetWriteBackPreferences,
  GetWSApptWritebackResults,
} from "../Util/pmsIntegrationApiUtil";

export default function EdenWritebackStatus() {
  const ui = useUIContext("v5filters");
  const [j47WebSchedLog, setJ47WebSchedLog] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [wSApptWritebackResults, setWSApptWritebackResults] = useState([]);
  const [mockData, setMockData] = useState({
    writeBackCountCover: "",
    webSchedCover: "",
    WriteBackPreferences: [],
    reminderWritebackPreferences: [],
  });

  const _getWSApptWritebackResults = async () => {
    GetWSApptWritebackResults(mockData.writeBackCountCover)
      .then((resp) => {
        setWSApptWritebackResults(resp.data);
      })
      .catch((error) => {
        HandleUpdateSnackBar(
          "error",
          `GetWSApptWritebackResults: ${error}`,
          false
        );
      });
  };

  const _getJ47WebSchedLog = async () => {
    GetJ47WebSchedLog(mockData.webSchedCover)
      .then((resp) => {
        setJ47WebSchedLog(resp.data);
      })
      .catch((error) => {
        HandleUpdateSnackBar("error", `getJ47WebSchedLog: ${error}`, false);
      });
  };

  const _searchWriteBackSettings = async () => {
    const promises = [
      GetWriteBackPreferences(mockData.writeBackCountCover)
        .then((resp) => {
          const wbSettingData = [];
          const reminderSettingData = [];
          const { wsApptWritebackPreferences, reminderWritebackPreferences } =
            resp.data;

          //wsApptWritebackPreferences
          if (wsApptWritebackPreferences != null)
            wbSettingData.push({
              ...wsApptWritebackPreferences,
              id: wsApptWritebackPreferences.coverKey,
              lastModifiedDate: moment(
                wsApptWritebackPreferences.lastModifiedDate
              ).format("MM/DD/YYYY hh:mm:ss A"),
              createdDate: moment(
                wsApptWritebackPreferences.createdDate
              ).format("MM/DD/YYYY hh:mm:ss A"),
            });

          //reminderWritebackPreferences
          if (reminderWritebackPreferences != null)
            reminderSettingData.push({
              ...reminderWritebackPreferences,
              id: reminderWritebackPreferences.coverKey,
              lastModifiedDate: moment(
                reminderWritebackPreferences.lastModifiedDate
              ).format("MM/DD/YYYY hh:mm:ss A"),
              createdDate: moment(
                reminderWritebackPreferences.createdDate
              ).format("MM/DD/YYYY hh:mm:ss A"),
            });

          setMockData({
            ...mockData,
            WriteBackPreferences: wbSettingData,
            reminderWritebackPreferences: reminderSettingData,
          });
        })
        .catch((error) => {
          HandleUpdateSnackBar(
            "error",
            `GetWriteBackPreferences: ${error}`,
            false
          );
        }),
    ];
    await Promise.all(promises);
  };

  const _showRecordResults = async () => {
    try {
      ui.ShowOverlay();
      await _getWSApptWritebackResults();
      await _searchWriteBackSettings();
      await _getJ47WebSchedLog();
    } finally {
      ui.HideOverlay();
    }
  };

  const HandleUpdateSnackBar = (
    severity,
    message,
    doNotOpenSnackBar = true
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);

    if (!doNotOpenSnackBar) setOpenSnackBar(true);
  };

  const setValue = (e) => {
    const { name, value } = e.target;
    setMockData({
      ...mockData,
      [name]: value,
    });
  };

  return (
    <>
      <SnackBarHelper
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Severity={snackBarSeverity}
        AlertMessage={snackBarMessage}
      />
      <h1 className="pmstitle-title__wrapper">Eden Writeback Status</h1>
      <ValidatorForm
        onSubmit={() => _showRecordResults()}
        onError={(errors) => console.log(errors)}
        className="edenStatus-memberconfiguration-form__wrapper"
      >
        <div>
          <TextBoxWithValidator
            style={{ minWidth: 500, margin: "10px 0" }}
            label="CoverKey for Write back Count (for MED use 3560)"
            value={mockData.writeBackCountCover || ""}
            onInput={(e) => setValue(e)}
            name="writeBackCountCover"
          />
          <TextBoxWithValidator
            style={{ minWidth: 500 }}
            label="CoverKey for Web Schedule Count (for MED use 3324)"
            value={mockData.webSchedCover || ""}
            onInput={(e) => setValue(e)}
            name="webSchedCover"
          />

          <Button
            style={{ width: "170px", margin: "5px 0 15px" }}
            type="submit"
            variant="outlined"
            color="primary"
          >
            Show Result
          </Button>
        </div>

        <Grid container spacing={1}>
          <Grid item xs={6} className="pms-EdenWbWebScheduled">
            <EdenWbWebScheduled
              tableData={wSApptWritebackResults}
              j47WebSchedLog={j47WebSchedLog}
            />
          </Grid>
          <Grid item xs={12} className="pms-EdenWbWebScheduled">
            <EdenWbWebScheduledList tableData={wSApptWritebackResults} />
          </Grid>
          <Grid item xs={12} className="pms-EdenWbWebScheduled">
            <EdenWbReminderSetting
              reminderWritebackPreferences={
                mockData.reminderWritebackPreferences
              }
            />
          </Grid>
          <Grid item xs={12} className="pms-EdenWbWebScheduled">
            <EdenWbWriteBackSetting
              WriteBackPreferences={mockData.WriteBackPreferences}
            />
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  );
}
