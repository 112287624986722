import ViewIcon from "@material-ui/icons/Cancel";
import { Modal, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import ToolTipWithIcon from "../../Components/Common/ToolTipWithIcon";
import TextBoxWithValidator from "../../Components/Common/TextBoxWithValidator";
import MaterialTableSelect from "./../../Components/Common/MaterialTableSelect";

export default function ShadowMgmtMemberModalForm({
  onAddShadowTeam,
  showModal,
  onCloseModal,
  isActionAdd,
  selectedShadowMember,
  listOfDicShadowPms,
}) {
  const [mockJ47ShadowTeamData, setMockJ47ShadowTeamData] = useState({
    pms: "",
    coverKey: "",
    shadowKey: "",
  });

  useEffect(() => {
    if (showModal === false) return;

    if (isActionAdd === false)
      return setMockJ47ShadowTeamData(selectedShadowMember);

    var newObj = {
      pms: "",
      coverKey: "",
      shadowKey: "",
    };
    setMockJ47ShadowTeamData(newObj);
  }, [showModal]);

  const setValue = (e) => {
    const { name, value } = e.target;
    setMockJ47ShadowTeamData({
      ...mockJ47ShadowTeamData,
      [name]: value,
    });
  };

  const RenderTitleBar = () =>
    isActionAdd === true
      ? "Add New Record"
      : `Detail of ${selectedShadowMember.coverKey} ${selectedShadowMember.pms}`;

  return (
    <Modal
      open={showModal}
      onClose={() => onCloseModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="Shadow-modal__wrapper"
    >
      <div className="Shadow-modal__container">
        <div className="Shadow-modal-title__container">
          <span style={{ cursor: "default" }}>{RenderTitleBar()}</span>
          <div>
            <ToolTipWithIcon
              title="Close"
              Icon={ViewIcon}
              onClick={() => onCloseModal(false)}
            />
          </div>
        </div>
        <div className="Shadow-modal-body__container">
          <ValidatorForm
            onSubmit={() => onAddShadowTeam(mockJ47ShadowTeamData)}
            onError={(errors) => console.log(errors)}
          >
            <TextBoxWithValidator
              name="coverKey"
              label="Cover Key"
              value={mockJ47ShadowTeamData.coverKey || ""}
              onInput={(e) => setValue(e)}
              readOnly={!isActionAdd && true}
            />
            <div className="shadow-memberModal__wrapper">
              <MaterialTableSelect
                data={listOfDicShadowPms}
                name="pms"
                label="PMS"
                onChange={(e) => setValue(e)}
                value={mockJ47ShadowTeamData.pms || ""}
                displayKey="pmsId"
                displayAttribute="pmsId"
              />
            </div>

            {!isActionAdd && (
              <>
                <TextBoxWithValidator
                  name="shadowKey"
                  label="Shadow Key"
                  value={mockJ47ShadowTeamData.shadowKey || ""}
                  onInput={(e) => setValue(e)}
                  readOnly={true}
                />
              </>
            )}

            <div className="Shadow-modal-button__container">
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                style={{ marginRight: 5 }}
              >
                Save
              </Button>
              <Button
                onClick={() => onCloseModal(false)}
                variant="outlined"
                color="secondary"
                type="button"
              >
                Cancel
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </div>
    </Modal>
  );
}
