
import IframeResizer from 'iframe-resizer-react'
import React , { useState, useEffect } from 'react'
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Overlay from '../../Components/Overlay/Overlay';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import CoverFilter from '../../ContextLib/CoreConsumer/Components/CoverLookup';
import { useCoverFiltersContext } from "../../ContextLib/contextHooks";
import Storage from '../../storage';
import JWT from 'expo-jwt';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };  
export default function PatientForms() {
    let componentId = "patientForms";
    const [formUrl, setFormUrl] = useState("")
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [errorSeverity, setErrorSeverity] = useState("success")
    const [SnackbarMessage, setSnackbarMessage] = useState("")
    const [loading, setLoading] = useState(false)

/*     useEffect(() => {  
        var url = `${process.env.REACT_APP_PATIENTFORMS_URL}`      
        setFormUrl(url)
    }, []) */
    let coverKey = -1;
    let lastCoverKey = null;

    const filtersContext = useCoverFiltersContext(componentId, null, async (newContext) => {
      //On filters changed
      coverKey = newContext.coverKey;

      if (lastCoverKey != newContext.coverKey) {
          lastCoverKey = newContext.coverKey;
      }

      let ready = newContext.coverKey != 0;
      _handleToggle(ready);
  });

    const _handleToggle = async (ready) =>
    {
        if (ready)
        {     
            try {  
                // const apiKey = `${process.env.REACT_APP_API_KEY}`;
              //  this.setState(() => ({ showLoadingOverlay: true }));
                // const url = `${process.env.REACT_APP_CAMPAIGN_API}/SearchCoverKey/${coverKey}?secretCode=${apiKey}`;
                // const res =  await axios.get(url);
    
                // if (res?.data) {
                    if (!loading) 
                    {
                       setShowLoadingOverlay(true)
                       setLoading(true)
                    }
                    //var token = Storage.getItem("cusr")
                    var userId = Storage.getItem("userId")

                    var key = `${process.env.REACT_APP_FORMS_JWTKEY_32BIT}`
                    var payload ={coverKey : coverKey , userID : userId}
                    var token  = JWT.encode(payload, key, { algorithm: 'HS256' });

                    var urlform = `${process.env.REACT_APP_PATIENTFORMS_URL}?userId=${userId}&token=${token}`      
                    setFormUrl(urlform)
                // }
                // else
                // {
                //     // No CoverKey
                //     setFormUrl("")
                // }
            }
            catch {
               // this.setState(() => ({  errorSeverity: 'error',showLoadingOverlay: false, openSnackBar: true, SnackbarMessage: 'Error in searching the coverkey.'})); 
            }    
        }
        else
        {
          setFormUrl("")
        }
   }             
    return(
        <>
        <div>
          <Box py={1} pl={2}  bgcolor="background.paper">
             <Typography>
             <h2 style={{margin: 0}}>Registration Form Intake</h2>
             </Typography>  
          </Box>
          <Divider/> 
          <Box>
            <CoverFilter />
          </Box>  
         
          {/* <Box style={{paddingLeft : "10px"}} p={1} bgcolor="background.paper">
          <ValidatorForm onSubmit={() => _handleToggle(true)} onError={errors => console.log(errors)}> 
          <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={1}>   
          <Grid item xs={12} sm={3} >
              <TextValidator
                  validators={['required', 'isPositive']}
                  errorMessages={['this field is required','Must be numeric and greater than zero']}                
                  fullWidth
                  value={coverKey}
                  onInput={ (e)=>setCoverKey(e.target.value)}  
                  onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    setCoverKey(ev.target.value);
                    ev.preventDefault();
                  }
              }}            
              label="Search Coverkey"
              InputProps={{
                  endAdornment: (
                  <InputAdornment>
                      <IconButton type="submit">
                      <SearchIcon />
                      </IconButton>                      
                  </InputAdornment>
                  )
              }}
              />   
              </Grid>   
              <Grid item xs={12} sm={3} > 
                <Button variant="contained" color="primary" type="submit">
                        Search
                </Button>                 
              </Grid>   
            </Grid>   
            </ValidatorForm>              
          </Box>      */}                      
        {formUrl !== "" && <div  style={{height: "100%", overflow : "auto"}}>
            <iframe 
                log
                checkOrigin
                onLoad={(e) => setShowLoadingOverlay(false)}
                sandbox="allow-scripts allow-same-origin allow-modals allow-top-navigation-by-user-activation"
                src={formUrl}
                allow="clipboard-read; clipboard-write"
                style={{ minWidth: '100%', height: "calc(100vh - 200px)", border:"0"}}
                />
        </div>}
        </div>    
        <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>      
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => setOpenSnackBar(false)}>
            <Alert onClose={() => setOpenSnackBar(false)} severity={errorSeverity}>
            {SnackbarMessage} 
            </Alert>
          </Snackbar>  
        </>
    )
}