import {
    Box,
    Button,
    Grid,
    InputAdornment,
    makeStyles,
    MenuItem,
    Paper,
    TextField,
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import Select from "react-select";
  import { SelectWrapper } from "./SelectWrapper";
  
  import FormControl from "@material-ui/core/FormControl";
  
  import "./ApptReminder.scss";
  
  import { TimePicker } from "@material-ui/pickers";
  import InfoIcon from '@material-ui/icons/Info';
  import axios from 'axios';
import Overlay from "../../Components/Overlay/Overlay";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AppointmentDays from "./AppointmentDays";

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    dropdownfield: {
      minWidth: "300px",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 250,
    },
  }));
  

  
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



  const ReminderSchedule = (props) => {
    const classes = useStyles();
    const [showLoadingOverlay,setShowLoadingOverlay]=useState(false);
    const useMountEffect = (functionName) => useEffect(functionName, []);
    const user = JSON.parse(localStorage.getItem("userId").toString());
    const [open, setOpen] = useState(false);
    var dt=new Date();
    const [bolDefaultSettingsLoaded,setDefaultSettingsLoaded]=useState(false);
    const [bolCommunicationChannelLoad,setCommunicationChannelLoaded]=useState(false);
    const [frm, setValues] = useState({
      secretcode:'ditac-1',
      CoverKey:Number(props.coverkey),
      Lockey:Number(props.locKey),
      AppointmentTypeId:props.appointmenttype,
      FutureNumberOfDaysEmail:1,
      FutureNumberOfDaysText:1,
      FutureNumberOfDaysVoice:1,
      ModalityId:1,
      StartTime: new Date(dt.getFullYear(), 1, 1, 4, 0, 0),
      EndTime: new Date(dt.getFullYear(), 1, 1, 19, 0, 0),
      AppointmentDay: { value: 1, label: "Monday" ,offset:3},
      FutureNumberOfDays:1,
      BeforeApptTimeSending:1,
      DayBeforeSending:1,
      ARSMSDay: { value: 1, label: "Monday",offset:0},
      MobileMessagingTimeSending: new Date(dt.getFullYear(), 1, 1, 16, 0, 0),
      User:user
    });

    const [selectAppointmentDay, setSelectedAppointmentDay] = useState([
      frm.AppointmentDay,
    ]);
    const [AppointmentDayOptions,setAppointmentDayOptions]=useState(
      [
        // { value:'Monday',label:'Monday',offset:3},
        // { value:'Saturday',label:'Saturday',offset:2},
        // { value:'Sunday',label:'Sunday',offset:2},
      ]
    );
    async function GetAppointmentDays()
    {
      const url = `${process.env.REACT_APP_ARTS_API}/GetAppointmentDays?secretCode=ditac-1&CoverKey=${frm.CoverKey}&LocKey=${frm.Lockey}&AppointmentTypeID=${frm.AppointmentTypeId}`;
      
      await axios.get(url).then((res)=>{
        
        setAppointmentDayOptions(res.data);
        
      }).catch((error)=>{
        console.log(error);
      });; 
      
    }

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    
    const handleAppointmentDay = (objName) => (selOption) => {
      // setValues({
      //   ...frm,
      //   [objName]:selOption
      // })
      if(objName==="AppointmentDay")
      {
        
        if(selOption.value===1)
        {
          setValues({
            ...frm,
            AppointmentDay:selOption,
            ARSMSDay:{value:5,label:'Friday',offset:0}
          });
          setSelectedARSMSDay({value:5,label:'Friday',offset:0})
        }
        if(selOption.value===6)
        {
          setValues({
            ...frm,
            AppointmentDay:selOption,
            ARSMSDay:{value:4,label:'Thursday',offset:0}
          });
          setSelectedARSMSDay({value:4,label:'Thursday',offset:0})
        }
        if(selOption.value===7)
        {
          setValues({
            ...frm,
            AppointmentDay:selOption,
            ARSMSDay:{value:5,label:'Friday',offset:0}
          });
          setSelectedARSMSDay({value:5,label:'Friday',offset:0})
        }
        
       
        
      }
      
    };
    
    const [selectedARSMSDay, setSelectedARSMSDay] = useState([
      frm.ARSMSDay,
    ]);
    const [bolSaved,setBolSaved]=useState(false);
  

    
    const handleChange = (objName) => (selOption) => {
      setValues({ ...frm, [objName]: selOption });
    };
  
   
   
  
  const [SMSDayOptions, setSMSDayOptions] = useState(
    [
    { value:1,label:'Monday',offset:0},
    { value:2,label:'Tuesday',offset:0},
    { value:3,label:'Wednesday',offset:0},
    { value:4,label:'Thursday',offset:0},
    { value:5,label:'Friday',offset:0},
    { value:6,label:'Saturday',offset:0},
    { value:7,label:'Sunday',offset:0}
  ]);
    
  async function createDefaultSettings()
  {
    setDefaultSettingsLoaded(false);
    setShowLoadingOverlay(true);
    const url = `${process.env.REACT_APP_ARTS_API}/InitializeDefaultLocationConfiguration?secretCode=ditac-1&coverkey=${props.coverkey}&locKey=${props.locKey}&communicationtypeid=${props.communicationType}`;
    await axios.post(url).then((res)=>{
      
      
      setDefaultSettingsLoaded(true);
      setShowLoadingOverlay(false);
  
    }).catch(()=>{
      console.log('error creating default settings for location')
    }); 
    
  }


  async function LoadCommunicationChannel(){
    setShowLoadingOverlay(true);
    setCommunicationChannelLoaded(false);
    const data = frm;
   
    const url = `${process.env.REACT_APP_ARTS_API}/LoadCommunicationChannelAPI?secretCode=ditac-1&CoverKey=${props.coverkey}&LocKey=${props.locKey}&AppointmentTypeID=${props.appointmenttype}`;

    await axios.get(url,data).then((res)=>{
     

      setValues({
      ...frm,
      AppointmentDay: res.data.appointmentDay,
      FutureNumberOfDaysEmail:res.data.futureNumberOfDaysEmail,
      FutureNumberOfDaysText:res.data.futureNumberOfDaysText,
      FutureNumberOfDaysVoice:res.data.futureNumberOfDaysVoice,
      StartTime:res.data.startTime,
      EndTime:res.data.endTime,
      MobileMessagingTimeSending:res.data.mobileMessagingTimeSending , 
      ARSMSDay:res.data.arsmsDay
      });
      
      setCommunicationChannelLoaded(true);
      setShowLoadingOverlay(false);
    }).catch((error)=>{
      console.log(error);
    });; 
    

  }


  async function SaveCommunicationChannel(){
    setShowLoadingOverlay(true);
    const data = frm;
   

    const url = `${process.env.REACT_APP_ARTS_API}/SaveCommunicationChannel`;
    const res =  await axios.post(url,data); 
    if (res?.data) 
    {
      console.log('Communication Channel saved');
      setBolSaved(!bolSaved);
      setShowLoadingOverlay(false);
      setOpen(true);
    }


  }


  const updateField = (e) => {
    const target = e.target;

    let value = target.value;
    let name = target.id;

    if (target.type === "checkbox") {
      value = target.checked;

      setValues({ ...frm, [name]: value });
    } else {
      setValues({ ...frm, [name]: value });
      
     
    }
  };
  const updateTextFieldInt = (e) => {
    const target = e.target;

    let value = target.value;
    let name = target.id;
    if(Number(value)<=0)
    {
      value=0;
    }
    if (target.type === "checkbox") {
      value = target.checked;

      setValues({ ...frm, [name]: value });
    } else {
      setValues({ ...frm, [name]: Number(value) });
      
     
    }
  };

  useEffect(()=>{
  
    createDefaultSettings();

  
    
 },[props.coverkey,props.communicationType,props.locKey,props.appointmenttype]);

 useEffect(()=>{
console.log('frm changed');
 },[frm]);
 useEffect(()=>{
 
  LoadCommunicationChannel();
  console.log('load communication ');
 },[props.coverkey,props.communicationType,props.locKey,props.appointmenttype,bolDefaultSettingsLoaded]);


 useEffect(()=>{

  GetAppointmentDays();
  console.log('loadappointment days');
 },[bolCommunicationChannelLoad]);

 



    return (
      <React.Fragment>
      
      {
        bolDefaultSettingsLoaded ? <><Box sx={{ paddingBottom: "0px",paddingTop:"30px" }}>
        <span><b>Reminder Schedule</b></span>
      </Box>
      <Grid container>
        {/* Email */}
          <Grid item md={4} xs={4}> 
          <span 
           style={{ paddingTop: "50px" }}>
            <u>Email</u>
          </span>
          <br />
          Days before appt:
          <br />
          <FormControl variant="outlined" style={{ width: 100 }}>
            <TextField
              id="FutureNumberOfDaysEmail"
              value={frm.FutureNumberOfDaysEmail}
              variant="outlined"
              size="small"
              type="number"
              min={0}
              onChange={updateTextFieldInt}
              style={{ marginRight: 10, height: 30 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">days</InputAdornment>
                ),
              }}
            />
          </FormControl>
          </Grid>
          {/* SMS */}
          <Grid item md={4} xs={4}>
          <span 
          style={{ paddingTop: "50px" }}>
           <u>SMS</u> 
          </span>
          <br />
          Days before appt:
              <br />
              <FormControl variant="outlined" style={{ width: 100 }}>
                <TextField
                id="FutureNumberOfDaysText"
                  value={frm.FutureNumberOfDaysText}
                  onChange={updateTextFieldInt}
                  type="number"
                  min={0}
                  variant="outlined"
                  size="small"
                  style={{ marginRight: 10, height: 30 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">days</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
          </Grid>
          {/* Voice */}
          <Grid item md={4} xs={4}> 
          <span 
          // className="rowItemHeader2"
          >
            <u>Voice</u>
            </span>
          <br />
          Days before appt:
          <br />
          <FormControl variant="outlined" style={{ width: 100 }}>
            <TextField
              id="FutureNumberOfDaysVoice"
              value={frm.FutureNumberOfDaysVoice}
              onChange={updateTextFieldInt}
              type="number"
              min={0}
              variant="outlined"
              size="small"
              style={{ marginRight: 10, height: 30 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">days</InputAdornment>
                ),
              }}
            />
          </FormControl>
          </Grid>
      </Grid>
      <Grid container >
          <Grid item md={4} xs={6} style={{display:"none"}}>
          Start Time:
                <br />
                <TextField
                  clearable
                  id="StartTime"
                  label="Start Time"
                  ampm={false}
                  type="time"
                  defaultValue="08:00"
                  value={frm.StartTime}
                  onChange={updateField}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
                <br />
          </Grid>
          <Grid item md={4} xs={6}style={{display:"none"}}>
          
               
                End Time:
                <br />
                <TextField
                  clearable
                  id="EndTime"
                  label="End Time"
                  ampm={false}
                  type="time"
                  defaultValue="19:00"
                  onChange={updateField}
                  value={frm.EndTime}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
                <br />
          </Grid>
          
           {/* SMS Additional Settings*/}
       
          <Box>
        <Grid container style={{paddingTop:"50px"}}>
            <Grid item md={12} xs={12}> 
            <span style={{paddingBottom:"10px"}} ><b>SMS Settings for Early Morning Appointment</b></span>
           
            <br />
            </Grid>
        </Grid>
       </Box>
       <Grid container>
          <Grid item md={4} xs={4}>
          Appointment Day:
                <br />
                <FormControl variant="outlined" style={{ width: 170 }}>
                  <Select 
                  
                  options={AppointmentDayOptions}
                  value={frm.AppointmentDay}
                  
                  onChange={handleAppointmentDay("AppointmentDay")}
                  style={{ height: 30 }
                
                }>
                   
                  </Select>
                </FormControl>
          </Grid>
          <Grid item md={4} xs={4}>
          SMS Day:
                <br />
                <FormControl variant="outlined" style={{ width: 170 }}>
                <Select
                  options={SMSDayOptions}
                  
                  value={frm.ARSMSDay}
                  onChange={handleChange("ARSMSDay")}
                  
                />
                </FormControl>
        </Grid>
        <Grid item md={4} xs={4}>
         <span title="This section details behavior for appointments that are before 11 am on any day."> SMS Delivery Time: &nbsp;<InfoIcon size="2" style={{color:"lightgrey",paddingTop:"5px"}} /> &nbsp;&nbsp;&nbsp;</span>
                <br />
                <TextField
                  clearable
                  id="MobileMessagingTimeSending"
                  
                  ampm={false}
                  type="time"
                  defaultValue="16:00"
                  onChange={updateField}
                  value={frm.MobileMessagingTimeSending}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
                <br />
          </Grid>
       </Grid></Grid></>:<>
       <Grid container>
        <Grid item xs={4} md={4}>
          
        </Grid>
        <Grid item xs={4} md={4}>
        <img src={process.env.PUBLIC_URL + '/spinner.gif'} width={"150px"}/><br/>
        </Grid>
        <Grid item xs={4} md={4}>
          
        </Grid>
        </Grid></>

      }
        
       
      {
        bolDefaultSettingsLoaded ? <>
        </>:<></>
      }
       
  
        
       {
        bolDefaultSettingsLoaded & bolCommunicationChannelLoad ? <><Grid container style={{paddingTop:"30px"}}>
        <Grid item md={12} xs={12}>

        
                <AppointmentDays coverkey={props.coverkey}  appointmenttype={props.appointmenttype} locKey={props.locKey} communicationType={props.communicationType} bolCommunicationChannelLoad={bolCommunicationChannelLoad} bolSaved={bolSaved} setShowLoadingOverlay={setShowLoadingOverlay}/>
        </Grid>

       </Grid>
     

        <Grid container style={{paddingTop:"30px"}}> 
         
         <Grid item md={4} xs={4}>
         <Button variant="contained" color="primary" type="button" onClick={()=>SaveCommunicationChannel()}>
             Save Settings
           </Button>
       </Grid>
      </Grid></>:<></>
       }
       
        
       
      
          
       

        <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>  
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Settings saved!
        </Alert>
      </Snackbar>
        {/* End of Main Grid */}
      </React.Fragment>
    );
  };
  
  export default ReminderSchedule;
  