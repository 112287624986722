import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment/moment';


export default function RowDetailsPatient(props) {
    const data = props.row
  
    return (
      <React.Fragment>
          <Box margin={1}>
  
          <Typography variant="h6" gutterBottom component="div">
              
            </Typography>
            <Table size="small" aria-label="details">
            <TableHead>
                <TableRow>
                  <TableCell>CoverKey</TableCell>                  
                  <TableCell>VwPatientID</TableCell>
                  <TableCell>PmsPatientID</TableCell>
                  <TableCell>VwLocationID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.coverKey}</TableCell>                    
                    <TableCell>{data.vwPatientID}</TableCell>
                    <TableCell>{data.pmsPatientID}</TableCell>
                    <TableCell>{data.vwLocationID}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>VwProviderID</TableCell>                  
                  <TableCell>PatientNumber</TableCell>
                  <TableCell>FirstName</TableCell>
                  <TableCell>LastName</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.vwProviderID}</TableCell>                    
                    <TableCell>{data.patientNumber}</TableCell>
                    <TableCell>{data.firstName}</TableCell>
                    <TableCell>{data.lastName}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>PrimaryPhone</TableCell>                  
                  <TableCell>PrimaryPhoneType</TableCell>
                  <TableCell>SecondaryPhone</TableCell>
                  <TableCell>SecondaryPhoneType</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.primaryPhone}</TableCell>                    
                    <TableCell>{data.primaryPhoneType}</TableCell>
                    <TableCell>{data.secondaryPhone}</TableCell>
                    <TableCell>{data.secondaryPhoneType}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>CellPhone</TableCell>                  
                  <TableCell>Email</TableCell>
                  <TableCell>Address1</TableCell>
                  <TableCell>Address2</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.cellPhone}</TableCell>                    
                    <TableCell>{data.email}</TableCell>
                    <TableCell>{data.address1}</TableCell>
                    <TableCell>{data.address2}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>City</TableCell>                  
                  <TableCell>Email</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Country</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.city}</TableCell>                    
                    <TableCell>{data.email}</TableCell>
                    <TableCell>{data.state}</TableCell>
                    <TableCell>{data.country}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>Zip</TableCell>                  
                  <TableCell>DOB</TableCell>
                  <TableCell>SSN</TableCell>
                  <TableCell>Gender</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.zip}</TableCell>                    
                    <TableCell>{data.dob}</TableCell>
                    <TableCell>{data.ssn}</TableCell>
                    <TableCell>{data.gender}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>PreferredLanguage</TableCell>                  
                  <TableCell>Title</TableCell>
                  <TableCell>CommunicationChoice1</TableCell>
                  <TableCell>CommunicationChoice2</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.preferredLanguage}</TableCell>                    
                    <TableCell>{data.title}</TableCell>
                    <TableCell>{data.communicationChoice1}</TableCell>
                    <TableCell>{data.communicationChoice2}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>CommunicationChoice3</TableCell>                  
                  <TableCell>SurveyEmailPreference</TableCell>
                  <TableCell>SurveySMSPreference</TableCell>
                  <TableCell>SurveyVoicePreference</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.communicationChoice3}</TableCell>                    
                    <TableCell>{data.surveyEmailPreference}</TableCell>
                    <TableCell>{data.surveySMSPreference}</TableCell>
                    <TableCell>{data.surveyVoicePreference}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>SurveyPostalPreference</TableCell>                  
                  <TableCell>ReminderEmailPreference</TableCell>
                  <TableCell>ReminderSMSPreference</TableCell>
                  <TableCell>ReminderVoicePreference</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.surveyPostalPreference}</TableCell>                    
                    <TableCell>{data.reminderEmailPreference}</TableCell>
                    <TableCell>{data.reminderSMSPreference}</TableCell>
                    <TableCell>{data.reminderVoicePreference}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>ReminderPostalPreference</TableCell>                  
                  <TableCell>RecallSMSPreference</TableCell>
                  <TableCell>RecallVoicePreference</TableCell>
                  <TableCell>RecallPostalPreference</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.reminderPostalPreference}</TableCell>                    
                    <TableCell>{data.recallSMSPreference}</TableCell>
                    <TableCell>{data.recallVoicePreference}</TableCell>
                    <TableCell>{data.recallPostalPreference}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>PickupEmailPreference</TableCell>                  
                  <TableCell>PickupSMSPreference</TableCell>
                  <TableCell>PickupVoicePreference</TableCell>
                  <TableCell>PickupPostalPreference</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.pickupEmailPreference}</TableCell>                    
                    <TableCell>{data.pickupSMSPreference}</TableCell>
                    <TableCell>{data.pickupVoicePreference}</TableCell>
                    <TableCell>{data.pickupPostalPreference}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>OtherEmailPreference</TableCell>                  
                  <TableCell>OtherSMSPreference</TableCell>
                  <TableCell>OtherVoicePreference</TableCell>
                  <TableCell>OtherPostalPreference</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.otherEmailPreference}</TableCell>                    
                    <TableCell>{data.otherSMSPreference}</TableCell>
                    <TableCell>{data.otherVoicePreference}</TableCell>
                    <TableCell>{data.otherPostalPreference}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>                  
                  <TableCell>ICD1</TableCell>
                  <TableCell>ICD2</TableCell>
                  <TableCell>ICD3</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.status}</TableCell>                    
                    <TableCell>{data.icD1}</TableCell>
                    <TableCell>{data.icD2}</TableCell>
                    <TableCell>{data.icD3}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>ICD1Date</TableCell>                  
                  <TableCell>ICD2Date</TableCell>
                  <TableCell>ICD3Date</TableCell>
                  <TableCell>VwInsurerIDPrimary</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.icD1Date}</TableCell>                    
                    <TableCell>{data.icD2Date}</TableCell>
                    <TableCell>{data.icD3Date}</TableCell>
                    <TableCell>{data.vwInsurerIDPrimary}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>VwInsurerIDSecondary</TableCell>                  
                  <TableCell>VwInsurerIDTertiary</TableCell>
                  <TableCell>ContactLensWearer</TableCell>
                  <TableCell>PrimaryInsuranceEIN</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.vwInsurerIDSecondary}</TableCell>                    
                    <TableCell>{data.vwInsurerIDTertiary}</TableCell>
                    <TableCell>{data.contactLensWearer}</TableCell>
                    <TableCell>{data.primaryInsuranceEIN}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>PrimaryInsuranceGroupID</TableCell>                  
                  <TableCell>SecondaryInsuranceGroupID</TableCell>
                  <TableCell>TertiaryInsuranceGroupID</TableCell>
                  <TableCell>PrimaryInsurancePolicyNo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.primaryInsuranceGroupID}</TableCell>                    
                    <TableCell>{data.secondaryInsuranceGroupID}</TableCell>
                    <TableCell>{data.tertiaryInsuranceGroupID}</TableCell>
                    <TableCell>{data.primaryInsurancePolicyNo}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>SecondaryInsurancePolicyNo</TableCell>                  
                  <TableCell>TertiaryInsurancePolicyNo</TableCell>
                  <TableCell>PrimaryInsurancePlanName</TableCell>
                  <TableCell>SecondaryInsurancePlanName</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.secondaryInsurancePolicyNo}</TableCell>                    
                    <TableCell>{data.tertiaryInsurancePolicyNo}</TableCell>
                    <TableCell>{data.primaryInsurancePlanName}</TableCell>
                    <TableCell>{data.secondaryInsurancePlanName}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>TertiaryInsurancePlanName</TableCell>                  
                  <TableCell>PmsSpecificName1</TableCell>
                  <TableCell>PmsSpecificValue1</TableCell>
                  <TableCell>PmsCreatedDate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.tertiaryInsurancePlanName}</TableCell>                    
                    <TableCell>{data.pmsSpecificName1}</TableCell>
                    <TableCell>{data.pmsSpecificValue1}</TableCell>
                    <TableCell>{data.pmsCreatedDate}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>PmsLastModifiedDate</TableCell>                  
                  <TableCell>LastVisitOrExamDate</TableCell>
                  <TableCell>Deleted</TableCell>
                  <TableCell>CreatedDate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.pmsLastModifiedDate}</TableCell>                    
                    <TableCell>{data.lastVisitOrExamDate}</TableCell>
                    <TableCell>{data.deleted ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{data.createdDate}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>LastModifiedDate</TableCell>                  
                  <TableCell>UtcCreatedDate</TableCell>
                  <TableCell>UtcLastModifiedDate</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.lastModifiedDate}</TableCell>                    
                    <TableCell>{data.utcCreatedDate}</TableCell>
                    <TableCell>{data.utcLastModifiedDate}</TableCell>
                    <TableCell></TableCell>                  
                  </TableRow>                     
              </TableBody>
            </Table>                     
            <br/>
          </Box>      
       </React.Fragment>
    );   

}