/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Divider } from "@material-ui/core";
import { useRequest, useUIContext, useV5Request } from '../../../ContextLib/contextHooks';
import { Severity } from '../../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import { useV5 } from "../../../ContextLib/CoreConsumer/v5Contexts";

const initialState = {
    mouseX: null,
    mouseY: null,
  };

const EventMenu = ({jsEvent, event, selectEvents, onSaved, editBlockGroup, deleteBlockGroup, isSingleEvent})=> {
    let componentId = "eventMenu";
    const ui = useUIContext(componentId);
    const {c, coverLocAcctDt, V5Request} = useV5();

    const [state, setState] = useState(initialState);
    const [isOnboarding, setIsOnboarding] = useState(false);
    const [fromLegacy, setFromLegacy] = useState(false);

    const handleOpen = (e) => {        
        setState({
            mouseX: e.clientX - 2,
            mouseY: e.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
        selectEvents(event.groupId, false);
    };

    const itemSelected = (op) => {
        handleClose();
        selectEvents(event.groupId, true);
        
        // if (event.extendedProps.fromOnboarding) {
        //     ui.MessageBox("Cannot delete/update onboarding blocks", "Delete/update block group");
        //     return;
        // }

        switch(op) {
            case "delete": {
                deleteBlockGroup({
                    groupId: event.groupId,
                    config: event.extendedProps.config, 
                    isBlackout: event.extendedProps.isBlackout,
                    editSingleEvent: event.extendedProps.editSingleEvent
                });
                break;
            }
            case "delete-all": {
                deleteBlockGroup({
                    groupId: event.groupId,
                    config: event.extendedProps.config, 
                    isBlackout: event.extendedProps.isBlackout,
                    editSingleEvent: null
                });
                break;
            }            
            case "edit": {
                let config = event.extendedProps.config;

                const dt = event.extendedProps.editSingleEvent;
                config.configFormData.startDate = dt.toLocaleDateString();
                config.configFormData.repetition.days = [false, false, false, false, false, false, false];
                config.configFormData.repetition.days[dt.getDay()] = true;
                config.configFormData.repetition.repeats = "once";
                config.configFormData.repetition.ends = "date";
                
                editBlockGroup({
                    groupId: event.groupId,
                    config: config, 
                    isBlackout: event.extendedProps.isBlackout,
                    editSingleEvent: event.extendedProps.editSingleEvent
                });
                break;
            }
            case "edit-all": {
                editBlockGroup({
                    groupId: event.groupId,
                    config: event.extendedProps.config, 
                    isBlackout: event.extendedProps.isBlackout,
                    editSingleEvent: null
                });
                break;
            }
            case "view-all": {
                editBlockGroup({
                    groupId: event.groupId,
                    config: event.extendedProps.config, 
                    isBlackout: event.extendedProps.isBlackout,
                    editSingleEvent: null,
                    readOnly: true
                });
                break;
            }
            case "delete-legacy-events": {
                deleteLegacyEvents();
                break;
            }
        }
    }

    const deleteLegacyEvents = async () => {
        let confirmed = await ui.ConfirmBox("Are you sure you want to delete this event(s)?");
        if (confirmed == 1) {   
            ui.ShowOverlay();
            const res = await V5Request.deleteLegacyEvents({
                coverKey: event.extendedProps.config.coverKey,
                locKey: event.extendedProps.config.locKey,
                acctKey: event.extendedProps.config.acctKey,
                data: {
                  mongoDBDocumentId: event.extendedProps.config.data.mongoDBDocumentId,
                  blockIds: event.extendedProps.config.data.blockIds
                }
            });
    
            if (res.success) {
                const provider = c.arrProviders.find(x => x.locKey == event.extendedProps.config.locKey && x.acctKey == event.extendedProps.config.acctKey);
                if (provider) {
                    provider.legacyData = [];
                    c.triggerUpdates();
                }
            }

            onSaved(res);
            
            ui.HideOverlay();
        }
        selectEvents(event.groupId, false);
    };


    const deleteBlock = async () => {
        let confirmed = await ui.ConfirmBox("Are you sure you want to delete this single block?");
        if (confirmed == 1) {   
            ui.ShowOverlay();
            const res = await V5Request.deleteWSAvailability({
                ...coverLocAcctDt(),
                data: {
                    groupId: event.groupId,
                    editSingleEvent: event.extendedProps.editSingleEvent.toLocaleDateString()
                }
            });
    
            onSaved(res);
            
            ui.HideOverlay();
        }
        selectEvents(event.groupId, false);
    };

    const deleteBlockGroup_ = async () => {
        let confirmed = await ui.ConfirmBox("Are you sure you want to delete this block group?");
        if (confirmed == 1) {   
            ui.ShowOverlay();
            const res = await V5Request.deleteWSAvailability({
                ...coverLocAcctDt(),
                data: {
                    groupId: event.groupId
                }
            });
    
            onSaved(res);
            
            ui.HideOverlay();
        }
        selectEvents(event.groupId, false);
    };

    useEffect(()=> {
        if (jsEvent) handleOpen(jsEvent);
    }, [jsEvent]);

    useEffect(() => {
        setIsOnboarding(event?.extendedProps?.fromOnboarding ?? false);
        setFromLegacy(event?.extendedProps?.fromLegacy ?? false);
    });

    return (<>
        <Menu
            keepMounted
            open={state.mouseY !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
            state.mouseY !== null && state.mouseX !== null
                ? { top: state.mouseY, left: state.mouseX }
                : undefined
            }
        >

            {isOnboarding ? <MenuItem onClick={(e)=> itemSelected("delete-all")}>Delete Event Group</MenuItem> : 
            fromLegacy ? (<>
                <MenuItem onClick={(e)=> itemSelected("view-all")}>View Details</MenuItem>
                <MenuItem onClick={(e)=> itemSelected("delete-legacy-events")}>Delete Event/Event Group</MenuItem>
            </>) :
            isSingleEvent(event?.groupId ?? "") ? (<>
                    <MenuItem onClick={(e)=> itemSelected("edit-all")}>Edit Event</MenuItem>
                    <MenuItem onClick={(e)=> itemSelected("delete-all")}>Delete Event</MenuItem>
                </>) : (<>
                    <MenuItem onClick={(e)=> itemSelected("edit")}>Edit Single Event</MenuItem>
                    <MenuItem onClick={(e)=> itemSelected("delete")}>Delete Single Event</MenuItem>
                    <Divider />
                    <MenuItem onClick={(e)=> itemSelected("edit-all")}>Edit Event Group</MenuItem>
                    <MenuItem onClick={(e)=> itemSelected("delete-all")}>Delete Event Group</MenuItem>
                </>)
            }
        </Menu>
    </>)
}

export default EventMenu;