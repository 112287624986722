import React from "react";

export default function TcDataViewerBody({ table }) {
  const RenderFirstTab = () => {
    if (!table.length > 0) return "";
    return "========================";
  };

  const RenderTableBody = ({ i, data }) => {
    return (
      <table>
        <thead>
          <tr>
            {data.columns.map((head, ii) => {
              return <th key={i + ii + "c"}>{head}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.rows.map((row, iii) => {
            return (
              <tr key={i + iii + "a"}>
                {row.values.map((val, x) => {
                  return <td key={i + x + iii + "b"}>{val}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {table &&
        table.map((n) => {
          return (
            <div>
              <a href={`#${n.tableName}`} className="pms-tcdataviewer-top">
                {n.tableName}
              </a>
            </div>
          );
        })}
      {RenderFirstTab()}
      {table &&
        table.map((n, i) => {
          return (
            <div
              key={i}
              id={`${n.tableName}`}
              className="pms-tc-dataviewer-bodywrapper"
            >
              <div className="pmsconfig-dataviewer-container">
                <label style={{ fontSize: "2em" }}>{n.tableName}</label>
                <div>
                  <span> Type: {n.tableType}</span>
                  <br />

                  <a href="#top" className="pms-tcdataviewer-top">
                    Back to Top
                  </a>
                </div>

                <div>
                  <RenderTableBody i={i} data={n} />
                </div>

                <label style={{ marginTop: 20 }}>
                  ========================
                </label>
              </div>
            </div>
          );
        })}
    </>
  );
}
