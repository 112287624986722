export function PmsGridSort(v1, v2) {
  if (v1 == null) v1 = "";
  if (v2 == null) v2 = "";
  return String(v1).localeCompare(String(v2));
}

export function PmsGridSortInt(v1, v2) {
  if (v1 == null) v1 = "";
  if (v2 == null) v2 = "";
  return v1 - v2;
}