import {
  GetAllShadowPms,
  GetJ47ShadowLoginsForBulkUpdate,
  UpsertJ47ShadowLoginBulkUpdate,
} from "../Util/pmsIntegrationShadMgmtApiUtil";
import { Button, Box, Popper, Fade } from "@material-ui/core";
import { PmsGridSort, PmsGridSortInt } from "../Util/PmsHelper";
import React, { useState, useEffect } from "react";
import { useUIContext } from "../../../ContextLib/contextHooks";
import { ValidatorForm } from "react-material-ui-form-validator";
import ToolTipWithIcon from "../Components/Common/ToolTipWithIcon";
import Looks3Icon from "@material-ui/icons/Looks3";
import Looks4Icon from "@material-ui/icons/Looks4";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import MoneyTwoToneIcon from "@material-ui/icons/Money";
import SwitchAccountIcon from "@material-ui/icons/PeopleAlt";
import MaterialDataGrid from "../Components/Common/MaterialDataGrid";
import MaterialTableSelect from "../Components/Common/MaterialTableSelect";
import TextBoxWithValidator from "../Components/Common/TextBoxWithValidator";
import ShadowTeamBulkConfirmation from "./ShadowTeamBulkConfirmation";
import PmsEnums from "./../Util/PmsEnums";

export default function ShadowTeamBulkUpdate({
  onSetActiveShadowTeam,
  onSetDisplayChange,
}) {
  const [mockData, setMockData] = useState({
    pms: "ALL",
    installPathDisplay: "",
    shadowTeamLoginDisplay: "",
    enableInstallPath: false,
    installPath: "",
  });

  const [open, setOpenPopper] = useState(false);
  const [popperSelectedId, setPopperSelectedId] = useState(0);
  const [popperAnchor, setPopperAnchor] = useState(null);

  // const [dataGridState, setDataGridState] = useState([]);

  const ui = useUIContext("v5filters");
  const [listOfPms, setListOfPms] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowsInGrid, setSelectedRowsInGrid] = useState([]);
  const [tmpSelectedRowsInGrid, setTmpSelectedRowsInGrid] = useState([]);
  const [listJ47ShadowLogins, setlistJ47ShadowLogins] = useState([]);
  const [toBeUpdatedJ47ShadowLogins, setToBeUpdatedJ47ShadowLogins] = useState(
    []
  );
  const HandleSortWithPopperInt = (v1, v2) => {
    setOpenPopper(false);
    return PmsGridSortInt(v1, v2);
  };

  const HandleSortWithPopper = (v1, v2) => {
    setOpenPopper(false);
    return PmsGridSort(v1, v2);
  };

  let dataGridState = [];

  const tableColumns = [
    {
      field: "login",
      headerName: "Login",
      width: 265,
      sortComparator: HandleSortWithPopper,
      renderCell: (params) => RenderRowWithClick(params),
    },
    {
      field: "shadowKey",
      headerName: "ShadowKey",
      width: 170,
      type: "number",
      sortComparator: HandleSortWithPopperInt,
      renderCell: (params) => RenderRowWithClick(params),
    },
    {
      field: "version",
      headerName: "Version",
      width: 260,
      sortComparator: HandleSortWithPopper,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              onClick={(e) => handlePopperClick(e, params.id)}
              style={{ cursor: "pointer" }}
            >
              {params.row.version}
            </span>
            <Popper
              className={getPopperClassname(params.id)}
              id={params.id}
              open={open}
              anchorEl={popperAnchor}
              transition
              style={{ maxWidth: 800 }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350} id={params.id}>
                  <Box
                    sx={{
                      border: 1,
                      p: 1,
                      bgcolor: "background.paper",
                      maxWidth: 800,
                      overflow: "scroll",
                      overflowY: "hidden",
                      boxShadow: "0px 0px 1px 1px #9E9E9E",
                    }}
                  >
                    {params.row.updateInstallationPath}
                  </Box>
                </Fade>
              )}
            </Popper>
          </>
        );
      },
    },
    {
      field: "isContainInstallPath",
      headerName: "Install Path?",
      width: 175,
      type: "boolean",
      sortComparator: HandleSortWithPopper,
      renderCell: (params) => RenderRowWithClick(params),
    },
    {
      field: "containMultiplePms",
      headerName: "Multiple PMS?",
      width: 195,
      type: "boolean",
      sortComparator: HandleSortWithPopper,
      renderCell: (params) => RenderRowWithClick(params),
    },
    {
      field: "pmsCount",
      headerName: "PMS Count",
      width: 165,
      type: "number",
      sortComparator: HandleSortWithPopperInt,
      renderCell: (params) => RenderRowWithClick(params),
    },
  ];

  const RenderRowWithClick = (event) => {
    if (event.colDef.type === "boolean") {
      if (event.formattedValue == "true")
        return (
          <CheckIcon
            onClick={() => setOpenPopper(false)}
            style={{ width: "100%" }}
          />
        );
      return (
        <CloseIcon
          onClick={() => setOpenPopper(false)}
          style={{ width: "100%" }}
        />
      );
    }
    return (
      <span onClick={() => setOpenPopper(false)} style={{ width: "100%" }}>
        {event.value}
      </span>
    );
  };

  const getPopperClassname = (id) => {
    if (open) {
      if (id == popperSelectedId) return `popper_show`;
    }
    return `popper_hide`;
  };

  const handlePopperClick = (event, id) => {
    setPopperSelectedId(id);
    setPopperAnchor(event.currentTarget);
    setOpenPopper((previousOpen) => !previousOpen);
  };

  const HandleShadowTeamChange = (data) =>
    setMockData({
      ...mockData,
      installPathDisplay: data.updateInstallationPath,
      shadowTeamLoginDisplay: data.login,
    });

  const setValue = (e, isCheckBox = false) => {
    const { name, value, checked } = e.target;
    let val = value;
    if (isCheckBox) val = checked;

    setMockData({
      ...mockData,
      [name]: val,
    });
  };

  useEffect(() => {
    ui.ShowOverlay();
    GetAllShadowPms()
      .then((resp) => {
        const { j47DictShadowPms } = resp.data;

        j47DictShadowPms.push({
          pmsName: "ALL",
          pmsId: "ALL",
          description: "",
          created: "",
          pmsKey: "99919",
        });

        setListOfPms(j47DictShadowPms);
      })
      .catch((error) => {
        ui.ShowSnackbar(`GetAllShadowPms: ${error}`, "error");
      })
      .finally(() => {
        ui.HideOverlay();
      });
  }, []);

  const _handleLoadShadowLogins = () => {
    ui.ShowOverlay();
    setOpenPopper(false);
    GetJ47ShadowLoginsForBulkUpdate(mockData.pms)
      .then((resp) => {
        setTmpSelectedRowsInGrid([]);
        setlistJ47ShadowLogins([]);

        const newData = resp.data.map((obj) => ({
          ...obj,
          id: obj.shadowKey,
          isContainInstallPath: obj.updateInstallationPath ? true : false,
          containMultiplePms: obj.containMultiplePms > 1 ? true : false,
        }));
        setlistJ47ShadowLogins(newData);
      })
      .catch((error) => {
        ui.ShowSnackbar(`GetJ47ShadowLoginsForBulkUpdate: ${error}`, "error");
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };

  const HandleSelectRecordsForXPercent = (num) => {
    let dataToBeSorted = [];

    if (dataGridState.length === 0) {
      dataToBeSorted = [...listJ47ShadowLogins];
    } else {
      dataToBeSorted = [...dataGridState];
    }

    console.log("1", dataToBeSorted);
    console.log(
      "A",
      dataToBeSorted.length,
      num,
      Math.round(dataToBeSorted.length * num)
    );
    const thirtyPercent = dataToBeSorted.slice(
      0,
      Math.round(dataToBeSorted.length * num)
    );

    console.log("2", thirtyPercent);

    const result = thirtyPercent.map((record) => record.id);
    setTmpSelectedRowsInGrid(result);
    setSelectedRowsInGrid(result);
  };

  const UpdateMultipleCover = () => {
    if (selectedRowsInGrid.length === 0)
      return ui.ShowSnackbar(
        `Please select one ore more Shadow Teams.`,
        "warning"
      );

    onSetActiveShadowTeam(selectedRowsInGrid);
    onSetDisplayChange(PmsEnums.BulkDisplay.COVERKEY);
  };

  const HandleSetDataGridState = (d) => {
    const { visibleRows, sorting } = d.state;
    let initialData = listJ47ShadowLogins;
    const filterCount = Object.keys(visibleRows.visibleRowsLookup).length;
    const sortModelCount = Object.keys(sorting.sortModel).length;

    if (initialData.length === 0) return [];

    if (filterCount !== 0) {
      initialData = initialData.filter((o) =>
        visibleRows.visibleRows.includes(o.id.toString())
      );
    }

    if (sortModelCount !== 0) {
      dataGridState = [...initialData].sort((a, b) => {
        return (
          sorting.sortedRows.indexOf(a.id.toString()) -
          sorting.sortedRows.indexOf(b.id.toString())
        );
      });

      return;
    }
    dataGridState = [...initialData];
  };

  const DataGridActions = (
    <>
      <ToolTipWithIcon
        title="Select 5% of the row in Grid"
        onClick={() => HandleSelectRecordsForXPercent(0.05)}
        Icon={LooksOneIcon}
      />
      <ToolTipWithIcon
        title="Select 10% of the row in Grid"
        onClick={() => HandleSelectRecordsForXPercent(0.1)}
        Icon={LooksTwoIcon}
      />
      <ToolTipWithIcon
        title="Select 25% of the row in Grid"
        onClick={() => HandleSelectRecordsForXPercent(0.25)}
        Icon={Looks3Icon}
      />
      <ToolTipWithIcon
        title="Select 50% of the row in Grid"
        onClick={() => HandleSelectRecordsForXPercent(0.5)}
        Icon={Looks4Icon}
      />
      <ToolTipWithIcon
        title="Select 100% of the row in Grid"
        onClick={() => HandleSelectRecordsForXPercent(1)}
        Icon={MoneyTwoToneIcon}
      />
      <ToolTipWithIcon
        title="Switch to cover specific bulk update for selected Teams"
        onClick={UpdateMultipleCover}
        Icon={SwitchAccountIcon}
      />
    </>
  );

  const HandleOpenConfirmation = () => {
    if (mockData.installPath === "" || selectedRowsInGrid.length === 0) {
      return ui.ShowSnackbar(
        `Please select one or more teams to be modified.`,
        "warning"
      );
    }

    const newData = listJ47ShadowLogins.filter((obj) =>
      selectedRowsInGrid.includes(obj.shadowKey)
    );

    const shadowKeys = [];
    newData.map((d) => shadowKeys.push(d.shadowKey));

    setTmpSelectedRowsInGrid(shadowKeys);
    setSelectedRowsInGrid(shadowKeys);
    setToBeUpdatedJ47ShadowLogins(newData);

    setShowModal(true);
  };

  const _handleBulkUpdate = async () => {
    if (mockData.installPath === "" || tmpSelectedRowsInGrid.length === 0) {
      return ui.ShowSnackbar(
        `Please add a new record to be modified first.`,
        "warning"
      );
    }

    const postData = {
      shadowKeys: tmpSelectedRowsInGrid,
      newInstallationPath: mockData.installPath,
    };

    ui.ShowOverlay();
    const promises = [
      UpsertJ47ShadowLoginBulkUpdate(postData)
        .then(async () => {
          _handleLoadShadowLogins();

          ui.ShowSnackbar(`Successfully UPDATED records`);
          setShowModal(false);
        })
        .catch((error) =>
          ui.ShowSnackbar(`handleBulkUpdate: ${error}`, "error")
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const ConfirmationValue = () => {
    return (
      <>
        <TextBoxWithValidator
          label="New Installation Path"
          name="installPathDisplay"
          rows={2}
          value={mockData.installPath || ""}
          multiline={true}
          required={false}
          readOnly={true}
        />
      </>
    );
  };
  return (
    <>
      <ShadowTeamBulkConfirmation
        onCloseModal={() => setShowModal(false)}
        showModal={showModal}
        tableColumns={tableColumns}
        tableData={toBeUpdatedJ47ShadowLogins}
        onSubmit={_handleBulkUpdate}
        ConfirmationValue={ConfirmationValue}
      />

      <h1 className="pmstitle-title__wrapper">Shadow Team Bulk Operation</h1>

      <div className="shadow-bulk__container">
        <div className="actionButton__container">
          <MaterialTableSelect
            data={listOfPms}
            name="pms"
            label="PMS"
            onChange={(e) => setValue(e)}
            value={mockData.pms || ""}
            displayKey="pmsId"
            displayAttribute="pmsId"
          />
          <Button
            onClick={() => _handleLoadShadowLogins()}
            variant="outlined"
            color="primary"
          >
            Search
          </Button>
        </div>
        <span id="bulk-span-id">
          Select a PMS to display teams having covers with the selected PMS
        </span>
        <div className="bulk-grid__wrapper">
          <MaterialDataGrid
            setDataGridState={HandleSetDataGridState}
            tableColumns={tableColumns}
            tableData={listJ47ShadowLogins}
            rowHeight={30}
            tableHeight={550}
            onSelectedRow={HandleShadowTeamChange}
            checkboxSelection={true}
            rowSelectionModel={tmpSelectedRowsInGrid}
            setRowSelectionModel={setSelectedRowsInGrid}
            GridActions={DataGridActions}
          />
        </div>
        <ValidatorForm
          onSubmit={_handleBulkUpdate}
          className="bulk-details__wrapper"
        >
          <div className="settings-configured">
            <span style={{ margin: "15px 0 10px 0 ", display: "block" }}>
              <strong>New Shadow Team Details</strong>
            </span>
            <TextBoxWithValidator
              label="New Installation Path"
              name="installPath"
              rows={3}
              value={mockData.installPath || ""}
              multiline={true}
              required={true}
              onInput={(e) => setValue(e)}
              style={{ marginBottom: 10 }}
            />
          </div>

          <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={HandleOpenConfirmation}
          >
            Bulk Update
          </Button>
        </ValidatorForm>
      </div>
    </>
  );
}
