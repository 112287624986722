import React from "react";
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";

export default function TextSearchWithIcon({
  label,
  onInput,
  onClick,
  type = "text",
}) {
  const HandleExecuteOnClick = (e) => {
    e.stopPropagation();
    onClick();
  };
  const SearchButtonRight = () => (
    <IconButton onClick={HandleExecuteOnClick}>
      <SearchIcon />
    </IconButton>
  );

  return (
    <TextField
      type={type}
      fullWidth
      onInput={(e) => onInput(e.target.value)}
      id="outlined-basic"
      label={label}
      variant="outlined"
      size="small"
      InputProps={{
        endAdornment: <SearchButtonRight />,
      }}
    />
  );
}
