import {
    Box,
    Button,
    Grid,
    InputAdornment,
    makeStyles,
    MenuItem,
    Paper,
    Switch,
    TextField,
  } from "@material-ui/core";
  import React, { useEffect, useRef, useState } from "react";
  import Select from "react-select";
  import { SelectWrapper } from "./SelectWrapper";
  
  import FormControl from "@material-ui/core/FormControl";
  
  import "./ApptReminder.scss";
  import axios from 'axios';
import Overlay from "../../Components/Overlay/Overlay";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MaterialTable from "material-table";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    dropdownfield: {
      minWidth: "300px",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 250,
    },
  }));
  
 
  
  const AppointmentSettings = (props) => {
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem("userId").toString());
    const [open, setOpen] = useState(false);
    const useMountEffect = (functionName) => useEffect(functionName, []);
    // const [inclusionChanged,setInclusionChanged]=useState(false);
    // const [exclusionChanged,setExclusionChanged]=useState(false);
    const [showLoadingOverlay,setShowLoadingOverlay]=useState(false);
    const tableRef = useRef(null);
    const tableRefExcluded = useRef(null)
 
    const [frm, setValues] = useState({
      secretcode:'ditac-1',
      coverkey:props.coverkey,
      appointmenttype:props.appointmenttype,
      appointmentstatus: [{ value: "1", label: "Kept" },{ value: "12", label: "Confirmed" }],
      appointmentreason: { value: "0", label: "ALL",isIncluded:1 },
      appointmentreasonexcluded: { value: "0", label: "All",isIncluded:0 },
      user:user
    });
    const [defaultAppointmentStatus, setDefaultAppointmentStatus] = useState([
      frm.appointmentstatus,
    ]);
    const handleChange = (objName) => (selOption) => {
      setValues({ ...frm, [objName]: selOption });
    };
  
    const [selectedAppointmentReason, setAppointmentReason] = useState([
      frm.appointmentreason,
    ]);
    const [includedCount, setIncludedCount] = useState(0);
   
    async function LoadAppointmentReasonsIncluded()
    {
      const url = `${process.env.REACT_APP_ARTS_API}/LoadAppointmentReasons?secretCode=ditac-1&coverKey=${frm.coverkey}&iAppointmentTypeID=${frm.appointmenttype}&bolIncluded=true`;
      
      await axios.get(url).then((res)=>{
        setIncludedCount(res.data.length);
        setAppointmentReason(res.data);
      
       
       
      }).catch((error)=>{
        console.log(error);
      });; 
      
    }

    const [excludedCount, setExcludedCount] = useState(0);
    const [selectedAppointmentReasonExcluded, setAppointmentReasonExcluded] = useState([
      frm.appointmentreasonexcluded,
    ]);
    async function LoadAppointmentReasonsExcluded()
    {
      setShowLoadingOverlay(true);
      const url = `${process.env.REACT_APP_ARTS_API}/LoadAppointmentReasons?secretCode=ditac-1&coverKey=${frm.coverkey}&iAppointmentTypeID=${frm.appointmenttype}&included=false`;
      
      await axios.get(url).then((res)=>{
        setExcludedCount(res.data.length);
        setAppointmentReasonExcluded(res.data);
        
        setShowLoadingOverlay(false);
      }).catch((error)=>{
        console.log(error);
      });; 
      
    }
    
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const [AppointmentReasonIncludedOptions, setAppointmentReasonIncludedOptions] = useState([]);
    const [AppointmentReasonExcludedOptions, setAppointmentReasonExcludedOptions] = useState([]);
    const [AppointmentReasonOptions, setAppointmentReasonOptions] = useState([]);
    async function GetAppointmentReasonAsync()
    {
      const url = `${process.env.REACT_APP_ARTS_API}/GetAppointmentReasonsDropDown?secretCode=ditac-1&coverKey=${frm.coverkey}`;
      
      await axios.get(url).then((res)=>{
        setAppointmentReasonOptions(res.data);
        setAppointmentReasonIncludedOptions(res.data);
        setAppointmentReasonExcludedOptions(res.data);
       
      }).catch((error)=>{
        console.log(error);
      });; 
      
    }

    const [AppointmentStatusOptions, setAppointmentStatusOptions] = useState([]);
    async function GetAppointmentStatusesAsync()
    {
      const url = `${process.env.REACT_APP_ARTS_API}/GetAppointmentStatuses?secretCode=ditac-1`;
      
      await axios.get(url).then((res)=>{
        // setAppointmentStatusOptions(res.data);
       setDefaultAppointmentStatus(res.data);
      }).catch((error)=>{
        console.log(error);
      });; 
      
    }
    
 

 
  
    const [selectedDate, setSelectedDate] = React.useState(
      new Date("2014-08-18T21:11:54")
    );
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  
    
   
    async function SaveAppointmentReasonsNew(selected,isIncluded){
      setShowLoadingOverlay(true);

      
      
      const data={
        
        secretcode:'ditac-1',
        coverkey:props.coverkey,
        appointmenttype:props.appointmenttype,
     
        appointmentreason: selected,
     
        user:user,
        isIncluded:isIncluded

      }

      
      const url = `${process.env.REACT_APP_ARTS_API}/SaveAppointmentReasons`;
      const res =  await axios.post(url,data); 
      if (res?.data) 
      {
        
        setShowLoadingOverlay(false);
        setOpen(true);
        LoadAppointmentReasonsIncluded();
        LoadAppointmentReasonsExcluded();
      }
  
  
    }
   
    useEffect(() => {
      if (selectedAppointmentReason != []) {
        setValues({ ...frm, ["appointmentreason"]: selectedAppointmentReason });
       

      }
     
    }, [selectedAppointmentReason]);

    useEffect(() => {
      if (selectedAppointmentReasonExcluded != []) {
        setValues({ ...frm, ["appointmentreasonexcluded"]: selectedAppointmentReasonExcluded });
       
      
      }
      
    }, [selectedAppointmentReasonExcluded]);
  
   
// useMountEffect(GetAppointmentReasonAsync);
   useEffect(()=>
  {
    GetAppointmentStatusesAsync();
  },[]);


 

    useEffect(()=>{
     
      GetAppointmentReasonAsync();
      LoadAppointmentReasonsIncluded();
      LoadAppointmentReasonsExcluded();
    },[props.appointmenttype]);
    

    useEffect(() => {
      if(includedCount>0)
      {
        tableRef.current.dataManager.changePageSize(includedCount);
      }
      
 }, [includedCount])
 useEffect(() => {
  if(excludedCount>0)
  {
    tableRefExcluded.current.dataManager.changePageSize(excludedCount);
  }
  
}, [excludedCount])

console.log(includedCount);
    return (
      <React.Fragment>
        
       
                
      <Grid container style={{padding:"20px"}}>
          <Grid  item md={12} xs={12}>
              <span><b>Appointment Status</b></span>
            </Grid>
           
       
          <Grid  item md={6} xs={6} style={{paddingRight:"40px"}}>
           
           <Select
                placeholderText="Select Appointment Type"
                isSearchable
                isMulti
                value={defaultAppointmentStatus}
                onChange={handleChange("appointmentstatus")}
                options={AppointmentStatusOptions}
                required
                isDisabled
                
              />
          
         
            </Grid> 
            
            </Grid>
  
         
          <Grid container style={{paddingLeft:"20px"}}>
            <Grid item xs={12} md={12}>
            <h3>Appointment Reasons</h3>
            </Grid>
          </Grid>
         <Grid container>
         <Grid item md={6} xs={6} style={{paddingLeft:"10px",width:"100%"}}>
        
           
          <MaterialTable
            tableRef={tableRef}
            title=""
            columns={[
              { title: 'value', field: 'value',hidden:true },
              { title: 'INCLUDED APPOINTMENT REASONS', field: 'label',headerStyle:{fontWeight:"bold"} ,width:"50px",cellStyle:{
                padding:"0px",margin:"0px"
              } },
         
             
            ]}
            data={selectedAppointmentReason}    
            options={{
              selection: true,
              search:true,
              pageSize:includedCount,
              pageSizeOptions:[50,75,100],
              showTextRowsSelected:false,
              rowStyle:{
                fontSize:"smaller",
                padding:"0px",
                spacing:"0px",
                border:0
              },
              tableLayout:"fixed",
              maxBodyHeight:"300px",
              minBodyHeight:"300px"
             
            }}
            actions={[
              {
                tooltip: 'Move to Exclusion',
                icon: ()=><ChevronRightIcon style={{
                  color:"black",fontSize:"50px",fontWeight:"bold"
                }}/>,
                onClick: (evt, data) => {
                  SaveAppointmentReasonsNew(data,false)
                }
              }
            ]}
          />
          </Grid>
          <Grid item md={6} xs={6} style={{paddingLeft:"10px",width:"100%"}}>
          <MaterialTable
          tableRef={tableRefExcluded}
            title=""
            columns={[
              { title: 'value', field: 'value',hidden:true },
              { title: 'EXCLUDED APPOINTMENT REASONS', field: 'label',headerStyle:{fontWeight:"bold"} ,width:"50px",cellStyle:{
                padding:"0px",margin:"0px"
              } },
         
             
            ]}
            data={selectedAppointmentReasonExcluded}        
            options={{
              
              selection: true,
              search:true,
              pageSize:excludedCount,
              pageSizeOptions:[50,75,100],
              showTextRowsSelected:false,
              rowStyle:{
                fontSize:"smaller",
                padding:"0px",
                spacing:"0px"
              },
              tableLayout:"fixed",
              maxBodyHeight:"300px",
              minBodyHeight:"300px"
             
            }}
            actions={[
              {
                tooltip: 'Move to Inclusion',
                icon: ()=><ChevronLeftIcon style={{
                  color:"black",fontSize:"50px",fontWeight:"bold"
                }} />,
                onClick: (evt, data) => {
                  SaveAppointmentReasonsNew(data,true)
                }
              }
            ]}
          />
          </Grid>
          </Grid> 
        
         <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>  
  
       
  
       
        {/* End of Main Grid */}
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Settings saved!
        </Alert>
      </Snackbar>
      </React.Fragment>
    );
  };
  
  export default AppointmentSettings;
  