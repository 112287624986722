import React, { useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Grid, Paper, Typography } from "@material-ui/core";
import EventNoteIcon from "@material-ui/icons/EventNote";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ApptRemSettingsMain from "./ApptRemSettingsMain";
import { BorderStyle } from "@material-ui/icons";
import ReminderSchedule from "./ReminderSchedule";
import AppointmentSettings from "./AppointmentSettings";
const AppointmentReminderSettings = (props) => {
 
  const useMountEffect = (functionName) => useEffect(functionName, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
  }));
  const classes = useStyles();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
        className="p-0 m-0"
      >
        {value === index && (
          <Box p={0} m={0}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }

  const TabAppointmentType = () => {
    return (
      <div className={classes.root}>  <AppBar position="static"
      style={{backgroundColor:"snow"}}
        color="default" 
       variant="fullWidth">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable"
          style={{ borderColor: "white",paddingTop:"0px" }}
          className="pb-0 mb-0"
        >
          
          <Tab
            label={
              <a className="blue-text font-weight-bold">
                Pre-Appointment
                <br />
              </a>
            }
            icon={<EventNoteIcon />}
            {...a11yProps(0)}
          />
          <Tab
            label={
              <a className="blue-text font-weight-bold">
                Appointment
                <br />
              </a>
            }
            icon={<EventAvailableIcon />}
            {...a11yProps(1)}
          />
        </Tabs></AppBar>
      </div>
    );
  };


  const SettingsHeader=(props)=>{
    
    return (<>
    <Grid container style={{paddingLeft:"40px",paddingRight:"40px",paddingBottom:"10px",paddingTop:"10px",backgroundColor:"whitesmoke"}}>
        <Grid item md={4} xs={4}>
        <span 
                className="rowItemHeader"
                >
                  {props.rowData.locName + " | " + props.rowData.locKey}{" "}</span>
        </Grid>
       
    </Grid>
    </>);
  }


  return (
    <React.Fragment>
        <SettingsHeader rowData={props.rowData} />
      <TabAppointmentType />
      <Paper square className={classes.root} style={{paddingLeft:"40px",paddingRight:"40px",paddingBottom:"40px"}}>
      
    
      <TabPanel value={value} index={0} style={{borderColor:"black",BorderStyle:"solid"}}>
       
      
        <ReminderSchedule coverkey={props.coverkey} appointmenttype={2} locKey={props.rowData.locKey} communicationType={props.communicationType}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
     
     
        <ReminderSchedule coverkey={props.coverkey}  appointmenttype={1} locKey={props.rowData.locKey} communicationType={props.communicationType}/>
        
      </TabPanel></Paper>
      <Paper square  style={{backgroundColor:"whitesmoke",height:"30px"}}>

      </Paper>
    </React.Fragment>
  );
};

export default AppointmentReminderSettings;
