import { Box, Snackbar, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import AppointmentTypeMapping from '../AppointmentTypeMapping/AppointmentTypeMapping';
import UserMapping from '../UserMapping/UserMapping';
import { Alert } from '@material-ui/lab';

const MappingIntegration = (props) => {
  const [tab, setTab] = useState(0);
  const [error, setError] = useState("");
  return <>
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={error !== ""} autoHideDuration={3000} onClose={() => setError("")}>
      <Alert onClose={() => setError("")} severity="error">
        {error} 
      </Alert>
    </Snackbar>  
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      height: "calc(100vh - 96px)"
    }}>
      <Tabs value={tab} onChange={(evt, newTab) => setTab(newTab)}>
        <Tab label="Appt Type Mapping" />
        <Tab label="User Mapping" />
      </Tabs>
      <Box sx={{
        minHeight: 0
      }}>
        <TabPanel selectedTab={tab} value={0}>
          <AppointmentTypeMapping onError={setError} />
        </TabPanel>
        <TabPanel selectedTab={tab} value={1}>
          <UserMapping onError={setError} />
        </TabPanel>
      </Box>
    </Box>
  </>
}

const TabPanel = ({selectedTab, value, children}) => {
  return selectedTab === value ? <>{children}</> : <></>
}


export default MappingIntegration;

