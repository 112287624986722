import axios from "axios";

export async function getJ47ShadowLogins(coverKey) {
  if (coverKey === undefined || coverKey === "" || coverKey === null)
    coverKey = 0;
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/api/PmsIntegration/ShadowManagement/GetJ47ShadowLogins" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/ShadowManagement/GetJ47ShadowLogins?secretCode=${process.env.REACT_APP_API_KEY}&coverKey=${coverKey}`;
  return await axios.get(url);
}

export async function GetJ47ShadowLoginsForBulkUpdate(pmsName) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/api/PmsIntegration/ShadowManagement/GetJ47ShadowLoginsForBulkUpdate" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetJ47ShadowLoginsForBulkUpdate?secretCode=${process.env.REACT_APP_API_KEY}&pmsName=${pmsName}`;
  return await axios.get(url);
}

export async function UpsertJ47ShadowLogin(data) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/ShadowManagement/UpsertJ47ShadowLogin" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/ShadowManagement/UpsertJ47ShadowLogin?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.put(url, data);
}

export async function UpsertJ47ShadowLoginBulkUpdate(data) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/ShadowManagement/UpsertJ47ShadowLoginBulkUpdate" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/UpsertJ47ShadowLoginBulkUpdate?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.put(url, data);
}

export async function UpsertJ47ShadowPMSDetailsBulkUpdate(data) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/ShadowManagement/UpsertJ47ShadowPMSDetailsBulkUpdate" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/UpsertJ47ShadowPMSDetailsBulkUpdate?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.put(url, data);
}

export async function GetJ47ShadowTeam(searchKey, search = "ShadowKey") {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/api/PmsIntegration/ShadowManagement/GetJ47ShadowTeam" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/ShadowManagement/GetJ47ShadowTeam?secretCode=${process.env.REACT_APP_API_KEY}&searchKey=${searchKey}&search=${search}`;
  return await axios.get(url);
}

export async function GetAllShadowPms() {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/api/PmsIntegration/ShadowManagement/GetAllShadowPms" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetAllShadowPms?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function GetShadowTeamMemberData() {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/api/PmsIntegration/ShadowManagement/GetShadowTeamMemberData" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/ShadowManagement/GetShadowTeamMemberData?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function GetJ47ShadowPMSDetails(coverKey) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/api/PmsIntegration/ShadowManagement/GetJ47ShadowPMSDetails" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/ShadowManagement/GetJ47ShadowPMSDetails?secretCode=${process.env.REACT_APP_API_KEY}&coverKey=${coverKey}`;
  return await axios.get(url);
}

export async function DeleteJ47ShadowTeam(coverKey, shadowKey) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/ShadowManagement//DeleteJ47ShadowTeam?mapId=123" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/ShadowManagement/DeleteJ47ShadowTeam?coverKey=${coverKey}&shadowKey=${shadowKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.delete(url);
}

export async function UpsertJ47ShadowTeam(data) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/ShadowManagement/UpsertJ47ShadowTeam" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/ShadowManagement/UpsertJ47ShadowTeam?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.put(url, data);
}

export async function UpsertShadowMemberConfig(data) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/ShadowManagement/UpsertShadowMemberConfig" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/ShadowManagement/UpsertShadowMemberConfig?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.put(url, data);
}
