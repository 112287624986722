import React from "react";
import MaterialCheckBox from "../Components/Common/MaterialCheckBox";

export default function HfJobOne({ setValue, mockData }) {
  return (
    <div>
      <div className="shadow-bulk__container">
        <span className="pms-ws_subTitle hf">Clear Residual Shadow Data</span>
        <span className="pms-ws_description hf">
          Job runs on a specified schedule to clear data from shadow databases
          for covers in a shadow team with the Clear directive set and for which
          automatic data clearing initiated from a blade may not have succeeded.
          Job works by first finding covers with uncleared data, then pausing
          for 48 hrs, and finally clearing data from the shadow database
          associated with the cover if Clear is still the blade directive.
        </span>

        <MaterialCheckBox
          onChange={(e) => setValue(e, true)}
          value={mockData.Job01EnableExecution || false}
          name="Job01EnableExecution"
          label="Enable execution?"
        />
      </div>
    </div>
  );
}
