import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import '../PatientInfo.scss';
import { Divider } from '@material-ui/core';

export default function PatientInfo_Insurance(props) {
 
  const [model, setModel] = useState(props.model);
  const [hasVisionInsurance, setHasVisionInsurance] = useState(props.model.v4DVisInsSect == 1);
  const [hasMedicalInsurance, setHasMedicalInsurance] = useState(props.model.v4DMedInsSect == 1);

  const onChange = (fieldName, value) => {
    let obj = {};
    obj[fieldName] = value;
    setModel({...model, ...obj});

    props.onChange(fieldName, value);

    if (fieldName == "v4DVisInsSect") {
      setHasVisionInsurance(value == 1);
    }

    if (fieldName == "v4DMedInsSect") {
      setHasMedicalInsurance(value == 1);
    }
  };
  
  const item = (fieldName, fieldValue, fieldLabel, triggers) => {
    let locked = props.isLocked(fieldName);

    return (
            <Grid item xs={12} sm={6} className={`lockable ${locked ? "locked" : ""}`}>
              <FormControl fullWidth>
                <InputLabel id={fieldName}>{fieldLabel}</InputLabel>
                {triggers ? 
                  <Select
                    labelId={fieldName}
                    id={fieldName}
                    value={fieldValue} 
                    disabled={locked}
                    onChange={e => {
                      onChange(fieldName, e.target.value);
                    }}
                  >
                      <MenuItem value={0}>Don't Display {locked && <Icon>lock</Icon>}</MenuItem>
                      <MenuItem value={1}>Display {locked && <Icon>lock</Icon>}</MenuItem>
                  </Select>
                :
                  <Select
                    labelId={fieldName}
                    id={fieldName}
                    value={fieldValue} 
                    disabled={locked}
                    onChange={e => {
                      onChange(fieldName, e.target.value);
                    }}
                  >
                      <MenuItem value={0}>Desired {locked && <Icon>lock</Icon>}</MenuItem>
                      <MenuItem value={-1}>Don't Ask {locked && <Icon>lock</Icon>}</MenuItem>
                      <MenuItem value={1}>Required {locked && <Icon>lock</Icon>}</MenuItem>
                  </Select>
                }
              </FormControl>
            </Grid>
    );
  };

  useEffect(() => {
    setModel(props.model);
    setHasVisionInsurance(props.model.v4DVisInsSect == 1);
    setHasMedicalInsurance(props.model.v4DMedInsSect == 1);
  }, [props.model]);

  return (
      <div>
        <h3>Vision Insurance Information</h3>

        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
          {item("v4DVisInsSect", model.v4DVisInsSect, "Do you have vision insurance?", true)}
        </Grid>  
        <div style={{display: hasVisionInsurance ? 'block' : 'none'}}>   
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {item("dinsCarrier", model.dinsCarrier, "Insurance Carrier")}
            {item("dSubDOB", model.dSubDOB, "Subscriber DOB")}
          </Grid> 
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {item("dSubId", model.dSubId, "Subscriber Number")}
            {item("dGroupNum", model.dGroupNum, "Group Number")}
          </Grid> 
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {item("dSubName", model.dSubName, "Subscriber Name")}
            {item("dRelToPat", model.dRelToPat, "Relation to Patient")}
          </Grid> 
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {item("dCustSrvNum", model.dCustSrvNum, "Insurance Customer Service Phone Number")}
            {item("v4DVisInsOtherIns", model.v4DVisInsOtherIns, "Other Vision Insurance")}
          </Grid> 
        </div>

        <br />
        <Divider />
        <br />
        <h3>Medical Insurance Information</h3>
        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
          {item("v4DMedInsSect", model.v4DMedInsSect, "Do you have medical insurance?", true)}
        </Grid>   
        <div style={{display: hasMedicalInsurance ? 'block' : 'none'}}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {item("v4DMedInsCarrier", model.v4DMedInsCarrier, "Insurance Carrier")}
            {item("v4DMedInsSubDOB", model.v4DMedInsSubDOB, "Subscriber DOB")}
          </Grid> 
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {item("v4DMedInsSubId", model.v4DMedInsSubId, "Subscriber Number")}
            {item("v4DMedInsGroupNum", model.v4DMedInsGroupNum, "Group Number")}
          </Grid> 
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {item("v4DMedInsSubName", model.v4DMedInsSubName, "Subscriber Name")}
            {item("v4DMedInsRel2Pat", model.v4DMedInsRel2Pat, "Relation to Patient")}
          </Grid> 
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {item("v4DMedInsCarrierSvcNum", model.v4DMedInsCarrierSvcNum, "Insurance Customer Service Phone Number")}
            {item("v4DMedInsOtherIns", model.v4DMedInsOtherIns, "Other Vision Insurance")}
          </Grid> 
        </div>
      </div>
      );
};

