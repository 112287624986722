import React from "react";
import {
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
} from "@material-ui/core";

export default function MaterialDialog({ open, onCloseDialog }) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => onCloseDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Warning. Unsaved Data."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved data of the member configuration. Please save or
            cancel before navigating to other records.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => onCloseDialog(false)}>Disagree</Button> */}
          <Button onClick={() => onCloseDialog(true)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
