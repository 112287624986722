import axios from "axios";

export async function getPmsLocations(shadowDb, coverKey) {
  if (coverKey == undefined || coverKey == null || coverKey == "") coverKey = 0;
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetPmsLocations?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetPmsLocations?shadowDbAlias=${shadowDb}&coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}
export async function getPmsProviders(shadowDb, coverKey) {
  if (coverKey == undefined || coverKey == null || coverKey == "") coverKey = 0;
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetPmsProviders?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetPmsProviders?shadowDbAlias=${shadowDb}&coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function get4pcLocations(coverKey) {
  if (coverKey == undefined || coverKey == null) coverKey = 0;
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/get4pcLocations?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/Get4pcLocations?coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function getShadowDbAliases(includeSqlConnection = false) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/getShadowDbAliases?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetShadowDbAliases?secretCode=${process.env.REACT_APP_API_KEY}&includeSqlConnection=${includeSqlConnection}`;
  return await axios.get(url);
}

export async function GetWSApptWritebackResults(coverKey) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetWSApptWritebackResults?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetWSApptWritebackResults?secretCode=${process.env.REACT_APP_API_KEY}&coverKey=${coverKey}`;
  return await axios.get(url);
}
export async function GetJ47WebSchedLog(coverKey) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetJ47WebSchedLog?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetJ47WebSchedLog?secretCode=${process.env.REACT_APP_API_KEY}&coverKey=${coverKey}`;
  return await axios.get(url);
}

export async function getPmsLocationTo4pcLocationMaps(coverKey) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/getPmsLocationTo4pcLocationMaps?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetPmsLocationTo4pcLocationMaps?coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function GetMapShadowProvidersAndAcctKey(shadowDb, coverKey) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetMapShadowProvidersAndAcctKey?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetMapShadowProvidersAndAcctKey?shadowDbAlias=${shadowDb}&coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function UpsertPmsprovidersTo4pcProviderResourceMap(data) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/UpsertPmsprovidersTo4pcProviderResourceMap" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/UpsertPmsprovidersTo4pcProviderResourceMap?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.put(url, data);
}

export async function UpsertPmsLocationTo4pcLocationMaps(data) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/UpsertPmsLocationTo4pcLocationMaps" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/UpsertPmsLocationTo4pcLocationMaps?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.put(url, data);
}

export async function UpsertPMSGeneralSettings(data) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/UpsertPMSGeneralSettings" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/UpsertPMSGeneralSettings?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.put(url, data);
}
export async function UpsertPmsApptReasonsTo4pcAppointmentReasonMap(data) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/UpsertPmsApptReasonsTo4pcAppointmentReasonMap" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/UpsertPmsApptReasonsTo4pcAppointmentReasonMap?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.put(url, data);
}
export async function UpsertWriteBackPreferences(data) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/UpsertWriteBackPreferences" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/UpsertWriteBackPreferences?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.put(url, data);
}

export async function DeletePmsprovidersTo4pcProviderResourceMap(mapId) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/DeletePmsprovidersTo4pcProviderResourceMap?mapId=123" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/DeletePmsprovidersTo4pcProviderResourceMap?mapId=${mapId}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.delete(url);
}

export async function DeletePmsLocationTo4pcLocationMaps(mapId) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/DeletePmsLocationTo4pcLocationMaps?mapId=123" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/DeletePmsLocationTo4pcLocationMaps?mapId=${mapId}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.delete(url);
}

export async function DeletePmsApptReasonsTo4pcAppointmentReasonMap(mapId) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/DeletePmsApptReasonsTo4pcAppointmentReasonMap?mapId=123" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/DeletePmsApptReasonsTo4pcAppointmentReasonMap?mapId=${mapId}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.delete(url);
}

export async function GetMapShadowApptReasonsAndTaskKey(shadowDb, coverKey) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetMapShadowApptReasonsAndTaskKey?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetMapShadowApptReasonsAndTaskKey?shadowDbAlias=${shadowDb}&coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function GetMapShadowLocationsAndLoc(shadowDb, coverKey) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetMapShadowLocationsAndLoc?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetMapShadowLocationsAndLoc?shadowDbAlias=${shadowDb}&coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function GetWriteBackPreferences(coverKey) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetWriteBackPreferences?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetWriteBackPreferences?coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function GetPmsGeneralSettings() {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetPmsGeneralSettings?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetPmsGeneralSettings?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}
export async function GetIniInbound(coverKey = 0) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetIniInbound?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetIniInbound?coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function GetCoverStatus(coverKey = 0) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetCoverStatus?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetCoverStatus?coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function GetDataTableInbound(coverKey, recordFilter) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetDataTableInbound?shadowDbAlias=sdb3&coverKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetDataTableInbound?coverKey=${coverKey}&recordFilter=${recordFilter}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function GetJ47WebSchedConfig(searchKey, joinTable) {
  if (searchKey == undefined || searchKey == null) searchKey = 0;
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetGetJ47WebSchedConfig?searchKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetJ47WebSchedConfig?searchKey=${searchKey}&joinTable=${joinTable}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url);
}

export async function GetWSCSMapConfigList(coverKey) {
  if (coverKey == undefined || coverKey == null) coverKey = 0;
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/GetGetWSCSMapConfigList?searchKey=4514" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/GetWSCSMapConfigList?coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.get(url, {
    transformResponse: (res) => res,
    responseType: "text",
  });
}

export async function PostWSCSMapConfigList(data) {
  //curl -X GET "https://dev-admin-crm.ditacapps.net/api/PmsIntegration/PostWSCSMapConfigList" -H "accept: text/plain"
  const url = `${process.env.REACT_APP_ADMIN_PMSINTEGRATION_API}/PostWSCSMapConfigList?secretCode=${process.env.REACT_APP_API_KEY}`;
  return await axios.post(url, data, {
    transformResponse: (res) => res,
    responseType: "text",
  });
}
