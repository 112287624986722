import React from "react";
import MaterialCheckBox from "../Components/Common/MaterialCheckBox";

export default function HfJobFour({ setValue, mockData }) {
  return (
    <div>
      <div className="shadow-bulk__container">
        <span className="pms-ws_subTitle hf">
          Clear Old Availability Slot Data
        </span>
        <span className="pms-ws_description hf">
          Job runs on a specified schedule to remove old data in the
          VwAvailabilitySlot tables in the shadow databases. Old data is defined
          as have an availability slot date older than two weeks ago.
        </span>

        <MaterialCheckBox
          onChange={(e) => setValue(e, true)}
          value={mockData.Job04EnableExecution || false}
          name="Job04EnableExecution"
          label="Enable execution?"
        />
      </div>
    </div>
  );
}
