import axios from 'axios';
import MaterialTable from "material-table";
import React , { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { withStyles } from '@material-ui/core/styles';

import './CoverLookup.scss';
import Constants from '../../../constants';
import { useUIContext, useCoverFiltersContext } from '../../contextHooks';
import { Severity } from './SnackbarMessage';
import Storage from '../../../storage';

export default function Coverlookup(props) {
    let componentId = "CoverlookupContextUI";
    const ui = useUIContext(componentId);    
    const [searchTypeID, setSearchTypeID] = useState("coverkey");
    const [searchInput, setSearchInput] = useState("");
    const [data, setData] = useState([]);
    const [coverKey, setCoverKey] = useState(-1);
    const [coverName, setCoverName] = useState("");
    const [searcHide, setSearcHide] = useState(false);

    const searchOptions = [
        {key : "covername" , value : "Cover Name"},
        {key : "coverkey" , value : "Cover Key"},
        {key : "coverphone" , value : "Cover Phone"},
        {key : "coverlogin" , value : "Cover Login"},
        {key : "lockey" , value : "Location Key"},
     ];

    const columns = [
        { field: 'coverKey', title: 'Coverkey', width: 100 , editable: 'never', emptyValue: '-'},
        { field: 'orgName', title: 'Practice Name', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.orgName} placement="top-start"><span>{dataRow.orgName}</span></HtmlTooltip>}},
        { field: 'status', title: 'Status', width: 100 , editable: 'never', emptyValue: '-'},
        { field: 'address1', title: 'Address 1', width: 150  , editable: 'never', emptyValue: '-'},
        { field: 'city', title: 'City', width: 100  , editable: 'never', emptyValue: '-'},
        { field: 'state', title: 'State', width: 100  , editable: 'never' , emptyValue: '-'},
        { field: 'email', title: 'Email', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.email} placement="top-start"><span>{dataRow.email}</span></HtmlTooltip>}},
        { field: 'phone', title: 'Phone', width: 150  , editable: 'never' , emptyValue: '-'},
    ];

    const filtersContext = useCoverFiltersContext(componentId, null, (newContext) => {   
        setCoverKey(newContext.coverKey);
        newContext.coverName !== undefined ? setCoverName(newContext.coverName) : setCoverName("");
    });  

    const setFilterValues = (val) => {
        filtersContext.SetValues(val);
    };


    const ActionClick = (val) =>
    {

        setSearcHide(false);
        filtersContext.SetValues(val);
		if(props.formName ==='TriageReportMaster')
		{
			setSearcHide(true);
		}       
    }

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 250,
          fontSize: theme.typography.pxToRem(16),
          border: '1px solid #dadde9',
          fontFamily:'Noto Sans, sans-serif',
        },
      }))(Tooltip);

    const componentDidMount = async () => {
        const user = Storage.getItem(Constants.currUserKey);
        let redirectToLogin = false;
        if (user && user.jwt) {
          try {
            ui.ShowOverlay();
            const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
            await axios.get(jwtCheckUrl);
            ui.HideOverlay();
          }
          catch (error) {
            ui.ShowSnackbar(error, Severity.error);
            redirectToLogin = true;
          }
        } 
        else {
            redirectToLogin = true;
        }

        if (redirectToLogin) return <Redirect to={{ pathname: '/' }} />;
    }       

    useEffect(() => {        
        componentDidMount();
    }, []);

    const onSubmit = async () => {
        try {
            ui.ShowOverlay();
            var request = {
                params: {
                  secretCode: `${process.env.REACT_APP_API_KEY}`,
                  queryType: searchTypeID,
                  queryString: searchInput
                }
              }

              const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getCoverLevelData`;
              const res = await axios.get(url, request);
              let d = res.data;
            
              if (res?.data.length > 0) { 
                setSearcHide(true)
                setData(d); 
                setFilterValues({coverKey: 0});
                setCoverName("")
              } else { 
                ui.ShowSnackbar("Cover does not exist in the database.", Severity.warning); 
                setData([]); 
                setFilterValues({coverKey: 0});
              }


        } catch (error) {
            ui.ShowSnackbar("Invalid search parameter! Please update search value and try again.", Severity.error);
            setData([]);
        }
        ui.HideOverlay();
    }

    const handelsetSearchTypeID=(e) => {  
        setSearchTypeID(e);
        setSearchInput('');
    }

    return(
    <div className="coverlookup">
      <Box p={2} bgcolor="background.paper" >
           
          <div className="coverlookup-data">
           
          <ValidatorForm onSubmit={onSubmit}  onError={errors => console.log(errors)} > 
            <Grid container justify="flex-start" alignItems="center" spacing={2} className="search-option">
                <Grid item>
                    <Typography variant="h6">Search By: </Typography>
                </Grid>

                <div className="search-fields">
                <Grid item>
                    <FormControl>
                    <Select 
                        id="searchType"
                        style ={{width:"150px"}}
                        value={searchTypeID}
                        onChange={ e=>handelsetSearchTypeID(e.target.value)}     
                        defaultValue='none'
                        disableUnderline       
                        native
                    >
                        {
                        searchOptions.map((item, index) => {
                        return <option key={index} value={item.key}>{item.value}</option>
                        })
                        }
                    </Select>
                    </FormControl>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem/>           
                <Grid item>
                    <TextValidator
                    style={{width:"170px"}}
                    id="searchInput"
                    autoComplete="off"
                    placeholder="Search Value"
                    value={searchInput}
                    onInput={e => setSearchInput(e.target.value)}  
                    validators={['required']}
                    errorMessages={['Search Value is required']}  
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                        <InputAdornment position="start">
                            <IconButton >
                            <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    />
                </Grid>
                </div>
                <Grid item>
                    <Button style={{maxWidth: '100px' , minWidth: '100px'}} variant="contained" color="primary" type="submit">
                    SEARCH
                    </Button>
                </Grid>
                {coverName !== "" && <>
                   <Grid item>
                    <Typography variant="h6">{coverName} ({coverKey})</Typography>
                    </Grid>
                </>}
            </Grid>
          </ValidatorForm>
          
          <Divider />     
          </div>
          {searcHide &&  <MaterialTable
                        height="200"
                        columns={columns}
                        data={data}
                        options={{
                            pageSize: 3,
                            pageSizeOptions: [3, 4, 5],
                            toolbar: false,
                            paging: true,
                            draggable: false,
                            sorting: false,
                            tableLayout: 'fixed',
                        }}       
                        actions={[
                            {
                            icon: () => <VisibilityIcon fontSize="small" />,
                            tooltip: 'View Data',
                            onClick: async (event, rowData) => ActionClick({coverKey: rowData.coverKey, coverName : rowData.orgName})
                            }
                        ]}
                        /> }

      </Box>
    </div >
    )
}