import React, { useEffect, useState, useRef  } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import EventTasks from './event-tasks';
import EventMenu from './event-menu';
import moment from 'moment';

const Calendar = ({events, setEvents, setCalendarDate, onSaved, onCalendarRef, highlightDate, editBlockGroup, deleteBlockGroup, setCalendar}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [popOverData, setPopoverData] = useState();

  const [jsEvent, setJSEvent] = useState();
  const [selectedEvent, setSelectedEvent] = useState();

  const calendarRef = useRef(null);
  
  const handleCalendarRef = (calendar) => {
    calendarRef.current = calendar;
    onCalendarRef(calendar);
  };

  const openMenu = (e, event) => {
    onEventMouseLeave();
    setJSEvent(e);
    setSelectedEvent(event);    
  }
  
  const selectEvents = (groupId, select) => {
    const ev = [...events];

    ev.forEach((x) => {
      if (x.groupId == groupId) {
        selectEvent(x, select);
      } else {
        selectEvent(x, false);
      }
    })
    
    setEvents(ev);
  }

  const selectEvent = (event, select) => {
    event.extendedProps.selected = select;
    event.classNames = select ? "calendar-event-selected" : undefined;
  }

  const headerToolbar = {
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay'
  }

  const onEventRender = (info) => {
    info.el.style.borderTop = `4px solid ${info.event.extendedProps.groupColor}`;
    info.el.setAttribute('title', `Group Id: ${info.event.groupId}`);

    if (info.event.extendedProps.isBreak) return;
    //if (info.event.extendedProps.fromLegacy) return;
    
    info.el.ondblclick = function(e) { openMenu(e, info.event); }
        
    var div = document.createElement('div'); 
    div.className="event-actions";
    div.innerHTML = "<b>...</b>";
    div.onclick = function(e) { openMenu(e, info.event); }
    info.el.appendChild(div);
  }

  const onDatesSet = (dateInfo) => {
    setCalendarDate(dateInfo);
  }

  const onEventClick = (info) => {
    selectEvents(info.event.groupId, !info.event.extendedProps.selected);
  }

  const onEventMouseEnter=(info) => {
    if (info.event.extendedProps.anyAppointment || info.event.extendedProps.isBlackout || info.event.extendedProps.isBreak) return; //if any appointment or blackout or break

    setPopoverData(info.event.extendedProps.apptTypes);
    setAnchorEl(info.el);
  }

  const onEventMouseLeave=() => {
    setPopoverData(null);
    setAnchorEl(null);
  }

  const isSingleEvent = (groupId) => {
    const evs = events.filter((x) => x.groupId == groupId);
    let dt = [];
    evs.forEach((x)=>{
      if (!dt.some((y)=>y == x.extendedProps.editSingleEvent.toLocaleDateString())) dt.push(x.extendedProps.editSingleEvent.toLocaleDateString());
    })
    return dt.length == 1;
  }

  useEffect(() => {
    if (highlightDate) {
      const newDate = new Date(highlightDate);
      handleDateCellContent(newDate);
    } else {
      handleRemoveDateCellContent();
    }
  }, [highlightDate]);

  const handleDateCellContent = (date) => {
    const cellDate = moment(date).format("YYYY-MM-DD");
    const dateCells = document.querySelectorAll('.fc-timegrid-cols [data-date]');
    dateCells.forEach((dateCell) => {
      const dateCellValue = dateCell.getAttribute('data-date');
      if (dateCellValue === cellDate) {
        dateCell.classList.add('fc-state-highlight');
      } else
      {
        dateCell.classList.remove('fc-state-highlight');
      }
    });
  };

  const handleRemoveDateCellContent = () => {
    const dateCells = document.querySelectorAll('.fc-timegrid-cols [data-date]');
    dateCells.forEach((dateCell) => {
        dateCell.classList.remove('fc-state-highlight');
    });
  };

  return (
    <>
      <FullCalendar
        ref={(calendar) => handleCalendarRef(calendar)}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin]}
        headerToolbar={headerToolbar}    
        events={events}
        datesSet={onDatesSet}
        eventMouseEnter={onEventMouseEnter}
        eventMouseLeave={onEventMouseLeave}
        eventDidMount={onEventRender}
        eventClick={onEventClick}
        slotMinTime="06:00"
        allDaySlot={false}
        initialView="timeGridWeek"
        editable={false}
        selectable={false}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
      />
      {popOverData && <EventTasks el={anchorEl} setEl={setAnchorEl} data={popOverData} />}
      <EventMenu jsEvent={jsEvent} event={selectedEvent} selectEvents={selectEvents} onSaved={onSaved} editBlockGroup={editBlockGroup} deleteBlockGroup={deleteBlockGroup} isSingleEvent={isSingleEvent} />
    </>
  );
}

export default Calendar;