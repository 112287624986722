import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import "./Preview-Schedule.scss";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import LeftIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import RightIcon from '@material-ui/icons/ArrowForwardIosTwoTone';
import CloseIcon from '@material-ui/icons/CancelTwoTone';
import ScheduleIcon from '@material-ui/icons/ScheduleOutlined';
import moment from 'moment';
import axios from 'axios';
import Overlay from '../../../Components/Overlay/Overlay';
import { useRequest } from '../../../ContextLib/contextHooks';
import { useV5 } from '../../../ContextLib/CoreConsumer/v5Contexts';
import { Constants } from '../../../ContextLib/Core/v5Core';

const ff = 'MM-DD-YYYY';

const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(2),
    },
    formControl: {
      minWidth: 280,
      maxWidth: 320
    },  
  }));  

export default function PreviewSchedule(props) {
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const classes = useStyles();
    const [listOfDates, setListOfDates] = React.useState([]);
    const [startDate, setSetStartDate] = React.useState();
    const [appointmentTypeList, setAppointmentTypeList] = React.useState([]);
    const [appointmentTypeId, setAppointmentTypeId] = React.useState(0);

    const [CoverKey, setCoverKey] = React.useState();
    const [LocKey, setLocKey] = React.useState();
    const [AcctKey, setAcctKey] = React.useState();
    const [FilterScheduleList, setFilterScheduleList] = React.useState([]);
    const [WithData, setWithData] = React.useState(false);
    const [showLoadingOverlay, setshowLoadingOverlay] = useState(false);
    const [RawSchedule, setRawSchedule] = React.useState([]);
    const [ShowNav, setShowNav] = React.useState(false);
    
  const { f, Request } = useV5();

     useEffect(() => {

        setAppointmentTypeList(f.activeAppointmentTypes);

        var sDate = moment().startOf('week');
        setSetStartDate(sDate.format('MM/DD/YYYY'));
        var lDates = [];
        lDates.push(sDate.format(ff));
        for (var i = 0; i < 6; i++) {
            lDates.push(sDate.add(1,'days').format(ff));
        };
        setListOfDates(lDates);
        setCoverKey(props.locationInfo.CoverKey);
        setLocKey(props.locationInfo.Key);
        setAcctKey(props.providerInfo.Key);
     }, []);
    
      const appointmentIdChange = async (taskKey) =>
      {
        setAppointmentTypeId(taskKey);
        try {
            setShowNav(true);
            setshowLoadingOverlay(true);
            setWithData(false);

            const params = { coverKey: CoverKey, acctKey: AcctKey, locKey: LocKey, taskKey: taskKey, gIdColors: props.gIdColors };
            let res = await Request.getAvailabilityPreview(params);

            if (res?.data) {
                let data = res.data;

              let dateList = [];
              data.forEach((schedule) =>
              {
                  let _f = moment(new Date(schedule.appointmentDate)).format(ff);
                  const s = { time: schedule.appointmentStartTime.toString(), color: schedule.color, groupId: schedule.groupId };

                if (!(_f in dateList)) {
                    dateList[_f] = [];
                    dateList[_f].push(s);
                } else {
                    dateList[_f].push(s);
                }
              });

              setRawSchedule(dateList);
              setWithData(data.length > 0 ? true : false);

             // Filter by List of Dates
              let filterDates = [];
              listOfDates.forEach((date) =>{
                if (date in dateList) {
                    filterDates.push(dateList[date]);
                }
              });

              // Get the longest array Length of Filter Dates
              let max = -Infinity;
              let index = -1;
              filterDates.forEach((a, i) => {
                if (a.length > max) {
                  max = a.length;
                  index = i;
                }
              });

              // Format the display per schedule
              let displaySchedules = [];
              for(var i =0 ; i < max ; i++)
              {
                if (!(i in displaySchedules)) displaySchedules[i.toString()] = [];

                listOfDates.forEach((date) =>{
                    if (date in dateList) {
                        if (dateList[date].length > i) displaySchedules[i.toString()].push(dateList[date][i]);   
                        else displaySchedules[i.toString()].push('-'); 
                    } else {
                        displaySchedules[i.toString()].push('-');  
                    }
                });
              }

              setFilterScheduleList(displaySchedules); 
              setshowLoadingOverlay(false);
            

            }
            else
            {
                setshowLoadingOverlay(false);
            }
            } catch (ex) {
                let message = 'Unexpected error while getting the schedules';
                setWithData(false);
                setShowNav(false);
                setshowLoadingOverlay(false);
            }          
      };


      const dateClick = (type) => {

        if (setShowNav)
        {
            var sdate = moment;
            if (type == 'subtract') 
                sdate = moment(startDate).subtract(1,'week');
            if (type == 'add') 
                sdate = moment(startDate).add(1,'week');   

            setSetStartDate(sdate.format('MM/DD/YYYY'));
            var lDates = [];
            lDates.push(sdate.format(ff));
            for (var i = 0; i < 6; i++) {
                lDates.push(sdate.add(1,'days').format(ff));
            };
            setListOfDates(lDates);
            
            /// ------------------
            var dateList = RawSchedule;
            let filterDates = [];

            lDates.map((date) =>{
            if (date in dateList)
                filterDates.push(dateList[date]);
            });

            // Get the longest array Length of Filter Dates
            let max = -Infinity;
            let index = -1;
            filterDates.forEach(function(a, i){
            if (a.length > max) {
                max = a.length;
                index = i;
            }
            });

            // Format the display per schedule
            let displaySchedules = [];
            for(var i =0 ; i < max ; i++)
            {
            if (!(i in displaySchedules))
                displaySchedules[i.toString()] = [];

                lDates.map((date) =>{
                if (date in dateList)
                    if (dateList[date].length > i)
                        displaySchedules[i.toString()].push(dateList[date][i]);   
                    else
                        displaySchedules[i.toString()].push('-'); 
                else
                    displaySchedules[i.toString()].push('-');  
            });
            }

            setWithData(displaySchedules.length > 0);
            setFilterScheduleList(displaySchedules);
      }

      }

  return (       
    <div>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={props.isOpen}
                onClose={props.handleClose}
            >
                <DialogTitle className="diaglogtitle" >
                  <Box>
                    <Typography variant="h5">
                    <ScheduleIcon style={{position: 'relative', top: '4px',  paddingRight : '3px'}}  />Preview Schedule
                    </Typography>
                  </Box>
                </DialogTitle>            
                <DialogContent
                    style={{ overflow: "hidden" }}
              >      
                  {props.generatingLegacyBlocks ? (<>
                      {Constants.generating}
                  </>) : (<>
                      
                <Grid container  direction="row"
                        justifyContent="flex-end"
                        alignContent="flex-end"
                        alignItems="center">   
                        <Grid item xs={6}>
                            <Typography variant="h4"> {props.providerInfo.Name}</Typography>   
                            <Typography variant="h6"> {props.locationInfo.Name}</Typography>  
                        </Grid>
                        <Grid container item xs={6} justify="flex-end">
                           <FormControl className={classes.formControl}>
                           <InputLabel id="demo-simple-select-label">Appointment Types</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={appointmentTypeId}
                                onChange={ e=> appointmentIdChange(e.target.value)}                                  
                            >
                                {appointmentTypeList.map((data) =>
                                {
                                    return  <MenuItem value={data.taskKey}>{data.appointmentType}</MenuItem>
                                })}
                            </Select>
                            </FormControl> 
                        </Grid>                                                     
                </Grid>                  
                <Grid container  direction="row"
                        justifyContent="flex-end"
                        alignContent="flex-end"
                        alignItems="center">  
                <Grid item>     
                <Box pt={1}>                              
                <div className="schedule-preview">   
                    { ShowNav && <div className="btn-left" onClick={() => dateClick("subtract")}> 
                        <LeftIcon />
                    </div>}
                    { ShowNav && <div className="btn-right" onClick={() => dateClick("add")}>
                        <RightIcon />
                    </div>}         
                    <table className="table text-center">
                       <thead>
                        <tr>
                            {
                                 listOfDates.map((d) =>
                                {
                                    return <th >{moment(d).format("ddd")} <br/>{moment(d).format("MMM DD")}</th>
                                })
                            }
                        </tr>
                        </thead>
                        <tbody>
                           { !ShowNav && !WithData &&
                            <> 
                                <tr>
                                <td colspan="7"></td>
                                </tr>
                                <tr>
                                <td colspan="7">Please select Appointment Type</td>
                                </tr>
                             </>
                            }   
                           { !WithData && ShowNav &&
                            <> 
                                <tr>
                                <td colspan="7"></td>
                                </tr>
                                <tr>
                                <td colspan="7">No availabilities found on these dates</td>
                                </tr>
                             </>
                            }  

                           { WithData &&  FilterScheduleList.map((date) =>  {
                               return <tr>
                                {date.map((day) =>
                                    {  
                                        if (day != '-')
                                        return <td >
                                            <div className="block" style={{borderTop: `3px solid ${day.color}`}}>{day.time}</div></td>
                                        else
                                        return <td >
                                                    <div className="empty"/>
                                                </td>                                 
                                    })}
                                </tr>
                            })
                           }                                               
                                                                                                                                                                        
                        </tbody>
                     </table>                   
                </div>    
                </Box>
                </Grid> 
                      </Grid>     
                
                      </>)}       
                </DialogContent>
                <DialogActions>
                <Button variant="outlined" startIcon={<CloseIcon />} onClick={props.handleClose}>
                Close
                </Button>
                </DialogActions>
            </Dialog>   
            <Overlay show={showLoadingOverlay}>
                <i className="spinner-eclipse"></i>
            </Overlay>         
    </div>
  );
}