import MaterialTable from 'material-table';
import React from 'react';

const GeneralEditor = ({
    columns,
    data,
    onCreate,
    onUpdate,
    onDelete,
    autoValues,
    pageSize,
    title
  }) => {
  const handleCreate = (item) => {
    if(onCreate) {
      const itemToAdd = autoValues === undefined ? item : {...item, ...autoValues};
      onCreate(itemToAdd);
    }
  }
  const handleUpdate = (newItem, oldItem) => {
    if(onUpdate) {
      onUpdate(newItem, oldItem);
    }
  }
  const handleDelete = (item) => {
    if(onDelete) {
      onDelete(item);
    }
  }

  //fix numeric fields
  const prepRow = (row) => {
    for(const column of columns) {
      if(column.type === 'numeric') {
        row[column.field] = Number.parseInt(row[column.field]);
      }
    }
    return row;
  }

  const pageSizeToUse = pageSize ? pageSize : 10;
  return <>
    <MaterialTable
      key={pageSizeToUse} //causes re-render when page size changes
      height={200}
      columns={columns}
      data={data}
      title={title ?? ""}
      options={{
        pageSize: pageSizeToUse,
        pageSizeOptions: [],
        paging: true,
        search: false,
        headerStyle: {
          fontSize: 14,
          padding: 10
        },
        actionsColumnIndex: -1,
        tableLayout: "auto"
      }}
      editable={{
        onRowAdd: onCreate ? async (newData) => {
          handleCreate(prepRow(newData));
        } : undefined,
        onRowUpdate: onUpdate ? async (newData, oldData) => {
          handleUpdate(prepRow(newData), oldData);
        } : undefined,
        onRowDelete: onDelete ? async (oldData) => {
          handleDelete(oldData);
        } : undefined
      }}
    />
  </>
}

export const createTableLookup = (data, keyField, valueField) => {
  const lookup = {};
  for(const item of data) {
    lookup[item[keyField]] = item[valueField];
  }
  return lookup;
}

export default GeneralEditor;

