/**
 * Represents a collection of enum values.
 */
const Enums = {
  /**
   * Represents the supported appointment statuses.
   */
  AppointmentStatus: {
    /**
     * Indicates a scheduled status.
     */
    get scheduled() {
      return 1;
    },

    /**
     * Indicates a queued status.
     */
    get queued() {
      return 0;
    },

    /**
     * Indicates a no show status.
     */
    get noShow() {
      return 5;
    },

    /**
     * Indicates a cancelled status.
     */
    get cancelled() {
      return 6;
    },
  },

  /**
   * Represents the supported http status codes.
   */
  HttpStatusCodes: {
    /**
     * Returns the HTTP status ok value.
     */
    get httpStatusOk() {
      return 200;
    },

    /**
     * Returns the HTTP status server error value.
     */
    get httpStatusInternalServerError() {
      return 500;
    },

    get httpStatusUnauthorizedError() {
      return 401;
    },
  },

  /**
   * Represents the supported keyboard keys.
   */
  KeyboardKeys: {
    /**
     * Indicates the Enter key.
     */
    get enterKey() {
      return "Enter";
    },
  },

  /**
   * Represents the supported user statuses.
   */
  UserStatus: {
    /**
     * Indicates the onboarding status.
     */
    get onboarding() {
      return "Onboarding";
    },

    /**
     * Indicates the active status.
     */
    get active() {
      return "Active";
    },

    /**
     * Indicates the password reset status.
     */
    get pwReset() {
      return "PwReset";
    },
  },

  PmsMapDirection: {
    get Down() {
      return "DOWN";
    },
    get Up() {
      return "UP";
    },
  },

  HostingAuditTrailTypeId: {
    /**
     * Indicates a scheduled status.
     */
    get create() {
      return 101;
    },

    /**
     * Indicates a queued status.
     */
    get update() {
      return 102;
    },

    /**
     * Indicates a no show status.
     */
    get remove() {
      return 103;
    }
  },
};

// Lock object to prevent modification (true static).
Object.freeze(Enums);

export default Enums;
