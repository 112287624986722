import React from 'react';
import axios from "axios";
import { ClDataRequestObject } from "../CoreConsumer/dataRequest";
import { objectsAreEqual } from "./coreLib";

export const Constants = {
    generating: 'Generating blocks. Please wait...'
};

export class RequestResultInfo {
    strApiName = null;
    success = false;
    cancelled = false;
    messages = [];
    errors = [];
    _statusCode = 0;

    constructor(obj, strApiName) {
        this.strApiName = strApiName;
        if (obj) {
            this.success = obj.success;
            this._statusCode = obj.statusCode ?? 0;
            this.cancelled = obj.cancelled ?? false;
            this.messages = obj.messages ?? [];
            this.errors = this.errors.concat(obj.errors ?? []);
            obj.error && this.errors.push(obj.error);            
        }
    }

    get hasError() {
        return this.errors.length > 0;
    }

    get strStatus() {
        return this.success ? "Successful" : 
                this.cancelled ? "Cancelled" : "Failed";
    }

    JsxMsg(isErr) {
        const o = isErr ? this.errors : this.messages;
        const s = o.length > 1 ? <ul>${o.map(x => <li>${x}</li>)}</ul> : <span>{o[0]}</span>;
        return (<><span>{`${this.strApiName ? `${this.strApiName}: ` : ""}`}</span>{s}</>);
    }
};

export class RequestTicket {
    _clRequestResultInfo = null;    
    _started = null;
    _ended = null;

    constructor(strApiName, objParams, v5) {
        this.strApiName = strApiName;
        this.objParams = objParams;                
        this._objRequestSource = axios.CancelToken.source();
        const cl = new ClDataRequestObject(this._objRequestSource.token);
        this._clRequest = v5 ? cl.V5Request : cl.Request;
    }

    _clear() {
        this._started = false;
        this._ended = false;
        this._clRequestResultInfo = null;
    }

    get isBusy() {
        return this._started && !this._ended;
    }

    get isDone() {
        return this._started && this._ended;
    }

    get clRequestResultInfo() {
        return this._clRequestResultInfo;
    }

    get strStatus() {
        const rem = this.isDone ? this._clRequestResultInfo?.strStatus : 
                    this.isBusy ? "Waiting for response..." : 
                    "Not started";

        return `${this.strApiName}: ${rem}`;
    }

    IsMatch(clRequestTicket) {
        const namesMatch = this.strApiName == clRequestTicket.strApiName;
        const paramsMatch = objectsAreEqual(this.objParams, clRequestTicket._objParams);
        return namesMatch && paramsMatch;
    }

    async Start() {
        this._clear();
        this._started = true;
        const res = await this._clRequest[this.strApiName](this.objParams);
        this._clRequestResultInfo = new RequestResultInfo(res, this.strApiName);
        this._ended = true;
        return new ReturnObj(res, this.strApiName);
    }

    Cancel() {
        this._objRequestSource.cancel();
    }
};

export class ReturnObj {
    data;
    info;

    constructor(ret, strApiName) {
        this.data = ret?.data?.data ?? ret?.data;
        this.info = new RequestResultInfo(ret, strApiName);
    }
}

export class RequestManager {
    _arrRequestTickets = [];

    get messages() {
        return this._arrRequestTickets.some(x => x.isBusy);
    }

    get isBusy() {
        return this._arrRequestTickets.some(x => x.isBusy);
    }

    get nDone() {
        return this._arrRequestTickets.filter(x => x.isDone).length;
    }

    get nRequests() {
        return this._arrRequestTickets.length;
    }

    get isCompleted() {
        return this.nRequests > 0 && this._arrRequestTickets.every(x => x.isDone);
    }

    get strStatus() {
        const rem = this.isCompleted? "Completed" : 
                    this.isBusy ? `Waiting for response...` : "";

        return rem;
    }

    Clear() {
        this._arrRequestTickets = [];
    }

    GetRequestTicket(index) {
        return this._arrRequestTickets[index];
    }

    async Start(clRequestTicket) {
        const existing = this._arrRequestTickets.find(x => x.IsMatch(clRequestTicket) && !x.isDone);
        existing && existing.Cancel();
        
        const i = this._arrRequestTickets.push(clRequestTicket) - 1;
        const res = await this._arrRequestTickets[i].Start();
        
        return res;
    }

    CancelAllRequests() {
        this._arrRequestTickets.forEach(x => x.Cancel());
    }
};

export class CoverLocAcct {
    constructor(obj) {
        if (obj) {
            if (Array.isArray(obj)) {
                this.coverKey = obj[0];
                this.locKey = obj[1];
                this.acctKey = obj[2];
            } else {
                this.coverKey = obj.coverKey;
                this.locKey = obj.locKey;
                this.acctKey = obj.acctKey;
            }
        } else {
            this.coverKey = 0;
            this.locKey = 0;
            this.acctKey = 0;
        }
    }       

    get key() {
        return `${this.locKey}-${this.acctKey}`;
    }

    get asCoverLocAcctObject() {
        return {
            coverKey: this.coverKey,
            locKey: this.locKey,
            acctKey: this.acctKey
        };
    }

    get asCoverLocAcctArray() {
        return [this.coverKey, this.locKey, this.acctKey];
    }

    ToCoverLocAcctString(delimeter) {
        return this.asCoverLocAcctArray.join(delimeter ?? "-");
    }
};

export class CoverLocAcctReq extends CoverLocAcct {    
    _requestMgr = new RequestManager();    
    info;

    get isBusy() {
        return this._requestMgr.isBusy;
    }

    get nDone() {
        return this._requestMgr.nDone;
    }
    
    get isCompleted() {
        return this._requestMgr.isCompleted;
    }

    get strStatus() {
        return this._requestMgr.strStatus;
    }
    
    Clear() {
        this.coverKey = 0;
        this.locKey = 0;
        this.acctKey = 0;
        this._requestMgr.Clear();
    }

    async GetData(strApiName, objParams) {        
        const requestTicket = new RequestTicket(strApiName, objParams, false);
        const ret = await this._requestMgr.Start(requestTicket);
        this.info = ret.info;
        return ret;
    }

    async GetData_UseV5Result(strApiName, objParams) {        
        const requestTicket = new RequestTicket(strApiName, objParams, true);
        const ret = await this._requestMgr.Start(requestTicket);
        this.info = ret.info;
        return ret;
    }

    CancelAllRequests() {
        this._requestMgr.CancelAllRequests();
    }
};