import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useEffect, useState } from 'react'
import SettingsIcon from '@material-ui/icons/Settings';
import { Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import AppointmentReminderSettings from './AppointmentReminderSettings';
import axios from 'axios';
const AppointmentDays = (props) => {
  const user = JSON.parse(localStorage.getItem("userId").toString());
 
    const [data,setData]=useState([]);
    

    const [SMSDayOptions, setSMSDayOptions] = useState(
        [
        { value:1,label:'Monday',offset:0},
        { value:2,label:'Tuesday',offset:0},
        { value:3,label:'Wednesday',offset:0},
        { value:4,label:'Thursday',offset:0},
        { value:5,label:'Friday',offset:0},
        { value:6,label:'Saturday',offset:0},
        { value:7,label:'Sunday',offset:0}
      ]);
  

    async function GetConfiguredAppointmentDays()
    {

      const url = `${process.env.REACT_APP_ARTS_API}/GetConfiguredAppointmentDays?secretCode=ditac-1&CoverKey=${props.coverkey}&LocKey=${props.locKey}&AppointmentTypeID=${props.appointmenttype}`;
      
      await axios.get(url).then((res)=>{

        setData(res.data);
        props.setShowLoadingOverlay(false);
       
      }).catch((error)=>{
        console.log(error);
      });; 
      
    }
    
  
    const apptDaysColumns = [
      
        {
          field: "dayName",
          title: "Appointment Day",
            width:300
        },
        {
            field: "dayName",
            title: "SMS Day",
            width:300,
            render:(rowData)=>{
   
                    let iIndex=0;

                    if(rowData.dayInt<rowData.offset)
                    {
                        iIndex=(rowData.dayInt + 7)-rowData.offset;
                    }
                    else
                    {
                        iIndex=rowData.dayInt-rowData.offset;
                    }
                    const tmp= SMSDayOptions.filter(x=>x.value==iIndex);
                     const sDay=tmp.map((item)=>item.label);
                return(<>
                    {
                        sDay 
                    }   

                   </>);
                
            }
          },
       
        
      ];

useEffect(()=>{
    GetConfiguredAppointmentDays();
},[props.coverkey,props.appointmenttype,props.locKey,props.bolSaved,props.bolCommunicationChannelLoad]);

  return (
    <React.Fragment>
<MaterialTable
                height="100"
               
                columns={apptDaysColumns}
                data={data}
                title={"Appointment and SMS Day Settings"}
                options={{
                  pageSize: 5,
                  pageSizeOptions: [],
                  toolbar: false,
                  paging: false,
                  search:false,
                  
                  headerStyle: {
                    fontSize: 14,
                    padding: 10,
                    zIndex:0,
                    fontWeight:"bold"
                  },
               
               
                }}  
                
              
                
              />
    </React.Fragment>
  )
}

export default AppointmentDays;