import axios from 'axios';
import 'date-fns';
import moment from 'moment/moment';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Constants from '../../constants';
import Overlay from '../../Components/Overlay/Overlay';
import Storage from '../../storage';
import Utils from '../../utils';
import './Reporting.scss'
import ValidatedDatePicker from '../../Components/DatePicker/ValidatedDatePicker';
import ReportingForm from '../../Components/Reporting/ReportingForm';

import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from '@material-ui/core/MenuItem';
import MuiAlert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import SearchIcon from "@material-ui/icons/Search";
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import { withStyles } from '@material-ui/core/styles';



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};  

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    fontFamily:'Noto Sans, sans-serif',
  },
}))(Tooltip);

/* const NARSColumns = [
  { field: 'pmsPatientID', title: 'Patient ID', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientFirstName', title: 'First Name', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientLastName', title: 'Last Name', width: 100  , editable: 'never', emptyValue: '-'},
  { field: 'email', title: 'Email', width: 150  , editable: 'never' , emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.email} placement="top-start"><span>{dataRow.email}</span></HtmlTooltip>}},
  { field: 'phoneNumber', title: 'Phone', width: 200  , editable: 'never' , emptyValue: '-'},
  { field: 'locKey', title: 'LocKey', width: 100  , editable: 'never', emptyValue: '-'},
  { field: 'acctKey', title: 'Account Key', width: 100  , editable: 'never', emptyValue: '-'},
  { field: 'locationName', title: 'Location', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.locationName} placement="top-start"><span>{dataRow.locationName}</span></HtmlTooltip>}},
  { field: 'providerName', title: 'Provider', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.providerName} placement="top-start"><span>{dataRow.providerName}</span></HtmlTooltip>}},
  { field: 'apptType', title: 'Appointment Type', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.apptType} placement="top-start"><span>{dataRow.apptType}</span></HtmlTooltip>}},
  { field: 'appointmentReason', title: 'Appointment Reason', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.appointmentReason} placement="top-start"><span>{dataRow.appointmentReason}</span></HtmlTooltip>}},
  { field: 'appointmentDateTime', title: 'Appointment Date', width: 200  , editable: 'never'},
  { field: 'status', title: 'Appointment Status', width: 150  , editable: 'never', emptyValue: '-'},
  { field: 'recallMethod', title: 'Recall Method', width: 150  , editable: 'never' , emptyValue: '-'},
  { field: 'recallPeriod', title: 'Recall Period', width: 100  , editable: 'never' , emptyValue: '-'},
  { field: 'reminderSentTime', title: 'Reminder Sent Time', width: 200  , editable: 'never'},
  { field: 'commStatus', title: 'Communication Status', width: 160  , editable: 'never', emptyValue: '-'},
  { field: 'modality', title: 'Modality', width: 100  , editable: 'never' , emptyValue: '-'},
]; */ 


const NARSColumns = [
  { field: 'appointmentId', title: 'Appt ID', width: 100 , editable: 'never', emptyValue: '-'},  
  { field: 'coverKey', title: 'CoverKey', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'pmsPatientID', title: 'Patient ID', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientFirstName', title: 'First Name', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientLastName', title: 'Last Name', width: 100  , editable: 'never', emptyValue: '-'},
  { field: 'status', title: 'Comm. Status', width: 150  , editable: 'never', emptyValue: '-'},
  { field: 'recallMethod', title: 'Recall Method', width: 150  , editable: 'never' , emptyValue: '-'},
  { field: 'reminderSentTime', title: 'Sent Date', width: 200  , editable: 'never'},
  { field: 'modality', title: 'Modality', width: 100  , editable: 'never' , emptyValue: '-'},
]; 

/* const CARSColumns = [
  { field: 'pmsPatientID', title: 'Patient ID', width: 100 , editable: 'never' , emptyValue: '-'},
  { field: 'patientFirstName', title: 'First Name', width: 100 , editable: 'never' , emptyValue: '-'},
  { field: 'patientLastName', title: 'Last Name', width: 100  , editable: 'never' , emptyValue: '-'},
  { field: 'email', title: 'Email', width: 150  , editable: 'never' , emptyValue: '-' , cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.email} placement="top-start"><span>{dataRow.email}</span></HtmlTooltip>}},
  { field: 'phoneNumber', title: 'Phone', width: 200  , editable: 'never' , emptyValue: '-'},
  { field: 'locKey', title: 'LocKey', width: 100  , editable: 'never' , emptyValue: '-'},
  { field: 'acctKey', title: 'Account Key', width: 100  , editable: 'never', emptyValue: '-'},
  { field: 'locationName', title: 'Location', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.locationName} placement="top-start"><span>{dataRow.locationName}</span></HtmlTooltip>}},
  { field: 'providerName', title: 'Provider', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.providerName} placement="top-start"><span>{dataRow.providerName}</span></HtmlTooltip>}},
  { field: 'apptType', title: 'Appointment Type', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.apptType} placement="top-start"><span>{dataRow.apptType}</span></HtmlTooltip>}},
  { field: 'appointmentReason', title: 'Appointment Reason', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.appointmentReason} placement="top-start"><span>{dataRow.appointmentReason}</span></HtmlTooltip>}},
  { field: 'appointmentDateTime', title: 'Appointment Date', width: 200  , editable: 'never'},
  { field: 'status', title: 'Appointment Status', width: 150  , editable: 'never' , emptyValue: '-'},
  { field: 'reminderSentTime', title: 'Reminder Sent Time', width: 200  , editable: 'never'},
  { field: 'commStatus', title: 'Communication Status', width: 160  , editable: 'never' , emptyValue: '-'},
  { field: 'modality', title: 'Modality', width: 100  , editable: 'never' , emptyValue: '-'},
]; */ 

const CARSColumns = [
  { field: 'appointmentId', title: 'Appt ID', width: 100 , editable: 'never', emptyValue: '-'},  
  { field: 'coverKey', title: 'CoverKey', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'pmsPatientID', title: 'Patient ID', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientFirstName', title: 'First Name', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientLastName', title: 'Last Name', width: 100  , editable: 'never', emptyValue: '-'},
  { field: 'status', title: 'Comm. Status', width: 150  , editable: 'never', emptyValue: '-'},
  { field: 'reminderSentTime', title: 'Sent Date', width: 200  , editable: 'never'},
  { field: 'modality', title: 'Modality', width: 100  , editable: 'never' , emptyValue: '-'}
];

/* const DIPSColumns = [
  { field: 'pmsPatientID', title: 'Patient ID', width: 100 , editable: 'never' , emptyValue: '-'},
  { field: 'patientFirstName', title: 'First Name', width: 100 , editable: 'never' , emptyValue: '-'},
  { field: 'patientLastName', title: 'Last Name', width: 100  , editable: 'never' , emptyValue: '-'},
  { field: 'email', title: 'Email', width: 150  , editable: 'never' , emptyValue: '-' , cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.email} placement="top-start"><span>{dataRow.email}</span></HtmlTooltip>}},
  { field: 'phoneNumber', title: 'Phone', width: 200  , editable: 'never' , emptyValue: '-'},
  { field: 'locKey', title: 'LocKey', width: 100  , editable: 'never' , emptyValue: '-'},
  { field: 'acctKey', title: 'Account Key', width: 100  , editable: 'never', emptyValue: '-'},
  { field: 'locationName', title: 'Location', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.locationName} placement="top-start"><span>{dataRow.locationName}</span></HtmlTooltip>}},
  { field: 'providerName', title: 'Provider', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.providerName} placement="top-start"><span>{dataRow.providerName}</span></HtmlTooltip>}},
  { field: 'apptType', title: 'Appointment Type', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.apptType} placement="top-start"><span>{dataRow.apptType}</span></HtmlTooltip>}},
  { field: 'appointmentReason', title: 'Appointment Reason', width: 150  , editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.appointmentReason} placement="top-start"><span>{dataRow.appointmentReason}</span></HtmlTooltip>}},
  { field: 'appointmentDateTime', title: 'Appointment Date', width: 200  , editable: 'never'},
  // { field: 'appointmentCreatedDate', title: 'Appointment Created Date', width: 200  , editable: 'never', emptyValue: '-'},
  // { field: 'appointmentUpdatedDate', title: 'Appointment Updated Date', width: 200  , editable: 'never', emptyValue: '-'},
  { field: 'status', title: 'Appointment Status', width: 150  , editable: 'never' , emptyValue: '-'},
  { field: 'reminderSentTime', title: 'Reminder Sent Time', width: 200  , editable: 'never'},
  { field: 'commStatus', title: 'Communication Status', width: 160  , editable: 'never' , emptyValue: '-'},
  { field: 'modality', title: 'Modality', width: 100  , editable: 'never' , emptyValue: '-'},
];  */

const DIPSColumns = [
  { field: 'appointmentId', title: 'Appt ID', width: 100 , editable: 'never', emptyValue: '-'},  
  { field: 'coverKey', title: 'CoverKey', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'pmsPatientID', title: 'Patient ID', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientFirstName', title: 'First Name', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientLastName', title: 'Last Name', width: 100  , editable: 'never', emptyValue: '-'},
  { field: 'status', title: 'Comm. Status', width: 150  , editable: 'never', emptyValue: '-'},
  { field: 'reminderSentTime', title: 'Sent Date', width: 200  , editable: 'never'},
  { field: 'modality', title: 'Modality', width: 100  , editable: 'never' , emptyValue: '-'},
  { field: 'clx', title: 'CLX', width: 100  , editable: 'never' , emptyValue: '-'}
]; 


const NSRSColumns = [
  { field: 'appointmentId', title: 'Appt ID', width: 100 , editable: 'never', emptyValue: '-'},  
  { field: 'coverKey', title: 'CoverKey', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'pmsPatientID', title: 'Patient ID', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientFirstName', title: 'First Name', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientLastName', title: 'Last Name', width: 100  , editable: 'never', emptyValue: '-'},
  { field: 'status', title: 'Comm. Status', width: 150  , editable: 'never', emptyValue: '-'},
  { field: 'reminderSentTime', title: 'Sent Date', width: 200  , editable: 'never'},
  { field: 'modality', title: 'Modality', width: 100  , editable: 'never' , emptyValue: '-'}
]; 

const WRSColumns = [
  { field: 'appointmentId', title: 'Appt ID', width: 100 , editable: 'never', emptyValue: '-'},  
  { field: 'coverKey', title: 'CoverKey', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'pmsPatientID', title: 'Patient ID', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientFirstName', title: 'First Name', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientLastName', title: 'Last Name', width: 100  , editable: 'never', emptyValue: '-'},
  { field: 'status', title: 'Comm. Status', width: 150  , editable: 'never', emptyValue: '-'},
  { field: 'reminderSentTime', title: 'Sent Date', width: 200  , editable: 'never'},
  { field: 'modality', title: 'Modality', width: 100  , editable: 'never' , emptyValue: '-'}
]; 

const RecallColumns = [
  { field: 'appointmentId', title: 'Appt ID', width: 100 , editable: 'never', emptyValue: '-'},  
  { field: 'coverKey', title: 'CoverKey', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'pmsPatientID', title: 'Patient ID', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientFirstName', title: 'First Name', width: 100 , editable: 'never', emptyValue: '-'},
  { field: 'patientLastName', title: 'Last Name', width: 100  , editable: 'never', emptyValue: '-'},
  { field: 'status', title: 'Comm. Status', width: 150  , editable: 'never', emptyValue: '-'},
  { field: 'reminderSentTime', title: 'Sent Date', width: 200  , editable: 'never'},
  { field: 'modality', title: 'Modality', width: 100  , editable: 'never' , emptyValue: '-'}
]; 

class Reporting extends Component {
  constructor(props) {
        super(props);  
      
       

    this.state = {
        data: [],    
        ARTSData: [],    
        CARSData: [],
        DIPSData: [],
        NARSData: [],
        RecallData: [],
        NSRSData: [],
        WRSData: [],
        searchTypeID: 'coverkey',
        searchInput: '',
        dateFrom: null,
        dateTo: null,
        redirectToLogin: false,
        showLoadingOverlay: false,
        openSnackBar : false,
        SnackbarMessage : '',
        errorSeverity : 'success',
        collapseLocation : false,
        secondSelectionFilter : [],
        secondSearchTypeID : "",
        secondSearchInput : "",
        value: "",
        phoneIsValid:true
    };
    this.logoUrl = Utils.getLogoUrl();
    this._handelPhoneNumberOnChange = this._handelPhoneNumberOnChange.bind(this)    
  };

  _selectionOptions = [
     {key : "coverkey" , value : "CoverKey"},
     {key : "covername" , value : "Location Name"},
     {key : "lockey" , value : "LocKey"},
     {key : "patientname" , value : "Patient Name"},
     {key : "patientemail" , value : "Patient Email"},
     {key : "patientnumber" , value : "Patient Phone Number"},
     {key : "pmspatientid" , value : "PMS Patient ID"},
  ]

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const user = Storage.getItem(Constants.currUserKey);
    if (user && user.jwt) {
      try {
        this.setState(() => ({ showLoadingOverlay: true }));
        const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
        await axios.get(jwtCheckUrl);
        this.setState(() => ({ showLoadingOverlay: false }));
      }
      catch {
          this.setState(() => ({
              redirectToLogin: true
          }));
      }
    } 
    else {
        this.setState(() => ({ redirectToLogin: true }));
        return;
    }       
  }

  _setSearchTypeID = (searchID) => {
    this.setState(() => ({ searchTypeID:searchID, searchInput: '' }));
    const secondOption = this.state.secondSearchTypeID;
    let options = [];
    this._selectionOptions.map((item, index) =>
    {
      if (searchID !== item.key)
        options.push(item)
    })
    const defaultKey = options[0].key;
    const secondTypeHasChange = secondOption === defaultKey ? false : true
    this.setState(() => ({ secondSelectionFilter: options , secondSearchTypeID : defaultKey ,
                           secondSearchInput : secondTypeHasChange ? "" : this.state.secondSearchInput , phoneIsValid:true })); 

  }

  _clickFilter = () => {
    this.setState(() => ({ collapseLocation:!this.state.collapseLocation}));
    if (this.state.secondSelectionFilter.length === 0)
    {
      const firstOption = this.state.searchTypeID;
      const secondOption = this.state.secondSearchTypeID;
      let options = [];
      this._selectionOptions.map((item, index) =>
      {
        if (firstOption !== item.key)
          options.push(item)
      })
      const defaultKey = options[0].key;
      const secondTypeHasChange = secondOption === defaultKey ? false : true
      this.setState(() => ({ secondSelectionFilter: options , secondSearchTypeID : defaultKey ,
                             secondSearchInput : !secondTypeHasChange ? "" : this.state.secondSearchInput  })); 
    }  
  }

  _setSecondSearchTypeID = (searchID) => {
    this.setState(() => ({ secondSearchTypeID:searchID, secondSearchInput: '' }));
  }
    
  _handleSearchInputChange = (searchText) => {
    this.setState(() => ({ searchInput: searchText }));   
    };

    _handleSecondSearchInputChange = (searchText) => {
      this.setState(() => ({ secondSearchInput: searchText }));   
      };  

  _handleDateFromChange = (date) => {
      this.setState(() => ({ dateFrom: date }));   
    };

  _handleDateToChange = (date) => {
    this.setState(() => ({ dateTo: date }));   
  };


  _handelPhoneNumberOnChange(e){
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === '' || re.test(e.target.value)) {
       this.setState({value: e.target.value})
       this.setState( {phoneIsValid:true });
    }
    else
    {
      this.setState({value: e.target.value})
      this.setState( {phoneIsValid:false });
    }
}

  _searchData = async() => {
    this.setState(() => ({ showLoadingOverlay: true }));

    try {  
      const SecondQueryTypeID = this.state.collapseLocation ? this.state.secondSearchTypeID : "";
      const SecondSearchInput = this.state.collapseLocation ? this.state.secondSearchInput : "";


      var request = {
        params: {
          secret: `${process.env.REACT_APP_API_KEY}`,
          queryType: this.state.searchTypeID,
          queryString: this.state.searchInput,
          queryType2: SecondQueryTypeID,
          queryString2: SecondSearchInput,          
          startDate: moment(this.state.dateFrom).format('MM/DD/YYYY 00:00:00'),
          endDate: moment(this.state.dateTo).format('MM/DD/YYYY 23:59:59')
        },
        headers: {
          'x-crm-apiauth-key': Constants.AuthKey
        }
      }
      const url = `${process.env.REACT_APP_REPORTING_API}/queryReportingData2`;
      const res = await axios.get(url, request);
      this.setState(() => ({ NARSData:[], CARSData:[], DIPSData:[], RecallData:[],NSRSData:[],WRSData:[]}));
      let d = res.data;
      if(res?.data.length > 0) {
        this.setState(() => ({ data:d }));
        await this._mapData(d);
      } else {
        this.setState(() => ({ NARSData:[], CARSData:[], DIPSData:[], RecallData:[],NSRSData:[],WRSData:[],showLoadingOverlay: false, errorSeverity: 'warning', openSnackBar: true, SnackbarMessage: 'No records found. Change your search parameters, then try again.'})); 
      }
      
    } catch (error){
      this.setState(() => ({ showLoadingOverlay: false, errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Invalid search parameters. No data was retrieved for the input entered.'})); 
    }
    this.setState(() => ({ showLoadingOverlay: false }));
  }

  _mapData = async (arrData) => {
    let arrReport = [];
    arrData.forEach((data) => {
      let apptDate = (data.appointmentDateTime ? moment(data.appointmentDateTime).format('MM/DD/YYYY - hh:mm A') : 'N/A');
      let apptCreatedDate = (data.createdDate ? moment(data.createdDate).format('MM/DD/YYYY - hh:mm A') : 'N/A');
      //let apptUpdatedDate = (data.appointmentUpdatedDate ? moment(data.appointmentUpdatedDate).format('MM/DD/YYYY - hh:mm A') : 'N/A');
      let reminderSentDate = (data.reminderSentTime ? moment(data.reminderSentTime).format('MM/DD/YYYY - hh:mm A') : 'N/A');
      let recallMethod = '';
      if (data.communicationTypeId === 2 ) {
        recallMethod = (data.recallMethod === 1 ? 'Trigger - Recall Date' : 'Trigger - Appt. Date');
      }
      if (data.communicationTypeId === 7 ) {
        recallMethod = (data.recallMethod === 1 ? 'Trigger - Recall Date' : 'Trigger - Appt. Date');
      }
      let status;
      if(data.isConfirmed) {
        status = 'Scheduled';
      } else if (data.isCanceled) {
        status = 'Cancelled';
      } else if (data.isCanceled) {
        status = 'Delivered';
      } else {
        status = 'Sent'
      }

     let commStatus = (data.isDelivered ? 'Delivered' : 'N/A');

      arrReport.push(Object.assign({
                                    "appointmentId" : data.appointmentId,
                                    "coverKey" : data.coverKey,
                                    "pmsPatientID": data.pmsPatientID,
                                    "patientFirstName": data.patientFirstName,
                                    "patientLastName": data.patientLastName,
                                    "locationName": data.locationName,
                                    "providerName": data.providerName,
                                    "appointmentDateTime": apptDate,
                                    "email": data.email,
                                    "phoneNumber": data.phoneNumber,
                                    "appointmentReason": data.appointmentReason,
                                    "locKey": data.locKey,
                                    "acctKey": data.acctKey,
                                    "status": status,
                                    "commStatus": commStatus,
                                    "communicationTypeId": data.communicationTypeId,
                                    "recallPeriod": data.recallPeriod,
                                    "recallMethod": recallMethod,
                                    "apptType": data.apptType,
                                    "reminderSentTime": reminderSentDate,
                                    "modality": data.modality,
                                    "appointmentCreatedDate": data.createdDate,
                                    "clx": data.clx
                                    // "appointmentUpdatedDate": apptUpdatedDate
                                  }));
    });

    let arrNARS = arrReport.filter((p) => p !== undefined && p.communicationTypeId === 2);
    let arrRecall = arrReport.filter((p) => p !== undefined && p.communicationTypeId === 7);
    let arrCARS = arrReport.filter((p) => p !== undefined && p.communicationTypeId === 3);
    let arrDIPS = arrReport.filter((p) => p !== undefined && p.communicationTypeId === 4);
    let arrNSRS = arrReport.filter((p) => p !== undefined && p.communicationTypeId === 6);
    let arrWRS = arrReport.filter((p) => p !== undefined && p.communicationTypeId === 5);
    
    this.setState(() => ({ CARSData:arrCARS, NARSData: arrNARS, DIPSData: arrDIPS, RecallData:arrRecall,NSRSData:arrNSRS,WRSData:arrWRS }));
   // this.setState(() => ({ CARSData:[], NARSData: [], DIPSData: [] }));
    //this.setState(() => ({ CARSData:arrCARS, NARSData: arrNARS, DIPSData: arrDIPS }));
  }


   /**
   * Renders the component.
   */
  render() {
    const {
        searchTypeID,
        searchInput,
        dateFrom,
        dateTo,
        CARSData,
        NARSData,
        RecallData,
        DIPSData,
        NSRSData,
        WRSData,
        redirectToLogin,
        showLoadingOverlay,
        openSnackBar,
        SnackbarMessage,
        errorSeverity,
        collapseLocation,
        secondSelectionFilter,
        secondSearchTypeID,
        secondSearchInput,
        phoneIsValid
    } = this.state;

    if (redirectToLogin) {
        return <Redirect to={{ pathname: '/' }} />;
      }
    
    const maxDt = new Date();
    let maxDtTo = new Date();
    maxDtTo.setDate(maxDtTo.getDate() + 5);

    let searchTextType = this.state.searchTypeID;
    const renderSearchText = () => {
      if (searchTextType === 'patientnumber') {
         return <div className="validatedPhoneNumber">          
         <TextValidator
         required
         style ={{width:"130px"}}
         required
         id="searchInput"
         margin="none"
         autoComplete="off"
         placeholder="Search Value"
         validators={['required', 'isPositive']}
         errorMessages={['Search Value is required', null]}
         value = {searchInput}
         onChange={this._handelPhoneNumberOnChange}
         onInput={ (e)=>this._handleSearchInputChange(e.target.value)}                               
         InputProps={{
           disableUnderline: true,
           startAdornment: (
             <InputAdornment position="start">
               <IconButton >
                 <SearchIcon />
               </IconButton>
             </InputAdornment>
           ),
         }}
         inputProps={{
            maxLength: 10
          }}
          />
          </div> ;
      } else {
        return  <div className="validatedPhoneNumber"><TextField
        style ={{width:"130px"}}
        required
        id="searchInput"
        autoComplete="off"
        placeholder="Search Value"
        value = {searchInput}
        onInput={ (e)=>this._handleSearchInputChange(e.target.value)}  
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <IconButton >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      </div>
      }
    };

    let isPhoneValid = this.state.phoneIsValid;
    const renderPhoneValidation = () => {
      if (!isPhoneValid ) {
         return <div className="phoneNumberError">     
           <span style={{color: "red", paddingLeft:120}}>Phone number format is incorrect. Please enter 10 digit number without any special characters</span>       
     </div>
      }
    };

    
    return (
        <div id="div-reporting">
          <Box p={4} pl={12} bgcolor="background.paper">
            <Grid container justify="flex-start" alignItems="center" spacing={2} className="reporting-header">
              <Typography variant="h5" >  
              Patient Engagement Reporting Data
              </Typography>
              <Divider orientation="vertical" variant="middle" flexItem/>

              <Typography variant="h6">  
                 Search and review data on communication sent to patients
              </Typography>
            </Grid>
          </Box>
         
          <Divider className='header-divider'/>
          
          <Box p={4} pb={1} pr={2}  bgcolor="background.paper" >
            
            <div className="reporting-box">
            {renderPhoneValidation()}
            <ValidatorForm ref="form" onSubmit={this._searchData}  onError={errors => console.log(errors)} > 
            <Grid container justify="flex-start" alignItems="top" spacing={2} className="reporting-search">

            <Grid item>
                <Typography variant="h5" >  
                  Search by: 
                </Typography>
              </Grid>

              <Box p={1} border={1} borderRadius={3} mr={1}>
              <Grid item className="">
                
              </Grid>
              <Grid item className="">
                <div className="searchType">
              <Select 
                    id="searchType"
                    style ={{width:"200px"}}
                    value={searchTypeID}
                    onChange={ e=>this._setSearchTypeID(e.target.value)}   
                    defaultValue='none'
                    disableUnderline       
                  >
                    {
                      
                      this._selectionOptions.map((item) => {
                        if ("pmspatientid" !== item.key)
                        {
                          return <MenuItem value={item.key} selected>{item.value}</MenuItem>
                        }
                  
                      })
                    }
                </Select>
                </div>
                  <Divider orientation="vertical" variant="middle"/>

                  {renderSearchText()} 
                    
                    <Divider orientation="vertical" variant="middle"/> 
                    
                <Typography>From: </Typography>
                <div className="validatedDatePicker">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ValidatedDatePicker
                      style ={{width:"130px"}}
                      autoComplete="off"
                      required
                      margin="none"
                      id="from-date-dialog"
                      format="MM/dd/yyyy"
                      validators={["required"]}
                      errorMessages={["start date is required"]}
                      value={dateFrom}
                      maxDate={maxDt}
                      onChange={e=>this._handleDateFromChange(e)}
                      onError={errors => console.log(errors)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                              <DateRangeIcon style={{cursor: "pointer"}}/>
                          </InputAdornment>
                        ),
                      }}
                    />
                </MuiPickersUtilsProvider>
                </div>

                <Typography> To: </Typography>
               <div className="validatedDatePicker">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ValidatedDatePicker
                      style ={{width:"130px"}}
                      autoComplete="off"
                      required
                      margin="none"
                      id="to-date-dialog"
                      validators={["required"]}
                      errorMessages={["end date is required"]}
                      format="MM/dd/yyyy"
                      value={dateTo}
                      maxDate={maxDtTo}
                      onChange={e=>this._handleDateToChange(e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                              <DateRangeIcon style={{cursor: "pointer"}}/>
                          </InputAdornment>
                        ),
                      }}
                    />
                </MuiPickersUtilsProvider>
                </div> 
                <Divider orientation="vertical" variant="middle"/> 
                <div className="filterButton">
                <Tooltip title="More filter" aria-label="More filter">  
                  <IconButton aria-label="More filter">
                     <FilterListIcon onClick={() => this._clickFilter()}/>
                  </IconButton>   
                  </Tooltip>               
                </div>

                <div >
                <Collapse in={collapseLocation} timeout="auto">  
                <div style ={{paddingTop:"7px"}}>
                <Divider orientation="horizontal" variant="middle"/>  
                <div  style ={{paddingTop:"10px"}}>          
                <Grid container justify="flex-start" alignItems="center" spacing={4} >

                  <Grid item >             
                  <Select 
                      id="searchType"
                      style ={{width:"150px"}}
                      value={secondSearchTypeID}
                      onChange={ e=> this._setSecondSearchTypeID(e.target.value)}   
                      defaultValue='none'
                      disableUnderline       
                    >
                    {
                      secondSelectionFilter.map((item, index) => {
                      return <MenuItem value={item.key} selected={index === 0 ? true : false}>{item.value}</MenuItem>
                      })
                    }                      
                  </Select>

                    <Divider orientation="vertical" variant="middle"/>

                  <TextField
                      style ={{width:"400px"}}
                      required={collapseLocation}
                      id="secondSearchInput"
                      autoComplete="off"
                      placeholder="Search Value"
                      value = {secondSearchInput}
                      onInput={ e => this._handleSecondSearchInputChange(e.target.value)}  
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                  />
                  </Grid>
                  </Grid>
                  </div>    
                  </div>
                </Collapse>
                </div>
               
              </Grid>
              </Box>

                 
            
              <Button style={{maxWidth: '100px' , minWidth: '100px'}} variant="contained" color="primary" type="submit">
                    SEARCH
              </Button>  
             
            </Grid>
            </ValidatorForm>
            <Divider/> 

            
          <ReportingForm reportType='Early Recall Report' columns={RecallColumns} data={NARSData} />  
          <ReportingForm reportType='Recall Report' columns={RecallColumns} data={RecallData} />  
          <ReportingForm reportType='Cancellation Retrieval Report' columns={CARSColumns} data={CARSData} />   
          <ReportingForm reportType='Purchase Retrieval Report' columns={DIPSColumns} data={DIPSData} />   
          <ReportingForm reportType='No Show  Report' columns={NSRSColumns} data={NSRSData} />   
          <ReportingForm reportType='Welcome Reminder Report' columns={WRSColumns} data={WRSData} /> 
          </div>
          </Box>
 
        

          <Overlay show={showLoadingOverlay}>
             <i className="spinner-eclipse"></i>
          </Overlay>      
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => this.setState({openSnackBar: false})}>
            <Alert onClose={() => this.setState({openSnackBar: false})} severity={errorSeverity}>
            {SnackbarMessage} 
            </Alert>
          </Snackbar>  
        </div>
    ); 
  }
        
}

export default Reporting;