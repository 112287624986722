import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { Checkbox, IconButton, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "../../../ContextLib/CoreConsumer/Components/Filters.scss";
import InfoIcon from '@material-ui/icons/Info';
import { useQuery, useUIContext } from '../../contextHooks';
import { useV5 } from '../v5Contexts';

const V5Filters = (props) => {
    const componentId = "v5filters";
    const ui = useUIContext(componentId);
    const query = useQuery();
  
    const [showNote, setShowNote] = useState(false);
    const [menuType, setMenuType] = useState(0);
  
    const {f} = useV5();
    
    const [coverLabel, setCoverLabel] = useState("");
    const [locDisable, setLocDisable] = useState(true);
    const [acctDisable, setAcctDisable] = useState(true);
    const [coverKeyInitialized, setCoverKeyInitialized] = useState(false);
  
    const loadCoversData = async () => {
      ui.ShowOverlay();
      await f.GetCovers();
      ui.HideOverlay();
    };
  
    const loadSubData = async () => {
      if (f.coverKey != 0) {
        ui.ShowOverlay();  
        await f.GetLocationsAndProviders();
        ui.HideOverlay();
      }
    };
  
    const getlabel = (d, action) => {
      let ret = "";
      if (action == "cover" && f.covers.length > 0) {
        let x = f.covers.filter(x => x.coverKey == d)[0];
        if (x) {        
          ret = `${x.coverKey}: ${x.orgName ?? "[No OrgName]"}`;  
        }
      } else if (action == "location" && f.arrDisplayLocations.length > 0) {
        let x = f.arrDisplayLocations.filter(x => x.locKey == d)[0];      
        if (x) {
          let n = x.orgName ?? "[No OrgName]";        
          ret = d==0 ? n : `${x.locKey}: ${n}`;  
        }
      } else if (action == "provider" && f.arrDisplayProviders.length > 0) {
        let x = f.arrDisplayProviders.filter(x => x.acctKey == d)[0];
        if (x) {
          let _n = (`${x.firstName ?? ""} ${x.lastName ?? ""}`).trim();
          let n = _n ? _n : "[No Name]";
          ret = d==0 ? x.orgName : `${x.acctKey}: ${n}`;
        }
      }
      return ret;
    };

    const setNotes = () => {
      setShowNote(false)
      setMenuType(props.menuType)
      if ((!f.noCover && f.locKey == 0) || (!f.noCover && f.acctKey == 0)) {
         setShowNote(true);
      }  
    };
  
    useEffect(()=> {
      loadCoversData();
    }, []);

    useEffect(()=> {
      if (!coverKeyInitialized) return;

      const urlCoverkey = parseInt(query.get('coverkey') ?? '0');
      if (urlCoverkey > 0 && f.coverKey != urlCoverkey) {
        f.coverKey = urlCoverkey; 
      }
    }, [coverKeyInitialized]);

    useEffect(()=> {
      const urlLockey = parseInt(query.get('lockey') ?? '0');
      const urlAcctkey = parseInt(query.get('acctkey') ?? '0');
      if (urlLockey > 0 && f.locKey != urlLockey) {
        f.displayLocationsByProvider = false;
        f.locKey = urlLockey; 
      }    
      if (urlAcctkey > 0 && f.acctKey != urlAcctkey) {
        f.displayProvidersByLocation = false;
        f.acctKey = urlAcctkey; 
      }
      f.triggerUpdates();
    }, [f.locations, f.providers]);
  
    useEffect(() => {       
      f.locKey = 0;
      f.acctKey = 0;

      if (f.onCoverLevel) {
          loadSubData();
      }
            
      setCoverLabel(f.noCover ? "Select Cover" : "Cover");  
      setLocDisable(f.noCover);
      setAcctDisable(f.noCover);

      setNotes();

      setCoverKeyInitialized(true);
    }, [f.coverKey]);
    
    useEffect(()=>{  
      setNotes();
      f.triggerUpdates();
    }, [f.locKey, f.acctKey]);

    useEffect(()=> {
      setMenuType(props.menuType);
    }, [props.menuType]);
    
    const noteText = menuType === 25 ?
                     "Please note that these filters will apply only to Task Keys." :
                     "Please note that all configuration changes will be applied to all Location AND/OR providers. Change your selection to a specific location/provider, if you do not intend to make changes to all of them.";

    return (<>
      <div className="filters">  
        {(showNote && (menuType !== 15 || menuType !== 16)) && <Grid container direction="row"spacing={3}>
                <div style={{display : "flex", paddingTop : "10px", paddingBottom : "10px"}}>
                    <div style={{marginLeft : "15px", marginTop : "5px", marginRight : "5px", color : "red"}}><InfoIcon/></div>
                    <div style={{ paddingTop : "5px", paddingBottom : "5px", color : "red"}}>{noteText}</div>
                </div>
            </Grid>}           
        {(f.locKey > 0 && props.menuType !== 15) && <div className="flex-row">
            <div>
            <p>Filter Providers by Location</p>
            </div>
            <div>
            <IconButton type="submit" component="span" >
                <Checkbox defaultChecked color="default" checked={f.displayProvidersByLocation} onChange={(e) => {
                  f.displayProvidersByLocation = e.target.checked;
                  f.triggerUpdates();
                }}/>
            </IconButton>
            </div>
        </div>
        }
        <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>  
            <Grid item xs={12} sm={4}>
                <FormControl fullWidth>                
                <Autocomplete
                    id="Cover"
                    options={f.covers.map(x => x.coverKey)}
                    getOptionLabel={(x) => getlabel(x, "cover")}
                    value={f.coverKey}
                    defaultValue={f.coverKey}
                    onChange={(event, newValue) => {                                                        
                        f.coverKey = newValue ?? 0; 
                        f.triggerUpdates();
                    }}
                    renderInput={(params) => <TextField {...params} label={coverLabel} variant="outlined" />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}  sm={4}>
                <FormControl fullWidth>
                <Autocomplete
                    id="Location"
                    disabled={locDisable}
                    options={f.arrDisplayLocations.map(x => x.locKey)}
                    getOptionLabel={(x) => getlabel(x, "location")}
                    value={f.locKey}
                    onChange={(event, newValue) => {                                
                      f.locKey = newValue ?? 0; 
                      f.acctKey = 0;
                      f.triggerUpdates();
                    }}
                    renderInput={(params) => <TextField {...params} label="Location" variant="outlined" />}
                    />
                </FormControl>
            </Grid>
            {props.menuType !== 15 &&
            <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
                <Autocomplete
                    id="Provider"
                    disabled={acctDisable}
                    options={f.arrDisplayProviders.map(x => x.acctKey)}
                    getOptionLabel={(x) => getlabel(x, "provider")}
                    value={f.acctKey}
                    onChange={(event, newValue) => {                                                              
                      f.acctKey = newValue ?? 0; 
                      f.triggerUpdates();
                    }}
                    renderInput={(params) => <TextField {...params} label="Provider" variant="outlined" />}
                    />
            </FormControl>
        </Grid>}           
        </Grid>
    </div>
    </>);
};
  
export default V5Filters;