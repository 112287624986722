import "../PmsConfig.scss";
import Enums from "../../../enums";
import React, { useState } from "react";
import SnackBarHelper from "../Components/Helper/SnackBarHelper";
import MappingPageSdbAndCoverKey from "../Components/MappingPage/MappingPageSdbAndCoverKey.js";
import MappingPageSearchAndListComp from "../Components/MappingPage/MappingPageSearchAndListComp.js";
import {
  GetMapShadowProvidersAndAcctKey,
  UpsertPmsprovidersTo4pcProviderResourceMap,
  DeletePmsprovidersTo4pcProviderResourceMap,
} from "../Util/pmsIntegrationApiUtil";
import { PmsGridSort } from "../Util/PmsHelper";

export default function ProviderMapping() {
  const [cover, setCover] = useState("");
  const [activeShadowDb, setActiveShadowDb] = useState("");
  const [selectedAcctKey, setSelectedAcctKey] = useState(0);
  const [selectedProvderID, setSelectedProvderID] = useState(0);

  const [selectedGridItem, setSelectedGridItem] = useState({});

  const [isOngoingRequest, setIsOngoingRequest] = useState(false);

  const [textAcctKeySearch, setTextAcctKeySearch] = useState("");
  const [textProviderIdSearch, setTextProviderIdSearch] = useState("");

  const [listOfAcctKey, setListOfAcctKey] = useState([]);
  const [listOfProviders, setListOfProviders] = useState([]);
  const [listOfAcctKeySearch, setListOfAcctKeySearch] = useState([]);
  const [listOfProvidersSearch, setListOfProvidersSearch] = useState([]);
  const [listOfProviderResourceMap, setListOfProviderResourceMap] = useState(
    []
  );

  const [selectedDirection, setSelectedDirection] = useState(
    Enums.PmsMapDirection
  );

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const columns = [
    {
      field: "coverKey",
      headerName: "CoverKey",
      width: 160,
      sortComparator: PmsGridSort,
    },
    {
      field: "acctKey",
      headerName: "AcctKey",
      width: 350,
      sortComparator: PmsGridSort,
    },
    {
      field: "providerResourceMapType",
      headerName: "ProviderResourceMapType",
      width: 350,
      sortComparator: PmsGridSort,
    },
    {
      field: "pmsProviderResourceID",
      headerName: "PMSProviderResourceID",
      width: 350,
      sortComparator: PmsGridSort,
    },
    {
      field: "direction",
      headerName: "Direction",
      width: 150,
    },
  ];

  const HandleCoverKeyChange = (cover) => setCover(cover);
  const handleShadowDbChange = (args) => setActiveShadowDb(args.target.value);

  const HandleRightRowSelected = (e, row) => setSelectedAcctKey(row);
  const HandleLeftRowSelected = (e, row) => setSelectedProvderID(row);

  const HandleProviderTextChange = (data) => setTextProviderIdSearch(data);
  const HandleAcctKeyTextChange = (data) => setTextAcctKeySearch(data);

  const HandleDirectionDown = () =>
    setSelectedDirection(Enums.PmsMapDirection.Down);

  const HandleDirectionUp = () =>
    setSelectedDirection(Enums.PmsMapDirection.Up);

  const HandleSearchProvider = () => {
    let data = [...listOfProviders];
    if (!textProviderIdSearch || textProviderIdSearch.length === 0)
      return setListOfProvidersSearch(data);

    let filteredData = data.filter((n) =>
      n.pmsProviderID.includes(textProviderIdSearch)
    );
    setListOfProvidersSearch(filteredData);
  };

  const HandleSearchAcctKey = () => {
    let data = [...listOfAcctKey];
    if (!textAcctKeySearch || textAcctKeySearch.length === 0)
      return setListOfAcctKeySearch(data);

    let filteredData = data.filter((n) =>
      n.acctKey.toString().includes(textAcctKeySearch)
    );
    setListOfAcctKeySearch(filteredData);
  };

  const updateSnackBar = (severity, message, doNotOpenSnackBar = true) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);

    if (!doNotOpenSnackBar) setOpenSnackBar(true);
  };

  const _handleDeleteRow = async () => {
    if (Object.keys(selectedGridItem).length === 0) {
      updateSnackBar("warning", `Kindly select a record first.`, false);
      return;
    }

    setIsOngoingRequest(true);

    const promises = [
      DeletePmsprovidersTo4pcProviderResourceMap(selectedGridItem.id)
        .then((resp) => {
          setSelectedGridItem({});

          let newDataSet = [...listOfProviderResourceMap].filter(
            (n) => n.id != selectedGridItem.id
          );
          setListOfProviderResourceMap(newDataSet);

          updateSnackBar(
            "success",
            "You have successfully DELETED a record",
            false
          );
        })
        .catch((error) =>
          updateSnackBar(
            "error",
            `Error while deleting of records: ${error}`,
            false
          )
        )
        .finally(() => {
          setIsOngoingRequest(false);
        }),
    ];

    await Promise.all(promises);
  };

  const _handleSubmitSearchByCoverKeyAndSdb = async () => {
    setIsOngoingRequest(true);
    const promises = [
      GetMapShadowProvidersAndAcctKey(activeShadowDb, cover)
        .then((resp) => {
          setSelectedGridItem({});
          setSelectedAcctKey({});
          setSelectedProvderID({});

          setListOfProviders(resp.data.pmsProviders);
          setListOfProvidersSearch(resp.data.pmsProviders);
          setListOfAcctKey(resp.data.j45Accts);
          setListOfAcctKeySearch(resp.data.j45Accts);
          setListOfProviderResourceMap(resp.data.providerResourceMaps);
        })
        .catch((error) =>
          updateSnackBar(
            "error",
            `Error while getting the records: ${error}`,
            false
          )
        )
        .finally(() => {
          setIsOngoingRequest(false);
        }),
    ];
    await Promise.all(promises);
  };

  const _handleSubmitByDirection = async () => {
    if (
      Object.keys(selectedAcctKey).length === 0 ||
      Object.keys(selectedProvderID).length === 0
    ) {
      updateSnackBar("warning", `Kindly select a record first.`, false);
      return;
    }

    const jsonBody = {
      coverKey: selectedProvderID.coverKey,
      acctKey: selectedAcctKey.acctKey,
      providerResourceMapType: "Provider",
      pmsProviderResourceID: selectedProvderID.pmsProviderID,
      direction: selectedDirection,
    };

    setIsOngoingRequest(true);
    const promises = [
      UpsertPmsprovidersTo4pcProviderResourceMap(jsonBody)
        .then((resp) => {
          let isExist = listOfProviderResourceMap.find(
            (x) => x.id == resp.data
          );

          jsonBody.id = resp.data;
          let newObject = [...listOfProviderResourceMap];

          if (isExist) {
            newObject[
              listOfProviderResourceMap.findIndex((x) => x.id == resp.data)
            ] = jsonBody;

            updateSnackBar(
              "success",
              "You have successfully UPDATED a record",
              false
            );
          } else {
            newObject.push(jsonBody);

            updateSnackBar(
              "success",
              "You have successfully ADDED a record",
              false
            );
          }

          setListOfProviderResourceMap(newObject);
        })
        .catch((error) =>
          updateSnackBar(
            "error",
            `Error while updating of records: ${error}`,
            false
          )
        )
        .finally(() => {
          setIsOngoingRequest(false);
        }),
    ];
    await Promise.all(promises);
  };

  return (
    <div>
      <SnackBarHelper
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Severity={snackBarSeverity}
        AlertMessage={snackBarMessage}
      />
      <MappingPageSdbAndCoverKey
        titlePage="Map Shadow Providers"
        onCoverKeyChange={HandleCoverKeyChange}
        propCover={cover}
        onShadowDbChange={handleShadowDbChange}
        propShadowDb={activeShadowDb}
        onSearchByCoverKeyAndSdb={_handleSubmitSearchByCoverKeyAndSdb}
        isOngoingRequest={isOngoingRequest}
      />

      <MappingPageSearchAndListComp
        leftSearchBarLabelName="PMSProviderID"
        rightSearchBarLabelName="AcctKey"
        leftListDataSource={listOfProvidersSearch}
        rightListDataSource={listOfAcctKeySearch}
        propSelectedItemRightList={selectedAcctKey}
        onLeftItemSelected={HandleLeftRowSelected}
        propSelectedItemLeftList={selectedProvderID}
        onRightItemSelected={HandleRightRowSelected}
        onDirectionDown={HandleDirectionDown}
        onDirectionUp={HandleDirectionUp}
        tableColumns={columns}
        onSubmitDirection={_handleSubmitByDirection}
        tableData={listOfProviderResourceMap}
        handleDeleteRow={_handleDeleteRow}
        isOngoingRequest={isOngoingRequest}
        onSearchLeftList={HandleSearchProvider}
        onSearchRightList={HandleSearchAcctKey}
        onTextChangeLeftSearchText={HandleProviderTextChange}
        onTextChangeRightSearchText={HandleAcctKeyTextChange}
        onSelectedGridItem={(data) => setSelectedGridItem(data)}
      />
    </div>
  );
}
