import React, { useState, useEffect } from "react";
import { Divider, Grid, Button } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useUIContext } from "../../../ContextLib/contextHooks";
import {
  GetPmsGeneralSettings,
  UpsertPMSGeneralSettings,
} from "../Util/pmsIntegrationApiUtil";
import SnackBarHelper from "../Components/Helper/SnackBarHelper";
import HfJobFive from "./HfJobFive";
import MaterialTableSelect from "../Components/Common/MaterialTableSelect";
import HfJobOne from "./HfJobOne";
import HfJobTwo from "./HfJobTwo";
import HfJobThree from "./HfJobThree";
import HfJobFour from "./HfJobFour";

export default function HfConfigurations({ listOfCovers }) {
  const ui = useUIContext("v5filters");

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const [mockData, setMockData] = useState({
    job05AvSlotThresholdPercentage: "",
    job05AvSlotDaysRecord: "",
    Job01EnableExecution: false,
    Job02EnableExecution: false,
    Job03EnableExecution: false,
    Job04EnableExecution: false,
    Job05EnableExecution: false,
    Job05Covers: [],
    listOfJobs: "",
    selectedJob: "",
    selectedJobPage: null,
    listCoverKeys: [],
  });

  const listOfJobs = [
    "JOB01 - Clear Residual Shadow Data",
    "JOB02 - Clear Orphaned Shadow Data",
    "JOB03 - Clear Old Appointment Block Data",
    "JOB04 - Clear Old Availability Slot Data",
    "JOB05 - Send alert Availability Slot Count in Shadow Database Differs From Norm",
  ];

  const HandleUpdateSnackBar = (
    severity,
    message,
    doNotOpenSnackBar = true
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);

    if (!doNotOpenSnackBar) setOpenSnackBar(true);
  };

  const setValue = (e, isCheckBox = false) => {
    const { name, value, checked } = e.target;
    let val = value;
    if (isCheckBox) val = checked;

    setMockData({
      ...mockData,
      [name]: val,
    });
  };

  const _postWriteBackSettings = async () => {
    const apiRequestBody = {
      hangfireGeneralSettings: Object.entries(mockData)
        .filter(
          ([settingName, _]) =>
            //we will only include properties that starts with job... and ignore the rest of the state.
            settingName.toLowerCase().includes("job01") ||
            settingName.toLowerCase().includes("job02") ||
            settingName.toLowerCase().includes("job03") ||
            settingName.toLowerCase().includes("job04") ||
            settingName.toLowerCase().includes("job05")
        )
        .map(([settingName, settingValue]) => {
          let setValx = settingValue == null ? "" : settingValue.toString();

          if (settingName.toLowerCase().includes("job05covers")) {
            setValx = settingValue.join(";");
          }
          return {
            settingName,
            settingValue: setValx,
            jobId: "00", // It's okay to hardcode this because this will not affect the update.
          };
        }),
    };

    ui.ShowOverlay();
    const promises = [
      UpsertPMSGeneralSettings(apiRequestBody)
        .then((resp) => {
          HandleUpdateSnackBar(
            "success",
            `SUCCESS updating the settings.`,
            false
          );
        })
        .catch((error) =>
          HandleUpdateSnackBar(
            "error",
            `Error while getting the records: ${error}`,
            false
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const HangfireSettingLookUp = (data, settingName) =>
    data.find((setting) => setting.settingName === settingName).settingValue;

  useEffect(() => {
    async function fetchData() {
      ui.ShowOverlay();
      const promises = [
        GetPmsGeneralSettings()
          .then((resp) => {
            const { hangfireGeneralSettings, j47ShadowTeams } = resp.data;

            let job05AvSlotDaysRecord = HangfireSettingLookUp(
              hangfireGeneralSettings,
              "job05AvSlotDaysRecord"
            );
            let job05AvSlotThresholdPercentage = HangfireSettingLookUp(
              hangfireGeneralSettings,
              "job05AvSlotThresholdPercentage"
            );
            let Job05EnableExecution =
              HangfireSettingLookUp(
                hangfireGeneralSettings,
                "Job05EnableExecution"
              ).toLowerCase() === "true";

            let Job01EnableExecution =
              HangfireSettingLookUp(
                hangfireGeneralSettings,
                "Job01EnableExecution"
              ).toLowerCase() === "true";

            let Job02EnableExecution =
              HangfireSettingLookUp(
                hangfireGeneralSettings,
                "Job02EnableExecution"
              ).toLowerCase() === "true";

            let Job03EnableExecution =
              HangfireSettingLookUp(
                hangfireGeneralSettings,
                "Job03EnableExecution"
              ).toLowerCase() === "true";

            let Job04EnableExecution =
              HangfireSettingLookUp(
                hangfireGeneralSettings,
                "Job04EnableExecution"
              ).toLowerCase() === "true";

            let Job05Covers = HangfireSettingLookUp(
              hangfireGeneralSettings,
              "Job05Covers"
            )
              .toLowerCase()
              .split(";");

            //we remove all possible duplicate CoverKey
            const uniqueCoverKeys = [];
            const uniqueSet = new Set();

            j47ShadowTeams.forEach((item) => {
              if (!uniqueSet.has(item.coverKey)) {
                uniqueSet.add(item.coverKey);
                uniqueCoverKeys.push(item);
              }
            });

            setMockData({
              ...mockData,
              job05AvSlotDaysRecord,
              Job05Covers,
              job05AvSlotThresholdPercentage,
              listCoverKeys: uniqueCoverKeys,
              Job01EnableExecution,
              Job02EnableExecution,
              Job03EnableExecution,
              Job04EnableExecution,
              Job05EnableExecution,
            });
          })
          .catch((error) =>
            HandleUpdateSnackBar(
              "error",
              `Error while getting the records: ${error}`,
              false
            )
          )
          .finally(() => {
            ui.HideOverlay();
          }),
      ];
      await Promise.all(promises);
    }

    fetchData();
  }, []);

  const setSelectedCovers = (data) => {
    //need to convert to string or it will break the transfer in job05
    const arrayOfStrings = data.map((integer) => String(integer));
    setMockData({
      ...mockData,
      Job05Covers: arrayOfStrings,
    });
  };

  const RenderJobSettings = () => {
    if (mockData.selectedJob.includes("JOB05"))
      return (
        <HfJobFive
          mockData={mockData}
          setValue={setValue}
          setSelectedCovers={(data) => setSelectedCovers(data)}
        />
      );
    if (mockData.selectedJob.includes("JOB01"))
      return <HfJobOne mockData={mockData} setValue={setValue} />;
    if (mockData.selectedJob.includes("JOB02"))
      return <HfJobTwo mockData={mockData} setValue={setValue} />;
    if (mockData.selectedJob.includes("JOB03"))
      return <HfJobThree mockData={mockData} setValue={setValue} />;
    if (mockData.selectedJob.includes("JOB04"))
      return <HfJobFour mockData={mockData} setValue={setValue} />;
    if (mockData.selectedJob.includes("JOB05"))
      return <HfJobFive mockData={mockData} setValue={setValue} />;

    return null;
  };

  const RenderButton = () => {
    if (mockData.selectedJob === "") return null;
    return (
      <>
        <Divider style={{ margin: "30px 0px 10px" }} />
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          onClick={() => _postWriteBackSettings()}
        >
          Save
        </Button>
      </>
    );
  };
  return (
    <>
      <SnackBarHelper
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Severity={snackBarSeverity}
        AlertMessage={snackBarMessage}
      />
      <h1 className="pmstitle-title__wrapper">Hangfire Job Settings</h1>
      <div className="pms-ws__setting-wrapper">
        <ValidatorForm onSubmit={_postWriteBackSettings}>
          <Grid
            container
            alignItems="center"
            spacing={1}
            style={{ display: "flex", alignItems: "baseline" }}
          >
            <Grid item sm={6}>
              <MaterialTableSelect
                data={listOfJobs}
                label="Job #"
                value={mockData.selectedJob || ""}
                name="selectedJob"
                onChange={(e) => setValue(e)}
                fullWidth={true}
              />
            </Grid>

            <Grid item sm={12}>
              <Divider style={{ margin: "10px 0px" }} />
            </Grid>

            <Grid item sm={12}>
              <RenderJobSettings />
            </Grid>
          </Grid>
        </ValidatorForm>
        <RenderButton />
      </div>
    </>
  );
}
