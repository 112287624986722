import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment/moment';


export default function RowDetailsProductPickup(props) {
    const data = props.row
  
    return (
      <React.Fragment>
          <Box margin={1}>
            <Table size="small" aria-label="details">
            <TableHead>
                <TableRow>
                  <TableCell>CoverKey</TableCell>                  
                  <TableCell>VwPickupID</TableCell>
                  <TableCell>PmsOrderID</TableCell>
                  <TableCell>VwPatientID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.coverKey}</TableCell>                    
                    <TableCell>{data.vwPickupID}</TableCell>
                    <TableCell>{data.pmsOrderID}</TableCell>
                    <TableCell>{data.vwPatientID}</TableCell>                  
                  </TableRow>                     
              </TableBody>

              <TableHead>
                <TableRow>
                  <TableCell>VwLocationID</TableCell>                  
                  <TableCell>VwProviderID</TableCell>
                  <TableCell>OrderDate</TableCell>
                  <TableCell>ReceivedDate</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.vwLocationID}</TableCell>                    
                    <TableCell>{data.vwProviderID}</TableCell>
                    <TableCell>{data.orderDate}</TableCell>    
                    <TableCell>{data.receivedDate}</TableCell>             
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>NotifiedDate</TableCell>                  
                  <TableCell>PickedUpDate</TableCell>
                  <TableCell>ProductType</TableCell>
                  <TableCell>ProductBrand1</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.notifiedDate}</TableCell>                    
                    <TableCell>{data.pickedUpDate}</TableCell>
                    <TableCell>{data.productType}</TableCell>    
                    <TableCell>{data.productBrand1}</TableCell>             
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>ProductBrand2</TableCell>                  
                  <TableCell>ProductBrand3</TableCell>
                  <TableCell>SpendAmt</TableCell>
                  <TableCell>OrderStatus</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.productBrand2}</TableCell>                    
                    <TableCell>{data.productBrand3}</TableCell>
                    <TableCell>{data.spendAmt}</TableCell>    
                    <TableCell>{data.orderStatus}</TableCell>             
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>PricedDate</TableCell>                  
                  <TableCell>StatusChangedDate</TableCell>
                  <TableCell>Deleted</TableCell>
                  <TableCell>PmsCreatedDate</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.pricedDate}</TableCell>                    
                    <TableCell>{data.statusChangedDate}</TableCell>
                    <TableCell>{data.deleted ? 'Yes' : 'No'}</TableCell>    
                    <TableCell>{data.pmsCreatedDate}</TableCell>             
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>PmsLastModifiedDate</TableCell>                  
                  <TableCell>CreatedDate</TableCell>
                  <TableCell>LastModifiedDate</TableCell>
                  <TableCell></TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.pmsLastModifiedDate}</TableCell>                    
                    <TableCell>{data.createdDate}</TableCell>
                    <TableCell>{data.lastModifiedDate}</TableCell>    
                    <TableCell></TableCell>             
                  </TableRow>                     
              </TableBody>
            </Table>                     
            <br/>
          </Box>      
       </React.Fragment>
    );   

}
