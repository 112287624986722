import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Popover, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    handle: {
        width: "100%",
        height: "100%"
    },
    popover: {
        pointerEvents: 'none'
    },
    paper: {
        padding: theme.spacing(1),
    },
    list: {
        margin: '0 0 0 20px',
        padding: 0,        
        fontSize: '11px'
    }
}));

const EventTasks = ({el, setEl, data})=> {
    const classes = useStyles();
    const open = Boolean(el);

    return (<>
        <Popover
            className={classes.popover}
            classes={{
                paper: classes.paper,
            }}
            open={open}
            anchorEl={el}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={() => setEl(null)}
            disableRestoreFocus
        >
            <Typography>
                <b>Appointment Types</b>
                <ul className={classes.list}>
                    {data.map((x)=> <li>{`${x.name}${x.length == -1 ? "" : ` (${x.length} mins.)`}`}</li>)}
                </ul>
            </Typography>
        </Popover>
    </>)
}

export default EventTasks;
