import React, { useState } from 'react';
import PatientInfo from './PatientInfo';
import InsuranceTypes from './InsuranceTypes';
import AppointmentTypes from './AppointmentTypes';
import General from "./General";
import "./WSMaster.scss";
import { Box, Divider, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import axios from 'axios';
import V5Filters from '../../ContextLib/CoreConsumer/Components/V5Filters';
import { useV5, V5StatesWrapper } from '../../ContextLib/CoreConsumer/v5Contexts';
import ScheduleCont from './Calendar/Schedule';
import LocatorIntegration from './LocatorIntegration';
import MappingIntegration from '../../Components/MappingIntegration/MappingIntegration';
import CustomMessages from './CustomMessages';

const SELECT_COVER_MESSAGE = "Please select a Cover";
const SELECT_LOCATION_MESSAGE = "Please select a Location";

export default function WSMaster(props) {
    return (
        <V5StatesWrapper>
            <WS {...props}/>
        </V5StatesWrapper>
    );
}

const WS = (props) => {   
    const { c, f } = useV5();
    const [mappings, setMappings] = useState([]);
            
    const triggerUpdates = React.useReducer(()=>({}))[1];   
    f.triggerUpdates = triggerUpdates;
    c.triggerUpdates = triggerUpdates;

    //useEffect(()=> {
    //    if (f.coverKey > 0) c.GetScopeData(f.coverKey);
    //}, [f.coverKey]);

    const getPracticeCoverMappings = async () => {
        const url = `${process.env.REACT_APP_LOCATOR_INTEGRATION_API}/PracticeCoverMapping/List`;
    
        await axios.get(url)
            .then(({ data, status }) => {
                if (status === 200) {
                    setMappings(data.mapping);
                }
            });
    };

    useEffect(() => {     
        if (props.menu === 15) {
            getPracticeCoverMappings();
        }

        return () => c.CancelAllRequests();
    }, []);
    
    const [cMsg, setCMsg] = useState(SELECT_COVER_MESSAGE);
    const [lackingParameter, setLackingParameter] = useState(false);

    const init = () => {
        let m = "";
        let lacking = false;
        if (props.menu !== 26)
        {
            if (f.coverKey == 0) {
                m = SELECT_COVER_MESSAGE;
            } else if (f.locations.length == 0) {
                m = "The selected Cover has no Location";
                lacking=true;
            } else if (props.menu === 25 && f.locKey === 0) {
                m = SELECT_LOCATION_MESSAGE;
                lacking = true;
            } else if (f.providers.length == 0 && props.menu !== 15) {
                if (f.locKey > 0) {
                    m = "The selected Location has no Provider";
                } else {
                    m = "The selected Cover has no Provider";
                }  
                lacking=true; 
            }
        }
        

        setCMsg(m);
        setLackingParameter(lacking);
    };

    useEffect(()=>{
        init();
    }, [f.coverKey, f.locKey, f.locations, f.providers]);
    
    return (        
        <div className="ws-master">
            <div className="ws-header">
                <V5Filters menuType={props.menu} />
            </div>
            <div className="ws-body">
                {f.coverKey==0 || lackingParameter? 
                    <>
                        <div className="reporting-box">
                            <Box p={2} bgcolor="background.paper" className="page-header"> 
                                <Typography variant="h5">
                                    {props.menu == 11 && "Appointment Types"}
                                    {props.menu == 12 && "Insurance Types"}
                                    {props.menu == 13 && "Patient Information"}
                                    {props.menu == 14 && "General"}
                                    {props.menu == 15 && "Locator Integration"}
                                    {props.menu == 16 && "Schedule"}
                                    {props.menu == 25 && "Appointment Type Mapping"}
                                    {props.menu == 26 && "Custom Message"}
                                </Typography>
                            </Box>
                        </div>
                        <Divider />
                        <div className="select-cover-message">
                            <h2>{cMsg}</h2>
                        </div>                    
                        <hr/>
                    </>
                :
                <>
                    {props.menu === 11 && <AppointmentTypes />}
                    {props.menu === 12 && <InsuranceTypes />}
                    {props.menu === 13 && <PatientInfo />}
                    {props.menu === 15 && <LocatorIntegration mappings={mappings} getMappings={getPracticeCoverMappings} />}
                    {props.menu === 16 && <ScheduleCont menuOnchange={props.menuOnchange} />}
                    {props.menu === 14 && <General />}
                    {props.menu === 25 && <MappingIntegration />}
                    {props.menu === 26 && <CustomMessages />}
                </>
                }                    
            </div>
        </div>
    );
};