import axios from 'axios';
import MaterialTable from "material-table";
import React , { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from "@material-ui/icons/Search";
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { withStyles } from '@material-ui/core/styles';

import './Coverlookup.scss';
import Constants from '../../constants';
import { useUIContext } from '../../ContextLib/contextHooks';
import { Severity } from '../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import Storage from '../../storage';

export default function AddLocationProvider() {
    let componentId = "AddLocationProvider";
    const ui = useUIContext(componentId);
    const [searchTypeID, setSearchTypeID] = useState("coverkey");
    const [searchInput, setSearchInput] = useState("");
    const [CoverKey, setCoverKey] = useState(0);

    const [coverData, setCoverData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [accountData, setAccountData] = useState([]);

    const [locOrgName, setLocOrgName] = useState('');
    const [locTitle, setLocTitle] = useState('');
    const [locFirstName, setLocFirstName] = useState('');
    const [locLastName, setLocLastName] = useState('');
    const [locAddress1, setLocAddress1]  = useState('');
    const [locAddress2, setLocAddress2]  = useState('');
    const [locCity, setLocCity] = useState('');
    const [locState, setLocState] = useState('AL');
    const [locZip, setLocZip] = useState('');
    const [locFrontLine, setLocFrontLine] = useState('');
    const [locFrontLineExt, setLocFrontLineExt] = useState('');
    const [locFax, setLocFax] = useState('');
    const [locEmail, setLocEmail] = useState('');

    const [acctTitle, setAcctTitle] = useState('');
    const [acctFirstName, setAcctFirstName] = useState('');
    const [acctMI, setAcctMI] = useState('');
    const [acctLastName, setAcctLastName] = useState('');
    const [acctSpeciality, setAcctSpeciality] = useState('');
    const [acctDegree, setAcctDegree] = useState('DDS');
    const [acctFrontLine, setAcctFrontLine] = useState('');
    const [acctFrontLineExt, setAcctFrontLineExt] = useState('');
    const [acctInsLine, setAcctInsLine] = useState('');
    const [acctInsLineExt, setAcctInsLineExt] = useState('');
    const [acctBackLine, setAcctBackLine] = useState('');
    const [acctBackLineExt, setAcctBackLineExt] = useState('');
    const [acctFax, setAcctFax] = useState('');
    const [acctEmail, setAcctEmail] = useState('');
    const [acctPmsProviderID, setAcctPmsProviderID] = useState('CompuLink');

    const [openLocationDrawer, setOpenLocationDrawer] = useState(false);
    const [openAccountDrawer, setOpenAccountDrawer] = useState(false);

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 250,
          fontSize: theme.typography.pxToRem(16),
          border: '1px solid #dadde9',
          fontFamily:'Noto Sans, sans-serif',
        },
      }))(Tooltip)

    const locationColumns = [
        { field: 'locKey', title: 'LocKey', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'orgName', title: 'Org Name', width: 150, editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.orgName} placement="top-start"><span>{dataRow.orgName}</span></HtmlTooltip>}},
        //{ field: 'title', title: 'Title', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'firstName', title: 'First Name', width: 150 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'lastName', title: 'Last Name', width: 150 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'address1', title: 'Address 1', width: 150, editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.address1} placement="top-start"><span>{dataRow.address1}</span></HtmlTooltip>}},
        { field: 'address2', title: 'Address 2', width: 150, editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.address2} placement="top-start"><span>{dataRow.address2}</span></HtmlTooltip>}},
        { field: 'city', title: 'City', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'state', title: 'State', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'zip', title: 'ZIP Code', width: 130, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'frontLine', title: 'Front Line', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'frontLineExt', title: 'Front Line Ext', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        // { field: 'fax', title: 'Fax', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'email', title: 'Email Address', width: 200, editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.email} placement="top-start"><span>{dataRow.email}</span></HtmlTooltip>}},
    ];

    const accountColumns = [
        { field: 'acctKey', title: 'AcctKey', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'title', title: 'Title', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'firstName', title: 'First Name', width: 150 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'mi', title: 'MI', width: 100 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'lastName', title: 'Last Name', width: 150 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'speciality', title: 'Speciality', width: 150, editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.speciality} placement="top-start"><span>{dataRow.speciality}</span></HtmlTooltip>}},
        { field: 'degree', title: 'Degree', width: 150, editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.degree} placement="top-start"><span>{dataRow.degree}</span></HtmlTooltip>}},
        { field: 'frontLine', title: 'Scheduling', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'frontLineExt', title: 'Scheduling Ext', width: 135, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'insLine', title: 'Admin/Ins', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'insLineExt', title: 'Admin/Ins Ext', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'backLine', title: 'Back Line', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'backLineExt', title: 'Back Line Ext', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        // { field: 'fax', title: 'Fax', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'email', title: 'Email Address', width: 200, editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.email} placement="top-start"><span>{dataRow.email}</span></HtmlTooltip>}},
    ];

    const stateOptions = [
        {key: "AL", value: "AL"}, {key: "AK", value: "AK"}, {key: "AZ", value: "AZ"}, {key: "AR", value: "AR"}, {key: "CA", value: "CA"}, {key: "CO", value: "CO"}, {key: "CT", value: "CT"}, {key: "DE", value: "DE"},
        {key: "FL", value: "FL"}, {key: "GA", value: "GA"}, {key: "HI", value: "HI"}, {key: "ID", value: "ID"}, {key: "IL", value: "IL"}, {key: "IN", value: "IN"}, {key: "IA", value: "IA"}, {key: "KS", value: "KS"}, 
        {key: "KY", value: "KY"}, {key: "LA", value: "LA"}, {key: "ME", value: "ME"}, {key: "MD", value: "MD"}, {key: "MA", value: "MA"}, {key: "MI", value: "MI"}, {key: "MN", value: "MN"}, {key: "MS", value: "MS"}, 
        {key: "MO", value: "MO"}, {key: "MT", value: "MT"}, {key: "NE", value: "NE"}, {key: "NV", value: "NV"}, {key: "NH", value: "NH"}, {key: "NJ", value: "NJ"}, {key: "NM", value: "NM"}, {key: "NY", value: "NY"}, 
        {key: "NC", value: "NC"}, {key: "ND", value: "ND"}, {key: "OH", value: "OH"}, {key: "OK", value: "OK"}, {key: "OR", value: "OR"}, {key: "PA", value: "PA"}, {key: "RI", value: "RI"}, {key: "SC", value: "SC"}, 
        {key: "SD", value: "SD"}, {key: "TN", value: "TN"}, {key: "TX", value: "TX"}, {key: "UT", value: "UT"}, {key: "VT", value: "VT"}, {key: "VA", value: "VA"}, {key: "WA", value: "WA"}, {key: "WV", value: "WV"}, 
        {key: "WI", value: "WI"}, {key: "WY", value: "WY"}   
    ];

    const searchOptions = [
        {key : "covername" , value : "Cover Name"},
        {key : "coverkey" , value : "Cover Key"}
    ];

    const pmsOptions = [
        {key : "CompuLink" , value : "CompuLink"},
        {key : "EyeCom" , value : "EyeCom"},
        {key : "Lytec 98-XE" , value : "Lytec 98-XE"},
        {key : "MediSoft 5.55+" , value : "MediSoft 5.55+"},
        {key : "OfficeMate" , value : "OfficeMate"},
        {key : "OPTO" , value : "OPTO"},
        {key : "Maxim Eyes" , value : "Maxim Eyes"},
        {key : "RLI" , value : "RLI"},
        {key : "Medical Manager" , value : "Medical Manager"},
        {key : "Sure Practice" , value : "Sure Practice"},
        {key : "EZ Frame Platinum" , value : "EZ Frame Platinum"},
        {key : "Versa Vision" , value : "Versa Vision"},
        {key : "Misys" , value : "Misys"},
        {key : "Altapoint 4+" , value : "Altapoint 4+"},
        {key : "DentalVision" , value : "DentalVision"},
        {key : "EagleSoft" , value : "EagleSoft"},
        {key : "Other" , value : "Other"}
    ];

	const degreesOptions = [
        {key : "DDS" , value : "DDS"},
        {key : "DO" , value : "DO"},
		{key : "MD" , value : "MD"},
		{key : "OD" , value : "OD"},
		{key : "PhD" , value : "PhD"},
		{key : "RN" , value : "RN"}
    ];

	const SpecialtiesOptions = [
        {key : "Optometry" , value : "Optometry"},
        {key : "Ophthalmology " , value : "Ophthalmology "},
		{key : "Allergy and immunology" , value : "Allergy and immunology"},
		{key : "Anesthesiology" , value : "Anesthesiology"},
		{key : "Chiropractor" , value : "Chiropractor"},
		{key : "Dentist" , value : "Dentist"},
		{key : "Dermatology" , value : "Dermatology"},
		{key : "Diagnostic radiology" , value : "Diagnostic radiology"},
		{key : "Emergency medicine" , value : "Emergency medicine"},
		{key : "Endocrinology" , value : "Endocrinology"},
		{key : "Family medicine" , value : "Family medicine"},
		{key : "Internal medicine" , value : "Internal medicine"},
		{key : "Medical genetics" , value : "Medical genetics"},
		{key : "Neurology" , value : "Neurology"},
		{key : "Obstetrics and gynecology" , value : "Obstetrics and gynecology"},
		{key : "Oncology" , value : "Oncology"},
		{key : "Orthopedics" , value : "Orthopedics"},
		{key : "Pathology" , value : "Pathology"},
		{key : "Pediatrics" , value : "Pediatrics"},
		{key : "Psychiatry" , value : "Psychiatry"},
		{key : "Radiation oncology" , value : "Radiation oncology"},
		{key : "Surgery" , value : "Surgery"},
		{key : "Urology" , value : "Urology"},


    ];

    const componentDidMount = async () => {
        const user = Storage.getItem(Constants.currUserKey);
        let redirectToLogin = false;
        if (user && user.jwt) {
          try {
            ui.ShowOverlay();
            const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
            await axios.get(jwtCheckUrl);
            ui.HideOverlay();
          }
          catch (error) {
            ui.ShowSnackbar(error, Severity.error);
            redirectToLogin = true;
          }
        } 
        else {
            redirectToLogin = true;
        }

        if (redirectToLogin) return <Redirect to={{ pathname: '/' }} />
    }       

    useEffect(() => {        
        componentDidMount();
    }, []);

    const getLocationsAndAccounts = () => {
        getCoverData();
        getLocations();
        getAccounts();
    }

    const getCoverData = async () => {
        try {
            ui.ShowOverlay();
            var request = {
                params: {
                  secretCode: `${process.env.REACT_APP_API_KEY}`,
                  queryType: searchTypeID,
                  queryString: searchInput
                }
              };

              const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getCoverLevelData`;
              const res = await axios.get(url, request);
              let d = res.data;
            
              if (res?.data.length > 0) { 
                  setCoverData(d); 
                  setCoverKey(d[0].coverKey);
                  setDefaultContactInfo(d);
                }
              else { 
                  ui.ShowSnackbar("Cover does not exist in the database.", Severity.warning); 
                  setCoverData([]);
              }


        } catch (error) {
            ui.ShowSnackbar("Invalid search parameter! Please update search value and try again.", Severity.error);
            setCoverData([]); 
        }
        ui.HideOverlay();
    }

    const getLocations = async () => {
        try {
            ui.ShowOverlay();
            var request = {
                params: {
                  secretCode: `${process.env.REACT_APP_API_KEY}`,
                  queryType: searchTypeID,
                  queryString: searchInput
                }
              };
              const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getLocationsByCover`;
              const res = await axios.get(url, request);
              let d = res.data;
              if (res?.data.length > 0) { 
                  setLocationData(d); 
              } else {
                ui.ShowSnackbar("No locations for this cover.", Severity.warning); 
                setLocationData([]); 
              }

        } catch (error) {
            ui.ShowSnackbar("Invalid search parameter! Please update search value and try again.", Severity.error);
            setLocationData([]);
        }
        ui.HideOverlay();
    }

    const getAccounts = async () => {
        try {
            ui.ShowOverlay();
            var request = {
                params: {
                  secretCode: `${process.env.REACT_APP_API_KEY}`,
                  queryType: searchTypeID,
                  queryString: searchInput
                }
              };
              const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getAccountsByCover`;
              const res = await axios.get(url, request);
              let d = res.data;
              if (res?.data.length > 0) { 
                setAccountData(d); 
              } else {
                ui.ShowSnackbar("No providers for this cover.", Severity.warning); 
                setAccountData([]); 
              }

        } catch (error) {
            ui.ShowSnackbar("Invalid search parameter! Please update search value and try again.", Severity.error);
            setAccountData([]); 
        }
        ui.HideOverlay();
    }

    const setDefaultContactInfo = (coverData) => {
        setLocEmail(coverData[0].email);
        setLocFrontLine(coverData[0].phone);
        setLocFrontLineExt(coverData[0].frontLineExt);
        setAcctEmail(coverData[0].email);
        setAcctFrontLine(coverData[0].phone);
        setAcctFrontLineExt(coverData[0].frontLineExt);
    }

    const resetLocFields = () => {
        setLocOrgName('');
        setLocTitle('');
        setLocFirstName('');
        setLocLastName('');
        setLocAddress1('');
        setLocAddress2('');
        setLocCity('');
        setLocState('AL');
        setLocZip('');
        setLocFax('');
        if (coverData) {
            setLocEmail(coverData[0].email);
            setLocFrontLine(coverData[0].phone);
            setLocFrontLineExt(coverData[0].frontLineExt);
        } else {
            setLocFrontLine('');
            setLocFrontLineExt('');
            setLocEmail('');
        }
    }

    const resetAcctFields = () => {
        setAcctTitle('');
        setAcctFirstName('');
        setAcctMI('');
        setAcctLastName('');
        setAcctSpeciality('');
        setAcctDegree('DDS');
        setAcctInsLine('');
        setAcctInsLineExt('');
        setAcctBackLine('');
        setAcctBackLineExt('');
        setAcctFax('');
        setAcctPmsProviderID('CompuLink');
        if (coverData) {
            setAcctEmail(coverData[0].email);
            setAcctFrontLine(coverData[0].phone);
            setAcctFrontLineExt(coverData[0].frontLineExt);
        } else {
            setAcctEmail('');
            setAcctFrontLine('');
            setAcctFrontLineExt('');
        }
    }

    const handelsetSearchTypeID=(e) => {  
        setSearchTypeID(e);
        setSearchInput('');
    }

    const closeLocationDrawer = () => {
        setOpenLocationDrawer(false);
        resetLocFields();
    }

    const addLocCancel = () => {
        setOpenLocationDrawer(false);
        resetLocFields();
    }

    const closeAccountDrawer = () => {
        setOpenAccountDrawer(false);
        resetAcctFields();
    }

    const addAcctCancel = () => {
        setOpenAccountDrawer(false);
        resetAcctFields();
    }

    const handleAddLocation = async() => {
        ui.ShowOverlay()
          try {
            const apiKey = `${process.env.REACT_APP_API_KEY}`
            var model = {
              coverKey: CoverKey,
              orgName: locOrgName,
            //title: locTitle,
              firstName: locFirstName,
              lastName: locLastName,
              address1: locAddress1,
              address2: locAddress2,
              city: locCity,
              state: locState,
              zip: locZip,
              frontLine: locFrontLine,
              frontLineExt: locFrontLineExt,
              fax: locFax,
              email: locEmail
            };

            const url = `${process.env.REACT_APP_COVERLOOKUP_API}/addLocation?secretCode=${apiKey}`;
            const res = await axios.post(url, model);
            
            if (res.status === 200) { 
              getLocations();
              resetLocFields();
              ui.ShowSnackbar("Location added successfully.", Severity.success);
            }
    
          } catch (error) {
            ui.ShowSnackbar("Unexpected error in adding location.", Severity.error);
          }
        setOpenLocationDrawer(false);
        ui.HideOverlay();
    }

    const handleAddAccount = async() => {
        ui.ShowOverlay()
          try {
            const apiKey = `${process.env.REACT_APP_API_KEY}`
            var model = {
              coverKey: CoverKey,
              title: acctTitle,
              firstName: acctFirstName,
              mi: acctMI,
              lastName: acctLastName,
              speciality: acctSpeciality,
              degree: acctDegree,
              frontLine: acctFrontLine,
              frontLineExt: acctFrontLineExt,
              insLine: acctInsLine,
              insLineExt: acctInsLineExt,
              backLine: acctBackLine,
              backLineExt: acctBackLineExt,
              fax: acctFax,
              email: acctEmail,
              pmsProviderID: acctPmsProviderID
            };

            const url = `${process.env.REACT_APP_COVERLOOKUP_API}/addAccount?secretCode=${apiKey}`;
            const res = await axios.post(url, model);
            
            if (res.status === 200) { 
              getAccounts();
              resetAcctFields();
              ui.ShowSnackbar("Provider added successfully.", Severity.success);
            }
    
          } catch (error) {
            ui.ShowSnackbar("Unexpected error in adding Provider.", Severity.error);
          }
        setOpenAccountDrawer(false);
        ui.HideOverlay();
    }

    return(
        <div className="location-provider">
            <Box p={2} bgcolor="background.paper">
                <Grid container justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h5">Cover Data</Typography>
                    </Grid>

                    <Divider orientation="vertical" variant="middle" flexItem/>

                    <Grid item>
                        <Typography variant="h6">  
                            Add Location and Provider
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Divider />

            <Box p={4} bgcolor="background.paper" >
                <div className="location-provider-data">
                    <ValidatorForm onSubmit={getLocationsAndAccounts} onError={errors => console.log(errors)} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2} className="search-option">
                            <Grid item>
                                <Typography variant="h6">Search By: </Typography>
                            </Grid>
        
                            <div className="search-fields">
                                <Grid item>
                                    <FormControl>
                                    <Select 
                                        id="searchType"
                                        style ={{width:"150px"}}
                                        value={searchTypeID}
                                        onChange={ e=>handelsetSearchTypeID(e.target.value)}     
                                        defaultValue='none'
                                        disableUnderline       
                                        native
                                    >
                                        {
                                        searchOptions.map((item, index) => {
                                        return <option key={index} value={item.key}>{item.value}</option>
                                        })
                                        }
                                    </Select>
                                    </FormControl>
                                </Grid>
            
                                <Divider orientation="vertical" variant="middle" flexItem/>
                            
                                <Grid item>
                                    <TextValidator
                                    style={{width:"170px"}}
                                    id="searchInput"
                                    autoComplete="off"
                                    placeholder="Search Value"
                                    value={searchInput}
                                    onInput={e => setSearchInput(e.target.value)}  
                                    validators={['required']}
                                    errorMessages={['Search Value is required']}  
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton >
                                            <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        ),
                                    }}
                                    />
                                </Grid>
                            </div>
        
                        <Grid item>
                            <Button style={{maxWidth: '100px' , minWidth: '100px'}} variant="contained" color="primary" type="submit">
                            SEARCH
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
                
                <Divider />
                { CoverKey === 0 ? 
                    <Box p={4}>
                        <Typography align="center" variant="h6">Please search for a cover</Typography>
                    </Box>
                    :
                    <>
                    <MaterialTable
                    height="200"
                    data={locationData}
                    columns={locationColumns}
                    title="Locations"
                    options={{
                        pageSize: 5,
                        pageSizeOptions: [10, 15, 20],
                        paging: true,
                        draggable: false,
                        sorting: false,
                        tableLayout: 'fixed',
                    }}
                    actions={[
                        {
                          icon: 'add',
                          tooltip: 'Add Location',
                          isFreeAction: true,
                          onClick: (event) => setOpenLocationDrawer(true)
                        }
                      ]}
                    /> 

                    <Divider className="divider-thick"/>
      
                    <MaterialTable
                        height="200"
                        data={accountData}
                        columns={accountColumns}
                        title="Providers"
                        options={{
                            pageSize: 5,
                            pageSizeOptions: [10, 15, 20],
                            paging: true,
                            draggable: false,
                            sorting: false,
                            tableLayout: 'fixed',
                        }}
                        actions={[
                            {
                              icon: 'add',
                              tooltip: 'Add Provider',
                              isFreeAction: true,
                              onClick: (event) => setOpenAccountDrawer(true)
                            }
                          ]}       
                    /> 
                    </>
                }
                </div>
            </Box>

            <Drawer anchor="right" open={openLocationDrawer} onClose={closeLocationDrawer} >
                <Box p={2} bgcolor="background.paper" style={{width: 500}}>
                    <Typography variant="h6">
                        Add Location
                    </Typography>
                    <Divider />
                    <ValidatorForm onSubmit={handleAddLocation}  onError={errors => console.log(errors)} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                <TextValidator
                                    id="locOrgName"
                                    autoComplete="off"
                                    label="Practice Name"
                                    fullWidth
                                    value={locOrgName}
                                    onInput={e => setLocOrgName(e.target.value)}  
                                    validators={['required']}
                                    errorMessages={['Practice Name is required']}       
                                    inputProps={{maxLength: 100}}             
                                    />
                            </Grid>

                            {/* <Grid item xs={2}>
                                <TextValidator
                                    id="locTitle"
                                    autoComplete="off"
                                    label="Title"
                                    fullWidth
                                    value={locTitle}
                                    onInput={e => setLocTitle(e.target.value)}     
                                    inputProps={{maxLength: 10}}         
                                    />
                            </Grid> */}

                            <Grid item xs={5}>
                                <TextValidator
                                    id="locFirstName"
                                    autoComplete="off"
                                    label="First Name"
                                    fullWidth
                                    value={locFirstName}
                                    onInput={e => setLocFirstName(e.target.value)}         
                                    validators={['required']}
                                    errorMessages={['First Name is required']}    
                                    inputProps={{maxLength: 50}}            
                                    />
                            </Grid>

                            <Grid item xs={5}>
                                <TextValidator
                                    id="locLastName"
                                    autoComplete="off"
                                    label="Last Name"
                                    fullWidth
                                    value={locLastName}
                                    onInput={e => setLocLastName(e.target.value)}         
                                    validators={['required']}
                                    errorMessages={['Last Name is required']}    
                                    inputProps={{maxLength: 50}}            
                                    />
                            </Grid>

                            <Grid item xs={10}>
                                <TextValidator
                                    id="locAddress1"
                                    autoComplete="off"
                                    label="Address 1"
                                    fullWidth
                                    value={locAddress1}
                                    onInput={e => setLocAddress1(e.target.value)}         
                                    validators={['required']}
                                    errorMessages={['Address 1 is required']}    
                                    inputProps={{maxLength: 100}}            
                                    />
                            </Grid>

                            <Grid item xs={10}>
                                <TextValidator
                                    id="locAddress2"
                                    autoComplete="off"
                                    label="Address 2"
                                    fullWidth
                                    value={locAddress2}
                                    onInput={e => setLocAddress2(e.target.value)}         
                                    inputProps={{maxLength: 100}}            
                                    />
                            </Grid>

                            <Grid item xs={10}>
                                <TextValidator
                                    id="locCity"
                                    autoComplete="off"
                                    label="City"
                                    fullWidth
                                    value={locCity}
                                    onInput={e => setLocCity(e.target.value)}            
                                    validators={['required']}  
                                    errorMessages={['City is required']}    
                                    inputProps={{maxLength: 50}}       
                                    />
                            </Grid>

                            <Grid item xs={10} style={{paddingLeft: 8}}>
                                <FormControl>
                                <InputLabel className="pageLabel" shrink>State</InputLabel>
                                    <Select 
                                        id="locState"
                                        value={locState}
                                        style ={{width:"300px"}}
                                        onChange={ e=> setLocState(e.target.value)}            
                                        native
                                        >
                                        {
                                        stateOptions.map((item, index) => {
                                        return <option key={index} value={item.key}>{item.value}</option>
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={10}>
                                <TextValidator
                                    id="locZip"
                                    autoComplete="off"
                                    label="Zip"
                                    fullWidth
                                    value={locZip}
                                    onInput={e => setLocZip(e.target.value)}            
                                    validators={['required']}  
                                    errorMessages={['Zip is required']}    
                                    inputProps={{maxLength: 12}}       
                                    />
                            </Grid>

                            <Grid item xs={8}>
                                <TextValidator
                                    id="locFrontLine"
                                    autoComplete="off"
                                    label="Phone"
                                    fullWidth
                                    value={locFrontLine}
                                    onInput={e => setLocFrontLine(e.target.value)}            
                                    validators={['required']}  
                                    errorMessages={['Phone is required']}    
                                    inputProps={{maxLength: 11}}       
                                    />
                            </Grid>

                            <Grid item xs={4}>
                                <TextValidator
                                    id="locFrontLineExt"
                                    autoComplete="off"
                                    label="Ext"
                                    value={locFrontLineExt}
                                    onInput={e => setLocFrontLineExt(e.target.value)}             
                                    inputProps={{maxLength: 10}}       
                                    />
                            </Grid>

                            <Grid item xs={10}>
                                <TextValidator
                                    id="locEmail"
                                    autoComplete="off"
                                    label="Email"
                                    fullWidth
                                    value={locEmail}
                                    onInput={e => setLocEmail(e.target.value)} 
                                    validators={['required']}  
                                    errorMessages={['Email is required']}               
                                    inputProps={{maxLength: 100}}       
                                    />
                            </Grid>

                            <Grid item xs={6}>
                                <Button variant="contained" color="primary" type="submit" fullWidth>
                                    CREATE
                                </Button>  
                            </Grid>

                            <Grid item xs={6}>
                                <Button variant="contained" type="reset" fullWidth onClick={addLocCancel}>
                                    CANCEL
                                </Button>  
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Box>
            </Drawer>

            <Drawer anchor="right" open={openAccountDrawer} onClose={closeAccountDrawer} >
                <Box p={2} bgcolor="background.paper" style={{width: 500}}>
                    <Typography variant="h6">
                        Add Provider
                    </Typography>
                    <Divider />
                    <ValidatorForm onSubmit={handleAddAccount}  onError={errors => console.log(errors)} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={1}>
                                <TextValidator
                                    id="acctTitle"
                                    autoComplete="off"
                                    label="Title"
                                    fullWidth
                                    value={acctTitle}
                                    onInput={e => setAcctTitle(e.target.value)}     
                                    inputProps={{maxLength: 10}}         
                                    />
                            </Grid>

                            <Grid item xs={5}>
                                <TextValidator
                                    id="acctFirstName"
                                    autoComplete="off"
                                    label="First Name"
                                    fullWidth
                                    value={acctFirstName}
                                    onInput={e => setAcctFirstName(e.target.value)}         
                                    validators={['required']}
                                    errorMessages={['First Name is required']}    
                                    inputProps={{maxLength: 50}}            
                                    />
                            </Grid>

                            <Grid item xs={1}>
                                <TextValidator
                                    id="acctMI"
                                    autoComplete="off"
                                    label="MI"
                                    fullWidth
                                    value={acctMI}
                                    onInput={e => setAcctMI(e.target.value)}     
                                    inputProps={{maxLength: 2}}         
                                    />
                            </Grid>

                            <Grid item xs={5}>
                                <TextValidator
                                    id="acctLastName"
                                    autoComplete="off"
                                    label="Last Name"
                                    fullWidth
                                    value={acctLastName}
                                    onInput={e => setAcctLastName(e.target.value)}         
                                    validators={['required']}
                                    errorMessages={['Last Name is required']}    
                                    inputProps={{maxLength: 50}}            
                                    />
                            </Grid>

                            <Grid item xs={10}>
                                {/* <TextValidator
                                    id="acctSpeciality"
                                    autoComplete="off"
                                    label="Speciality"
                                    fullWidth
                                    value={acctSpeciality}
                                    onInput={e => setAcctSpeciality(e.target.value)}         
                                    validators={['required']}
                                    errorMessages={['Speciality is required']}    
                                    inputProps={{maxLength: 60}}            
                                    /> */}
								<FormControl>
									<InputLabel className="pageLabel" shrink>Speciality</InputLabel>
									<Select
										id="acctSpeciality"
										value={acctSpeciality}
										style={{ width: "300px" }}
										onChange={e => setAcctSpeciality(e.target.value)}
										native
									>
										{
											SpecialtiesOptions.map((item, index) => {
												return <option key={index} value={item.key}>{item.value}</option>
											})
										}
									</Select>
								</FormControl>
                            </Grid>

                            <Grid item xs={10}>
                             {/* <TextValidator
                                    id="acctDegree"
                                    autoComplete="off"
                                    label="Degree"
                                    fullWidth
                                    value={acctDegree}
                                    onInput={e => setAcctDegree(e.target.value)}         
                                    validators={['required']}
                                    errorMessages={['Degree is required']}    
                                    inputProps={{maxLength: 10}}            
                                    /> */}
								<FormControl>
									<InputLabel className="pageLabel" shrink>Degree</InputLabel>
									<Select
										id="acctDegree"
										value={acctDegree}
										style={{ width: "300px" }}
										onChange={e => setAcctDegree(e.target.value)}
										native
									>
										{
											degreesOptions.map((item, index) => {
												return <option key={index} value={item.key}>{item.value}</option>
											})
										}
									</Select>
								</FormControl>
                            </Grid>

                            <Grid item xs={8}>
                                <TextValidator
                                    id="acctFrontLine"
                                    autoComplete="off"
                                    label="Scheduling"
                                    fullWidth
                                    value={acctFrontLine}
                                    onInput={e => setAcctFrontLine(e.target.value)}            
                                    validators={['required']}  
                                    errorMessages={['Scheduling is required']}    
                                    inputProps={{maxLength: 11}}       
                                    />
                            </Grid>

                            <Grid item xs={4}>
                                <TextValidator
                                    id="acctFrontLineExt"
                                    autoComplete="off"
                                    label="Ext"
                                    value={acctFrontLineExt}
                                    onInput={e => setAcctFrontLineExt(e.target.value)}             
                                    inputProps={{maxLength: 10}}       
                                    />
                            </Grid>

                            <Grid item xs={8}>
                                <TextValidator
                                    id="acctInsLine"
                                    autoComplete="off"
                                    label="Admin/Ins"
                                    fullWidth
                                    value={acctInsLine}
                                    onInput={e => setAcctInsLine(e.target.value)}            
                                    inputProps={{maxLength: 11}}       
                                    />
                            </Grid>

                            <Grid item xs={4}>
                                <TextValidator
                                    id="acctInsLineExt"
                                    autoComplete="off"
                                    label="Ext"
                                    value={acctInsLineExt}
                                    onInput={e => setAcctInsLineExt(e.target.value)}             
                                    inputProps={{maxLength: 10}}       
                                    />
                            </Grid>

                            <Grid item xs={8}>
                                <TextValidator
                                    id="acctBackLine"
                                    autoComplete="off"
                                    label="Back Line"
                                    fullWidth
                                    value={acctBackLine}
                                    onInput={e => setAcctBackLine(e.target.value)}            
                                    inputProps={{maxLength: 11}}       
                                    />
                            </Grid>

                            <Grid item xs={4}>
                                <TextValidator
                                    id="acctBackLineExt"
                                    autoComplete="off"
                                    label="Ext"
                                    value={acctBackLineExt}
                                    onInput={e => setAcctBackLineExt(e.target.value)}             
                                    inputProps={{maxLength: 10}}       
                                    />
                            </Grid>

                            {/* <Grid item xs={10}>
                                <TextValidator
                                    id="acctFax"
                                    autoComplete="off"
                                    label="Fax"
                                    fullWidth
                                    value={acctFax}
                                    onInput={e => setAcctFax(e.target.value)}            
                                    inputProps={{maxLength: 11}}       
                                    />
                            </Grid> */}

                            <Grid item xs={10}>
                                <TextValidator
                                    id="acctEmail"
                                    autoComplete="off"
                                    label="Email"
                                    fullWidth
                                    value={acctEmail}
                                    onInput={e => setAcctEmail(e.target.value)} 
                                    validators={['required']}  
                                    errorMessages={['Email is required']}               
                                    inputProps={{maxLength: 100}}       
                                    />
                            </Grid>

                            <Grid item xs={10} style={{paddingLeft: 8}}>
                                <FormControl>
                                <InputLabel className="pageLabel" shrink>Practice Management System</InputLabel>
                                    <Select 
                                        id="acctPmsProviderID"
                                        value={acctPmsProviderID}
                                        style ={{width:"300px"}}
                                        onChange={ e=> setAcctPmsProviderID(e.target.value)}            
                                        native
                                        >
                                        {
                                        pmsOptions.map((item, index) => {
                                        return <option key={index} value={item.key}>{item.value}</option>
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <Button variant="contained" color="primary" type="submit" fullWidth>
                                    CREATE
                                </Button>  
                            </Grid>

                            <Grid item xs={6}>
                                <Button variant="contained" type="reset" fullWidth onClick={addAcctCancel}>
                                    CANCEL
                                </Button>  
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Box>
            </Drawer>
        </div>
    )
}