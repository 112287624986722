import {
  GetJ47ShadowTeam,
  UpsertJ47ShadowPMSDetailsBulkUpdate,
} from "../Util/pmsIntegrationShadMgmtApiUtil";
import { Button, Box, Popper, Fade } from "@material-ui/core";
import { PmsGridSort,PmsGridSortInt } from "../Util/PmsHelper";
import React, { useState, useEffect } from "react";
import { useUIContext } from "../../../ContextLib/contextHooks";
import { ValidatorForm } from "react-material-ui-form-validator";
import MaterialDataGrid from "../Components/Common/MaterialDataGrid";
import TextBoxWithValidator from "../Components/Common/TextBoxWithValidator";
import ShadowTeamBulkConfirmation from "./ShadowTeamBulkConfirmation";
import PmsEnums from "./../Util/PmsEnums";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

export default function ShadowCoverBulkUpdate({
  selectedShadowTeams,
  onSetDisplayChange,
}) {
  const [mockData, setMockData] = useState({
    coverKey: "",
    remoteBladeCommandDisplay: "",
    pmsUrlDisplay: "",
    selectedCoverKeyDisplay: "",
    enableRemoteBladeCommand: false,
    remoteBladeCommand: "",
    enablePmsUrl: false,
    pmsUrl: "",
  });

  const [open, setOpenPopper] = useState(false);
  const [popperSelectedId, setPopperSelectedId] = useState(0);
  const [popperAnchor, setPopperAnchor] = useState(null);

  const ui = useUIContext("v5filters");
  const [showModal, setShowModal] = useState(false);
  const [selectedRowsInGrid, setSelectedRowsInGrid] = useState([]);
  const [tmpSelectedRowsInGrid, setTmpSelectedRowsInGrid] = useState([]);
  const [listOfCoverKeys, setListOfCoverKeys] = useState([]);
  const [toBeUpdatedJ47ShadowLogins, setToBeUpdatedJ47ShadowLogins] = useState(
    []
  );

  useEffect(() => {
    setSelectedRowsInGrid(tmpSelectedRowsInGrid);
  }, [tmpSelectedRowsInGrid]);

  const HandleSortWithPopper = (v1, v2) => {
    setOpenPopper(false);
    return PmsGridSort(v1, v2);
  };
  const HandleSortWithPopperInt = (v1, v2) => {
    setOpenPopper(false);
    return PmsGridSortInt(v1, v2);
  };
  const tableColumns = [
    {
      field: "coverKey",
      headerName: "Cover Key",
      type: "number",
      width: 180,
      sortComparator: HandleSortWithPopperInt,
      renderCell: (params) => RenderRowWithClick(params),
    },
    {
      field: "pms",
      headerName: "PMS",
      width: 210,
      sortComparator: HandleSortWithPopper,
      renderCell: (params) => RenderRowWithClick(params),
    },
    {
      field: "login",
      headerName: "Shadow Login",
      width: 240,
      sortComparator: HandleSortWithPopper,
      renderCell: (params) => RenderRowWithClick(params),
    },
    {
      field: "shadowKey",
      headerName: "Shadow Key",
      width: 150,
      type: "number",
      sortComparator: HandleSortWithPopperInt,
      renderCell: (params) => RenderRowWithClick(params),
    },
    {
      field: "rmb",
      headerName: "Contains RBC?",
      type: "boolean",
      width: 190,
      sortComparator: HandleSortWithPopper,
      renderCell: (params) => {
        return (
          <>
            {GetIcon(params)}
            {params.row.remoteBladeCommand !== null && (
              <Popper
                className={getPopperClassname(params.id)}
                id={params.id}
                open={open}
                anchorEl={popperAnchor}
                transition
                style={{ maxWidth: 800, minWidth: 200 }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350} id={params.id}>
                    <Box
                      sx={{
                        border: 1,
                        p: 1,
                        bgcolor: "background.paper",
                        maxWidth: 800,
                        minWidth: 200,
                        overflow: "scroll",
                        overflowY: "hidden",
                        boxShadow: "0px 0px 1px 1px #9E9E9E",
                      }}
                    >
                      {params.row.remoteBladeCommand}
                    </Box>
                  </Fade>
                )}
              </Popper>
            )}
          </>
        );
      },
    },
    {
      field: "pmsUrl",
      headerName: "Pms Url",
      width: 700,
      sortComparator: HandleSortWithPopper,
      renderCell: (params) => RenderRowWithClick(params),
    },
  ];

  const getPopperClassname = (id) => {
    if (open) {
      if (id == popperSelectedId) return `popper_show`;
    }
    return `popper_hide`;
  };

  const GetIcon = (event) => {
    if (event.formattedValue == "true")
      return (
        <CheckIcon
          onClick={(e) => handlePopperClick(e, event.id)}
          style={{ width: "100%", cursor: "pointer" }}
        />
      );
    return (
      <CloseIcon
        onClick={(e) => handlePopperClick(e, event.id)}
        style={{ width: "100%", cursor: "pointer" }}
      />
    );
  };

  const handlePopperClick = (event, id) => {
    setPopperSelectedId(id);
    setPopperAnchor(event.currentTarget);
    setOpenPopper((previousOpen) => !previousOpen);
  };

  const RenderRowWithClick = (event) => {
    if (event.colDef.type === "boolean") {
      return GetIcon(event);
    }
    return (
      <span onClick={() => setOpenPopper(false)} style={{ width: "100%" }}>
        {event.value}
      </span>
    );
  };

  useEffect(() => {
    _handleLoadShadowLogins(selectedShadowTeams.join(","));
  }, []);

  const HandleShadowTeamChange = (data) => {
    setMockData({
      ...mockData,
      remoteBladeCommandDisplay: data.remoteBladeCommand,
      selectedCoverKeyDisplay: data.coverKey,
      pmsUrlDisplay: data.pmsUrl,
    });
  };

  const setValue = (e, isCheckBox = false) => {
    const { name, value, checked } = e.target;
    let val = value;
    if (isCheckBox) val = checked;

    setMockData({
      ...mockData,
      [name]: val,
    });
  };
  const _handleLoadShadowLogins = (shadowKey) => {
    ui.ShowOverlay();
    GetJ47ShadowTeam(shadowKey, "BulkShadowKey")
      .then((resp) => {
        const newData = resp.data.map((obj) => ({
          ...obj,
          id: obj.coverKey,
          pmsUrl: obj.pmsUrl,
          rmb:
            obj.remoteBladeCommand === null || obj.remoteBladeCommand === ""
              ? false
              : true,
        }));
        setListOfCoverKeys(newData);
      })
      .catch((error) => {
        ui.ShowSnackbar(`GetJ47ShadowTeam: ${error}`, "error");
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };

  const _searchCoverInGrid = () => {
    let arr = mockData.coverKey.split(",");
    setTmpSelectedRowsInGrid(arr);
    setSelectedRowsInGrid(arr);
  };

  const HandleOpenConfirmation = () => {
    if (
      (mockData.remoteBladeCommand === "" && mockData.pmsUrl === "") ||
      selectedRowsInGrid.length === 0
    ) {
      return ui.ShowSnackbar(
        `Please add a new record to be modified first.`,
        "warning"
      );
    }

    const newData = listOfCoverKeys.filter((obj) =>
      selectedRowsInGrid.includes(obj.coverKey)
    );

    const coverKeys = [];
    newData.map((d) => coverKeys.push(d.coverKey));

    setTmpSelectedRowsInGrid(coverKeys);
    setSelectedRowsInGrid(coverKeys);
    setToBeUpdatedJ47ShadowLogins(newData);

    setShowModal(true);
  };

  const _handleBulkUpdate = async () => {
    if (
      (mockData.remoteBladeCommand === "" && mockData.pmsUrl === "") ||
      selectedRowsInGrid.length === 0
    ) {
      return ui.ShowSnackbar(
        `Please add a new record to be modified first.`,
        "warning"
      );
    }

    const postData = {
      coverKeys: tmpSelectedRowsInGrid,
      NewRemoteBladeCommand: mockData.remoteBladeCommand,
      NewPmsUrl: mockData.pmsUrl,
      DoNotUpdateRemoteBlade: !mockData.enableRemoteBladeCommand,
      DoNotUpdateRemotePmsUrl: !mockData.enablePmsUrl,
    };

    ui.ShowOverlay();
    const promises = [
      UpsertJ47ShadowPMSDetailsBulkUpdate(postData)
        .then(async () => {
          onSetDisplayChange(PmsEnums.BulkDisplay.SHADOWTEAMS);

          ui.ShowSnackbar(`Successfully UPDATED records`);
          setShowModal(false);
        })
        .catch((error) =>
          ui.ShowSnackbar(
            `UpsertJ47ShadowPMSDetailsBulkUpdate: ${error}`,
            "error"
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const ConfirmationValue = () => {
    return (
      <>
        <TextBoxWithValidator
          style={{ marginTop: "20px", marginBottom: "10px" }}
          label="New PMS Url"
          name="pmsUrl"
          value={mockData.pmsUrl || ""}
          multiline={false}
          onInput={(e) => setValue(e)}
          required={false}
          readOnly={true}
        />

        <TextBoxWithValidator
          style={{ marginTop: "0px", marginBottom: "13px" }}
          label="New Remote Blade Command"
          name="remoteBladeCommand"
          rows={4}
          value={mockData.remoteBladeCommand || ""}
          multiline={true}
          required={false}
          readOnly={true}
        />
      </>
    );
  };

  return (
    <>
      <ShadowTeamBulkConfirmation
        onCloseModal={() => setShowModal(false)}
        showModal={showModal}
        tableColumns={tableColumns}
        tableData={toBeUpdatedJ47ShadowLogins}
        remoteBladeCommand={mockData.remoteBladeCommand}
        onSubmit={_handleBulkUpdate}
        ConfirmationValue={ConfirmationValue}
      />

      <h1 className="pmstitle-title__wrapper">
        Shadow Team Bulk Operation - Cover Specific
      </h1>
      <ValidatorForm onSubmit={_handleBulkUpdate}>
        <div className="shadow-bulk__container">
          <div className="actionButton__container">
            <TextBoxWithValidator
              label="Cover Key"
              fullWidth={true}
              value={mockData.coverKey || ""}
              onInput={(e) => setValue(e)}
              name="coverKey"
              id="coverKey"
            />
            <Button
              onClick={() => _searchCoverInGrid()}
              variant="outlined"
              color="primary"
            >
              Select
            </Button>
          </div>
          <span id="bulk-span-id">
            Select a particular Cover Key or Multi Cover Key Select. (e.g.
            '4501,1234')
          </span>
          <div className="bulk-grid__wrapper">
            <MaterialDataGrid
              tableColumns={tableColumns}
              tableData={listOfCoverKeys}
              rowHeight={30}
              tableHeight={550}
              onSelectedRow={HandleShadowTeamChange}
              checkboxSelection={true}
              rowSelectionModel={tmpSelectedRowsInGrid}
              setRowSelectionModel={setSelectedRowsInGrid}
            />
          </div>
          <div className="bulk-details__wrapper">
            <span style={{ margin: "15px 0 10px 0 ", display: "block" }}>
              <strong>New Cover Specific Details</strong>
            </span>
            <div className="settings-configured">
              <TextBoxWithValidator
                label="New PMS Url"
                name="pmsUrl"
                value={mockData.pmsUrl || ""}
                multiline={false}
                required={false}
                onInput={(e) => setValue(e)}
                style={{ marginBottom: 10 }}
              />
            </div>
            <div className="settings-configured">
              <TextBoxWithValidator
                label="New Remote Blade Command"
                name="remoteBladeCommand"
                rows={4}
                value={mockData.remoteBladeCommand || ""}
                multiline={true}
                required={false}
                onInput={(e) => setValue(e)}
                style={{ marginBottom: 10 }}
              />
            </div>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 5, width: "140px" }}
              type="button"
              onClick={HandleOpenConfirmation}
            >
              Bulk Update
            </Button>
            <Button
              variant="outlined"
              style={{ width: "140px" }}
              color="secondary"
              type="button"
              onClick={() =>
                onSetDisplayChange(PmsEnums.BulkDisplay.SHADOWTEAMS)
              }
            >
              BACK
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </>
  );
}
