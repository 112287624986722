import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment/moment';


export default function RowDetailsRecall(props) {
    const data = props.row
  
    return (
      <React.Fragment>
          <Box margin={1}>
  
          <Typography variant="h6" gutterBottom component="div">
            </Typography>
            <Table size="small" aria-label="details">                        
              <TableHead>
                <TableRow>
                  <TableCell>CoverKey</TableCell>                  
                  <TableCell>VwRecallID</TableCell>
                  <TableCell>PmsRecallID</TableCell>
                  <TableCell>VwPatientID</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.coverKey}</TableCell>                    
                    <TableCell>{data.vwRecallID}</TableCell>
                    <TableCell>{data.pmsRecallID}</TableCell>    
                    <TableCell>{data.vwPatientID}</TableCell>             
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>VwLocationID</TableCell>                  
                  <TableCell>VwProviderID</TableCell>
                  <TableCell>RecallDate</TableCell>
                  <TableCell>VwRecallReasonID</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.vwLocationID}</TableCell>                    
                    <TableCell>{data.vwProviderID}</TableCell>
                    <TableCell>{data.recallDate}</TableCell>    
                    <TableCell>{data.vwRecallReasonID}</TableCell>             
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>Deleted</TableCell>                  
                  <TableCell>CreatedDate</TableCell>
                  <TableCell>LastModifiedDate</TableCell>
                  <TableCell></TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.deleted ? 'Yes' : 'No'}</TableCell>                    
                    <TableCell>{data.createdDate}</TableCell>
                    <TableCell>{data.lastModifiedDate}</TableCell>    
                    <TableCell></TableCell>             
                  </TableRow>                     
              </TableBody>
            </Table>                     
            <br/>
          </Box>      
       </React.Fragment>
    );   

}
