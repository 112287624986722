import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import TextBoxWithValidator from "../Common/TextBoxWithValidator";
import MaterialTableSelect from "./../Common/MaterialTableSelect";
import { getShadowDbAliases } from "../../Util/pmsIntegrationApiUtil";

export default function MappingPageSdbAndCoverKey({
  titlePage,
  onCoverKeyChange,
  propCover,
  onShadowDbChange,
  propShadowDb,
  onSearchByCoverKeyAndSdb,
  isOngoingRequest,
  doNotShowShadowDbList = false,
}) {
  const [shadowDbs, setShadowDbs] = useState([]);

  useEffect(() => {
    getShadowDbAliases(false).then((resp) => {
      setShadowDbs(["eden", ...resp.data]);
    });
  }, []);

  const disableEnterCoverKeyButton = () => {
    if (isOngoingRequest) return true;
    if (doNotShowShadowDbList) return propCover === "" ? true : false;
    return propCover === "" || propShadowDb === "" ? true : false;
  };

  return (
    <>
      <h1 className="pmstitle-title__wrapper">{titlePage}</h1>
      <ValidatorForm
        onSubmit={onSearchByCoverKeyAndSdb}
        onError={(errors) => console.log(errors)}
      >
        {!doNotShowShadowDbList && (
          <MaterialTableSelect
            size="medium"
            data={shadowDbs}
            label="Shadow Database"
            onChange={onShadowDbChange}
            value={propShadowDb}
            style={{ minWidth: 500 }}
          />
        )}

        <TextBoxWithValidator
          style={{ minWidth: 500, margin: "10px 0" }}
          label="Cover Key"
          value={propCover || ""}
          onInput={(e) => onCoverKeyChange(e.target.value)}
          name="coverKey"
          id="coverKey1"
        />

        <Button
          type="submit"
          variant="outlined"
          color="primary"
          disabled={disableEnterCoverKeyButton()}
        >
          Enter CoverKey
        </Button>
      </ValidatorForm>
    </>
  );
}
