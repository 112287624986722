import ViewIcon from "@material-ui/icons/Cancel";
import { Button, Modal } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import ToolTipWithIcon from "./../Components/Common/ToolTipWithIcon";
import MaterialTextField from "./../Components/Common/MaterialTextField";
import TextBoxWithValidator from "../Components/Common/TextBoxWithValidator";

export default function ShadowMgmtModalForm({
  showModal,
  onCloseModal,
  isActionAdd = true,
  onAddNewLogin,
  selectedShadowTeam,
}) {
  const [mockJ47ShadowLoginData, setMockJ47ShadowLoginData] = useState({
    login: "",
    pwd: "",
    description: "",
    shadowKey: "",
    updateInstallationPath: "",
    priorUpdateInstallationPath: "",
    updateInstallationPathDateModified: "",
  });

  useEffect(() => {
    if (showModal === false) return;

    if (isActionAdd === false)
      return setMockJ47ShadowLoginData(selectedShadowTeam);

    var newObj = {
      login: "",
      pwd: "",
      description: "",
      shadowKey: "",
      updateInstallationPath: "",
      priorUpdateInstallationPath: "",
      updateInstallationPathDateModified: "",
    };
    setMockJ47ShadowLoginData(newObj);
  }, [showModal]);

  const HandleSubmitForm = () => onAddNewLogin(mockJ47ShadowLoginData);

  const setValue = (e) => {
    const { name, value } = e.target;
    setMockJ47ShadowLoginData({
      ...mockJ47ShadowLoginData,
      [name]: value,
    });
  };

  const RenderTitleBar = () =>
    isActionAdd === true
      ? "Add New Record"
      : `Detail of ${selectedShadowTeam.login}`;

  return (
    <Modal
      open={showModal}
      onClose={() => onCloseModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="Shadow-modal__wrapper"
    >
      <div className="Shadow-modal__container">
        <div className="Shadow-modal-title__container">
          <span style={{ cursor: "default" }}>{RenderTitleBar()}</span>
          <div>
            <ToolTipWithIcon
              title="Close"
              Icon={ViewIcon}
              onClick={() => onCloseModal(false)}
            />
          </div>
        </div>
        <div className="Shadow-modal-body__container">
          <ValidatorForm
            onSubmit={HandleSubmitForm}
            onError={(errors) => console.log(errors)}
          >
            <TextBoxWithValidator
              name="login"
              label="Login"
              value={mockJ47ShadowLoginData.login || ""}
              onInput={(e) => setValue(e)}
              readOnly={!isActionAdd && true}
            />
            {!isActionAdd && (
              <MaterialTextField
                data={mockJ47ShadowLoginData.shadowKey || ""}
                label="ShadowKey"
                readOnly={true}
              />
            )}
            <TextBoxWithValidator
              label="Password"
              type="password"
              name="pwd"
              value={mockJ47ShadowLoginData.pwd || ""}
              onInput={(e) => setValue(e)}
            />
            <label className={isActionAdd ? "pms-sm-description" : undefined}>
              <TextBoxWithValidator
                label="Description"
                name="description"
                value={mockJ47ShadowLoginData.description || ""}
                onInput={(e) => setValue(e)}
                id="shadow-team-description"
              />
            </label>

            {!isActionAdd && (
              <>
                <TextBoxWithValidator
                  label="Installation Path"
                  name="updateInstallationPath"
                  multiline={true}
                  rows={4}
                  value={mockJ47ShadowLoginData.updateInstallationPath || ""}
                  onInput={(e) => setValue(e)}
                  required={false}
                />
                <TextBoxWithValidator
                  label="Prior Path"
                  name="PriorPath"
                  multiline={true}
                  rows={4}
                  value={
                    mockJ47ShadowLoginData.priorUpdateInstallationPath || ""
                  }
                  onInput={(e) => setValue(e)}
                  required={false}
                  readOnly={true}
                />

                <MaterialTextField
                  data={
                    mockJ47ShadowLoginData.updateInstallationPathDateModified ||
                    ""
                  }
                  label="Path Modified on UTC"
                  readOnly={true}
                />
              </>
            )}
            <div className="Shadow-modal-button__container">
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                style={{ marginRight: 5 }}
              >
                Save
              </Button>
              <Button
                onClick={() => onCloseModal(false)}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </div>
    </Modal>
  );
}
