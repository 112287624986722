import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment/moment';


export default function RowDetailsAppointment(props) {
    const data = props.row
  
    return (
      <React.Fragment>
          <Box margin={1}>
  
          <Typography variant="h6" gutterBottom component="div">
            </Typography>
            <Table size="small" aria-label="details">
            <TableHead>
                <TableRow>
                  <TableCell>ApptStatus</TableCell>                  
                  <TableCell>ApptType</TableCell>
                  <TableCell>CoverKey</TableCell>
                  <TableCell>CreatedDate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.apptStatus}</TableCell>                    
                    <TableCell>{data.apptType}</TableCell>
                    <TableCell>{data.coverKey}</TableCell>
                    <TableCell>{data.createdDate}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>ICD1</TableCell>                  
                  <TableCell>ICD2</TableCell>
                  <TableCell>ICD3</TableCell>
                  <TableCell>Deleted</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.icD1}</TableCell>                    
                    <TableCell>{data.icD2}</TableCell>
                    <TableCell>{data.icD3}</TableCell>
                    <TableCell>{data.deleted ? 'Yes' : 'No'}</TableCell>                  
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>                
                  <TableCell>CPT1</TableCell>
                  <TableCell>CPT2</TableCell>
                  <TableCell>CPT3</TableCell>  
                  <TableCell>LastModifiedDate</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>                  
                    <TableCell>{data.cpT1}</TableCell>
                    <TableCell>{data.cpT2}</TableCell>    
                    <TableCell>{data.cpT3}</TableCell>      
                    <TableCell>{data.lastModifiedDate}</TableCell>         
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>Length</TableCell>                  
                  <TableCell>PmsAppointmentID</TableCell>
                  <TableCell>ShowedUp</TableCell>
                  <TableCell>SpendAmt</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.length}</TableCell>                    
                    <TableCell>{data.pmsAppointmentID}</TableCell>
                    <TableCell>{data.showedUp ? 'Yes' : 'No'}</TableCell>    
                    <TableCell>{data.spendAmt}</TableCell>             
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>StartDate</TableCell>                  
                  <TableCell>StartTime</TableCell>
                  <TableCell>VwAppointmentID</TableCell>
                  <TableCell>VwPatientID</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.startDate}</TableCell>                    
                    <TableCell>{data.startTime}</TableCell>
                    <TableCell>{data.vwAppointmentID}</TableCell>    
                    <TableCell>{data.vwPatientID}</TableCell>             
                  </TableRow>                     
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>VwLocationID</TableCell>                  
                  <TableCell>VwProviderID</TableCell>
                  <TableCell>VwResourceID</TableCell>
                  <TableCell>VwReasonID</TableCell>  
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>{data.vwLocationID}</TableCell>                    
                    <TableCell>{data.vwProviderID}</TableCell>
                    <TableCell>{data.vwResourceID}</TableCell>    
                    <TableCell>{data.vwReasonID}</TableCell>             
                  </TableRow>                     
              </TableBody>
            </Table>                     
            <br/>
          </Box>      
       </React.Fragment>
    );   

}