import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

export default function MaterialCheckBox({
  disabled = false,
  onChange,
  value,
  name,
  label,
}) {
  const GetCheckBoxValue = (data) =>
    data === true || data === "True" ? true : false;

  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          color="primary"
          onChange={onChange}
          checked={GetCheckBoxValue(value)}
          name={name}
        />
      }
      label={label}
    />
  );
}
