import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRequest, useUIContext, useV5Request } from "../contextHooks";
import { distinct, arraySort, getRefDate } from "../Core/coreLib";
import { CoverLocAcctReq } from "../Core/v5Core";
import { Severity } from "./Components/SnackbarMessage";


export const V5View = {
    schedules: false,
    utility: false,
    legacyData: false
};

export class V5ProviderContextObject extends CoverLocAcctReq {    
    legacyData = [];

    constructor(triggerUpdates, objMigrationLog) {
        super(objMigrationLog);
        this.strVersion = (objMigrationLog.version ?? 0) == 0 ? "Not set" : objMigrationLog.version.toString();
        this.dtLastSuccessfulImport = objMigrationLog.lastSuccessfulImport;
        this.triggerUpdates = triggerUpdates;
    }

    get strRemarks() {
        return this.hasActiveLegacyEntries ? "Active legacy entries detected" : "No issue found";
    }

    get hasActiveLegacyEntries() {
        return this.legacyData?.length > 0;
    }
};

export class V5CoverContextObject extends CoverLocAcctReq {
    arrProviders = [];
    view = {...V5View, schedules: true};    
    schedules_getData = null;
    hideV5Entries = false;    
    triggerUpdates = null;
    
    get isBusy() {
        return this.arrProviders.some(x => x.isBusy);
    }

    get nProviders() {
        return this.arrProviders.length;
    }

    get strStatus() {
        return `Retrieved ${this.nProviders} provider data`;
    }

    get nVersion() {
        const arr = distinct(this.arrProviders.map(x=>x.strVersion));
        return arr?.length > 1 ? `[${arr.join(", ")}]` : 
                arr?.length == 1 ? `[${arr[0]}]` : "";
    }

    get strRemarks() {
        return this.arrProviders.some(x => x.hasActiveLegacyEntries) ? "Active legacy entries detected" : "No issue found";
    }

    get hasActiveLegacyEntries() {
        return this.arrProviders.find(x=>x.HasActiveLegacyEntries);
    }

    get coverAllGood() {
        return this.arrProviders.every(x => x.strVersion == '5' && !x.hasActiveLegacyEntries);
    }

    GetCurrentProvider(f) {
        return this.arrProviders.find(x => x.locKey == f.locKey && x.acctKey == f.acctKey);
    }

    Clear() {
        this.arrProviders = [];
        super.Clear();
    }

    async GetScopeData(coverKey) {
        const params = {
            coverKey: coverKey,
            locKey: 0,
            acctKey: 0,
            refDate: getRefDate()
        };

        const res = await this.GetData_UseV5Result("getWSV5MigrationLogs", params);
        if (res?.info.success) {
            this.arrProviders = (res.data?.wsV5MigrationLogs ?? []).map(x => {
                const provider = new V5ProviderContextObject(this.triggerUpdates, x);
                provider.legacyData = res.data.legacyConfigSummary.filter(y => y.locKey == x.locKey && y.acctKey == x.acctKey);
                return provider;
            });

            this.arrProviders.forEach(x => x.triggerUpdates());
        }
        return res.info;
    }

    CancelAllRequests() {
        this.arrProviders.forEach(x => x.CancelAllRequests());
    }
};

export class V5FiltersContextObject extends CoverLocAcctReq {
    covers = [];
    locations = [];
    providers = [];
    validAppointmentTypes = [];
    activeAppointmentTypes = [];
    displayProvidersByLocation = false;
    displayLocationsByProvider = false;
    triggerUpdates = null;

    get arrDisplayLocations() { 
        var tmp = [{locKey: 0, orgName: 'All Locations'}];
        return tmp.concat(this.locations.filter(x => !this.displayLocationsByProvider || (x.acctKey == this.acctKey)));
    };
    
    get arrDisplayProviders() {  
        var tmp = [{acctKey: 0, orgName: 'All Providers'}];
        return tmp.concat(this.providers.filter(x => !this.displayProvidersByLocation || (x.locKeys.split(',').includes(this.locKey.toString()))));
    };

    get noCover() {
        return this.coverKey == 0;
    }

    get onCoverLevel() {
        return this._getLevel() == 1;
    }

    get onSubLevel() {
        return this._getLevel() == 2;
    }

    get onProviderLevel() {
        return this._getLevel() == 3;
    }

    get strText() {
        const c = this.covers.find(x=>x.coverKey==this.coverKey);
        const l = this.locations.find(x=>x.locKey==this.locKey);
        const p = this.providers.find(x=>x.acctKey==this.acctKey);
        
        const strCover = `${this.coverKey}: ${c?.orgName ?? "[No OrgName]"}`;
        const strLocation = `${this.locKey}: ${l?.orgName ?? "[No OrgName]"}`;
        const _p = (`${p?.firstName ?? ""} ${p?.lastName ?? ""}`).trim();
        const strProvider = `${this.acctKey}: ${_p ? _p : "[No Name]"}`;

        return this.locKey==0 && this.acctKey==0 ? `all locations and providers under cover "${strCover}"` : 
                    this.acctKey==0 ? `all providers under location "${strLocation}"` : 
                    this.locKey==0 ? `all locations having provider "${strProvider}"` : 
                    `provider "${strProvider}", under location "${strLocation}"`;    
    }
    
    _getLevel() {
        return this.coverKey>0 && this.locKey>0 && this.acctKey>0 ? 3 :
            this.coverKey>0 && (this.locKey>0 || this.acctKey>0) ? 2 : 
            this.coverKey>0 ? 1 : 0;
    }

    async GetCovers() {
        const res = await this.GetData("getCovers", null);
        this.covers = arraySort((res.data ?? []), "orgName");

        this.triggerUpdates();
        return res.info;
    }

    async GetLocationsAndProviders() {
        const res = await Promise.all([
            this.GetData("getLocationsByCoverKey", {coverKey: this.coverKey}),
            this.GetData("getProvidersByCoverKeyExt", {coverKey: this.coverKey})  
        ]);
        if (res[0].info.success) this.locations = arraySort(res[0].data, "orgName");
        if (res[1].info.success) this.providers = arraySort(res[1].data, "lastName");    

        this.triggerUpdates();
        return res;
    }

    async GetAppointmentTypes() {
        const params = { 
            coverKey: this.coverKey,
            locKey: this.locKey,
            acctKey: this.acctKey,
            data : true,
            refDate: getRefDate()
        };
        
        const res = await this.GetData("getAppointmentTypes", params);
        this.validAppointmentTypes = arraySort(res.data ?? [], "name");
        this.activeAppointmentTypes = arraySort((res.data ?? []).filter(x => x.active), "name");

        this.triggerUpdates();
        return res;
    }
};

const reducer = (state, op) => {
    if (op.prop == "object") {
        state = op.value;
    } else if (op.index || op.index == 0) {
        state[op.prop][op.index] = op.value;
    } else {
        state[op.prop] = op.value;
    }
    return state;
};

export const V5CoverContext = React.createContext();
export const V5FiltersContext = React.createContext();

export const V5StatesWrapper = ({children}) => {
    const [f] = React.useReducer(reducer, new V5FiltersContextObject());
    const [c] = React.useReducer(reducer, new V5CoverContextObject());

    return (
        <V5FiltersContext.Provider value={f}>
            <V5CoverContext.Provider value={c}>
                {children}
            </V5CoverContext.Provider>
        </V5FiltersContext.Provider>        
    );
};

export const useV5 = () => {
    const ui = useUIContext();    
    const f = useContext(V5FiltersContext);
    const c = useContext(V5CoverContext);

    const n = useState([]);
    const onReturn = (ret) => {
        n[1](ret.info);
    };
    
    const Request = useRequest(onReturn);
    const V5Request = useV5Request(onReturn);


    const coverLocAcctDt = (refDate, data) => {
        return {
            coverKey: f.coverKey,
            locKey: f.locKey,
            acctKey: f.acctKey,
            refDate: getRefDate(refDate),
            data: data
        }        
    };
    
    const showMsg = (o) => {
        const duration = 10000;
        if (o?.messages?.length > 0) {
            ui.ShowSnackbar(o.JsxMsg(), Severity.info, duration);
            o.messages = [];
        }
        if (!o?.success && !o?.cancelled && o?.errors?.length > 0) {
            ui.ShowSnackbar(o.JsxMsg(true), Severity.error, duration);
            o.errors = [];
        }
    };

    useEffect(() => {        
        showMsg(c.info);
        showMsg(f.info);
        showMsg(n[0]);
    }, [c.info, f.info, n[0]]);

    return {
        c: c,
        f: f,
        coverLocAcctDt: coverLocAcctDt,
        n: n,
        Request: Request,
        V5Request: V5Request
    };
};
