import React,{useState,useEffect} from 'react'
import PmsEnums from './../Util/PmsEnums';
import ShadowTeamBulkUpdate from './ShadowTeamBulkUpdate';
import ShadowCoverBulkUpdate from './ShadowCoverBulkUpdate';

export default function ShadowBulkOperationLanding() {

    const [activeDisplay, setActiveDisplay] =useState(PmsEnums.BulkDisplay.SHADOWTEAMS);
    const [selectedShadowTeams,setSelectedShadowTeams] = useState([])

    const HandleDisplayChange= (n) => 
            setActiveDisplay(n);
    
    const RenderDisplay = () => {
        if(activeDisplay == PmsEnums.BulkDisplay.SHADOWTEAMS) 
        return <ShadowTeamBulkUpdate 
        onSetActiveShadowTeam={setSelectedShadowTeams}
        onSetDisplayChange={HandleDisplayChange}
        />

        if(activeDisplay == PmsEnums.BulkDisplay.COVERKEY)
        return <ShadowCoverBulkUpdate selectedShadowTeams={selectedShadowTeams}
        
        onSetDisplayChange={HandleDisplayChange}
        
        />

    }
  return (
    <>
      {RenderDisplay()}
    </>
  )
}
