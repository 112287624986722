import "../PmsConfig.scss";
import React, { useState } from "react";
import { useUIContext } from "../../../ContextLib/contextHooks";
import SnackBarHelper from "../Components/Helper/SnackBarHelper";
import MappingPageSdbAndCoverKey from "../Components/MappingPage/MappingPageSdbAndCoverKey";
import {
  GetWriteBackPreferences,
  UpsertWriteBackPreferences,
} from "../Util/pmsIntegrationApiUtil";
import MaterialCheckBox from "../Components/Common/MaterialCheckBox";
import { Divider, Grid, Button } from "@material-ui/core";

export default function WritebackSetting() {
  const ui = useUIContext("v5filters");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const [reminderWritebackPreferences, setReminderWritebackPreferences] =
    useState({
      noConfirmedApptWriteback: false,
      noLeftMessageWriteback: false,
      noCancelledApptWriteback: false,
      noProductPickupWriteback: false,
    });
  const [wsApptWritebackPreferences, setWsApptWritebackPreferences] = useState({
    doWSApptWritebackForValidatedPatient: false,
    useExistingPlaceholderPatient: false,
  });

  const [mockData, setMockData] = useState({
    coverKey: "",
    shadowDb: "",
    doneSearch: false,
  });

  const HandleUpdateSnackBar = (
    severity,
    message,
    doNotOpenSnackBar = true
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);

    if (!doNotOpenSnackBar) setOpenSnackBar(true);
  };

  const HandleCoverKeyChange = (cover) =>
    setMockData({ ...mockData, coverKey: cover });

  const _searchWriteBackSettings = async () => {
    ui.ShowOverlay();
    const promises = [
      GetWriteBackPreferences(mockData.coverKey)
        .then((resp) => {
          const { wsApptWritebackPreferences, reminderWritebackPreferences } =
            resp.data;

          if (reminderWritebackPreferences)
            setReminderWritebackPreferences(reminderWritebackPreferences);
          else
            setReminderWritebackPreferences({
              noConfirmedApptWriteback: false,
              noLeftMessageWriteback: false,
              noCancelledApptWriteback: false,
              noProductPickupWriteback: false,
            });

          if (wsApptWritebackPreferences)
            setWsApptWritebackPreferences(wsApptWritebackPreferences);
          else
            setWsApptWritebackPreferences({
              doWSApptWritebackForValidatedPatient: false,
              useExistingPlaceholderPatient: false,
            });

          setMockData({ ...mockData, doneSearch: true });

          let logMessage = `No DB settings found on: `;
          if (!reminderWritebackPreferences)
            logMessage += "ReminderWritebackPreferences ";
          if (!wsApptWritebackPreferences)
            logMessage += "wsApptWritebackPreferences";
          if (!reminderWritebackPreferences || !wsApptWritebackPreferences)
            HandleUpdateSnackBar("warning", logMessage, false);
        })
        .catch((error) =>
          HandleUpdateSnackBar(
            "error",
            `Error while getting the records: ${error}`,
            false
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const _postWriteBackSettings = async () => {
    const wbPrefObj = {
      ...wsApptWritebackPreferences,
      coverKey: Number(mockData.coverKey),
    };
    const reminderWbObj = {
      ...reminderWritebackPreferences,
      coverKey: Number(mockData.coverKey),
    };

    const obj = {
      WSApptWritebackPreferences: wbPrefObj,
      ReminderWritebackPreferences: reminderWbObj,
    };

    ui.ShowOverlay();
    const promises = [
      UpsertWriteBackPreferences(obj)
        .then((resp) => {
          HandleUpdateSnackBar(
            "success",
            `SUCCESS creating the setting records.`,
            false
          );
        })
        .catch((error) =>
          HandleUpdateSnackBar(
            "error",
            `Error while getting the records: ${error}`,
            false
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const setValueReminderWritebackPreferences = (e) => {
    const { name, checked } = e.target;

    setReminderWritebackPreferences({
      ...reminderWritebackPreferences,
      [name]: checked,
    });
  };

  const setValuewsApptWritebackPreferences = (e) => {
    const { name, checked } = e.target;

    setWsApptWritebackPreferences({
      ...wsApptWritebackPreferences,
      [name]: checked,
    });
  };

  return (
    <>
      <SnackBarHelper
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Severity={snackBarSeverity}
        AlertMessage={snackBarMessage}
      />
      <MappingPageSdbAndCoverKey
        onCoverKeyChange={HandleCoverKeyChange}
        titlePage="General Writeback Settings"
        propCover={mockData.coverKey}
        propShadowDb={mockData.shadowDb}
        doNotShowShadowDbList={true}
        onSearchByCoverKeyAndSdb={_searchWriteBackSettings}
      />

      {mockData.doneSearch && (
        <>
          <Divider style={{ margin: "25px 0 20px" }} />
          <div className="pms-ws__setting-wrapper">
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              <Grid item md={6} sm={12}>
                <div>
                  <span className="pms-ws_subTitle">Reminder</span>
                  <div>
                    <MaterialCheckBox
                      onChange={(e) => setValueReminderWritebackPreferences(e)}
                      value={
                        reminderWritebackPreferences.noConfirmedApptWriteback ||
                        false
                      }
                      name="noConfirmedApptWriteback"
                      label="Do Not write back Appt. confirmations"
                    />
                  </div>
                  <div>
                    <MaterialCheckBox
                      onChange={(e) => setValueReminderWritebackPreferences(e)}
                      value={
                        reminderWritebackPreferences.noLeftMessageWriteback ||
                        false
                      }
                      name="noLeftMessageWriteback"
                      label="Do Not write back Appt. 'left message'"
                    />
                  </div>
                  <div>
                    <MaterialCheckBox
                      onChange={(e) => setValueReminderWritebackPreferences(e)}
                      value={
                        reminderWritebackPreferences.noCancelledApptWriteback ||
                        false
                      }
                      name="noCancelledApptWriteback"
                      label="Do Not write back Appt. cancellations"
                    />
                  </div>
                  <div>
                    <MaterialCheckBox
                      onChange={(e) => setValueReminderWritebackPreferences(e)}
                      value={
                        reminderWritebackPreferences.noProductPickupWriteback ||
                        false
                      }
                      name="noProductPickupWriteback"
                      label="Do Not write back Product Pickup messages"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={12}>
                <div>
                  <span className="pms-ws_subTitle">
                    Web Scheduled Appointments
                  </span>
                  <div className="pms-member__tooltip wbSetting">
                    <MaterialCheckBox
                      onChange={(e) => setValuewsApptWritebackPreferences(e)}
                      value={
                        wsApptWritebackPreferences.doWSApptWritebackForValidatedPatient ||
                        false
                      }
                      name="doWSApptWritebackForValidatedPatient"
                      label="Write back appointments for matched & validated patients"
                    />
                    <span className="tooltiptext">
                      Unchecking turns OFF web scheduling appointment writeback!
                    </span>
                  </div>
                  <div>
                    <MaterialCheckBox
                      onChange={(e) => setValuewsApptWritebackPreferences(e)}
                      value={
                        wsApptWritebackPreferences.useExistingPlaceholderPatient ||
                        false
                      }
                      name="useExistingPlaceholderPatient"
                      label="Use existing placeholder patient (for legacy TCs) OR Create new patients if need to (for Shadow blades)"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ marginTop: 10 }}
              onClick={() => _postWriteBackSettings()}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </>
  );
}
