import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import Overlay from '../../Components/Overlay/Overlay';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 200,
  },  
}));

 



export default function SendEmailForm() {

  const classes = useStyles();
  const [coverKey, setCoverKey] = useState('');
  const [locKey, setLocKey] = useState('');
  const [email, setEmail] = useState('');
  const [communicationType, setCommunicationType] = useState(0);

  const [ecpName, setEcpName] = useState('');
  const [ecpAddr1, setEcpAddr1] = useState('');
  const [ecpAddr2, setEcpAddr2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [logoLink, setLogoLink] = useState('');
  const [newCommunicationType, setNewCommunicationType] = useState(0);
  const [showLoadingOverlay, setshowLoadingOverlay] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState('');
  const [errorSeverity, setErrorSeverity] = useState('success');
  const [locKeyList, setlocKeyList] = useState([]);
  const [coverKeyHasChange, setCoverKeyHasChange] = useState(false);

  const CoverKeyOnInput = (coverKey) => {
    setCoverKeyHasChange(true);
    setCoverKey(coverKey);
    setLocKey("");
  };
  

  const  _handleSubmit = async () => {
    //event.preventDefault();
    setshowLoadingOverlay(true);
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const data = {'coverKey':coverKey , 'LocKey': locKey, 'TestEmailAddress' : email, 'CommunicationType' : communicationType, 'NewEcp' : false};
    const url = `${process.env.REACT_APP_CAMPAIGN_API}/sendEcpTestEmail?secretCode=${apiKey}`;
    const res = await axios.post(url, data);

    if (res?.data) {
      let data = res.data;
      let message = 'Email send successfully.';
      setErrorSeverity("success");
      if (data.errors.length > 0)
      {
        message = 'Unable to find data with this cover key and loc key';
        setErrorSeverity("error");
      }
      setOpenSnackBar(true);
      setSnackbarMessage(message);
    }   
      setshowLoadingOverlay(false);
    

   }


   const  CoverKeyChange = async  () => {   
     if (coverKeyHasChange)
     {
          setCoverKeyHasChange(false);
          setshowLoadingOverlay(true);
          setlocKeyList([]);
          const apiKey = `${process.env.REACT_APP_API_KEY}`;
          const url = `${process.env.REACT_APP_CAMPAIGN_API}/searchLocKeyByCoverKey/${coverKey}?secretCode=${apiKey}`;
          const res =  await axios.get(url);
          if (res?.data) {
            let d = res.data;
            if (d.length > 0)
            {
              let datas = res.data;
              setlocKeyList(datas);
            }
            else   
            {
              let message = 'Coverkey doesn\'t exist!';
              setOpenSnackBar(true);
              setSnackbarMessage(message);
            }
      
          }
          else   
          {
            let message = 'Coverkey doesn\'t exist!';
            setOpenSnackBar(true);
            setSnackbarMessage(message);
          }

          setshowLoadingOverlay(false);
    }

  }
  
  // const section = {
  //   height: "100%",
  //   paddingTop: 5,
  //   backgroundColor: "#fff"
  // };

   function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
   };  

   const  _handleSubmit_New = async  (event) => {
      event.preventDefault();
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      setshowLoadingOverlay(true);
      const data = {'LocationName':ecpName , 'LocationAddress1': ecpAddr1, 'LocationAddress2' : ecpAddr2, 
                    'LocationCity' : city, 'LocationState' : state , 'LocationZip' : zipCode ,'LocationPhone' : phoneNumber ,
                    'TestEmailAddress' : newEmail , 'LogoURL' : logoLink, 'CommunicationType' : newCommunicationType, 'NewEcp' : true } ;
      const url = `${process.env.REACT_APP_CAMPAIGN_API}/sendEcpTestEmail?secretCode=${apiKey}`;
      const res = await axios.post(url, data);
 

    if (res?.data) {
      let data = res.data;
      let message = 'Email send successfully.';
      setErrorSeverity("success");
      if (data.errors.length > 0)
      {
        message = 'Unable to find data with this cover key and loc key';
        setErrorSeverity("error");
      }
      setOpenSnackBar(true);
      setSnackbarMessage(message);
    }   
      setshowLoadingOverlay(false);      
              
   }

  return (
    <React.Fragment>
      <Box p={2} bgcolor="background.paper">
        <Typography>
        Run Test Email: Use this to send a test email of a practice's email template for verification.
        </Typography>
      </Box>
      <Divider/>
      <Box p={3} bgcolor="background.paper">
           <Typography variant="h6" gutterBottom>
               Email Testing
            </Typography>        
            <ValidatorForm onSubmit={_handleSubmit} onError={errors => console.log(errors)}>
            <Grid container justify="space-evenly"
                       alignItems="center" spacing={3} >
              <Grid item xs={12} sm={2}>
                <TextValidator
                    required
                    id="coverKey"                
                    name="coverKey"
                    validators={['required', 'isPositive']}
                    errorMessages={['this field is required','Must be numeric and greater than zero']}                
                    fullWidth
                    value={coverKey}
                    onInput={ (e)=>CoverKeyOnInput(e.target.value)}            
                    label="Coverkey"
                />                
              </Grid>                       
                <Grid item xs={12} sm={3}>
                    <Autocomplete
                    noOptionsText={'No Lockey found'}
                    options={locKeyList.map((item) => item.value)}
                    id="locKey"
                    value={locKey}
                    onFocus={() => { CoverKeyChange(); }}
                    onChange={(event, value) => setLocKey(value)}
                    renderInput={(params) => <TextField required  {...params} label="LocKey" margin="dense"/>}
                    />       
                </Grid>                        
                <Grid item xs={12} sm={3}>
                  <TextValidator
                      required
                      id="newEmail"                
                      name="testemailaddress"
                      label="Test Email Address"
                      fullWidth      
                      value={email}
                      onInput={ e=>setEmail(e.target.value)}                                
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'email is not valid']}
                  />
              </Grid> 
              <Grid item xs={12}  sm={3}>
                  <FormControl className={classes.formControl}>

                    <InputLabel id="Communication_Type">Communication Type</InputLabel>
                    <Select
                      labelId="Communication_Type"
                      id="communication_type"
                      value={communicationType}
                      onChange={ e=>setCommunicationType(e.target.value)}              
                    >
                      <MenuItem value={0}>ALL</MenuItem>
                      <MenuItem value={1}>Reminders</MenuItem>
                      <MenuItem value={2}>Early Recall</MenuItem>
                      <MenuItem value={7}>Recall</MenuItem>
                      <MenuItem value={3}>Cancellation Retrieval</MenuItem>
                      <MenuItem value={4}>Purchase Retrieval</MenuItem>     
                      <MenuItem value={9}>DIPS CLX</MenuItem>                    
                      <MenuItem value={5}>Welcome Email</MenuItem>
                      <MenuItem value={6}>No Show</MenuItem>
                    </Select>
                    </FormControl>
                  </Grid>               
              <Grid item xs={12}  sm={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  endIcon={<Icon>send</Icon>}
                >
                   Send Email
                </Button>    
               </Grid>   
              </Grid>                 
            </ValidatorForm>
      </Box>      
      <Divider/>
      <Box p={3} bgcolor="background.paper">
      <Typography variant="h6" gutterBottom>
        New ECP
      </Typography>
      <ValidatorForm onSubmit={_handleSubmit_New} onError={errors => console.log(errors)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
            <TextValidator
                required
                id="ecpName"                
                name="ecpName"
                label="ECP Name"
                fullWidth      
                value={ecpName}
                onInput={ e=>setEcpName(e.target.value)}                                
                validators={['required']}
                errorMessages={['this field is required']}
                autoComplete="ecpName"
            />         
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextValidator
                required
                id="address1"                
                name="address1"
                label="Address 1"
                fullWidth      
                value={ecpAddr1}
                onInput={ e=>setEcpAddr1(e.target.value)}                                
                validators={['required']}
                errorMessages={['this field is required']}
                autoComplete="address1"
            />    
        </Grid>
        <Grid item xs={12}  sm={4}>
            <TextValidator
                id="address2"                
                name="address2"
                label="Address 2"
                fullWidth      
                value={ecpAddr2}
                onInput={ e=>setEcpAddr2(e.target.value)}                                
                autoComplete="address2"
            />            
        </Grid>
        <Grid item xs={12}  sm={3}>
          <TextValidator
                required
                id="city"                
                name="city"
                label="City"
                fullWidth   
                validators={['required']}
                errorMessages={['this field is required']}                   
                value={city}
                onInput={ e=>setCity(e.target.value)}                                
                autoComplete="city"
            />               
        </Grid>     
        <Grid item xs={12}  sm={3}>
            <TextValidator
                id="state"                
                name="state"
                label="State"
                fullWidth                   
                value={state}
                onInput={ e=>setState(e.target.value)}                                
                autoComplete="state"
            />           
        </Grid>           
        <Grid item xs={12}  sm={3}>
           <TextValidator
                id="zipcode"                
                name="zipcode"
                label="ZipCode"
                fullWidth                   
                value={zipCode}
                onInput={ e=>setZipCode(e.target.value)}                                
                autoComplete="zipcode"
            />             
        </Grid>    
        <Grid item xs={12}  sm={3}>
           <TextValidator
                id="phonenumber"                
                name="phonenumber"
                label="Phone Number"
                fullWidth                   
                value={phoneNumber}
                onInput={ e=>setPhoneNumber(e.target.value)}                                
                autoComplete="phonenumber"
            />               
        </Grid>   
        <Grid item xs={12}  sm={3}>
             <TextValidator
                required
                id="newEmail"                
                name="testNewemailaddress"
                label="Test Email Address"
                fullWidth      
                value={newEmail}
                onInput={ e=>setNewEmail(e.target.value)}                                
                validators={['required', 'isEmail']}
                errorMessages={['this field is required', 'email is not valid']}
            />          
        </Grid>       
        <Grid item xs={12}  sm={6}>
           <TextValidator
                required
                id="logolink"                
                name="logolink"
                label="Logo Link"
                fullWidth          
                validators={['required']}
                errorMessages={['this field is required']}                          
                value={logoLink}
                onInput={ e=>setLogoLink(e.target.value)}                                
                autoComplete="logolink"
            />            
        </Grid>       
        <Grid item xs={12}  sm={6}>
          <FormControl className={classes.formControl}>
          <InputLabel id="newLabelCommunicationType">Communication Type</InputLabel>
          <Select
            labelId="newCommunicationType"
            id="newCommunicationType"
            value={newCommunicationType}
            onChange={ e=>setNewCommunicationType(e.target.value)}  
           >
                      <MenuItem value={0}>ALL</MenuItem>
                      <MenuItem value={1}>Reminders</MenuItem>
                      <MenuItem value={2}>Early Recall</MenuItem>
                      <MenuItem value={7}>Recall</MenuItem>
                      <MenuItem value={3}>Cancellation Retrieval</MenuItem>
                      <MenuItem value={4}>Purchase Retrieval</MenuItem>  
                      <MenuItem value={9}>DIPS CLX</MenuItem>                     
                      <MenuItem value={5}>Welcome Email</MenuItem>
                      <MenuItem value={6}>No Show</MenuItem>
          </Select>
          </FormControl>
        </Grid>     
        <Grid item xs={12}  sm={12}>
          <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<Icon>send</Icon>}
          >
          Send Email
        </Button>              
        </Grid>                                                
      </Grid>   
      </ValidatorForm>   
      </Box>
      <Divider/>
      <Overlay show={showLoadingOverlay}>
         <i className="spinner-eclipse"></i>
       </Overlay>    
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => setOpenSnackBar(false)}>
            <Alert onClose={() => setOpenSnackBar(false)} severity={errorSeverity}>
            {SnackbarMessage} 
            </Alert>
      </Snackbar>             
    </React.Fragment>
  );
}