import React, { useEffect, useState } from "react";
import { PmsGridSort } from "../../Util/PmsHelper";
import MaterialDataGrid from "../Common/MaterialDataGrid";

export default function EdenWbReminderSetting({
  reminderWritebackPreferences,
}) {
  const [rowData, setRowData] = useState([]);
  const tableColumn = [
    {
      field: "noConfirmedApptWriteback",
      headerName: "NoConfirmedApptWriteback",
      width: 270,
      sortComparator: PmsGridSort,
      type: "boolean",
    },
    {
      field: "noLeftMessageWriteback",
      headerName: "NoLeftMessageWriteback",
      width: 250,
      sortComparator: PmsGridSort,
      type: "boolean",
    },
    {
      field: "noCancelledApptWriteback",
      headerName: "NoCancelledApptWriteback",
      type: "boolean",
      width: 270,
      sortComparator: PmsGridSort,
    },
    {
      field: "noProductPickupWriteback",
      headerName: "NoProductPickupWriteback",
      type: "boolean",
      width: 270,
      sortComparator: PmsGridSort,
    },
    {
      field: "createdDate",
      headerName: "CreatedDate",
      width: 200,
      sortComparator: PmsGridSort,
    },
    {
      field: "lastModifiedDate",
      headerName: "LastModifiedDate",
      width: 200,
      sortComparator: PmsGridSort,
    },
  ];

  useEffect(() => {
    if (
      reminderWritebackPreferences === undefined ||
      reminderWritebackPreferences === null
    )
      return;
    setRowData(reminderWritebackPreferences);
  }, [reminderWritebackPreferences]);

  return (
    <>
      <label>Reminder</label>
      <MaterialDataGrid
        tableHeight={200}
        tableColumns={tableColumn}
        tableData={rowData}
        onSelectedRow={undefined}
      />
    </>
  );
}
