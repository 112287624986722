import React, { useState, useEffect } from 'react';
import "./InsuranceTypes.scss";
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useRequest, useUIContext } from '../../ContextLib/contextHooks';
import MaterialTable from 'material-table';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import InsuranceTypeEntry from './InsuranceTypeEntry';
import { Severity } from '../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import { arraySort } from '../../ContextLib/Core/coreLib';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import { useV5 } from '../../ContextLib/CoreConsumer/v5Contexts';

export default function InsuranceTypes() {
    let componentId = "insuranceTypes";
    const ui = useUIContext(componentId);

    const NO_INSURANCE = "No Insurance";
    const OTHER_INSURANCE = "Other Insurance";

    let lastCoverKey = null;
    const { f, Request } = useV5();

    const initData = async () => {
        if (lastCoverKey != f.coverKey) {
            await initInsuranceData();
            //await initInsuranceData_Onboarding();
            lastCoverKey = f.coverKey;
        }
        getInsuranceData();
    };

    useEffect(()=>{
        initData();
    }, [f.coverKey, f.locKey, f.acctKey]);

    const [data, setData] = useState([]);

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 150,
            fontSize: theme.typography.pxToRem(16),
            border: '1px solid #dadde9',
            fontFamily: 'Noto Sans, sans-serif',
        },
    }))(Tooltip);

    const columns = [
        { field: 'insName', title: 'Insurance Carriers', width: 170, editable: 'never', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: "left" }, render: dataRow => { return <HtmlTooltip title={dataRow.insName} placement="top-start"><span>{dataRow.insName}</span></HtmlTooltip> } },
        { field: 'daysBlocked', title: 'Pre-Processing Time', type: 'numeric', width: 100, headerStyle: { paddingRight: 50, textAlign: "left" }, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: "left" }, render: dataRow => { return <HtmlTooltip title={dataRow.daysBlocked} placement="top-start"><span>{dataRow.daysBlocked}</span></HtmlTooltip> },
        editComponent: (props) => (
            <Input 
              defaultValue={props.value} 
              onChange={(e) => {
                let ok = !isNaN(e.target.value);
                if (ok) ok = parseInt(e.target.value) >= 0;
                if (!ok) e.target.value = 0;
                props.onChange(e.target.value);
              }}
              type="number"
              inputProps={{ "min": 0 }}
            /> 
        )},
        { field: 'orderKey', title: 'Display order', width: 100, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: "left" },
            render: dataRow => (orderKeyDisplayRender(dataRow.orderKey)),
            editComponent: props => (orderKeyEditComponentRender(props))},
        { field: 'schedule', title: 'Schedule', width: 225, editable: 'never', width: 105, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: "left" }, render: dataRow => { return <HtmlTooltip title={dataRow.schedule} placement="top-start"><span>{dataRow.schedule}</span></HtmlTooltip> } },
    ];

    const orderKeyDisplayRender = (orderKey) => {
        if (orderKey === 0) {
            return null;
        } else if (orderKey === 1) {
            return <span>Display first</span>
        }
        return <span>Display last</span>
    }

    const orderKeyEditComponentRender = (props) => {
        if (props.value === 0) {
            return null;
        }
        return <Select native value={props.value} 
                onChange={(event) => {props.onChange(event.target.value)}}>
                <option value={-1}>Display last</option>
                <option value={1}>Display first</option>
               </Select>
    }

    const initInsuranceData = async () => {
        if (f.coverKey == 0) return;
        ui.ShowOverlay();

        let model = {
            coverKey: f.coverKey
        };

        let res = await Request.modInitInsuranceData(model);

        if (res.success) {
            let d = res.data;
            if (d.Inserted > 0) {
                let msg = `Insurance data was successfully initialized. Inserted ${d.Inserted} record(s)`;                    
                ui.ShowSnackbar(msg, Severity.success, 10000);
            }            
        }
        ui.HideOverlay();
    };

    const initInsuranceData_Onboarding = async () => {
        if (f.coverKey == 0) return;
        ui.ShowOverlay();

        let model = {
            coverKey: f.coverKey
        };

        let res = await Request.modInitInsurance_FromOnboardingAsync(model);

        if (res.success) {
            let d = res.data;
            if (d.Inserted > 0) {
                let msg = `Onboarding settings were successfully added to Insurance data. Inserted ${d.Inserted} record(s)`;                    
                ui.ShowSnackbar(msg, Severity.success, 10000);
            }            
        } 
        ui.HideOverlay();
    };

    const getInsuranceData = async () => {
        if (f.coverKey == 0) return;
        ui.ShowOverlay();

        let model = {
            coverKey: f.coverKey,
            locKey: f.locKey,
            acctKey: f.acctKey
        };

        let res = await Request.modGetInsuranceData(model);

        if (res.success) {
            //let d = arraySort(res.data, "insName");
            setData(res.data);
        } 
        ui.HideOverlay();
    };

    const onAdded = ()=> {
        getInsuranceData();
    };

    const deleteInsuranceData = async (model) => {
        if (protectedRecord(model)) {
            ui.ErrorBox("This is a required Insurance carrier and cannot be deleted");
            return;
        }

        let ret = await ui.ConfirmBox("Where would you like to delete this record?", 
        "Delete Record", {
            confirmText: "From this Schedule only",
            cancelText: "From All"
        });

        if (ret == -1) {
            return;
        }

        if (ret == 0) {
            model.locKey = f.locKey;
            model.acctKey = f.acctKey;
        }

        ui.ShowOverlay();    
        let res = await Request.modDeleteInsuranceData(model);

        if (res.success) {
            getInsuranceData();
            
            let d = res.data;
            let summary = "";
            if (d.deleted.length > 0) summary += " Deleted " + d.deleted.length + " record(s)";
            
            ui.ShowSnackbar("Insurance Type was successfully deleted" + summary);
        }
        ui.HideOverlay();
    };

    const saveInsuranceData = async (model, callback) => {
        const warningMsg = (<>The entry will be applied to {f.strText}. <br /><br />Do you want to continue?</>);
        let confirmed = await ui.ConfirmBox(warningMsg);
        if (confirmed == 1) {      
            ui.ShowOverlay();    
            let res = await Request.modSaveInsuranceData(model);

            if (res.success) {
                getInsuranceData();

                let d = res.data;
                let summary = "";
                if (d.updated.length > 0) summary += " Updated " + d.updated.length + " existing record(s)";
                if (d.inserted.length > 0) summary += (summary == "" ? "" : " and") + " Added " + d.inserted.length + " new record(s)"; 
                
                if (summary !== "")
                  ui.ShowSnackbar("Insurance Type was successfully saved" + summary);
                else
                  ui.ErrorBox (<>Failed to Add Insurance to : {f.strText}<br/> Please map the provider to the location before adding Insurance.</>);
                   
            }
            ui.HideOverlay();
        }
        callback && callback();
    };

    const protectedRecord = (model) => {
        return model.insName == NO_INSURANCE || model.insName == OTHER_INSURANCE;
    };

  return (
    <div className="reporting-box">
        <Box p={2} bgcolor="background.paper" className="page-header">
            <Typography variant="h5">
                Insurance Types
            </Typography>
        </Box>
        <Divider />
          <Box p={2} bgcolor="background.paper" className="info">
            <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>
              <Grid item xs={12} sm={6}>
                <h3>  
                The following information is Patient Insurance data:
                </h3>
              </Grid>
            </Grid>        
          </Box>      
        <Divider />

        
        <div className="page-body-outline insurance-data">

            <InsuranceTypeEntry onAdded={onAdded} saveInsuranceData={saveInsuranceData} />
            <br />
            <Box>   
                <MaterialTable
                        height="200"
                        columns={columns}
                        data={data}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [10, 15, 20],
                            toolbar: false,
                            paging: true,
                            draggable: false,
                            sorting: false,
                            tableLayout: 'fixed',
                        }}
                        editable={{
                            isDeletable: rowData => !protectedRecord(rowData), 
                            onRowUpdate: saveInsuranceData,
                            onRowDelete: deleteInsuranceData
                        }}
                    />
            </Box>
        </div>
    
    </div>
)
}