import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { getObjectDiff, objectsAreEqual } from '../../ContextLib/Core/coreLib';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useRequest, useUIContext } from '../../ContextLib/contextHooks';
import Config from "./general-config.json";
import GeneralInfo from './Components/GeneralInfo';
import "./General.scss";
import { Severity } from '../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import { useV5 } from '../../ContextLib/CoreConsumer/v5Contexts';
import axios from 'axios';

export const generalFieldDisplayNames = {
    enabled: "Scheduling Enabled",
    maxSchedWeeksOut: "Max No. of Weeks Out Appts",
    sendPatWSDataInEmail: "Appointment Data in Email to Practice",
    sendWSDataInEmail: "Practice Email Security",
    mLogo: "Mobile Logo URL",
    nmImg1: "Desktop Logo URL",
    nmImg2: "",
    profilePic: "Profile Picture URL",
    endingMesg: "Ending Message",
    emailSchedTo: "Notification Email",
    providerAgnostic: "Provider Agnostic",
    limitOneAppt: "Limit One Appt"
}

export default function General () {
  const componentId = "general";
  const ui = useUIContext(componentId);
  
  const { f, Request } = useV5();

  useEffect(()=>{
    loadData();
  }, [f.coverKey, f.locKey, f.acctKey]);

  const confAndFilters = () => {
    return {...Config.fields,
      coverKey: f.coverKey,
      locKey: f.locKey,
      acctKey: f.acctKey};
  };
  
  let p = confAndFilters();

  const [rec, setRec] = useState(p);
  const [recBak, setRecBak] = useState(p);
  const [inDB, setInDB] = useState(false);
  const [inDBBak, setInDBBak] = useState(false);
  const [isDefaultConfig, setIsDefaultConfig] = useState(true);
  const [notify, setNotify] = useState("");

  //Indicates when v6 is showing a different image than what is indicated in the logo URL field.
  //This is caused by intentional legacy behavior and the user can fix this by simply uploading a logo.
  //When the two are not different, these should be set to undefined so it's not displayed.
  const [legacyMobileLogo, setLegacyMobileLogo] = useState(undefined);
  const [legacyDesktopLogo, setLegacyDesktopLogo] = useState(undefined);
  const [lastCoverLevelMobileLogo, setLastCoverLevelMobileLogo] = useState(undefined);
  const [lastCoverLevelDesktopLogo, setLastCoverLevelDesktopLogo] = useState(undefined);

  //Undefined means image is not dirty
  //Null means image will be deleted
  //A value means image will be updated
  const [mobileImage, setMobileImage] = useState(undefined);
  const [desktopImage, setDesktopImage] = useState(undefined);
  const [profileImage, setProfileImage] = useState(undefined);

  const [endingMsg, setEndingMsg] = useState(p.endingMesg);

  const diff = getObjectDiff(recBak, rec);
  if(mobileImage !== undefined && !diff.includes('mLogo')) {
    diff.push('mLogo');
  }
  if(desktopImage !== undefined && !diff.includes('nmImg1')) {
    diff.push('nmImg1');
  }
  if(profileImage !== undefined && !diff.includes('profilePic')) {
    diff.push('profilePic');
  }
  if(rec && endingMsg !== rec.endingMesg) {
    diff.push('endingMesg');
  }

  const isValidEmailAddress = (emailAddress) => {
    if (!emailAddress) return true;

    const emailAddressRegExp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" +
      "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
      "[a-zA-Z0-9])?)*$");
    return emailAddressRegExp.test(emailAddress);
  };
  
  const deleteImage = async (type) => {    

    await Request.deleteAllFile({
      coverKey: f.coverKey, 
      locKey: f.locKey, 
      acctKey: f.acctKey,
      picFileType: type
    });
  }

  const uploadImage = async (type) => {    
    let ret = "";
    let img;
    let fn;
    if (type === 1) {
      img = mobileImage;
      fn = "mobile.png";
    } else if (type === 2) {
      img = desktopImage;
      fn = "desktop.png";
    } else {
      img = profileImage;
      fn = "profile.png";
    }

    const urlAcctKey = (type === 1 || type === 2) ? 0 : f.acctKey; //for logos, always use 0 for acct, since they live at cover or loc level only
    if (img) {
      const res = await Request.postImageByDataUrl({
        coverKey: f.coverKey, 
        locKey: f.locKey, 
        acctKey: urlAcctKey,
        type: type,
        data: img,
        fn: fn
      });

      if (res.success) {
        ret = res.data;

        //Remove dirty indicator
        if (type === 1) {
          setMobileImage(undefined);
        } else if (type === 2) {
          setDesktopImage(undefined);
        } else {
          setProfileImage(undefined);
        }
      }
    }
    return ret;
  }

  const updateBucket = () => {
    if(mobileImage !== undefined) {
      if (mobileImage) uploadImage(1); else deleteImage(1);
    }
    if(desktopImage !== undefined) {
      if (desktopImage) uploadImage(2); else deleteImage(2);
    }
    if(profileImage !== undefined) {
      if (profileImage) uploadImage(3); else deleteImage(3);
    }
  }

  const onSave = async () => {
    if (f.coverKey == 0) {
      ui.MessageBox("Please select a Cover first");
      return;
    }

    if (!isValidEmailAddress(rec.emailSchedTo)) {
      ui.ShowSnackbar('Invalid \'Notification Email\' entry', Severity.error);
      return;
    }
    
    const warningMsg = (
      <>
        Changes to the following field(s) will be applied to {f.strText}.
        <br /><br />
        <b>
        {diff.map(item => generalFieldDisplayNames[item]).filter(item => item).map(item => <>{item}<br /></>)}
        </b>
        <br />
        Do you want to continue?
      </>
    );
    let confirmed = await ui.ConfirmBox(warningMsg);
    if (confirmed == 1) {      
      ui.ShowOverlay();

      updateBucket();

      let res = await Request.saveGeneral({
        ...rec, 
        endingMesg: endingMsg,
        coverKey: f.coverKey, 
        locKey: f.locKey, 
        acctKey: f.acctKey,
        diff: diff
      });

      if (res.success && res.data) {
        loadData();

        let d = res.data;
        let summary = "";
        if (d.updated.length > 0) summary += " Updated " + d.updated.length + " existing records";
        if (d.inserted.length > 0) summary += (summary == "" ? "" : " and") + " Added " + d.inserted.length + " new records"; 
        
        ui.MessageBox("Settings were successfully saved." + summary);
      } else {
        ui.ErrorBox(res.error, "Saving Settings");
      }      
      
      ui.HideOverlay();
    }
   
  };
  
  const onChange = (fieldName, value) => {
    let obj = {};
    obj[fieldName] = value;

    let newRec = {...rec, ...obj};   
    
    setRec(newRec);
    updateNotify({
      rec: newRec,
      recBak: recBak,
      inDB: inDB,
      inDBBak: inDBBak,
      isDefaultConfig: isDefaultConfig
    }, true);
  };

  const dataIsEqualToDefaultConfig = (data) => {
    let obj1 = confAndFilters();
    return objectsAreEqual(data, obj1);
  }

  const updateNotify = (s, reevaluate) => {
    let ret = "";

    if (reevaluate) {
      s.isDefaultConfig = dataIsEqualToDefaultConfig(s.rec);

      let eq2 = objectsAreEqual(s.recBak, s.rec);
      s.inDB = s.inDBBak && eq2;    

      setIsDefaultConfig(s.isDefaultConfig);
      setInDB(s.inDB);
    }

    ret += s.isDefaultConfig ? "Default Configuration" : "";
    ret += s.inDB ? (ret == "" ? "" : ". ") + "This setting has already been saved in the Database" : "";
    
    setNotify(ret);
  }
  
  //Retrieves the cover provider info that will be shown in V6, to show the user if legacy behavior is causing a different
  //logo to be shown than what is shown for the logo URL
  const fetchCoverProviderInfo = async (coverKey, locKey, mLogo, nmImg1) => {
    const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/v1/ECPDashboard/GetCoverProviderInfo?coverKey=${coverKey}&locKey=${locKey}`;
    try {
      const response = await axios.get(url);
      if(response && response.data) {
        let newLegacyMobileLogo = undefined;
        let newLegacyDesktopLogo = undefined;
        if(response.data.mobileLogo !== mLogo && response.data.mobileLogo !== lastCoverLevelMobileLogo) {
          newLegacyMobileLogo = response.data.mobileLogo;
        }
        if(response.data.desktopLogo !== nmImg1 && response.data.desktopLogo !== lastCoverLevelDesktopLogo) {
          newLegacyDesktopLogo = response.data.desktopLogo;
        }
        setLegacyMobileLogo(newLegacyMobileLogo);
        setLegacyDesktopLogo(newLegacyDesktopLogo);
      }
    } catch (err) {
      console.error(err)
    }
  };

  const loadData = async () => {    
    if (f.coverKey != 0) {
      
      ui.ShowOverlay();
      let res = await Request.getGeneral({coverKey: f.coverKey, locKey: f.locKey, acctKey: f.acctKey});

      if (res.success) {
        var p, db, eq;

        if (res.data) {      

          p = {...confAndFilters(), ...res.data};
          eq = dataIsEqualToDefaultConfig(res.data);        
          db = true;
        } else {
          p = confAndFilters();
          eq = true;
          db = false;
        }
  
        setRec(p);
        setRecBak(p);
        setInDB(db);
        setInDBBak(db);
        setIsDefaultConfig(eq);

        if(f.locKey === 0) {
          setLastCoverLevelMobileLogo(p.mLogo);
          setLastCoverLevelDesktopLogo(p.nmImg1);
        }

        await fetchCoverProviderInfo(f.coverKey, f.locKey, p.mLogo, p.nmImg1);
    
        updateNotify({
          rec: p,
          recBak: p,
          inDB: db,
          inDBBak: db,
          isDefaultConfig: eq
        });
      } else if (!res.cancelled) {
        ui.ErrorBox(res.error, "Getting Settings");
      }

      ui.HideOverlay();
    }
  };

  const enableSave = (diff.length > 0);
  return (
    <div className="reporting-box">
      <Box p={2} bgcolor="background.paper" className="page-header"> 
        <Typography variant="h5">
          General
        </Typography>
      </Box>
      <Divider />
      <Box p={2} bgcolor="background.paper" className="info">
        <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>
          <Grid item xs={12} sm={6}>
            <h3>  
            The following information is Patient Information data:
            </h3>
          </Grid>
        </Grid>        
      </Box>        
      <Divider />
     
      <Box p={2} bgcolor="background.paper" border="1px solid #e0e0e0">
        {notify && <div className="default-config">{notify}</div>}
        
        <GeneralInfo 
          model={rec} 
          setEndingMsg={setEndingMsg} 
          onChange={onChange} 
          setMobileImage={setMobileImage} 
          setDesktopImage={setDesktopImage} 
          setProfileImage={setProfileImage} 
          isValidEmailAddress={isValidEmailAddress}
          legacyDesktopLogo={legacyDesktopLogo}
          legacyMobileLogo={legacyMobileLogo}
        />
      </Box>
      <Box pt={1}>
        <Grid container direction="row" justify="center" alignItems="flex-end" spacing={3}>
          <Grid item xs={12} sm={12} style={{textAlign:"right"}}>
            <Button
              disabled={!enableSave}
              variant="contained"
              color={f.coverKey > 0 ? "primary" : "default"}
              onClick={onSave}
            >
              Save
            </Button>
          </Grid>   
        </Grid>       
      </Box>
    </div>
  );
}

