import React, { Component } from 'react';
import Constants from '../../constants';
import MainMenu from './MainMenu';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Utils from '../../utils';
import Storage from '../../storage';
import ContextProviders from '../../ContextLib/ContextProviders';

class Mainform extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectToLogin: false
        };
        this.logoUrl = Utils.getLogoUrl();
    };
    /**
     * Executes when the component has mounted to the DOM.
     */
    async componentDidMount() {
        const user = Storage.getItem(Constants.currUserKey);
        if (user && user.jwt) {
            try {
                const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
                await axios.get(jwtCheckUrl);
            }
            catch {
                this.setState(() => ({
                    redirectToLogin: true
                }));
            }

        }
        else {
            this.setState(() => ({ redirectToLogin: true }));
            return;
        }
    }

    render() {
        const {
            redirectToLogin
        } = this.state;

        if (redirectToLogin) {
            return <Redirect to={{ pathname: '/' }} />;
        }

        return (
            <ContextProviders>
                <MainMenu />
            </ContextProviders>
        );
    }
}

export default Mainform;