import React from "react";
import { TextField } from "@material-ui/core";

export default function MaterialTextField({ data, label, readOnly = false }) {
  return (
    <TextField
      fullWidth
      id="outlined-basic"
      label={label}
      variant="outlined"
      defaultValue={data}
      size="small"
      className={readOnly ? "sm-disabled" : ""}
      inputProps={{ readOnly: readOnly }}
    />
  );
}
